import { Button, Stack, Tooltip, useMediaQuery } from '@mui/material';
import { chatActions } from 'ducks';
import { useGetChatCounterpart, useRoles } from 'hooks';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

function StickyChatActions({ conversation, minimal }) {
    const isMobile = useMediaQuery((t) => t.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const { isAdmin } = useRoles();
    const counterpart = useGetChatCounterpart(conversation);

    const handleClose = (e) => {
        e.stopPropagation();
        dispatch(chatActions.removeStickyChat(conversation));
    };

    const handleMinimize = (e) => {
        e.stopPropagation();
        dispatch(chatActions.setActiveStickyChat(false));
    };

    return (
        <Fragment>
            <Stack direction="row">
                {isAdmin && !minimal && !isMobile && (
                    <Tooltip title="Go to user profile">
                        <Button component={Link} to={`/clients/${counterpart?.id}`} size="narrow">
                            <i className="fas fa-user" />
                        </Button>
                    </Tooltip>
                )}
                {!minimal && (
                    <Fragment>
                        <Tooltip title="Open full chat">
                            <Button
                                size="narrow"
                                component={Link}
                                to={`/chats/${conversation?._id}`}
                                onClick={handleMinimize}
                            >
                                <i className="fas fa-comments" />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Minimize">
                            <Button size="narrow" onClick={handleMinimize}>
                                <i className="fas fa-window-minimize" />
                            </Button>
                        </Tooltip>
                    </Fragment>
                )}
                <Tooltip title="Close">
                    <Button size="narrow" onClick={handleClose}>
                        <i className="fas fa-times" />
                    </Button>
                </Tooltip>
            </Stack>
        </Fragment>
    );
}

export default StickyChatActions;
