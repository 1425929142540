import { useState } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormLabel,
    Link,
    Stack,
    Typography,
} from '@mui/material';

import { VideoFileControl } from 'components/Exercises';
import { S3VideoBox } from 'common';
import { useS3Static } from 'hooks';

function Step6({ formMethods }) {
    const getStaticName = useS3Static();
    const [open, setOpen] = useState(false);
    const { watch } = formMethods;
    const videoURL = watch('videoURL');
    const demoVideoURL = getStaticName('movement-analysis-demo.mp4');

    return (
        <Stack spacing={4} flexGrow={1}>
            <FormLabel component="legend">
                Upload a video of yourself doing the overhead squat exercise and the single leg
                squat exercise front and side views, as shown in the{' '}
                <Button
                    sx={{ display: 'inline' }}
                    size="mini"
                    variant="outlined"
                    onClick={(e) => setOpen(true)}
                >
                    example video
                </Button>
                .
            </FormLabel>

            <VideoFileControl name="video" existingValue={videoURL} form={formMethods} pb={0} />

            <Typography variant="caption">
                <i className="fas fa-lock fa-mr-1" />
                By submitting this form I acknowledge that my data and uploaded video will be stored
                securely and privately for the sole purpose of my trainer to provide with a tailored
                fitness program, I confirm that I read and I agree to the{' '}
                <Link href="/terms-conditions-and-privacy-policy" target="_blank">
                    terms, conditions and privacy policy
                </Link>
                .
            </Typography>

            <Dialog open={open} onClose={(e) => setOpen(false)} fullWidth maxWidth="md">
                <DialogContent>
                    <S3VideoBox
                        width="100%"
                        video={demoVideoURL}
                        autoPlay
                        controls
                        useOriginal
                        noFallback
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}

export default Step6;
