export const toFormData = (obj) => {
    return Object.entries(obj).reduce((acc, [k, v]) => {
        if (!v && v !== false) return acc;
        if (Array.isArray(v)) {
            v.forEach((q) => acc.append(k, q));
        } else {
            acc.append(k, v);
        }
        return acc;
    }, new FormData());
};

export const sortByDate = (data, key = 'date', factor = 1) =>
    data?.slice().sort((a, b) => (new Date(a?.[key]) - new Date(b?.[key])) * factor);