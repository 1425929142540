import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

const PARAM_NAME = 'id';

function useRouteState(initialValue) {
    const history = useHistory();
    const currentMatch = useRouteMatch();
    const path = `${currentMatch.url}/:${PARAM_NAME}?`;
    const match = useRouteMatch(path);

    const value = match?.params?.[PARAM_NAME] || initialValue;
    const setValue = useCallback(
        (v) => history.push(`${currentMatch.url}/${v || ''}`),
        [currentMatch.url, history],
    );

    return [value, setValue];
}

export default useRouteState;
