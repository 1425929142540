import { Fragment } from 'react';
import { ResourcesRow } from 'components/Resources';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

function ResourcesTable({
    data,
    empty,
    isManaging,
    isAdmin,
    handleOpenResource,
    handleEditResource,
    handleAssignResource,
    assignLoading,
    assigned,
}) {
    return (
        <TableContainer component={Paper} variant={isManaging ? 'outlined' : undefined}>
            {empty && (
                <Typography variant="subtitle1" m={2}>
                    <i className="fas fa-search fa-mr-1" />
                    No resources are currently {assigned && isManaging ? 'assigned' : 'available'}
                </Typography>
            )}
            {!empty && (
                <Table>
                    <TableHead>
                        <TableRow sx={{ whiteSpace: 'nowrap' }}>
                            <TableCell>Title</TableCell>
                            <TableCell>Category</TableCell>
                            {isAdmin && (
                                <Fragment>
                                    <TableCell sx={{ textAlign: 'center' }}>Public</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>File</TableCell>
                                    <TableCell></TableCell>
                                </Fragment>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!data?.length && (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <i className="fas fa-search fa-mr-1" />
                                    {isManaging && !assigned
                                        ? 'No resources'
                                        : 'There are no resources with this filter'}
                                </TableCell>
                            </TableRow>
                        )}
                        {data.map((row) => (
                            <ResourcesRow
                                key={row._id}
                                row={row}
                                handleOpen={handleOpenResource(row?._id)}
                                handleEdit={handleEditResource?.(row?._id)}
                                handleAssignResource={handleAssignResource?.(row?._id, assigned)}
                                isManaging={isManaging}
                                assignLoading={assignLoading}
                                assigned={assigned}
                            />
                        ))}
                    </TableBody>
                </Table>
            )}
        </TableContainer>
    );
}

export default ResourcesTable;
