import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AUTH_PATHS, TOPBAR_HEIGHT } from '@constants';
import { useMe, useRoles } from 'hooks';
import { CreditsWidget } from 'common';

import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Menu,
    MenuItem,
    Stack,
    Box,
    useMediaQuery,
} from '@mui/material';

function Topbar({ title, routes, setMenuOpen }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [logout, setLogout] = useState(false);
    const user = useMe();
    const { isAdmin } = useRoles();
    const isMedium = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setLogout(true);
        handleClose();
    };

    if (logout) return <Redirect to={AUTH_PATHS.LOGOUT} />;

    return (
        <Fragment>
            <AppBar
                position="sticky"
                color="transparent"
                elevation={0}
                sx={{ bgcolor: 'grey.50', borderBottom: 1, borderBottomColor: 'grey.200' }}
            >
                <Toolbar sx={{ height: TOPBAR_HEIGHT }}>
                    <Box onClick={(e) => setMenuOpen((v) => !v)} mr={1.5} display={{ md: 'none' }}>
                        <i className="fas fa-bars" />
                    </Box>
                    <Typography variant="h6" component="h2" sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={4}>
                        {!isAdmin && isMedium && <CreditsWidget data={user} small />}
                        <Button
                            variant="contained"
                            color="light"
                            onClick={handleMenu}
                            sx={{
                                minHeight: '2.6em',
                            }}
                        >
                            <i className="fas fa-user fa-sm fa-mr-1" />
                            <Box
                                component="span"
                                display={{
                                    xs: 'none',
                                    md: 'unset',
                                }}
                            >
                                {user?.name}
                            </Box>
                            <i className="fas fa-caret-down fa-sm fa-ml-1" />
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {routes.map((v) =>
                                v.userMenu ? (
                                    <MenuItem
                                        key={v.id}
                                        component={Link}
                                        to={'/' + v.id}
                                        onClick={handleClose}
                                        disabled={v.disabled}
                                    >
                                        {v.title}
                                    </MenuItem>
                                ) : null,
                            )}
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </Stack>
                </Toolbar>
            </AppBar>
            {!isAdmin && !isMedium && (
                <Box
                    px={2}
                    py={1.5}
                    mx={{
                        xs: 2,
                        sm: 3,
                    }}
                    my={1}
                    bgcolor="white"
                    borderRadius={1}
                >
                    <CreditsWidget data={user} small justifyContent="center" />
                </Box>
            )}
        </Fragment>
    );
}

export default Topbar;
