import { useRef } from 'react';

function useScrollToBottom(boxRef, userScrolledRef) {
    const scrollToBottom = useRef((instant, checkUserScrolled) => {
        if (checkUserScrolled && userScrolledRef?.current) return;

        const scrollDistance = 999 * 200;

        if (instant) {
            boxRef.current?.scrollBy?.({ top: scrollDistance });
        } else {
            setTimeout(() => {
                boxRef.current?.scrollBy?.({ top: scrollDistance, behavior: 'smooth' });
            }, 100);
        }
    });

    return scrollToBottom.current;
}

export default useScrollToBottom;
