import React, { Fragment, useMemo, useRef, useState } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { Link } from 'routes';
import { Box, Fade, Grid, Slide, Stack, useMediaQuery } from '@mui/material';
import { MiniFooter, Sidebar, Topbar } from 'components/Layout';
import { KEEP_PARENT_TITLE, getFixedTitles } from 'routes/Routes';
import useScrollToTop from 'hooks/useScrollToTop';
import { useRoles } from 'hooks';

function Main({ children, routes }) {
    const { pathname } = useLocation();
    const { url } = useRouteMatch();
    const scrollRef = useRef(null);
    useScrollToTop(scrollRef);
    const [menuOpen, setMenuOpen] = useState(false);
    const { isAdmin } = useRoles();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const title = useMemo(() => {
        if (!routes) return '';

        const route = routes?.find((v) => pathname.indexOf(url + v.id) === 0);
        const routeId = route?.id ?? '';
        const backPath = url + routeId;

        const keepTitle =
            KEEP_PARENT_TITLE.indexOf(pathname) > -1 ||
            getFixedTitles(isAdmin).findIndex((v) => pathname.indexOf(v) > -1) > -1;

        const result =
            pathname.length > backPath.length && !keepTitle ? (
                <Link to={backPath} sx={{ fontWeight: 'inherit' }}>
                    {!isMobile && <i className="fas fa-chevron-left fa-mr-2 fa-xs" />} Back to{' '}
                    {route?.title}
                </Link>
            ) : (
                route?.title
            );
        return result;
    }, [routes, url, pathname, isAdmin, isMobile]);

    const handleMenuClose = (e) => setMenuOpen(false);

    return (
        <Fragment>
            <Grid
                container
                flexWrap="nowrap"
                sx={{
                    height: '100vh',
                    maxHeight: '-webkit-fill-available',
                }}
            >
                {isMobile ? (
                    <Fade in={menuOpen}>
                        <Box
                            sx={{
                                width: '100%',
                                position: 'absolute',
                                left: 0,
                                bgcolor: '#0008',
                                zIndex: 1200,
                            }}
                            onClick={handleMenuClose}
                        >
                            <Slide direction="right" in={menuOpen}>
                                <Box
                                    sx={{
                                        width: '250px',
                                        height: '100vh',
                                        maxHeight: '-webkit-fill-available',
                                        bgcolor: 'white',
                                        borderRight: '1px solid',
                                        borderColor: 'grey.200',
                                        overflow: 'scroll',
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Sidebar routes={routes} handleMenuClose={handleMenuClose} />
                                </Box>
                            </Slide>
                        </Box>
                    </Fade>
                ) : (
                    <Grid item flex="1 0 250px" maxWidth="250px">
                        <Sidebar routes={routes} />
                    </Grid>
                )}
                <Grid item flexGrow={1} bgcolor="grey.50" overflow="auto" ref={scrollRef}>
                    <Stack minHeight="100%">
                        <Topbar routes={routes} title={title} setMenuOpen={setMenuOpen} />
                        {children}
                        <Box pb={3}>
                            <MiniFooter />
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Main;
