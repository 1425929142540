import {
    Box,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    LinearProgress,
} from '@mui/material';
import { ALL_HOURS, DAYS, TOPBAR_HEIGHT } from '@constants';
import { DayLabel, Event } from 'components/Booking';
import { useGetCalendarQuery } from 'api';
import { useCallback, useMemo } from 'react';
import { getTimezoneOffsetHours, makeGMTString } from 'helpers';
import { getScheduleMatrix, getTimeData } from 'components/Booking/ViewWeek';
import {
    addMinutes,
    differenceInMinutes,
    isToday,
    isWithinInterval,
    setHours,
    setMinutes,
    setSeconds,
} from 'date-fns';

const CELL_PROPS = { p: 1 / 2, height: 0 };
const HEADER_CELL_PROPS = {
    top: TOPBAR_HEIGHT,
    zIndex: 10,
    whiteSpace: 'nowrap',
};

const tzOffset = getTimezoneOffsetHours();

// TODO generalize this with ViewWeek

function ViewDay({ start, isThisWeek, data, handleCreate = () => {}, handleView = () => {} }) {
    const calendarQuery = useGetCalendarQuery();

    const dayNumber = (start.getDay() + 6) % 7;
    const day = start;
    const today = isToday(day);

    const scheduleMatrix = useMemo(() => {
        return getScheduleMatrix(calendarQuery?.data);
    }, [calendarQuery?.data]);

    const getEventAndOpenStatus = useCallback(
        (currentEntry) => {
            const { hours, minutes, time } = getTimeData(currentEntry);
            const event = data?.[day.getDate()]?.[time];
            const eventStartDate = setMinutes(setHours(day, +hours), +minutes);
            const open =
                scheduleMatrix?.[dayNumber]?.[currentEntry] &&
                differenceInMinutes(eventStartDate, new Date()) >= 59;
            return [event, open];
        },
        [data, day, dayNumber, scheduleMatrix],
    );

    const anyOpenings = useMemo(() => {
        return ALL_HOURS.reduce((a, currentEntry) => {
            const [event, open] = getEventAndOpenStatus(currentEntry);
            return a || open || !!event;
        }, false);
    }, [getEventAndOpenStatus]);

    if (calendarQuery?.isLoading) return <LinearProgress />;

    return (
        <Box mx={-3 / 4}>
            <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                ...CELL_PROPS,
                                width: '5em',
                                ...HEADER_CELL_PROPS,
                                fontWeight: 'normal',
                                fontSize: 'caption.fontSize',
                                color: 'text.secondary',
                            }}
                        >
                            {makeGMTString(tzOffset)}
                        </TableCell>
                        <TableCell sx={{ ...CELL_PROPS, ...HEADER_CELL_PROPS }}>
                            {DAYS[dayNumber]}
                            <DayLabel
                                day={day}
                                ml={3 / 4}
                                fontWeight={today ? 'bold' : undefined}
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!anyOpenings && (
                        <TableRow>
                            <TableCell colSpan={2}>
                                There are no time slots available today.
                            </TableCell>
                        </TableRow>
                    )}
                    {anyOpenings &&
                        ALL_HOURS.map((currentEntry) => {
                            let CURRENT_HOUR_PROPS = {};
                            const { hours, minutes, time } = getTimeData(currentEntry);

                            const startHours = setMinutes(setHours(new Date(), hours), minutes);
                            const timeInterval = {
                                start: startHours,
                                end: setSeconds(addMinutes(startHours, 29), 59),
                            };
                            const isCurrentHour = isWithinInterval(new Date(), timeInterval);

                            if (isThisWeek && isCurrentHour) {
                                CURRENT_HOUR_PROPS = {
                                    borderBottomColor: 'error.main',
                                    borderBottomWidth: 2,
                                };
                            }

                            const eventStartDate = setMinutes(setHours(day, +hours), +minutes);
                            const [event, open] = getEventAndOpenStatus(currentEntry);
                            const collapse = !open && !event;

                            const FINAL_CELL_PROPS = {
                                ...CELL_PROPS,
                                ...CURRENT_HOUR_PROPS,
                                ...(collapse ? { p: 1 / 4 } : {}),
                            };

                            return (
                                <TableRow
                                    key={currentEntry}
                                    hover
                                    sx={{
                                        '&.MuiTableRow-hover:hover': {
                                            bgcolor: '#00000003',
                                        },
                                    }}
                                >
                                    <TableCell sx={FINAL_CELL_PROPS}>{!collapse && time}</TableCell>
                                    <TableCell
                                        key={dayNumber}
                                        sx={{
                                            ...FINAL_CELL_PROPS,
                                            borderLeft: '1px solid',
                                            borderLeftColor: 'grey.200',
                                            bgcolor: open ? undefined : '#f6f6f6',
                                            position: 'relative',

                                            '&:after': {
                                                content: '""',
                                                position: 'absolute',
                                                width: 11,
                                                height: 11,
                                                borderRadius: 5,
                                                left: -6,
                                                bottom: -6,
                                                zIndex: 5,
                                                bgcolor: 'red',
                                                display: today && isCurrentHour ? 'block' : 'none',
                                            },

                                            '&:hover': {
                                                cursor: open ? 'pointer' : undefined,
                                            },
                                        }}
                                        onClick={
                                            event || !open
                                                ? undefined
                                                : (e) =>
                                                      handleCreate(
                                                          eventStartDate,
                                                          addMinutes(eventStartDate, 30),
                                                      )
                                        }
                                    >
                                        {event && (
                                            <Event
                                                key={event?._id}
                                                event={event}
                                                today={today}
                                                handleView={handleView}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </Box>
    );
}

export default ViewDay;
