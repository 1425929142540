import { useMe } from 'hooks';

function useRoles() {
    const user = useMe();

    if (!user) return {};

    return {
        isAdmin: user?.role !== 'user',
        isCoach: user?.isCoach,
        me: user,
    };
}

export default useRoles;
