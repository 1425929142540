import { useState, useCallback } from 'react';

function useLocalStorage(name, defaultValue) {
    const [value, setValue] = useState(localStorage.getItem(name) || defaultValue);

    const handleChange = useCallback(
        newValue => {
            localStorage.setItem(name, newValue);
            setValue(newValue);
        },
        [name],
    );

    return [value, handleChange];
}

export default useLocalStorage;
