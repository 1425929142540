import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Stack, Typography, LinearProgress } from '@mui/material';
import { uiActions } from 'ducks';
import { useGetLogsByUserIdQuery } from 'api';
import { useAutoNotify } from 'hooks';
import { LogDialog, LogEntry } from 'components/Activity';
import { ExerciseDialog } from 'components/Exercises';
import { format, isSameDay } from 'date-fns';

function LogHistory({ userId }) {
    const dispatch = useDispatch();
    const match = useRouteMatch();

    const { data, error, isLoading, isSuccess } = useGetLogsByUserIdQuery(userId, {
        skip: !userId,
    });
    useAutoNotify(error);

    if (isLoading) return <LinearProgress />;
    if (!isSuccess) return <Typography>There has been an error.</Typography>;

    return (
        <Fragment>
            <ExerciseDialog />
            <LogDialog parentPath={match?.url} />

            <Stack
                spacing={1}
                sx={{
                    // flexWrap: 'wrap',
                    // overflowX: 'auto',
                }}
            >
                {!data?.length && <Typography>No activity yet.</Typography>}
                {data?.map((v, i) => {
                    return (
                        <Fragment key={v._id}>
                            {(!i ||
                                !isSameDay(new Date(v?.date), new Date(data?.[i - 1]?.date))) && (
                                <Typography variant="caption" pt={!!i ? 2 : undefined}>
                                    {format(new Date(v?.date), 'd MMM. y')}
                                </Typography>
                            )}
                            <LogEntry
                                data={v}
                                noDate
                                onEdit={(e) => {
                                    dispatch(
                                        uiActions.setCurrentLogData([
                                            v._id,
                                            {
                                                ...(v?.exerciseInstanceId || {}),
                                                exerciseId: v?.exerciseId,
                                                groupId: v?.groupId,
                                            },
                                        ]),
                                    );
                                }}
                            />
                        </Fragment>
                    );
                })}
            </Stack>
        </Fragment>
    );
}

export default LogHistory;
