import { useS3 } from 'hooks';
import { useEffect, useState } from 'react';

function useS3Query(s3Key, skip = false, useOriginal = false) {
    const [data, setData] = useState();
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState();
    const [isError, setIsError] = useState(false);

    const getUrl = useS3();

    useEffect(() => {
        if (!s3Key || skip || !getUrl) return;

        const getData = async () => {
            try {
                const url = await getUrl(s3Key, useOriginal);

                setData(url);
                setIsSuccess(true);

                setIsError(false);
                setError(undefined);
            } catch (error) {
                setData(undefined);
                setIsSuccess(false);

                setIsError(true);
                setError(error);
            }
        };

        getData();

        return () => {
            setData(undefined);
            setIsSuccess(false);
            setIsError(false);
            setError(undefined);
        };
    }, [getUrl, s3Key, skip, useOriginal]);

    return { url: data, isSuccess, error, isError };
}

export default useS3Query;
