import { Fragment } from 'react';
import { TextField, InputAdornment } from '@mui/material';

function Step2CircumferenceField({ cm, label, registerField, name }) {
    return (
        <Fragment key={cm}>
            {cm ? (
                <TextField
                    InputProps={{
                        endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    }}
                    label={label}
                    type="number"
                    fullWidth
                    {...registerField(`${name}.cm`)}
                />
            ) : (
                <TextField
                    InputProps={{
                        endAdornment: <InputAdornment position="end">in</InputAdornment>,
                    }}
                    label={label}
                    type="number"
                    fullWidth
                    {...registerField(`${name}.in`)}
                />
            )}
        </Fragment>
    );
}

export default Step2CircumferenceField;
