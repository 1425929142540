import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { InitialAnimation } from 'common';

import { useLogoutQuery } from 'api';
import { useDispatch } from 'react-redux';
import { resetStateAction } from 'ducks';

function Logout() {
    const dispatch = useDispatch();
    const { isLoading, isSuccess, isError } = useLogoutQuery();

    useEffect(() => {
        if (isSuccess) dispatch(resetStateAction());
    }, [dispatch, isSuccess]);

    if (isLoading) return <InitialAnimation />;
    if (isSuccess) return <Redirect to="/login" />;
    if (isError) return <Redirect to="/" />;
    return null;
}

export default Logout;
