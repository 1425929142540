import { Paper } from '@mui/material';
import { useGetMeState } from 'api';
import { LogHistory } from 'components/LogHistory';

function MyActivity() {
    const me = useGetMeState();
    const userId = me?.data?._id;

    return (
        <Paper sx={{ p: 3 }}>
            <LogHistory userId={userId} myActivity />
        </Paper>
    );
}

export default MyActivity;
