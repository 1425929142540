import React, { Fragment, useState } from 'react';
import { useRouteMatch, Link as RouterLink } from 'react-router-dom';

import {
    Box,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    LinearProgress,
    Button,
} from '@mui/material';

import { VideoDialog } from 'common';
import { ExerciseDialog, ExercisesRow } from 'components/Exercises';

import { useAutoNotify } from 'hooks';
import { useGetExercisesQuery, useGetCategoriesQuery } from 'api';

function Exercises() {
    const [currentVideo, setCurrentVideo] = useState(false);
    const match = useRouteMatch();

    const { data, error, isLoading } = useGetExercisesQuery();
    useAutoNotify(error);

    const categories = useGetCategoriesQuery();
    useAutoNotify(categories.error);

    const handleVideoClick = (val) => (e) => {
        setCurrentVideo(val);
    };
    const handleVideoClose = (e) => {
        setCurrentVideo(false);
    };

    if (isLoading) return <LinearProgress />;

    return (
        <Fragment>
            <ExerciseDialog parentPath={match?.url} categories={categories?.data} />
            <VideoDialog currentVideo={currentVideo} onClose={handleVideoClose} />

            <Box mb={3} display="flex" justifyContent="space-between">
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    sx={{
                        '&.MuiButtonBase-root': {
                            px: 2,
                        },
                    }}
                    component={RouterLink}
                    to={match?.url + '/add'}
                >
                    <i className="fas fa-plus fa-mr-1" /> Add new exercise
                </Button>
            </Box>
            <TableContainer component={Paper}>
                {!!data && (
                    <Table>
                        <TableHead>
                            <TableRow sx={{ whiteSpace: 'nowrap' }}>
                                <TableCell>Video</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Categories</TableCell>
                                <TableCell>Muscles worked</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <ExercisesRow
                                    key={row._id}
                                    row={row}
                                    handleVideoClick={handleVideoClick}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </Fragment>
    );
}

export default Exercises;
