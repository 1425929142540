import { useGetUserByIdQuery } from 'api';
import { isAfter } from 'date-fns';
import useMe from 'hooks/useMe';
import useRoles from 'hooks/useRoles';
import { useMemo } from 'react';

function useOngoingSubscription(userId) {
    const { isAdmin } = useRoles();
    const userQuery = useGetUserByIdQuery(userId, { skip: !isAdmin });
    const me = useMe();
    const obj = isAdmin ? userQuery?.data : me;
    const endDateStr = obj?.subscriptionEndDate;

    const endDate = useMemo(() => {
        if (!endDateStr) return undefined;
        return new Date(endDateStr);
    }, [endDateStr]);

    const hasOngoingSubscription = useMemo(() => {
        return isAfter(endDate, new Date());
    }, [endDate]);

    return { hasOngoingSubscription, planName: obj?.subscriptionType, planEndDate: endDate };
}

export default useOngoingSubscription;
