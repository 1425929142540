import { TabbedPage } from 'common';
import { Calendar, Settings } from 'components/Booking';

function BookingAdmin() {
    return (
        <TabbedPage
            pages={[
                {
                    id: 'calendar',
                    name: 'Calendar',
                    Component: Calendar,
                },
                {
                    id: 'settings',
                    name: 'Settings',
                    Component: Settings,
                    PaperProps: { maxWidth: 600 },
                },
            ]}
        />
    );
}

export default BookingAdmin;
