import { Link } from 'react-router-dom';

import {
    Typography,
    LinearProgress,
    Paper,
    Button,
    Alert,
    Link as MuiLink,
    Box,
    useMediaQuery,
} from '@mui/material';

import { ClientProfileView } from 'components/Clients';
import { useAccountCompletion, useAutoNotify } from 'hooks';
import { useGetMyProfileQuery } from 'api';
import { Fragment } from 'react';

function Profile() {
    const { data, error, isLoading, isSuccess } = useGetMyProfileQuery();
    useAutoNotify(error);

    const { hasPurchased, hasProgram } = useAccountCompletion();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    if (isLoading || !isSuccess) {
        return isLoading ? <LinearProgress /> : <Typography>There has been an error.</Typography>;
    }

    return (
        <Paper sx={{ p: 3 }}>
            {!hasProgram && (
                <Alert
                    severity="warning"
                    action={
                        <Button
                            size="small"
                            component={Link}
                            to="/profile/edit"
                            sx={{
                                textAlign: {
                                    xs: 'center',
                                    sm: 'right',
                                },
                            }}
                        >
                            Update this information
                        </Button>
                    }
                    sx={{
                        mb: 3,
                        flexDirection: {
                            xs: 'column',
                            sm: 'row',
                        },
                        alignItems: {
                            xs: 'center',
                            sm: 'initial',
                        },
                        textAlign: {
                            xs: 'center',
                            sm: 'initial',
                        },

                        '& .MuiAlert-action': isMobile
                            ? {
                                  margin: '0 auto',
                                  paddingLeft: 0,
                              }
                            : {},
                    }}
                >
                    <Box flexGrow={1}>
                        This information is editabile until a program is created for you.{' '}
                        {!hasPurchased && (
                            <Fragment>
                                To get a program you must{' '}
                                <MuiLink component={Link} to="/plans">
                                    purchase a plan
                                </MuiLink>
                                .
                            </Fragment>
                        )}
                    </Box>
                </Alert>
            )}
            <ClientProfileView data={data} />
        </Paper>
    );
}

export default Profile;
