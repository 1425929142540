import { Stack, TextField, InputAdornment } from '@mui/material';

function Step2CompositionField({ kg, label, registerField, name, unit }) {
    return (
        <Stack
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            spacing={2}
            key={kg}
        >
            {unit ? (
                <TextField
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                    }}
                    label={label}
                    type="number"
                    sx={{ maxWidth: { xs: 'none', sm: 200 } }}
                    fullWidth
                    {...registerField(name)}
                />
            ) : kg ? (
                <TextField
                    InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    }}
                    label={label}
                    type="number"
                    fullWidth
                    {...registerField(`${name}.kg`)}
                />
            ) : (
                <Stack direction="row" spacing={2}>
                    <TextField
                        InputProps={{
                            endAdornment: <InputAdornment position="end">st</InputAdornment>,
                        }}
                        InputLabelProps={{ shrink: true }}
                        label={label}
                        type="number"
                        sx={{ width: '100%' }}
                        {...registerField(`${name}.st`)}
                    />
                    <TextField
                        InputProps={{
                            endAdornment: <InputAdornment position="end">lb</InputAdornment>,
                        }}
                        type="number"
                        sx={{ width: '100%' }}
                        {...registerField(`${name}.lb`)}
                    />
                </Stack>
            )}
            <TextField
                label="Measurement method"
                fullWidth
                sx={{ minWidth: 160, width: '100%' }}
                InputLabelProps={{ shrink: true }}
                {...registerField(`${name}Measurement`)}
            />
        </Stack>
    );
}

export default Step2CompositionField;
