import { Typography, LinearProgress } from '@mui/material';
import { QuestionnaireForm } from 'components/Questionnaire';
import { useAutoNotify } from 'hooks';
import { useGetMyProfileQuery } from 'api';

function Questionnaire() {
    const { data, error, isLoading, isSuccess } = useGetMyProfileQuery();
    useAutoNotify(error);

    if (isLoading || !isSuccess) {
        return isLoading ? <LinearProgress /> : <Typography>There has been an error.</Typography>;
    }

    return <QuestionnaireForm data={data} />;
}

export default Questionnaire;
