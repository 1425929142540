import { Stack, Typography, Paper, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAutoNotify, useForm } from 'hooks';
import { useUpdateMyPassMutation } from 'api';
import * as yup from 'yup';
import { Redirect } from 'react-router-dom';
import { AUTH_PATHS } from '@constants';

const schema = yup
    .object({
        passwordCurrent: yup.string().required('This is required'),
        password: yup
            .string()
            .required('This is required')
            .min(8, 'Password must have at least 8 characters'),
        passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    })
    .required();

function MyAccountPassForm() {
    const { handleSubmit, registerField } = useForm(schema);

    const [submit, { error, isSuccess, isLoading }] = useUpdateMyPassMutation();
    useAutoNotify(error, isSuccess, 'Password updated successfully.');

    const onSubmit = (form) => {
        submit(form);
    };

    if (isSuccess) return <Redirect to={AUTH_PATHS.LOGOUT} />;

    return (
        <Paper sx={{ p: 3, flexGrow: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
                <Stack spacing={5} sx={{ height: '100%' }}>
                    <div>
                        <Typography variant="h6">Change password</Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            Leave empty if no change
                        </Typography>
                    </div>
                    <Stack spacing={3} sx={{ flexGrow: 1 }}>
                        <TextField
                            {...registerField('passwordCurrent')}
                            fullWidth
                            label="Current password *"
                            type="password"
                        />
                        <TextField
                            {...registerField('password')}
                            fullWidth
                            label="New password *"
                            type="password"
                        />
                        <TextField
                            {...registerField('passwordConfirm')}
                            fullWidth
                            label="Confirm new password *"
                            type="password"
                        />
                    </Stack>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        size="large"
                        fullWidth
                        type="submit"
                    >
                        Update password
                    </LoadingButton>
                </Stack>
            </form>
        </Paper>
    );
}

export default MyAccountPassForm;
