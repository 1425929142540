import {
    addDays,
    addMonths,
    addWeeks,
    endOfWeek,
    format,
    startOfWeek,
    subDays,
    subMonths,
    subWeeks,
} from 'date-fns';
import { DATE_OPTIONS } from '@constants';

import {
    Stack,
    Typography,
    Button,
    ButtonGroup,
    ToggleButtonGroup,
    ToggleButton,
    CircularProgress,
} from '@mui/material';

const getDateHeader = (date, isWeek, isLarge) => {
    if (!isWeek) return format(date, isLarge ? 'MMMM YYY' : 'MMM YYY');
    if (!isLarge) return format(date, 'dd MMM YYY');

    const getFormatted = (f) => format(f(date, DATE_OPTIONS), 'dd MMM YYY');

    return [getFormatted(startOfWeek), getFormatted(endOfWeek)].join(' - ');
};

function CalendarHeader({
    selectedDate,
    setSelectedDate,
    calendarView,
    isFetching,
    isLarge,
    ...props
}) {
    const { view, setView, VIEWS, isWeek } = calendarView;

    const handleClickView = (e, newView) => {
        setView(newView);
    };

    return (
        <Stack
            direction={isLarge ? 'row' : 'column-reverse'}
            justifyContent="space-between"
            alignItems="center"
            spacing={isLarge ? 1 : 2}
            {...props}
        >
            <Stack
                direction="row"
                alignItems={isLarge ? 'stretch' : 'center'}
                spacing={2}
                justifyContent={isLarge ? undefined : 'space-between'}
                width={isLarge ? undefined : '100%'}
            >
                <ButtonGroup
                    size="small"
                    sx={{
                        '[class*=fa-]': {
                            lineHeight: '1.8em',
                        },
                    }}
                >
                    <Button
                        onClick={(e) => {
                            setSelectedDate((pv) =>
                                (!isWeek ? subMonths : isLarge ? subWeeks : subDays)(pv, 1),
                            );
                        }}
                    >
                        <i className="fas fa-angle-left" />
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={(e) => {
                            setSelectedDate(new Date());
                        }}
                    >
                        {isLarge ? 'Today' : <i className="fas fa-calendar-day" />}
                    </Button>
                    <Button
                        onClick={(e) => {
                            setSelectedDate((pv) =>
                                (!isWeek ? addMonths : isLarge ? addWeeks : addDays)(pv, 1),
                            );
                        }}
                    >
                        <i className="fas fa-angle-right" />
                    </Button>
                </ButtonGroup>
                <Stack direction={isLarge ? 'row' : 'row-reverse'} alignItems="center" spacing={1}>
                    <Typography variant={isLarge ? 'h5' : 'h6'} whiteSpace="nowrap">
                        {getDateHeader(selectedDate, isWeek, isLarge)}
                    </Typography>
                    {isFetching && <CircularProgress size={20} />}
                </Stack>
            </Stack>

            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                width={isLarge ? undefined : '100%'}
            >
                <ToggleButtonGroup size="small" value={view} fullWidth={!isLarge}>
                    <ToggleButton
                        onClick={handleClickView}
                        value={VIEWS.WEEK}
                        sx={{ lineHeight: 'unset' }}
                    >
                        {isLarge ? 'Week' : 'Day'}
                    </ToggleButton>
                    <ToggleButton
                        onClick={handleClickView}
                        value={VIEWS.MONTH}
                        sx={{ lineHeight: 'unset' }}
                    >
                        Month
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
        </Stack>
    );
}

export default CalendarHeader;
