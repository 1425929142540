import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';

function CustomDateTimePicker({
    control,
    name,
    inputFormat = 'dd/MM/yyyy HH:mm',
    mask = '__/__/____ __:__',
    placeholder,
    getErrorProps = () => ({}),
    TextFieldProps = {},
    onInputValueChange = (e, onChange) => onChange(e),
    onChange = (v, onChange) => onChange(v),
    value,
    required,
    views = ['year', 'month', 'day', 'hours', 'minutes'],
    ...DateTimePickerProps
}) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value: fieldValue, ...field } }) => (
                <MuiDateTimePicker
                    inputFormat={inputFormat}
                    mask={mask}
                    views={views}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required={required}
                            variant="outlined"
                            placeholder={placeholder}
                            {...TextFieldProps}
                            {...getErrorProps(name)}
                            inputProps={{
                                ...params.inputProps,
                                onChange: (e) => {
                                    onInputValueChange(e, params.inputProps.onChange);
                                },
                            }}
                        />
                    )}
                    {...DateTimePickerProps}
                    {...field}
                    {...(value ? { value } : { value: fieldValue || '' })}
                    onChange={(v) => {
                        onChange(v, field.onChange);
                    }}
                />
            )}
        />
    );
}

export default CustomDateTimePicker;
