// import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import PlusJakartaSansExtraBold from 'fonts/PlusJakartaSans-ExtraBold.woff2';

// eslint-disable-next-line no-unused-vars
import { brandingLightTheme, brandingDarkTheme, defaultTheme } from 'theme-branding';
import shadows from 'theme-shadows';

const brandingTheme = brandingLightTheme;
const t = brandingTheme;

const theme = {
    // shape: {
    //     borderRadius: 4,
    // },
    palette: {
        light: {
            main: t.palette.common.white,
            // contrastText: t.palette.common.black,
        },
        lightgrey: {
            main: t.palette.grey.A700,
        },
        text: {
            secondary: t.palette.grey[700],
        },
    },
    shadows,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                'html, body': {
                    WebkitOverflowScrolling: 'touch !important',
                    // overflowY: 'auto !important',
                    // height: '100% !important',
                    overflowX: 'hidden !important',
                },
                // a: {
                //     textDecoration: 'none',
                // },
                // b: {
                //     fontWeight: 500,
                // },
                '@font-face': {
                    fontFamily: 'PlusJakartaSans-ExtraBold',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    fontDisplay: 'swap',
                    src: `url(${PlusJakartaSansExtraBold}) format('woff2')`,
                },
                img: {
                    maxWidth: '100%',
                },
                '.fa-transition-rotate': {
                    transition: '0.3s transform',
                },
                '.fa-sq': {
                    width: '1em',
                },
                '.fa-2xs': {
                    fontSize: '0.67em',
                },
                '.fa-3xs': {
                    fontSize: '0.5em',
                },
                '.fa-mr-half': {
                    marginRight: t.spacing(1 / 2),
                },
                '.fa-ml-half': {
                    marginLeft: t.spacing(1 / 2),
                },
                ...Array.from(Array(10).keys()).reduce((acc, v) => {
                    acc[`.fa-mr-${v + 1}`] = { marginRight: t.spacing(v + 1) };
                    acc[`.fa-ml-${v + 1}`] = { marginLeft: t.spacing(v + 1) };
                    return acc;
                }, {}),
                '.fa-bold-fix': {
                    position: 'relative',
                    top: '0.05em',
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                placement: 'top',
            },
        },
        MuiFormControl: {
            variants: [
                {
                    props: { readOnly: true },
                    style: {
                        '& .MuiOutlinedInput-root, & .MuiInputBase-root': {
                            color: t.palette.text.disabled,
                            pointerEvents: 'none',
                        },
                        '& .MuiFormLabel-root, & .MuiInputLabel-root': {
                            color: t.palette.text.disabled,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: t.palette.action.disabled,
                        },
                    },
                },
            ],
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    margin: 0,

                    '.MuiSwitch-root + .MuiFormControlLabel-label': {
                        marginLeft: t.spacing(1),
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '& + .MuiFormGroup-root': {
                        marginTop: t.spacing(1),
                    },
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    '& .MuiFormControlLabel-root': {
                        marginLeft: -11,
                        marginRight: 16,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    ...t.typography.caption,
                    lineHeight: '2.5em',
                    marginTop: t.spacing(2.5),
                    textTransform: 'uppercase',
                    // color: '#999',
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    borderRadius: t.shape.borderRadius,
                    marginTop: t.spacing(1 / 4),
                    marginBottom: t.spacing(1 / 4),

                    '&.MuiButtonBase-root': {
                        '& [class*=fa-caret]': {
                            color: t.palette.grey.A700,
                        },
                        '&.Mui-selected': {
                            backgroundColor: t.palette.grey[200],
                            color: t.palette.grey[800],

                            '&, & .MuiListItemIcon-root': {
                                color: t.palette.grey[800],
                            },
                            '& .MuiTypography-root': {
                                fontWeight: t.typography.fontWeightBold,
                            },
                            '&:hover': {
                                backgroundColor: t.palette.grey[300],
                            },
                        },
                        '&:hover': {
                            backgroundColor: t.palette.grey[100],
                        },
                    },
                },
            },
        },
        // MuiTable: {
        //     styleOverrides: {
        //         root: {
        //             // borderCollapse: 'separate',
        //         },
        //     },
        // },
        MuiTableRow: {
            // variants: [
            //     {
            //         props: { variant: 'alternate' },
            //         style: {
            //             root: {
            //             },
            //         },
            //     },
            // ],
            // styleOverrides: {
            //     root: {
            //         '& th.MuiTableCell-root': {
            //             fontWeight: t.typography.fontWeightBold,
            //         },
            //         '& .MuiTableCell-root': {
            //             border: 0,
            //             paddingTop: t.spacing(2),
            //             paddingBottom: t.spacing(2),
            //         },
            //         '& .MuiTableCell-head': {
            //             paddingTop: t.spacing(1),
            //         },
            //         '&:nth-of-type(odd)': {
            //             '& .MuiTableCell-body': {
            //                 backgroundColor: '#f9f9f9',
            //                 '&:first-child': {
            //                     borderTopLeftRadius: t.shape.borderRadius * 0.75,
            //                     borderBottomLeftRadius: t.shape.borderRadius * 0.75,
            //                 },
            //                 '&:last-child': {
            //                     borderTopRightRadius: t.shape.borderRadius * 0.75,
            //                     borderBottomRightRadius: t.shape.borderRadius * 0.75,
            //                 },
            //             },
            //         },
            //         '&:nth-of-type(even)': {
            //             '& .MuiTableCell-body': {
            //                 paddingTop: t.spacing(3),
            //                 paddingBottom: t.spacing(3),
            //             },
            //         },
            //         '&:hover': {
            //             '& .MuiTableCell-body': {
            //                 backgroundColor: t.palette.grey[100],
            //             },
            //         },
            //     },
            // },
        },
        MuiTableCell: {
            variants: [
                {
                    props: {
                        divider: 'none',
                    },
                    style: {
                        borderBottom: 0,
                    },
                },
                // {
                //     props: {
                //         variant: 'outlined',
                //     },
                //     style: {
                //         borderBottom: 0,
                //         border: '1px solid black',
                //         borderRadius: t.shape.borderRadius,
                //     },
                // },
            ],
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.MuiButton-root': {
                        paddingLeft: t.spacing(1.5),
                        paddingRight: t.spacing(1.5),
                    },
                },
            },
        },
        MuiChip: {
            // styleOverrides: {
            //     root: {},
            // },
            variants: [
                {
                    props: {
                        color: 'default',
                    },
                    style: {
                        border: '1px solid',
                        borderColor: t.palette.grey[400],
                        backgroundColor: t.palette.grey[100],
                    },
                },
            ],
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    '& [class*=fa-]': {
                        lineHeight: 'inherit',
                    },
                },
            },
            variants: [
                {
                    props: {
                        variant: 'text',
                        size: 'narrow',
                    },
                    style: {
                        minWidth: 0,
                    },
                },
                {
                    props: {
                        variant: 'outlined',
                        size: 'mini',
                    },
                    style: {
                        borderRadius: (t.shape.borderRadius * 8) / 10,
                        padding: `${t.spacing(4 / 10)} ${t.spacing(1)} ${t.spacing(
                            1 / 2,
                        )} !important`,
                        lineHeight: '1em',
                    },
                },
            ],
        },
    },
};

const finalTheme = deepmerge(t, theme);
// const finalTheme = deepmerge(brandingDarkTheme, theme);

// if (process.env.REACT_APP_ENV !== 'production') window.theme = finalTheme;
window.theme = finalTheme;

export default finalTheme;
