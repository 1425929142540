import { getURLParams } from 'helpers';

const POLLING_KEY = 'polling_interval';

export const IS_DEV = process.env.NODE_ENV !== 'production';

export const getPollingSeconds = (prodValue) => {
    const params = getURLParams();
    return IS_DEV ? parseInt(params.get(POLLING_KEY)) || 0 : prodValue;
};

export const setPollingSeconds = (value) => {
    const params = getURLParams();
    params.set(POLLING_KEY, value);
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
};
