import { Fragment, useState } from 'react';
import { Stack, Typography, Button, Avatar, Tooltip } from '@mui/material';
import { CreditsWidget, Tag } from 'common';
import { useAccountCompletion } from 'hooks';
import { EditCreditsDialog, EditAccountCompletionDialog } from 'components/Clients';

function ClientHeader({ data }) {
    const [creditsDialog, setCreditsDialog] = useState(false);
    const [accountCompletionDialog, setAccountCompletionDialog] = useState(false);

    const { accountCompletionLabel, hasProfile } = useAccountCompletion(data);

    return (
        <Fragment>
            <EditCreditsDialog
                open={creditsDialog}
                data={data}
                handleClose={() => setCreditsDialog(false)}
            />
            <EditAccountCompletionDialog
                open={accountCompletionDialog}
                data={data}
                handleClose={() => setAccountCompletionDialog(false)}
            />

            <Stack
                direction={{
                    xs: 'column',
                    lg: 'row',
                }}
                alignItems={{
                    xs: 'flex-start',
                    lg: 'center',
                }}
                justifyContent="space-between"
            >
                <Stack
                    direction="row"
                    spacing={{
                        xs: 0,
                        lg: 2,
                    }}
                    alignItems="center"
                >
                    {data?.name && (
                        <Avatar
                            sx={{
                                width: 56,
                                height: 56,
                                display: {
                                    xs: 'none',
                                    lg: 'flex',
                                },
                            }}
                        >
                            {data?.name
                                ?.split(' ')
                                .map((v) => v[0])
                                .join('')}
                        </Avatar>
                    )}
                    <div>
                        <Stack
                            direction={{
                                xs: 'column-reverse',
                                lg: 'row',
                            }}
                            alignItems={{
                                xs: 'flex-start',
                                lg: 'center',
                            }}
                            spacing={{
                                xs: 0,
                                lg: 1.5,
                            }}
                        >
                            <Typography variant="h4" component="h1">
                                {data?.name}
                            </Typography>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Tag size="small" disabled>
                                    {data?.role}
                                </Tag>
                                <Tooltip title="Update account completion status" placement="right">
                                    <div>
                                        <Tag
                                            size="small"
                                            bgcolor="primary.main"
                                            onClick={() => setAccountCompletionDialog(true)}
                                        >
                                            {accountCompletionLabel}
                                            {hasProfile && ', has profile'}
                                        </Tag>
                                    </div>
                                </Tooltip>
                            </Stack>
                        </Stack>
                        <Typography variant="subtitle1" color="text.secondary">
                            {data?.email}
                        </Typography>
                    </div>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Tooltip title="Edit credits" placement="left">
                        <Button
                            onClick={() => setCreditsDialog(true)}
                            sx={{
                                mt: {
                                    xs: 2,
                                    lg: 0,
                                },
                                ml: {
                                    xs: -1.5,
                                    lg: 0,
                                },
                            }}
                        >
                            <CreditsWidget data={data} key={JSON.stringify(data)} />
                        </Button>
                    </Tooltip>
                </Stack>
            </Stack>
        </Fragment>
    );
}

export default ClientHeader;
