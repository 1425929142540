import { Fragment } from 'react';
import { Stack, TextField } from '@mui/material';
import { DatePicker, RadioGroup } from 'form';

function Step1({ formMethods }) {
    const { registerField, getErrorProps, control, getError } = formMethods;

    return (
        <Fragment>
            <Stack spacing={4} flexGrow={1}>
                <div>
                    <DatePicker
                        control={control}
                        name="DOB"
                        label="Date of birth *"
                        getErrorProps={getErrorProps}
                    />
                </div>
                <RadioGroup
                    label="Gender *"
                    getError={getError}
                    control={control}
                    name="gender"
                    items={[
                        { value: 'male', label: 'Male' },
                        { value: 'female', label: 'Female' },
                    ]}
                />
                <RadioGroup
                    label="Sleeping habits *"
                    getError={getError}
                    control={control}
                    name="sleepingHabits"
                    items={[
                        { value: 'less than 6 hours/night' },
                        { value: '6-8 hours/night' },
                        { value: 'more than 8 hours/night' },
                    ]}
                />
                <RadioGroup
                    label="What is your water intake? *"
                    getError={getError}
                    control={control}
                    name="waterIntake"
                    items={[
                        { value: 'less than 2 litres/day' },
                        { value: 'more than 2 litres/day' },
                    ]}
                />
                <TextField label="Occupation" {...registerField('occupation')} />
            </Stack>
        </Fragment>
    );
}

export default Step1;
