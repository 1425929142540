import { Box, Stack, useMediaQuery } from '@mui/material';
import { useGetMyChatsQuery } from 'api';
import { Conversation, ConversationConterpart, StickyChatActions } from 'components/Chat';
import { chatActions, chatSelectors } from 'ducks';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useAddStickyChat,
    useAutoNotify,
    useGetChatCounterpart,
    useMe,
    useRoles,
    useSliceStickyChats,
} from 'hooks';
import { useSocket } from 'common/socket';

function StickyChats({ test }) {
    const { isAdmin } = useRoles();
    const me = useMe();
    const dispatch = useDispatch();
    const isMedium = useMediaQuery((t) => t.breakpoints.up('md'), { defaultMatches: true });

    const myChats = useGetMyChatsQuery();
    useAutoNotify(myChats.error);

    const [unreadMap, firstUnread] = useMemo(() => {
        if (!myChats.data) return [{}, false];
        let first = false;
        const obj = myChats.data?.reduce((a, v) => {
            if (v?.unreadMessages > 0) {
                a[v?._id] = true;
                first = v;
            }
            return a;
        }, {});

        return [obj, first];
    }, [myChats.data]);

    useSliceStickyChats();
    const addStickyChat = useAddStickyChat();

    const stickyChats = useSelector(chatSelectors.getStickyChats);
    const activeStickyChat = useSelector(chatSelectors.getActiveStickyChat);
    const getCounterpart = useGetChatCounterpart();

    const socket = useSocket(true);

    useEffect(() => {
        if (!socket) return;

        socket.emit('subscribeToUserChats', me?._id);

        return () => {
            socket.emit('unsubscribeToUserChats', me?._id);
        };
    }, [me?._id, socket]);

    useEffect(() => {
        if (!socket) return;

        socket.on('newChats', () => {
            myChats.refetch();
        });
    }, [myChats, socket]);

    useEffect(() => {
        if (!firstUnread) return;
        if (!stickyChats?.length) addStickyChat(firstUnread, isAdmin);
        if (!isAdmin) dispatch(chatActions.setActiveStickyChat(firstUnread));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstUnread, dispatch, isAdmin]);

    const handleSetActive = (chat) => (e) => {
        dispatch(chatActions.setActiveStickyChat(chat));
    };

    return (
        <Stack
            direction="row"
            alignItems="flex-end"
            position="fixed"
            right={(t) => t.spacing(isMedium ? 4 : 2)}
            bottom={0}
            zIndex={1200}
            spacing={isMedium ? 2 : 1}
            sx={{
                pointerEvents: 'none',
            }}
        >
            {stickyChats.map((v) => {
                const minimized = activeStickyChat?._id !== v?._id;

                return (
                    <Box
                        key={v?._id}
                        width={minimized ? 'auto' : 440}
                        height={minimized ? 42 : 500}
                        maxWidth={(t) => `calc(100vw - ${t.spacing(4)})`}
                        maxHeight={(t) => `calc(100vh - ${t.spacing(2)})`}
                        bgcolor="common.white"
                        boxShadow="0 0 10px 0 #0003"
                        borderRadius={1}
                        sx={{
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            pointerEvents: 'initial',
                        }}
                        overflow="hidden"
                    >
                        {minimized ? (
                            <Stack
                                direction="row"
                                height="100%"
                                alignItems="center"
                                pl={1}
                                pr={1 / 4}
                                spacing={1}
                                whiteSpace="nowrap"
                                sx={{ cursor: 'pointer' }}
                                onClick={handleSetActive(v)}
                            >
                                <ConversationConterpart
                                    counterpart={getCounterpart(v)}
                                    mini
                                    isSticky
                                />
                                {unreadMap[v?._id] && (
                                    <Box
                                        component="i"
                                        color="error.main"
                                        className="fas fa-circle fa-2xs"
                                    />
                                )}
                                <StickyChatActions conversation={v} minimal />
                            </Stack>
                        ) : (
                            <Conversation currentConversation={v} compact isSticky />
                        )}
                    </Box>
                );
            })}
        </Stack>
    );
}

export default StickyChats;
