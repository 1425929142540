import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Box,
    Stack,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    useMediaQuery,
} from '@mui/material';

import { uiActions } from 'ducks';
import { ExerciseParameter } from 'components/Program';
import { LogEntry } from 'components/Activity';

function GroupItem({ data, logs, selectedDate, groupId }) {
    const dispatch = useDispatch();
    const [currentNotes, setCurrentNotes] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleCloseNotes = () => {
        setCurrentNotes(false);
    };

    return (
        <Fragment>
            <Dialog open={!!currentNotes} maxWidth="sm" fullWidth onClose={handleCloseNotes}>
                <DialogTitle>Exercise notes</DialogTitle>
                <DialogContent>{currentNotes}</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNotes}>Close</Button>
                </DialogActions>
            </Dialog>

            <Box>
                <Stack
                    direction={{
                        xs: 'column',
                        sm: 'row',
                    }}
                    spacing={1}
                    alignItems={{
                        xs: 'flex-start',
                        sm: 'center',
                    }}
                    sx={{ mb: 1.5 }}
                >
                    <Typography
                        fontWeight="fontWeightBold"
                        lineHeight="1em"
                        component="div"
                        color="primary.main"
                        sx={{ cursor: 'pointer' }}
                        onClick={(e) =>
                            dispatch(uiActions.setCurrentExerciseId(data?.exerciseId?._id))
                        }
                    >
                        {data?.exerciseId?.name}
                    </Typography>
                    <Button
                        size="small"
                        variant={isMobile ? 'outlined' : 'text'}
                        disabled={!data?.notes}
                        onClick={(e) => setCurrentNotes(data?.notes)}
                    >
                        <i className="far fa-sticky-note fa-mr-1 fa-xs" />
                        <Box component="span" lineHeight="1.8em">
                            Notes
                        </Box>
                    </Button>
                    {!logs?.length && selectedDate && (
                        <Button
                            size="small"
                            variant={isMobile ? 'outlined' : 'text'}
                            onClick={(e) => {
                                dispatch(
                                    uiActions.setCurrentLogData(['add', { ...data, groupId }]),
                                );
                            }}
                            color="warning"
                        >
                            <i className="fas fa-clipboard fa-mr-1 fa-xs" />
                            <Box component="span" lineHeight="1.8em">Log</Box>
                        </Button>
                    )}
                </Stack>
                <Stack
                    direction={{
                        xs: 'column',
                        sm: 'row',
                    }}
                    alignItems={{
                        xs: 'flex-start',
                        sm: 'center',
                    }}
                    spacing={1.5}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        <ExerciseParameter label="Reps" value={data?.repetitions || '-'} />
                        <ExerciseParameter label="Sets" value={data?.sets || '-'} />
                        <ExerciseParameter
                            label="Guide Wgt."
                            value={data?.guideWeight || '-'}
                            tooltip="Guide weight"
                        />
                        <ExerciseParameter label="Duration" value={data?.duration || '-'} />
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        <ExerciseParameter label="Rest" value={data?.restTime || '-'} />
                        <ExerciseParameter
                            label="Tempo"
                            value={data?.tempo || '-'}
                            tooltip={
                                ''
                                // <div>
                                //     <Typography variant="body2">Lorem ipsum dolor</Typography>
                                //     <Link
                                //         href="https://google.com"
                                //         target="_blank"
                                //         sx={{ color: 'white', mt: 1, textDecoration: 'underline' }}
                                //     >
                                //         Learn more in this video
                                //     </Link>
                                // </div>
                            }
                        />
                        <ExerciseParameter
                            label="Intensity"
                            value={data?.intensity ? `${data?.intensity}%` : '-'}
                            tooltip={
                                <div>
                                    <Typography variant="body2">
                                        Weight used - compared to one repetition maximum
                                    </Typography>
                                    {/* <Link
                                        href="https://google.com"
                                        target="_blank"
                                        sx={{ color: 'white', mt: 1, textDecoration: 'underline' }}
                                    >
                                        Learn more in this video
                                    </Link> */}
                                </div>
                            }
                        />
                    </Stack>
                </Stack>

                {logs &&
                    logs.map((v) => (
                        <Box mt={2} key={v._id}>
                            <LogEntry
                                data={v}
                                onEdit={(e) => {
                                    dispatch(uiActions.setCurrentLogData([v._id, data]));
                                }}
                            />
                        </Box>
                    ))}
            </Box>
        </Fragment>
    );
}

export default GroupItem;
