import { useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { TabbedPage } from 'common';
import { ClientProfile, ClientHeader } from 'components/Clients';
import { Program } from 'components/Program';
import { LogHistory } from 'components/LogHistory';
import { History } from 'components/Subscriptions';

import { useGetUserByIdQuery } from 'api';
import { useAutoNotify } from 'hooks';
import { FollowUps } from 'components/Reconsultation';
import { Resources } from 'components/Resources';

function ViewClient() {
    const { id: userId } = useParams();
    const { data, error, isLoading, isSuccess } = useGetUserByIdQuery(userId);
    useAutoNotify(error);

    if (isLoading) return <LinearProgress />;
    if (!isSuccess) return null;

    return (
        <TabbedPage
            pages={[
                {
                    id: 'profile',
                    name: 'Profile',
                    Component: ClientProfile,
                },
                {
                    id: 'program',
                    name: 'Program',
                    Component: Program,
                },
                {
                    id: 'logs',
                    name: 'Logs',
                    Component: LogHistory,
                },
                {
                    id: 'payments',
                    name: 'Payments',
                    Component: History,
                },
                {
                    id: 'follow-ups',
                    name: 'Follow-ups',
                    Component: FollowUps,
                },
                {
                    id: 'resources',
                    name: 'Resources',
                    Component: Resources,
                },
            ]}
            componentProps={{
                userId,
                stripeCustomerId: data?.stripeCustomerId,
            }}
        >
            <ClientHeader data={data} />
        </TabbedPage>
    );
}

export default ViewClient;
