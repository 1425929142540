import { Stack, Typography, useMediaQuery } from '@mui/material';
import { ChatAvatar } from 'components/Chat';
import { Link } from 'routes';

function ConversationConterpart({ counterpart, compact, mini, isSticky }) {
    const isLarger = useMediaQuery((t) => t.breakpoints.up('sm'));

    const TextComponent = isSticky ? Typography : Link;
    const textProps = isSticky
        ? {}
        : {
              to: `/clients/${counterpart?.id}`,
              target: '_blank',
              sx: {
                  fontWeight: 'normal',
              },
          };

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            {isLarger && <ChatAvatar name={counterpart?.name} compact={compact} mini={mini} />}
            <TextComponent variant={mini ? 'subtitle1' : 'h6'} lineHeight="1.1em" {...textProps}>
                {counterpart?.name}
            </TextComponent>
        </Stack>
    );
}

export default ConversationConterpart;
