import React, { Fragment, useMemo, useState } from 'react';
import { LinearProgress, Button, Typography, Stack, useMediaQuery } from '@mui/material';
import { ResourceCategories, ResourceDialog, ResourcesTable } from 'components/Resources';
import { useAutoNotify, useRoles } from 'hooks';
import { useAssignResourcesMutation, useGetResourcesQuery, useGetUserResourcesQuery } from 'api';

function Resources({ userId }) {
    const smallDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const largeUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const [currentResourceId, setCurrentResourceId] = useState(false);
    const [edit, setEdit] = useState(false);

    const [currentCategory, setCurrentCategory] = useState(false);
    const [showPublic, setShowPublic] = useState(true);

    const { isAdmin } = useRoles();
    const isManaging = isAdmin && !!userId;

    const allResourcesQuery = useGetResourcesQuery(undefined, { skip: !isAdmin });
    const allData = allResourcesQuery.data;
    useAutoNotify(allResourcesQuery.error);

    const useQuery = userId ? useGetUserResourcesQuery : useGetResourcesQuery;
    const { data, error, isLoading } = useQuery(userId, { skip: !userId && !isAdmin });
    useAutoNotify(error);

    const [assign, assignMutation] = useAssignResourcesMutation();
    useAutoNotify(
        assignMutation.error,
        assignMutation.isSuccess,
        'Resources updated successfully.',
    );

    const handleOpenResource = (id) => () => {
        setCurrentResourceId(id);
    };
    const handleEditResource = (id) => () => {
        setCurrentResourceId(id);
        setEdit(true);
    };
    const handleAddResource = () => {
        setCurrentResourceId(-1);
        setEdit(true);
    };
    const handleCloseDialog = () => {
        setCurrentResourceId(false);
        setEdit(false);
    };

    const [filteredData, assignedObj] = useMemo(() => {
        let assigned = {};
        if (!data) return [[], assigned];

        const filtered = data?.filter((v) => {
            if (!v.isPublic) assigned[v._id] = true;
            let show = true;
            if (!!currentCategory) show &&= v.category.includes(currentCategory);
            if (!showPublic) show &&= !v.isPublic;

            return show;
        });
        return [filtered, assigned];
    }, [currentCategory, showPublic, data]);

    const filteredAllData = useMemo(() => {
        if (!allData) return [];

        return allData?.filter((v) => {
            if (v?.isPublic) return false;

            let show = true;
            if (!!currentCategory) show &&= v.category.includes(currentCategory);

            return show && !assignedObj[v?._id];
        });
    }, [allData, currentCategory, assignedObj]);

    const handleAssignResource = (id, remove) => () => {
        const currentIds = Object.keys(assignedObj);
        const newIds = remove ? currentIds.filter((v) => v !== id) : [...currentIds, id];

        assign({
            userId,
            resources: newIds,
        });
    };

    if (isLoading || allResourcesQuery.isLoading) return <LinearProgress />;

    return (
        <Fragment>
            <ResourceDialog
                id={currentResourceId}
                edit={edit}
                handleClose={handleCloseDialog}
                handleEdit={handleEditResource(currentResourceId)}
            />

            <Stack
                mb={3}
                direction={{
                    xs: 'column-reverse',
                    sm: 'row',
                }}
                justifyContent="space-between"
                alignItems={{
                    xs: 'stretch',
                    sm: 'center',
                }}
                spacing={2}
            >
                <ResourceCategories
                    currentCategory={currentCategory}
                    handleSelectCategory={setCurrentCategory}
                    outlined={isManaging}
                    showPublic={showPublic}
                    togglePublic={() => setShowPublic((v) => !v)}
                />
                {isAdmin && !isManaging && (
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        sx={{
                            '&.MuiButtonBase-root': {
                                px: 2,
                            },
                        }}
                        onClick={handleAddResource}
                    >
                        {smallDown || largeUp ? (
                            <Fragment>
                                <i className="fas fa-plus fa-mr-1" /> Add new resource
                            </Fragment>
                        ) : (
                            <i className="fas fa-plus" />
                        )}
                    </Button>
                )}
            </Stack>
            <Stack spacing={3} mt={isManaging ? 4 : undefined}>
                <Stack spacing={1}>
                    {isManaging && (
                        <Typography variant="h6" fontWeight="600">
                            Assigned & public resources
                        </Typography>
                    )}
                    <ResourcesTable
                        data={filteredData}
                        empty={!data?.length}
                        isManaging={isManaging}
                        isAdmin={isAdmin}
                        handleOpenResource={handleOpenResource}
                        handleEditResource={handleEditResource}
                        handleAssignResource={handleAssignResource}
                        assignLoading={assignMutation?.isLoading}
                        assigned
                    />
                </Stack>
                {isManaging && (
                    <Stack spacing={1}>
                        <Typography variant="h6">Unassigned</Typography>
                        <ResourcesTable
                            data={filteredAllData}
                            empty={!allData?.length}
                            isManaging={isManaging}
                            isAdmin={isAdmin}
                            handleOpenResource={handleOpenResource}
                            handleEditResource={handleEditResource}
                            handleAssignResource={handleAssignResource}
                            assignLoading={assignMutation?.isLoading}
                        />
                    </Stack>
                )}
            </Stack>
        </Fragment>
    );
}

export default Resources;
