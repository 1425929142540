import { useEffect, useMemo, useState } from 'react';

function useDialogState(value, initialValue = false) {
    const [localValue, setLocalValue] = useState(initialValue);

    useEffect(() => {
        if (!value) return;
        setLocalValue(value);
    }, [value]);

    const DialogProps = useMemo(
        () => ({
            open: !!value,
            TransitionProps: { onExited: () => setLocalValue(false) },
        }),
        [value],
    );

    return [localValue, DialogProps];
}

export default useDialogState;
