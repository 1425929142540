import { getPollingSeconds } from 'components/Developer/helpers';

export const MESSAGE_DATA_ID_KEY = 'messageid';
export const CONVERSATION_HEADER_CLASS = 'conversation-header';
export const CONVERSATION_INPUT_CLASS = 'conversation-input';
export const MAX_MB_FILE_SIZE = 100;

export const POLLING_INTERVAL = getPollingSeconds(5) * 1000;

export const MAX_FILES = 5;
export const ACCEPTED_VIDEOS = ['.mp4', '.mov', '.avi', '.quicktime'];
export const ACCEPTED_IMAGES = ['.png', '.jpg', '.jpeg'];
export const ACCEPTED_FILES = [
    'video/*',
    'image/*',
    ...ACCEPTED_VIDEOS,
    ...ACCEPTED_IMAGES,
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.pages',
    '.numbers',
    '.pdf',
    '.csv',
    '.txt',
];
export const FILE_ICONS = {
    ...ACCEPTED_VIDEOS.reduce((a, v) => {
        a[v] = 'far fa-file-video';
        return a;
    }, {}),
    ...ACCEPTED_IMAGES.reduce((a, v) => {
        a[v] = 'far fa-file-image';
        return a;
    }, {}),
    '.doc': 'far fa-file-word',
    '.docx': 'far fa-file-word',
    '.xls': 'far fa-file-excel',
    '.xlsx': 'far fa-file-excel',
    '.pages': 'far fa-file-word',
    '.numbers': 'far fa-file-excel',
    '.pdf': 'far fa-file-pdf',
    '.csv': 'far fa-file-alt',
    '.txt': 'far fa-file-alt',
    '.plain': 'far fa-file-alt',
};
export const getFileIcon = (fileName) => {
    const ext = fileName.split('.').pop();
    return FILE_ICONS?.[`.${ext}`] || 'far fa-file';
};
