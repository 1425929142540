import { useDispatch } from 'react-redux';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { LogParameter } from 'components/Activity';
import { format } from 'date-fns';
import { uiActions } from 'ducks';
import { Fragment } from 'react';

function LogEntry({ data, onEdit, noDate }) {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    if (!data) return null;

    const { date, exerciseId, notes, sets } = data;

    return (
        <Stack
            direction={{
                xs: 'column',
                md: 'row',
            }}
            alignItems={{
                xs: 'flex-start',
                md: 'center',
            }}
            divider={
                isMobile ? undefined : (
                    <Typography variant="body2" component="span" mx={1}>
                        /
                    </Typography>
                )
            }
            spacing={isMobile ? 1 / 2 : undefined}
            sx={
                isMobile
                    ? {
                          pl: 1,
                          borderLeft: '2px solid',
                          borderColor: 'grey.500',
                      }
                    : { whiteSpace: 'nowrap', flexGrow: 1 }
            }
        >
            {!isMobile && <LogParameter icon="clipboard" />}
            {date && !noDate && (
                <Stack direction="row" alignItems="center">
                    {isMobile && (
                        <Fragment>
                            <i className="fas fa-clipboard fa-xs fa-mr-1" />{' '}
                            <Typography variant="body2" mr={1 / 2}>
                                Logged
                            </Typography>
                        </Fragment>
                    )}
                    <LogParameter value={format(new Date(date), 'd MMM. y')} />
                </Stack>
            )}
            {exerciseId && (
                <LogParameter
                    button={exerciseId?.name}
                    onClick={(e) => {
                        dispatch(uiActions.setCurrentExerciseId(exerciseId?._id));
                    }}
                />
            )}
            <Stack
                direction="row"
                alignItems="center"
                divider={
                    <Typography variant="body2" component="span" mx={1}>
                        /
                    </Typography>
                }
            >
                {isMobile && <Typography variant="body2">Reps</Typography>}
                {Array.isArray(sets) &&
                    sets.map((v) => (
                        <LogParameter
                            key={v._id}
                            value={v.reps}
                            reps
                            tooltip={
                                <div>
                                    {v?.reps && (
                                        <Typography variant="body2" fontWeight="fontWeightBold">
                                            {v.reps} reps
                                        </Typography>
                                    )}
                                    {v?.usedWeight && (
                                        <Typography variant="body2">
                                            Used weight: {v.usedWeight}
                                        </Typography>
                                    )}
                                    {v?.intensity && (
                                        <Typography variant="body2">
                                            Intensity: {v.intensity}%
                                        </Typography>
                                    )}
                                    {v?.rpe && (
                                        <Typography variant="body2">
                                            RPE (Rate of Percieved Effort): {v.rpe}
                                        </Typography>
                                    )}
                                </div>
                            }
                        />
                    ))}
            </Stack>
            {notes && (
                <LogParameter
                    tooltip={isMobile ? '' : <Typography variant="body2">{notes}</Typography>}
                    value={isMobile ? notes : 'Notes'}
                    sx={isMobile ? { py: 1 / 2 } : undefined}
                    lineHeight={isMobile ? '1.2em' : undefined}
                />
            )}
            {onEdit && <LogParameter button="Edit" onClick={onEdit} color="primary.main" />}
        </Stack>
    );
}

export default LogEntry;
