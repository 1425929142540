import { Link, Link as RouterLink } from 'react-router-dom';
import { Stack, Typography, Button } from '@mui/material';

function StepSuccess({ startOver }) {
    return (
        <Stack spacing={4} maxWidth={550}>
            <Typography>
                You will be able to edit this questionaire until you purchase a plan. After that you
                will only be able to view it.
            </Typography>
            <Typography>
                If you need to check-in regularly with the trainer via video meetings, you can
                choose the Premium plan which includes bookings.
            </Typography>
            <Stack
                direction={{
                    xs: 'column',
                    sm: 'row',
                }}
                spacing={1}
            >
                <Button variant="contained" component={RouterLink} to="/plans">
                    Purchase a plan
                </Button>
                <Button onClick={startOver}>Edit profile</Button>
                <Button component={Link} to="/profile" color="inherit">
                    View profile
                </Button>
            </Stack>
        </Stack>
    );
}

export default StepSuccess;
