import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

function CustomDatePicker({
    control,
    name,
    inputFormat = 'dd/MM/yyyy',
    mask = '__/__/____',
    placeholder,
    getErrorProps = () => ({}),
    TextFieldProps = {},
    value,
    required,
    views = ['year', 'month', 'day'],
    ...DatePickerProps
}) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value: fieldValue, ...field } }) => (
                <MuiDatePicker
                    inputFormat={inputFormat}
                    mask={mask}
                    views={views}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required={required}
                            variant="outlined"
                            placeholder={placeholder}
                            {...TextFieldProps}
                            {...getErrorProps(name)}
                        />
                    )}
                    {...DatePickerProps}
                    {...field}
                    {...(value ? { value } : { value: fieldValue || '' })}
                />
            )}
        />
    );
}

export default CustomDatePicker;
