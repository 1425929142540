import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useGetChatMessagesQuery, useMarkMessageReadMutation } from 'api';
import { getScrollInfo } from 'helpers';
import { useMe, useScrollToBottom } from 'hooks';
import { ChatMessage, MessageMenu } from 'components/Chat';
import {
    MESSAGE_DATA_ID_KEY,
    CONVERSATION_HEADER_CLASS,
    CONVERSATION_INPUT_CLASS,
} from 'components/Chat/constants';
import { useSocket } from 'common/socket';

function ConversationHistory({
    chatId,
    boxRef,
    limit,
    loadMoreMessages,
    handleEditMessage,
    editingMessageId,
    scrollTimestamp,
    userScrolledRef,
    jumpButtonBottom,
}) {
    // REFS

    const notFirstRequestRef = useRef(false);
    const loadingOlderMessagesRef = useRef(false);
    const oldScrollInfoRef = useRef([]);

    const scrollToBottom = useScrollToBottom(boxRef, userScrolledRef);

    // STATES

    const [menu, setMenu] = useState({});
    const [userScrolled, setUserScrolled] = useState(false);
    const [anyUnread, setAnyUnread] = useState([]);

    const me = useMe();
    const isMine = useCallback((v) => v?.sentBy?._id === me._id || v?.sentBy === me._id, [me._id]);

    // QUERIES

    const {
        data: [data, total] = [],
        currentData,
        isSuccess,
        isLoading,
        isFetching,
        refetch,
    } = useGetChatMessagesQuery(
        { chatId, limit },
        { skip: !chatId, refetchOnMountOrArgChange: true },
    );

    const [markMessageRead] = useMarkMessageReadMutation();

    // HANDLERS

    const handleLoadOlder = (e) => {
        loadingOlderMessagesRef.current = true;
        loadMoreMessages();

        oldScrollInfoRef.current = getScrollInfo(boxRef.current);
    };

    const handleMenuClick = useCallback(
        (v) => (e) => {
            setMenu({
                anchor: e.currentTarget,
                messageId: v?._id,
                text: v?.text,
                isMyMessage: isMine(v),
                chatId: v?.chat,
                limit,
            });
        },
        [limit, isMine],
    );

    const handleMenuClose = useCallback(() => {
        setMenu({});
    }, []);

    const handleScrollToUnread = () => {
        if (!anyUnread?.length) return;

        const newAnyUnread = [...anyUnread];
        const elem = newAnyUnread.pop();

        elem?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
        });

        setAnyUnread(newAnyUnread);
    };

    // EFFECTS

    const socket = useSocket();

    useEffect(() => {
        if (!socket) return;

        socket.emit('subscribeToChat', chatId);

        return () => {
            socket.emit('unsubscribeFromChat', chatId);
        };
    }, [chatId, socket]);

    useEffect(() => {
        if (!socket) return;

        socket.on('newMessage', () => {
            refetch();
        });
    }, [refetch, socket]);

    useEffect(() => {
        if (editingMessageId) scrollToBottom(true, true);
    }, [editingMessageId, scrollToBottom]);

    useEffect(() => {
        if (isSuccess && !loadingOlderMessagesRef.current) {
            scrollToBottom(true);
            notFirstRequestRef.current = true;
        }
    }, [chatId, isSuccess, scrollToBottom]);

    useEffect(() => {
        if (notFirstRequestRef.current && !loadingOlderMessagesRef.current) {
            scrollToBottom(false, true);
        }

        if (!!currentData && loadingOlderMessagesRef.current) {
            loadingOlderMessagesRef.current = false;
            const newScrollInfo = getScrollInfo(boxRef.current);
            boxRef.current.scrollTop =
                newScrollInfo[1] - oldScrollInfoRef.current[1] + oldScrollInfoRef.current[0];
        }
    }, [boxRef, currentData, scrollToBottom]);

    useEffect(() => {
        if (scrollTimestamp === undefined) return;

        const [st, maxSt] = getScrollInfo(boxRef.current);
        const newValue = st < maxSt - 10;

        userScrolledRef.current = newValue;
        setUserScrolled(newValue);
    }, [boxRef, scrollTimestamp, userScrolledRef]);

    const unreadMessages = useMemo(() => {
        return (data || []).reduce((a, v) => {
            if (!isMine(v) && !v?.read) a.push(v?._id);
            return a;
        }, []);
    }, [data, isMine]);

    useEffect(() => {
        if (scrollTimestamp === undefined) return;
        if (!unreadMessages?.length) {
            setAnyUnread([]);
            return;
        }

        const headerElem = document.querySelector('.' + CONVERSATION_HEADER_CLASS);
        const inputElem = document.querySelector('.' + CONVERSATION_INPUT_CLASS);
        if (!headerElem || !inputElem) return;
        const boxRect = boxRef.current?.getBoundingClientRect();
        const headerHeight = headerElem.getBoundingClientRect().height;
        const inputHeight = inputElem.getBoundingClientRect().height;
        const elems = boxRef.current?.querySelectorAll(`:scope [data-${MESSAGE_DATA_ID_KEY}]`);
        const newAnyUnread = [];

        const unreadIds = [...elems].reduce((a, v) => {
            const id = v.dataset[MESSAGE_DATA_ID_KEY];

            if (!unreadMessages?.includes(id)) return a;

            const elemRect = v?.getBoundingClientRect();
            const topLimit = boxRect.top + headerHeight;
            const bottomLimit = boxRect.bottom - inputHeight;
            const isVisible = topLimit < elemRect.top && elemRect.bottom < bottomLimit;

            if (isVisible) a.push(id);
            else newAnyUnread.unshift(v);

            return a;
        }, []);

        setAnyUnread([...newAnyUnread]);

        if (!unreadIds?.length) return;

        markMessageRead({ messageIds: unreadIds, chatId });
    }, [boxRef, chatId, markMessageRead, scrollTimestamp, unreadMessages]);

    return (
        <Fragment>
            <Box
                position="sticky"
                bgcolor="warning.main"
                color="common.white"
                borderRadius={2}
                px={2}
                py={3 / 4}
                margin="0 auto"
                top={100}
                width="max-content"
                height="36px"
                zIndex={5}
                sx={{
                    cursor: 'pointer',
                    boxShadow: (t) => `0 0 5px 0 ${t.palette.grey['500']}aa`,
                    transform: `translateY(${!!anyUnread?.length ? '0' : '-90px'})`,
                    opacity: !!anyUnread?.length ? 1 : 0,
                    transition: '0.3s transform, 0.3s opacity',
                }}
                onClick={handleScrollToUnread}
            >
                <Typography variant="subtitle2">See unread messages</Typography>
            </Box>

            {isLoading && <LinearProgress />}
            {isSuccess && total > limit && (
                <Box textAlign="center" mt={2} mb={5}>
                    <LoadingButton
                        variant="outlined"
                        onClick={handleLoadOlder}
                        loading={isFetching && loadingOlderMessagesRef.current}
                    >
                        Load older messages
                    </LoadingButton>
                </Box>
            )}

            {isSuccess && (
                <Fragment>
                    <MessageMenu
                        {...menu}
                        handleClose={handleMenuClose}
                        handleEdit={handleEditMessage}
                    />

                    {(data || []).map((v) => (
                        <ChatMessage
                            key={v?._id}
                            data={v}
                            handleMenuClick={handleMenuClick(v)}
                            editing={editingMessageId === v?._id}
                            messageDataIdKey={MESSAGE_DATA_ID_KEY}
                        />
                    ))}
                </Fragment>
            )}
            <Box
                position="sticky"
                bgcolor="primary.main"
                color="common.white"
                borderRadius={2}
                px={2}
                py={3 / 4}
                margin="0 auto"
                justifySelf="center"
                bottom={jumpButtonBottom}
                width="max-content"
                height="36px"
                mt="-36px"
                sx={{
                    cursor: 'pointer',
                    boxShadow: (t) => `0 0 5px 0 ${t.palette.primary.dark}88`,
                    transform: `translateY(${userScrolled ? '0' : '90px'})`,
                    opacity: userScrolled ? 1 : 0,
                    transition: '0.3s transform, 0.3s opacity, 0.3s background-color',

                    '&:hover': {
                        bgcolor: 'primary.dark',
                    },
                }}
                onClick={(e) => scrollToBottom()}
            >
                <Typography variant="subtitle2">↓&nbsp;&nbsp; Jump to latest messages</Typography>
            </Box>
        </Fragment>
    );
}

export default ConversationHistory;
