import { Controller } from 'react-hook-form';
import { Chip, TextField, Autocomplete as MuiAutocomplete } from '@mui/material';

function Autocomplete({
    control,
    name,
    options = [],
    label,
    placeholder,
    defaultValue,
    getErrorProps = () => ({}),
    TextFieldProps = {},
    ...AutocompleteProps
}) {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, ...field } }) => (
                <MuiAutocomplete
                    options={options}
                    onChange={(e, newVal) => onChange(newVal)}
                    renderTags={(value, getTagProps) =>
                        value.map((v, index) => (
                            <Chip {...getTagProps({ index })} label={v} size="small" />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={label}
                            placeholder={placeholder}
                            {...TextFieldProps}
                            {...getErrorProps(name)}
                        />
                    )}
                    {...AutocompleteProps}
                    {...field}
                />
            )}
        />
    );
}

export default Autocomplete;
