import { useCallback, useEffect, useRef } from 'react';
import { S3Client, GetObjectCommand, HeadObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { useGetAWSCredentialsQuery, useGetMeState, useInvalidateAWSCredentialsMutation } from 'api';
import { differenceInSeconds } from 'date-fns';
import { ACCEPTED_VIDEOS } from 'components/Chat/constants';
import { replaceExtension } from 'helpers';

const getClient = (data, awsRegion) => {
    if (!data) return false;

    const credentials = {
        accessKeyId: data?.AccessKeyId,
        secretAccessKey: data?.SecretAccessKey,
        sessionToken: data?.SessionToken,
    };
    const client = new S3Client({ region: awsRegion, credentials });

    // TODO Temporary fix as per https://github.com/aws/aws-sdk-js-v3/pull/4705
    // Starting with Chrome 113 without it we get an error
    client.middlewareStack.addRelativeTo(
        (next) => async (args) => {
            const { response } = await next(args);
            if (response.body == null) {
                response.body = new Uint8Array();
            }
            return {
                response,
            };
        },
        {
            name: 'nullFetchResponseBodyMiddleware',
            toMiddleware: 'deserializerMiddleware',
            relation: 'after',
            step: 'deserialize',
            override: true,
        },
    );

    return client;
};

function isVideo(filename) {
    const extension = filename.split('.').pop();
    return ACCEPTED_VIDEOS.includes(`.${extension}`);
}

function getOptimizedPath(filename) {
    const key = isVideo(filename) ? replaceExtension(filename, '.mp4') : filename;
    return `${process.env.REACT_APP_S3_OPTIMIZED_PATH}${key}`;
}

const getUrl = async (...args) => {
    const [credentials, awsRegion, awsBucketName, s3Key, optimized] = args;

    if (!credentials || !awsBucketName || !s3Key) return undefined;

    const client = getClient(credentials, awsRegion);

    const params = {
        Bucket: awsBucketName,
        Key: optimized ? getOptimizedPath(s3Key) : s3Key,
    };

    try {
        const headCommand = new HeadObjectCommand(params);
        await client.send(headCommand);

        const getCommand = new GetObjectCommand(params);
        const url = await getSignedUrl(client, getCommand);
        return url;
    } catch (error) {
        if (optimized) return getUrl(...args.slice(0, -1));
        return undefined;
    }
};

// active check – verifies time left on each URL request, updates credentials if needed
//      # useful for videos and most situations
// passive check - sets a timer and updates credentials before they expire
//      # useful for chat downloads
function useS3(passiveCheck) {
    const timeoutRef = useRef(null);

    const meState = useGetMeState();
    const credentialsQuery = useGetAWSCredentialsQuery(undefined, { skip: !meState?.data });
    const [invalidateCredentials] = useInvalidateAWSCredentialsMutation();

    const getDifference = useCallback(() => {
        const { Expiration: expiration } = credentialsQuery?.data || {};

        if (!expiration) return false;

        return differenceInSeconds(new Date(expiration), new Date());
    }, [credentialsQuery?.data]);

    useEffect(() => {
        if (!passiveCheck) return;

        const difference = getDifference();
        if (difference === false) return;

        timeoutRef.current = setTimeout(() => {
            invalidateCredentials();
        }, (difference - 2 * 60) * 1000);

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [credentialsQuery.data, getDifference, invalidateCredentials, passiveCheck]);

    const getter = useCallback(
        async (s3Key, useOriginal = false) => {
            const { awsBucketName, awsRegion = 'eu-west-2' } = meState?.data || {};
            if (!passiveCheck) {
                const difference = getDifference();
                if (difference === false) return '#';
                if (difference < 5 * 60) {
                    invalidateCredentials();
                    return '#';
                }
            }

            const url = await getUrl(
                credentialsQuery?.data,
                awsRegion,
                awsBucketName,
                s3Key,
                !useOriginal,
            );
            return url;
        },
        [credentialsQuery?.data, getDifference, invalidateCredentials, meState?.data, passiveCheck],
    );

    if (!credentialsQuery?.data) return false;

    return getter;
}

export default useS3;
