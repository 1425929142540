import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Divider,
    Grid,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    useMediaQuery,
} from '@mui/material';
import { Conversation, ChatsList } from 'components/Chat';
import { useGetMyChatsState } from 'api';
import { useRoles, useRouteState } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions, chatSelectors } from 'ducks';

function Chats() {
    const dispatch = useDispatch();
    const isMedium = useMediaQuery((t) => t.breakpoints.up('md'));
    const { isAdmin } = useRoles();
    const [showMobileConversation, setShowMobileConversation] = useState(!isAdmin);

    const myChats = useGetMyChatsState();

    const newRecipient = useSelector(chatSelectors.getNewRecipient);
    const showNew = useSelector(chatSelectors.getShowNew);

    const [currentConversationId, setCurrentConversationId] = useRouteState(false);

    useEffect(() => {
        if (!myChats.isSuccess) return;
        dispatch(chatActions.setNoConversations(!myChats.data?.length));

        if (!myChats.data?.length) return;
        const routeIdValid = myChats.data.some((v) => v?._id === currentConversationId);

        if (!currentConversationId || !routeIdValid) {
            setCurrentConversationId(myChats.data[0]?._id);
        }
    }, [
        dispatch,
        myChats.data,
        myChats.isSuccess,
        currentConversationId,
        setCurrentConversationId,
    ]);

    useEffect(() => {
        if (!myChats.data || !newRecipient) return;

        const chat = myChats.data.find((v) => v?.users.some((q) => q.id === newRecipient?._id));
        if (!chat) return;

        setCurrentConversationId(chat?._id);
        dispatch(chatActions.toggleShowNew(false));
    }, [dispatch, myChats.data, newRecipient, setCurrentConversationId]);

    useEffect(() => {
        if (!showNew) return;
        setShowMobileConversation(true);
    }, [showNew]);

    const currentConversation = useMemo(() => {
        if (!myChats.data) return null;

        return myChats.data.find((v) => v._id === currentConversationId);
    }, [currentConversationId, myChats.data]);

    const handleChatClick = useCallback(
        (id) => {
            setCurrentConversationId(id);
            dispatch(chatActions.toggleShowNew(false));
            setShowMobileConversation(true);
        },
        [dispatch, setCurrentConversationId],
    );

    return (
        <Fragment>
            <Paper
                sx={{
                    height: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid
                    container
                    sx={{
                        flexGrow: 1,
                        height: 0,

                        '.MuiGrid-item': {
                            maxHeight: '100%',
                        },
                    }}
                >
                    {(isMedium || !showMobileConversation) && isAdmin && (
                        <Grid item xs={12} md={5} lg={3}>
                            <ChatsList
                                query={myChats}
                                selectedId={currentConversationId}
                                onConversationSelect={handleChatClick}
                                noData={!myChats.data?.length && myChats.isSuccess}
                            />
                        </Grid>
                    )}
                    {(isMedium || showMobileConversation) && (
                        <Grid item xs={12} {...(isAdmin ? { md: 7, lg: 9 } : {})}>
                            <Conversation
                                currentConversation={currentConversation}
                                key={currentConversationId}
                            />
                        </Grid>
                    )}
                </Grid>

                {!isMedium && isAdmin && (
                    <Fragment>
                        <Divider />
                        <Box p={1}>
                            <ToggleButtonGroup
                                fullWidth
                                exclusive
                                color="primary"
                                value={showMobileConversation}
                                onChange={(e, v) => setShowMobileConversation(v)}
                                size="small"
                            >
                                <ToggleButton value={false}>Chats</ToggleButton>
                                <ToggleButton value={true}>Conversation</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Fragment>
                )}
            </Paper>
        </Fragment>
    );
}

export default Chats;
