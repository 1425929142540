import { Link } from 'routes';
import { Box, Stack, Typography } from '@mui/material';
import { PAGES_PATHS } from '@constants';

function MiniFooter(props) {
    return (
        <Typography
            textAlign="center"
            variant="body2"
            mt={7}
            {...props}
            mb={{
                xs: 7,
                md: 3,
            }}
        >
            <Stack
                direction={{
                    xs: 'column',
                    md: 'row',
                }}
                justifyContent="center"
                alignItems="center"
                spacing={{
                    xs: 1 / 4,
                    md: 1,
                }}
                component="span"
                divider={
                    <Box
                        component="span"
                        display={{
                            xs: 'none',
                            md: 'inline-block',
                        }}
                    >
                        {' • '}
                    </Box>
                }
            >
                <span>&copy; {new Date().getFullYear()} The Anabolist</span>
                <Link to={PAGES_PATHS.TERMS_CONDITIONS}>Terms & Conditions</Link>
                <Link href="https://theanabolist.com" target="_blank">
                    Website
                </Link>
                <span>
                    Developed by{' '}
                    <Link href="https://codingbees.net" target="_blank">
                        codingbees
                    </Link>
                    .
                </span>
            </Stack>
        </Typography>
    );
}

export default MiniFooter;
