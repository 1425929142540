import React, { useEffect, useRef } from 'react';
import { Route as RouterRoute, useHistory } from 'react-router-dom';
import { useInitialLocation, useMe } from 'hooks';

function Route({ children, publicOnly, ...rest }) {
    const history = useHistory();
    const user = useMe();
    const [initialLocation, setInitialLocation] = useInitialLocation();
    const redirectTo = useRef();

    useEffect(() => {
        redirectTo.current = initialLocation;
    }, [initialLocation]);

    useEffect(() => {
        if (publicOnly && user) {
            history.push(redirectTo.current || '/');
            setInitialLocation('');
        }
    }, [history, publicOnly, setInitialLocation, user]);

    return publicOnly && user ? null : <RouterRoute {...rest} render={(props) => children} />;
}

export default Route;
