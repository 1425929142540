import { useAutoNotify } from 'hooks';
import { useGetResurveyQuery } from 'api';
import { Dialog, DialogContent, LinearProgress } from '@mui/material';
import { SurveyEdit, SurveyView } from 'components/Reconsultation';
import { useCallback, useRef } from 'react';

function SurveyDialog({ id, onClose, edit, onSuccess, onEdit }) {
    const dialogRef = useRef();

    const { data, isFetching, error } = useGetResurveyQuery(id, { skip: !id });
    useAutoNotify(error);

    const Component = edit ? SurveyEdit : SurveyView;

    const resetScroll = useCallback(() => {
        const container = dialogRef.current?.querySelector('.MuiDialog-container');
        if (!container) return;
        container.scroll(0, 0);
    }, []);

    return (
        <Dialog
            open={!!id}
            onClose={onClose}
            maxWidth={edit ? 'lg' : 'sm'}
            fullWidth
            scroll="body"
            ref={dialogRef}
        >
            {isFetching ? (
                <DialogContent>
                    <LinearProgress />
                </DialogContent>
            ) : (
                <Component
                    onClose={onClose}
                    data={data}
                    onSuccess={onSuccess}
                    onEdit={onEdit}
                    resetScroll={resetScroll}
                />
            )}
        </Dialog>
    );
}

export default SurveyDialog;
