import { Box } from '@mui/material';

function SolidCircle({ selected, children }) {
    return (
        <Box
            color={selected ? 'common.white' : undefined}
            bgcolor={selected ? 'grey.700' : 'grey.200'}
            fontSize={11}
            lineHeight="2em"
            height="2em"
            width="2em"
            textAlign="center"
            borderRadius="1em"
            fontWeight="fontWeightBold"
        >
            {children}
        </Box>
    );
}

export default SolidCircle;
