import { Paper } from '@mui/material';
import { Calendar } from 'components/Booking';

function BookingUser() {
    return (
        <Paper
            sx={{
                p: {
                    xs: 2,
                    sm: 3,
                },
            }}
        >
            <Calendar />
        </Paper>
    );
}

export default BookingUser;
