import { Fragment } from 'react';
import { CssBaseline } from '@mui/material';
import { Routes } from 'routes';
import { Logout } from 'components/Auth';
import { useRouteMatch } from 'react-router-dom';
import { AUTH_PATHS } from '@constants';
import { useScrollToTop } from 'hooks';
import { ImageViewer, VideoViewer } from 'common';
// import { DeveloperBox } from 'components/Developer';

function App() {
    const match = useRouteMatch(AUTH_PATHS.LOGOUT);
    useScrollToTop();

    return (
        <Fragment>
            {/* <DeveloperBox /> */}
            <CssBaseline />

            <VideoViewer />
            <ImageViewer />

            {match ? <Logout /> : <Routes />}
        </Fragment>
    );
}

export default App;
