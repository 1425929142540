import { Chip, Stack } from '@mui/material';
import { format } from 'date-fns';
import { useRoles } from 'hooks';
import { useMemo } from 'react';

function SurveyInfo({ data }) {
    const { isAdmin } = useRoles();

    const tags = useMemo(() => {
        const result = [data?.isMandatory ? 'Mandatory' : 'Optional'];
        if (isAdmin && data?.createdAt) {
            result.push('Created ' + format(new Date(data?.createdAt), 'dd MMM.'));
        }
        if (isAdmin && data?.emailSent) {
            result.push('Email sent ' + format(new Date(data?.emailSent), 'dd MMM.'));
        }
        if (isAdmin && data?.read) result.push('Read ' + format(new Date(data?.read), 'dd MMM.'));
        if (isAdmin && data?.skipped) {
            result.push('Skipped ' + format(new Date(data?.skipped), 'dd MMM.'));
        }
        if (data?.completed) {
            result.push('Completed ' + format(new Date(data?.completed), 'dd MMM.'));
        } else {
            result.push('Deadline ' + format(new Date(data?.expiresIn), 'dd MMM.'));
        }
        return result;
    }, [data, isAdmin]);

    return (
        <Stack
            direction="row"
            alignItems="flex-start"
            component="span"
            flexWrap="wrap"
            sx={{
                m: -1 / 4,

                '& .divider': {
                    xs: {
                        display: 'none',
                    },
                    lg: {
                        display: 'block',
                    },
                },
            }}
        >
            {tags.map((v, i) => (
                <Chip
                    component="span"
                    size="small"
                    variant="outlined"
                    color={
                        !i
                            ? 'primary'
                            : tags?.length === i + 1
                            ? data?.completed
                                ? 'success'
                                : 'warning'
                            : undefined
                    }
                    key={v}
                    label={v}
                    sx={{ m: 1 / 4 }}
                />
            ))}
        </Stack>
    );
}

export default SurveyInfo;
