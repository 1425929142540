import { useMemo } from 'react';
import { Box, Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import { DATE_OPTIONS, DAYS } from '@constants';
import { DayLabel, Event } from 'components/Booking';
import { eachWeekOfInterval, addDays, isWithinInterval, isToday } from 'date-fns';

function ViewMonth({ start, end, data, handleView = () => {}, isLarge }) {
    const currentMonthInterval = useMemo(() => ({ start, end }), [end, start]);
    const weeks = eachWeekOfInterval(currentMonthInterval, DATE_OPTIONS);

    return (
        <Box mx={-3 / 4}>
            <Table sx={{ tableLayout: 'fixed' }}>
                {isLarge && (
                    <TableHead>
                        <TableRow>
                            {DAYS.map((_, dayNumber) => {
                                return (
                                    <TableCell
                                        key={dayNumber}
                                        divider="none"
                                        sx={{ p: 3 / 4, height: 0 }}
                                    >
                                        {DAYS[dayNumber]}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {weeks.map((firstWeekDay) => {
                        return (
                            <TableRow key={firstWeekDay.toString()}>
                                {DAYS.map((_, dayNumber) => {
                                    const day = addDays(firstWeekDay, dayNumber);
                                    const today = isToday(day);
                                    const events = data?.[day.getDate()];
                                    const isInThisMonth = isWithinInterval(
                                        day,
                                        currentMonthInterval,
                                    );

                                    if (!isLarge && (!isInThisMonth || !events?.length)) {
                                        return null;
                                    }

                                    return (
                                        <TableCell
                                            key={dayNumber}
                                            divider="none"
                                            sx={{
                                                p: 3 / 4,
                                                height: isLarge ? 0 : 'initial',
                                                display: isLarge ? 'table-cell' : 'block',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    border: 'solid',
                                                    borderColor: (t) =>
                                                        today
                                                            ? t.palette.primary.main
                                                            : t.palette.grey['300'],
                                                    borderWidth: today ? 2 : 1,
                                                    borderRadius: 1 / 2,
                                                    p: 1,
                                                    height: '100%',
                                                    opacity: isInThisMonth ? 1 : 0.4,
                                                }}
                                            >
                                                <Box
                                                    sx={
                                                        isLarge
                                                            ? {
                                                                  textAlign: 'right',
                                                              }
                                                            : {
                                                                  display: 'flex',
                                                                  flexDirection: 'row-reverse',
                                                                  justifyContent: 'flex-end',
                                                              }
                                                    }
                                                >
                                                    {!isLarge && (
                                                        <Box ml={1 / 2} component="span">
                                                            {DAYS[dayNumber]}
                                                        </Box>
                                                    )}
                                                    <DayLabel day={day} fontWeight="bold" />
                                                </Box>
                                                {events &&
                                                    isInThisMonth &&
                                                    events.map((event) => (
                                                        <Event
                                                            key={event?._id}
                                                            event={event}
                                                            today={today}
                                                            handleView={handleView}
                                                            my={1 / 2}
                                                            showStartTime
                                                            fontSize="0.9em"
                                                        />
                                                    ))}
                                            </Box>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Box>
    );
}

export default ViewMonth;
