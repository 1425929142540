import { Fragment, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { uiActions } from 'ducks';

import { SolidCircle } from 'common';
import { GroupItem } from 'components/Program';

import {
    Box,
    Stack,
    Typography,
    Paper,
    Button,
    Collapse,
    Divider,
    useMediaQuery,
    Tooltip,
} from '@mui/material';

const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function Group({ data, defaultOpen, selectedDate, logs, groupNotes }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(defaultOpen);
    const { days = [] } = data;
    const date = new Date(selectedDate);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const logsByExerciseInstanceId = useMemo(
        () =>
            logs?.reduce((a, v) => {
                const key = v?.exerciseInstanceId?._id;
                if (a[key]) {
                    a[key].push(v);
                } else {
                    a[key] = [v];
                }
                return a;
            }, {}),
        [logs],
    );

    return (
        <Fragment>
            <Paper
                variant="outlined"
                sx={{
                    bgcolor: '#fafafa',
                    p: 1.5,
                    ...(data?.isCorrective ? { borderColor: 'warning.light' } : {}),
                }}
            >
                <Stack
                    direction={{
                        xs: 'column',
                        sm: 'row',
                    }}
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    {selectedDate && isMobile && (
                        <Typography variant="overline" lineHeight="1em">
                            {format(date, 'eee, d MMM')}
                        </Typography>
                    )}
                    <div>
                        <Stack
                            direction="row"
                            alignItems="flex-start"
                            justifyContent="space-between"
                        >
                            <div>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="fontWeightBold"
                                    color={data?.isCorrective ? 'text.secondary' : undefined}
                                >
                                    {data?.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {data?.goals}
                                </Typography>
                            </div>
                        </Stack>
                        <Stack
                            direction={{
                                xs: 'column',
                                sm: 'row',
                            }}
                            alignItems={{ xs: 'flex-start', sm: 'center' }}
                            spacing={2}
                            mt={1}
                        >
                            <Stack direction="row" spacing={1 / 2}>
                                {DAYS.map((v) => (
                                    <SolidCircle key={v} selected={days.indexOf(v) > -1}>
                                        {v[0]}
                                    </SolidCircle>
                                ))}
                            </Stack>
                            {selectedDate &&
                                (!groupNotes?.length ? (
                                    <Button
                                        size="small"
                                        variant={isMobile ? 'outlined' : 'text'}
                                        onClick={(e) => {
                                            dispatch(
                                                uiActions.setCurrentGroupNoteData([
                                                    'add',
                                                    { groupId: data?._id, groupTitle: data?.title },
                                                ]),
                                            );
                                        }}
                                        color="warning"
                                    >
                                        <i className="fas fa-clipboard fa-mr-1 fa-xs" />
                                        <Box component="span" lineHeight="1.8em">
                                            Add note
                                        </Box>
                                    </Button>
                                ) : (
                                    <Tooltip
                                        title={`Note: ${groupNotes?.[0]?.note}`}
                                        placement="top"
                                    >
                                        <Button
                                            size="small"
                                            variant="text"
                                            onClick={(e) => {
                                                dispatch(
                                                    uiActions.setCurrentGroupNoteData([
                                                        groupNotes[0]._id,
                                                        {
                                                            groupId: data?._id,
                                                            groupTitle: data?.title,
                                                        },
                                                    ]),
                                                );
                                            }}
                                        >
                                            <i className="fas fa-clipboard fa-mr-1 fa-xs" />
                                            Edit note
                                        </Button>
                                    </Tooltip>
                                ))}
                        </Stack>
                    </div>
                    {!defaultOpen && (
                        <Button
                            variant={open ? 'outlined' : 'contained'}
                            size="small"
                            onClick={(e) => setOpen((prev) => !prev)}
                        >
                            {open ? 'Hide' : 'Show'}
                        </Button>
                    )}
                    {selectedDate && !isMobile && (
                        <Stack textAlign="center" mt={1 / 2} spacing={1 / 4}>
                            <Typography variant="overline" lineHeight="1em">
                                {format(date, 'MMM')}
                            </Typography>
                            <Typography variant="h6" lineHeight="1em" mt={1 / 2}>
                                {format(date, 'd')}
                            </Typography>
                        </Stack>
                    )}
                </Stack>
                <Collapse in={open}>
                    <Box mt={2}>
                        <Divider sx={{ mx: -1.5 }} />
                        <Stack
                            spacing={4}
                            sx={{ bgcolor: 'common.white', mx: -1.5, p: 1.5, py: 2 }}
                        >
                            {data?.exercises.map((v) => (
                                <GroupItem
                                    key={v._id}
                                    data={v}
                                    logs={logsByExerciseInstanceId?.[v._id]}
                                    selectedDate={selectedDate}
                                    groupId={data?._id}
                                />
                            ))}
                        </Stack>
                        <Divider sx={{ mx: -1.5, mb: 1.5 }} />
                        <Typography
                            fontWeight="fontWeightBold"
                            variant="subtitle2"
                            color="text.secondary"
                        >
                            Notes
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {data?.notes ?? '-'}
                        </Typography>
                    </Box>
                </Collapse>
            </Paper>
        </Fragment>
    );
}

export default Group;
