import { Menu, MenuItem } from '@mui/material';
import { useDeleteMessageMutation } from 'api';
import { useAutoNotify, useConfirm, useRoles } from 'hooks';
import { useEffect } from 'react';

function MessageMenu({
    anchor,
    messageId,
    text,
    isMyMessage,
    chatId,
    limit,
    handleClose,
    handleEdit,
}) {
    const { isAdmin } = useRoles();
    const [confirm, closeConfirm] = useConfirm();
    const [deleteMessage, deleteMessageMutation] = useDeleteMessageMutation();

    useAutoNotify(deleteMessageMutation.error, deleteMessageMutation.isSuccess, 'Message deleted.');

    const handleEditClick = () => {
        handleEdit(messageId, text);
        handleClose();
    };

    const handleDeleteClick = async () => {
        try {
            await confirm('Are you sure you want to delete this message?', 'Delete message', true);
            deleteMessage({ messageId, chatId, limit });
        } catch (error) {}
    };

    useEffect(() => {
        if (deleteMessageMutation.isSuccess || deleteMessageMutation.isError) {
            handleClose();
            closeConfirm();
        }
    }, [closeConfirm, deleteMessageMutation, handleClose]);

    return (
        <Menu
            anchorEl={anchor}
            open={!!anchor}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: isMyMessage ? 'right' : 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: isMyMessage ? 'right' : 'left',
            }}
        >
            <MenuItem onClick={handleEditClick}>Edit</MenuItem>
            {isAdmin && (
                <MenuItem
                    sx={{ '&.MuiMenuItem-root': { color: 'error.main' } }}
                    onClick={handleDeleteClick}
                >
                    Delete
                </MenuItem>
            )}
        </Menu>
    );
}

export default MessageMenu;
