import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    Alert,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useAutoNotify, useConfirm, useMe, useRoles } from 'hooks';
import { useDeleteBookingMutation } from 'api';
import { Fragment, useEffect, useMemo } from 'react';
import { differenceInHours, format } from 'date-fns';
import { Link } from 'routes';
import { makeGMTString } from 'helpers';

function ViewBookingDialog({ open, handleClose = () => {}, data = {} }) {
    const { isAdmin } = useRoles();
    const me = useMe();
    const [confirm] = useConfirm();
    const isBooking = data?.type === 1;
    const userObj = data?.user === me?._id ? me : data?.user?.[0];

    const [deleteBooking, { error, isLoading, isSuccess, reset }] = useDeleteBookingMutation();

    useAutoNotify(error, isSuccess, isAdmin && !isBooking ? 'Slot unlocked.' : 'Booking canceled.');

    const handleDelete = async () => {
        try {
            if (isBooking) {
                await confirm('Are you sure you want to cancel this booking?', 'Cancel booking');
            }
            deleteBooking(data?._id);
        } catch (error) {}
    };

    useEffect(() => {
        if (isSuccess) {
            handleClose();
            reset();
        }
    }, [handleClose, isSuccess, reset]);

    const [startDate, endDate] = useMemo(() => {
        if (!data.startDate) return [];
        return [new Date(data.startDate), new Date(data.endDate)];
    }, [data.endDate, data.startDate]);

    if (!startDate) return null;

    const cannotBeCancelled = differenceInHours(startDate, new Date()) <= (isAdmin ? 0 : 24);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
            <DialogTitle>{isBooking ? 'Booking' : 'Locked slot'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="overline">
                        {startDate && format(startDate, 'E, dd MMM YYY')}
                    </Typography>
                </DialogContentText>
                <DialogContentText sx={{ mb: 1 }}>
                    {format(startDate, 'HH:mm')} - {format(endDate, 'HH:mm')}{' '}
                    <Typography component="span" color="text.disabled">
                        ({makeGMTString()})
                    </Typography>
                </DialogContentText>
                {!!data?.user && isBooking && (
                    <Fragment>
                        <DialogContentText>
                            <Link
                                to={isAdmin ? `/clients/${userObj?._id}` : '#'}
                                target={isAdmin ? '_blank' : undefined}
                            >
                                <i className="fas fa-user fa-bold-fix fa-mr-1 fa-xs" />
                                {userObj?.name || 'Unknown'}
                            </Link>
                        </DialogContentText>
                        <DialogContentText>
                            <Link
                                href={`mailto:${userObj?.email || '-'}`}
                                sx={{ fontWeight: 'regular' }}
                            >
                                <i className="far fa-envelope fa-bold-fix fa-mr-1 fa-xs" />
                                {userObj?.email || '-'}
                            </Link>
                        </DialogContentText>
                        <DialogContentText sx={{ mt: 1.5, mb: 1 }}>
                            <b>Discussion topics:</b> {(data?.discussionTypes || ['-']).join(', ')}
                        </DialogContentText>
                        <DialogContentText>
                            <b>Notes:</b> {data?.notes || '-'}
                        </DialogContentText>
                        {cannotBeCancelled && !isAdmin && (
                            <Alert severity="info" sx={{ mt: 2 }}>
                                Booking cannot be cancelled within less than 24 hours prior to the
                                meeting or after the meeting has passed.
                            </Alert>
                        )}
                    </Fragment>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <LoadingButton
                    onClick={handleDelete}
                    color={isBooking ? 'error' : 'primary'}
                    loading={isLoading}
                    disabled={cannotBeCancelled}
                >
                    {isBooking ? 'Cancel booking' : 'Unlock'}
                </LoadingButton>
                <Button onClick={handleClose} color="inherit" disabled={isLoading}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ViewBookingDialog;
