import { Stack, TextField, MenuItem } from '@mui/material';
import { Checkboxes, Select } from 'form';

function Step5({ formMethods }) {
    const { control, registerField, getError } = formMethods;

    return (
        <Stack spacing={4} flexGrow={1}>
            <div>
                <div>
                    <Checkboxes
                        label="What are your short and long-term goals?"
                        name="goals"
                        control={control}
                        getError={getError}
                        options={[
                            'Body recomposition',
                            'Gain strength',
                            'Lose body fat',
                            'Become faster',
                            'Move better',
                        ]}
                    />
                </div>
                <TextField
                    label="Other goals"
                    {...registerField('otherGoals')}
                    multiline
                    minRows={1}
                    fullWidth
                    sx={{ mt: 2 }}
                />
            </div>
            <TextField
                label="What is your diet like?"
                {...registerField('diet')}
                multiline
                minRows={3}
                helperText="List in detail your dietary habits, along with your typical meals in a day. You may include portion sizes as well as caloric intake (if known)."
            />
            <TextField
                label="Most recent exercise program"
                {...registerField('otherExerciseProgram')}
                multiline
                minRows={3}
                helperText="Describe in detail your most recent exercise program. What progress did you make?"
            />
            <Select
                label="How many times per week are you able to train?"
                {...registerField('trainDuration')}
                control={control}
            >
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
            </Select>
            <TextField
                label="How much time do you have available per training session?"
                type="text"
                {...registerField('sessionTime')}
            />
            <TextField
                label="What's the breakdown of your training sessions?"
                {...registerField('breakdownSession')}
                multiline
                minRows={3}
                helperText="Are you able to train at home or at the gym? Please include other training sessions that you may have such as football practice, basketball, etc."
            />
            <TextField
                label="What equipment do you have available?"
                {...registerField('equipmentAvailable')}
                multiline
                minRows={3}
            />
        </Stack>
    );
}

export default Step5;
