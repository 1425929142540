import { useState, useRef, useEffect } from 'react';
import { Alert, AlertTitle, Link } from '@mui/material';
import { getURLParams, track } from 'helpers';

const currency = 'GBP';

function CheckoutInfo() {
    const [dismissed, setDismissed] = useState(false);
    const query = useRef(getURLParams());

    useEffect(() => {
        const isSuccess = query.current.get('success');

        if (isSuccess) {
            const price = query.current.get('price');
            const sessionId = query.current.get('session_id');

            track({
                fb: [
                    'Purchase',
                    {
                        transaction_id: sessionId,
                        currency,
                        value: price,
                        // contents: [
                        //     {
                        //         id: '301',
                        //     },
                        //     {
                        //         id: '401',
                        //     },
                        // ],
                    },
                ],
                ga: [
                    'purchase',
                    {
                        transaction_id: sessionId,
                        currency,
                        value: price,
                        // items: [
                        //     {
                        //         item_id: 'SKU_12345',
                        //         item_name: 'qwe',
                        //     },
                        // ],
                    },
                ],
            });
        }
    }, []);

    if (dismissed) return null;

    if (query.current.get('success')) {
        return (
            <Alert severity="success" sx={{ mb: 3 }} onClose={() => setDismissed(true)}>
                <AlertTitle>Purchase successful.</AlertTitle>
                Your purchase was successful and the appropriate amount of credits has been added to
                your balance.{' '}
                <b>
                    If the balance hasn't updated, please{' '}
                    <Link href="#" onClick={(e) => window.location.reload()}>
                        refresh the page
                    </Link>
                    .
                </b>
            </Alert>
        );
    }
    if (query.current.get('cancelled')) {
        return (
            <Alert severity="warning" sx={{ mb: 3 }} onClose={() => setDismissed(true)}>
                <AlertTitle>Purchase cancelled.</AlertTitle>
                Your purchase was cancelled. Try again whenever you're ready.
            </Alert>
        );
    }

    return null;
}

export default CheckoutInfo;
