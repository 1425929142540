import { Fragment } from 'react';
import { SurveyInfo } from 'components/Reconsultation';
import { useUpdateResurveyMutation } from 'api';
import { useAutoNotify, useRoles } from 'hooks';
import { LoadingButton } from '@mui/lab';

import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Stack,
    Typography,
} from '@mui/material';

const showData = (obj, property, fallback = '-') => obj?.[property] || fallback;

function SurveyView({ onClose, data, onEdit, resetScroll }) {
    const { isAdmin } = useRoles();
    const [submit, { isLoading, error, isSuccess }] = useUpdateResurveyMutation();
    useAutoNotify(error, isSuccess, 'Form submitted successfully!');

    const handleEdit = () => {
        onEdit();
        resetScroll();
    };

    const handleToggleMandatory = () => {
        submit({
            _id: data?._id,
            isMandatory: !data?.isMandatory,
        });
    };

    return (
        <Fragment>
            <DialogTitle variant="h5">Follow-up answers</DialogTitle>
            <DialogContent>
                <Box mb={2}>
                    <SurveyInfo data={data} />
                </Box>
                {!data?.completed ? (
                    <Typography>
                        This survey hasn't been completed yet.
                        <LoadingButton
                            loading={isLoading}
                            component="span"
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{ ml: 1 }}
                            onClick={handleToggleMandatory}
                        >
                            Make {data?.isMandatory ? 'optional' : 'mandatory'}
                        </LoadingButton>
                    </Typography>
                ) : (
                    <Fragment>
                        <Stack spacing={2}>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography paragraph variant="subtitle1">
                                    What would you like to focus on in the next phase of your
                                    program?
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {showData(data, 'focusNext')}
                                </Typography>
                            </Paper>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography paragraph variant="subtitle1">
                                    What were your favourite exercises from the past phase?
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {showData(data, 'favouriteExercises')}
                                </Typography>
                            </Paper>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography paragraph variant="subtitle1">
                                    What were your least favourite exercises and why?
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {showData(data, 'leastFavouriteExercises')}
                                </Typography>
                            </Paper>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography paragraph variant="subtitle1">
                                    List any time periods during which you won’t be available to
                                    train, or it would be difficult to commit
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {showData(data, 'unavailabilityToTrain')}
                                </Typography>
                            </Paper>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography paragraph variant="subtitle1">
                                    Which days would suit best for training?
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {showData(data, 'bestDays')}
                                </Typography>
                            </Paper>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography variant="subtitle1">
                                    List any significant changes that occured from your past
                                    training cycle
                                </Typography>
                                <Typography paragraph variant="caption">
                                    (moved to a different gym, different equipment, time
                                    constraints, new job, etc.)
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {showData(data, 'significantChanges')}
                                </Typography>
                            </Paper>
                        </Stack>

                        <Typography variant="h5" mt={5} mb={2}>
                            Survey
                        </Typography>

                        <Stack spacing={2}>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography paragraph variant="subtitle1">
                                    How has your overall experience been so far?
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {showData(data, 'overallExperience')}
                                </Typography>
                            </Paper>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography paragraph variant="subtitle1">
                                    If we could undergo any changes that would help better with your
                                    experience, what would those changes be?
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {showData(data, 'anyChanges')}
                                </Typography>
                            </Paper>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography paragraph variant="subtitle1">
                                    What features would you like to see on our platform in the near
                                    future?
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {showData(data, 'otherFeatures')}
                                </Typography>
                            </Paper>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography paragraph variant="subtitle1">
                                    Would you recommend The Anabolist to your friends?
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {showData(data, 'recommendToFriends')}
                                </Typography>
                            </Paper>
                            {data?.recommendToFriends === 'No' && (
                                <Paper variant="outlined" sx={{ p: 2 }}>
                                    <Typography paragraph variant="subtitle1">
                                        Can you tell us why you chose No?
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {showData(data, 'recommendToFriendsReason')}
                                    </Typography>
                                </Paper>
                            )}
                        </Stack>
                    </Fragment>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                {isAdmin ? (
                    <Button onClick={handleEdit} color="primary">
                        Edit
                    </Button>
                ) : (
                    <div />
                )}
                <Button onClick={onClose} color="inherit">
                    Close
                </Button>
            </DialogActions>
        </Fragment>
    );
}

export default SurveyView;
