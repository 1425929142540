import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { isToday } from 'date-fns';

function DayLabel({ day, ...props }) {
    const dateProps = useMemo(
        () =>
            isToday(day)
                ? {
                      bgcolor: 'primary.dark',
                      color: 'primary.contrastText',
                      display: 'inline-block',
                      width: '1.5em',
                      height: '1.5em',
                      textAlign: 'center',
                      borderRadius: 1,
                  }
                : { display: 'inline-block' },
        [day],
    );

    return (
        <Typography
            fontWeight="light"
            component="span"
            fontSize="inherit"
            letterSpacing="-0.05em"
            {...dateProps}
            {...props}
        >
            {day.getDate()}
        </Typography>
    );
}

export default DayLabel;
