import { useMemo } from 'react';

import {
    Stack,
    TextField,
    FormControl,
    FormLabel,
    InputAdornment,
    ToggleButtonGroup,
    ToggleButton,
    Alert,
    AlertTitle,
} from '@mui/material';

import { Step2CompositionField, Step2CircumferenceField } from 'components/Questionnaire';
import { impToCm, impToKg } from 'helpers';

function Step2({ formMethods, kg, setKg, cm, setCm }) {
    const { registerField, resetField, watch } = formMethods;

    const handleLengthUnitChange = (e, v) => {
        setCm(v);

        const reset = (name) =>
            resetField(name, { defaultValue: { ft: undefined, in: undefined, cm: undefined } });

        reset('height');
        reset('waist');
        reset('hips');
        reset('neck');
        reset('chest');
        reset('upperArms');
        reset('thighs');
        reset('calves');
    };

    const handleWeightUnitChange = (e, v) => {
        setKg(v);

        const reset = (name) =>
            resetField(name, { defaultValue: { st: undefined, lb: undefined, kg: undefined } });

        reset('weight');
        reset('leanBodyMass');
        reset('fatMass');
        reset('fatDistribution');
    };

    const weight = watch('weight');
    const height = watch('height');
    const [bmi, bmiLabel] = useMemo(() => {
        const metricHeight = Math.round(cm ? height?.cm : impToCm(height?.ft, height?.in));
        const metricWeight =
            Math.round((kg ? weight?.kg : impToKg(weight?.st, weight?.lb)) * 10) / 10;

        const value = Math.round((metricWeight / metricHeight / metricHeight) * 100000) / 10;
        let label;

        if (isNaN(value)) {
            label = false;
        } else if (value < 18.5) {
            label = 'underweight';
        } else if (value < 25) {
            label = 'normal';
        } else if (value < 30) {
            label = 'overweight';
        } else {
            label = 'obese';
        }

        return [value, label];
    }, [weight?.kg, weight?.st, weight?.lb, height?.cm, height?.ft, height?.in, kg, cm]);

    const waist = watch('waist');
    const hips = watch('hips');

    const [whr, whrLabel] = useMemo(() => {
        const metricWaist = Math.round(cm ? waist?.cm : impToCm(0, waist?.in));
        const metricHips = Math.round(cm ? hips?.cm : impToCm(0, hips?.in));

        const value = Math.round((metricWaist / metricHips) * 100) / 100;
        let label;

        if (isNaN(value)) {
            label = false;
        } else if (value < 1) {
            label = 'are within moderate range';
        } else {
            label =
                'have an increased risk of heart disease and other conditions that are linked to being overweight';
        }

        return [value, label];
    }, [waist?.cm, waist?.in, hips?.cm, hips?.in, cm]);

    return (
        <Stack spacing={4} flexGrow={1}>
            <Stack
                direction={{
                    xs: 'column',
                    sm: 'row',
                }}
                spacing={2}
            >
                <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={cm}
                    onChange={handleLengthUnitChange}
                >
                    <ToggleButton value={false}>Feet, Inch</ToggleButton>
                    <ToggleButton value={true}>Centimeters</ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={kg}
                    onChange={handleWeightUnitChange}
                >
                    <ToggleButton value={false}>Stones, Pounds</ToggleButton>
                    <ToggleButton value={true}>Kilograms</ToggleButton>
                </ToggleButtonGroup>
            </Stack>

            <FormControl component="fieldset" variant="standard">
                <Stack spacing={3}>
                    <FormLabel component="legend">What is your height and weight?</FormLabel>
                    {cm ? (
                        <TextField
                            InputProps={{
                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                            }}
                            label="Height *"
                            type="number"
                            sx={{ maxWidth: { xs: 'none', sm: 200 } }}
                            {...registerField('height.cm')}
                        />
                    ) : (
                        <Stack direction="row" spacing={2}>
                            <TextField
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">ft</InputAdornment>
                                    ),
                                }}
                                label="Height *"
                                type="number"
                                sx={{ maxWidth: { xs: 'none', sm: 200 }, width: '100%' }}
                                {...registerField('height.ft')}
                            />
                            <TextField
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">in</InputAdornment>
                                    ),
                                }}
                                type="number"
                                sx={{ maxWidth: { xs: 'none', sm: 200 }, width: '100%' }}
                                {...registerField('height.in')}
                            />
                        </Stack>
                    )}
                    {kg ? (
                        <TextField
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                            }}
                            label="Weight *"
                            type="number"
                            sx={{ maxWidth: { xs: 'none', sm: 200 } }}
                            {...registerField('weight.kg')}
                        />
                    ) : (
                        <Stack direction="row" spacing={2}>
                            <TextField
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">st</InputAdornment>
                                    ),
                                }}
                                label="Weight *"
                                type="number"
                                sx={{ maxWidth: { xs: 'none', sm: 200 }, width: '100%' }}
                                {...registerField('weight.st')}
                            />
                            <TextField
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">lb</InputAdornment>
                                    ),
                                }}
                                type="number"
                                sx={{ maxWidth: { xs: 'none', sm: 200 }, width: '100%' }}
                                {...registerField('weight.lb')}
                            />
                        </Stack>
                    )}
                </Stack>
            </FormControl>

            {!isNaN(bmi) && bmi > 0 && bmi < Infinity && (
                <Alert severity="info">
                    <AlertTitle>
                        Your Body Mass Index (BMI) is <b>{bmi}</b>
                    </AlertTitle>
                    Your result suggests you are within the <b>{bmiLabel}</b> range.
                </Alert>
            )}

            <FormControl component="fieldset" variant="standard">
                <Stack spacing={3}>
                    <FormLabel component="legend">Body composition (if known)</FormLabel>
                    <Step2CompositionField
                        registerField={registerField}
                        name="leanBodyMass"
                        kg={kg}
                        label="Lean Body Mass"
                    />
                    <Step2CompositionField
                        registerField={registerField}
                        name="fatMass"
                        kg={kg}
                        label="Fat Mass"
                    />
                    <Step2CompositionField
                        registerField={registerField}
                        name="fatDistribution"
                        kg={kg}
                        label="Fat Distribution"
                    />
                    <Step2CompositionField
                        registerField={registerField}
                        name="bodyFatPercentage"
                        unit="%"
                        label="Body Fat Percentage"
                    />
                    <TextField label="Other" {...registerField('otherBodyComposition')} />
                </Stack>
            </FormControl>

            <FormControl component="fieldset" variant="standard">
                <Stack spacing={3}>
                    <FormLabel component="legend">Circumference measurement (if known)</FormLabel>
                    <Stack
                        direction={{
                            xs: 'column',
                            sm: 'row',
                        }}
                        spacing={2}
                    >
                        <Step2CircumferenceField
                            registerField={registerField}
                            name="waist"
                            cm={cm}
                            label="Waist"
                        />
                        <Step2CircumferenceField
                            registerField={registerField}
                            name="hips"
                            cm={cm}
                            label="Hips"
                        />
                    </Stack>
                    <Stack
                        direction={{
                            xs: 'column',
                            sm: 'row',
                        }}
                        spacing={2}
                    >
                        <Step2CircumferenceField
                            registerField={registerField}
                            name="neck"
                            cm={cm}
                            label="Neck"
                        />
                        <Step2CircumferenceField
                            registerField={registerField}
                            name="chest"
                            cm={cm}
                            label="Chest"
                        />
                        <Step2CircumferenceField
                            registerField={registerField}
                            name="upperArms"
                            cm={cm}
                            label="Upper Arms"
                        />
                    </Stack>
                    <Stack
                        direction={{
                            xs: 'column',
                            sm: 'row',
                        }}
                        spacing={2}
                    >
                        <Step2CircumferenceField
                            registerField={registerField}
                            name="thighs"
                            cm={cm}
                            label="Thighs"
                        />
                        <Step2CircumferenceField
                            registerField={registerField}
                            name="calves"
                            cm={cm}
                            label="Calves"
                        />
                    </Stack>
                </Stack>
            </FormControl>

            {!isNaN(whr) && whr > 0 && whr < Infinity && (
                <Alert severity="info">
                    <AlertTitle>
                        Your Waist-to-Hip Ratio (WHR) is <b>{whr}</b>
                    </AlertTitle>
                    Your result suggests you {whrLabel}.
                </Alert>
            )}
        </Stack>
    );
}

export default Step2;
