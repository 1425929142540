import { VideoBox } from 'common';
import { useS3Query } from 'hooks';

function S3VideoBox({ video, noFallback, useOriginal = false, ...props }) {
    const urlQuery = useS3Query(video, undefined, useOriginal);

    return <VideoBox noFallback={noFallback} video={urlQuery?.url} {...props} />;
}

export default S3VideoBox;
