import { Box, LinearProgress, List, Typography } from '@mui/material';
import { ChatsListItem, ChatsListHeader } from 'components/Chat';
import { useMe } from 'hooks';
import { getChatCounterpart } from 'hooks/useGetChatCounterpart';
import { useCallback, useMemo, useState } from 'react';

function NoResults({ children }) {
    return (
        <Box p={3}>
            <Typography>
                <i className="fas fa-search fa-sm fa-mr-half" /> {children}
            </Typography>
        </Box>
    );
}

function ChatsList({ query, selectedId, onConversationSelect }) {
    const [filter, setFilter] = useState('');
    const me = useMe();

    const filteredDdata = useMemo(() => {
        if (!filter) return query.data;

        return query.data?.filter((v) => {
            const counterpart = getChatCounterpart(v?.users, me);
            return `${counterpart?.name} ${counterpart?.email}`.toLowerCase().indexOf(filter) > -1;
        });
    }, [filter, me, query.data]);

    const handleClearFilter = useCallback(() => {
        setFilter('');
    }, []);

    return (
        <Box
            sx={{
                overflow: 'scroll',
                height: '100%',
                borderRight: '1px solid',
                borderRightColor: 'grey.200',
            }}
        >
            <ChatsListHeader value={filter} setValue={setFilter} onClear={handleClearFilter} />
            {query.isLoading && <LinearProgress />}
            {query.isSuccess && query.data?.length === 0 ? (
                <NoResults>No recent chats</NoResults>
            ) : (
                filteredDdata?.length === 0 && (
                    <NoResults>
                        No results.{' '}
                        <Typography
                            component="span"
                            color="primary.main"
                            sx={{ cursor: 'pointer' }}
                            onClick={handleClearFilter}
                        >
                            Clear filter
                        </Typography>
                    </NoResults>
                )
            )}
            <List sx={{ px: 1 }}>
                {filteredDdata?.map((v) => (
                    <ChatsListItem
                        key={v?._id}
                        data={v}
                        selected={selectedId === v?._id}
                        onConversationSelect={(e) => onConversationSelect(v?._id)}
                    />
                ))}
            </List>
        </Box>
    );
}

export default ChatsList;
