import { Link as RouterLink } from 'react-router-dom';
import { Link as MaterialLink } from '@mui/material';

function Link({ children, href, ...props }) {
    return (
        <MaterialLink component={!href ? RouterLink : undefined} href={href} {...props}>
            {children}
        </MaterialLink>
    );
}

export default Link;
