import { Fragment, useEffect, useCallback, useMemo } from 'react';
import {
    addWeeks,
    subDays,
    isWithinInterval,
    addDays,
    differenceInCalendarWeeks,
    addHours,
    setHours,
    setMinutes,
} from 'date-fns';
import { useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
    Box,
    Stack,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    FormLabel,
    Alert,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { PickersDay } from '@mui/x-date-pickers';

import { useAutoNotify, useForm, useConfirm } from 'hooks';
import { useAddBlockMutation, useUpdateBlockMutation, useDeleteBlockMutation } from 'api';
import { DatePicker, Select, Switch } from 'form';
import { BlockFormGroup } from 'components/Program';

const schema = yup.object({
    startDate: yup.date().required().typeError('Invalid date'),
    duration: yup.number().min(1, 'Pick a duration'),
    credits: yup.number().required().typeError('Pick a cost'),
    groups: yup
        .array()
        .of(
            yup.object({
                title: yup.string().required('Name is required'),
                days: yup
                    .array()
                    .test('at-least-one', 'Select at least one day', (value) =>
                        value.reduce((a, v) => a || v, false),
                    ),
                exercises: yup
                    .array()
                    .of(
                        yup.object().shape({
                            exerciseId: yup
                                .object()
                                .shape({
                                    _id: yup.string().required(),
                                })
                                .required()
                                .typeError('Pick an exercise'),
                        }),
                    )
                    .min(1, 'Add at least one exercise'),
            }),
        )
        .min(1, 'Add at least one group'),
});

const dataToForm = (data, intervals) => {
    if (!data) {
        const startDate = intervals.reduce((a, v) => {
            if (isWithinInterval(a, v)) {
                a = setMinutes(setHours(addDays(v.end, 1), 1), 0);
            }
            return a;
        }, new Date());

        return {
            startDate,
            duration: 1,
            credits: '',
            hasRPE: false,
            hasIntensity: false,
            groups: [],
            active: true,
        };
    }

    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);

    return {
        ...data,
        startDate,
        duration: differenceInCalendarWeeks(addDays(endDate, 1), startDate) / 4,
    };
};

const formToData = (data, extraFields) => {
    const { active, credits, hasIntensity, hasRPE } = data;
    return {
        startDate: new Date(data.startDate).toISOString(),
        endDate: new Date(data.endDate).toISOString(),
        groups: data.groups.map(({ exercises, ...group }) => ({
            ...group,
            exercises: exercises.map(({ exerciseId, ...exercise }) => ({
                ...exercise,
                exerciseId: exerciseId?._id,
            })),
        })),
        active,
        credits,
        hasIntensity,
        hasRPE,
        ...extraFields,
    };
};

function BlockForm({ data, handleClose, clientId, blocks }) {
    const { action, id } = useParams();
    const [confirm] = useConfirm();
    const edit = action === 'edit';

    const useMutation = edit ? useUpdateBlockMutation : useAddBlockMutation;
    const [submit, { error, isLoading, isSuccess }] = useMutation();
    useAutoNotify(error, isSuccess, `Block ${edit ? 'updated' : 'added'} successfully.`);

    const [deleteBlock, blockDeletion] = useDeleteBlockMutation();
    useAutoNotify(blockDeletion.error, blockDeletion.isSuccess, 'Block deleted.');

    const intervals = useMemo(
        () =>
            blocks?.reduce((a, v) => {
                if (v._id === data?._id) return a;
                a.push({
                    start: setMinutes(setHours(subDays(new Date(v?.startDate), 27), 0), 0),
                    end: setMinutes(setHours(new Date(v?.endDate), 23), 59),
                });
                return a;
            }, []),
        [blocks, data?._id],
    );

    const form = useForm(schema, { defaultValues: dataToForm(data, intervals), mode: 'onChange' });

    const { handleSubmit, getErrorProps, control, watch, setValue, getError } = form;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'groups',
        keyName: 'fieldId',
    });

    const groupsError = getError('groups');
    const startDate = watch('startDate');
    const duration = watch('duration');

    const handleAdd = useCallback(
        (e) => append({ title: '', goals: '', days: [], exercises: [], notes: '' }),
        [append],
    );
    const handleRemove = useCallback((i) => (e) => remove(i), [remove]);

    const handleDeleteBlock = async () => {
        try {
            await confirm('Are you sure you want to delete this block?', 'Delete Block');
            deleteBlock(id);
        } catch (error) {}
    };

    const onSubmit = (form) => {
        const extraFields = edit ? { blockId: id } : { clientId };
        submit(formToData(form, extraFields));
    };

    useEffect(() => {
        if (isSuccess || blockDeletion?.isSuccess) handleClose();
    }, [blockDeletion?.isSuccess, handleClose, isSuccess]);

    useEffect(() => {
        setValue('endDate', subDays(addWeeks(new Date(startDate), duration * 4), 1));
    }, [startDate, duration, setValue]);

    return (
        <Fragment>
            <DialogTitle>{edit ? 'Edit' : 'Add'} block</DialogTitle>

            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent sx={{ pb: 3 }}>
                    <Stack spacing={3}>
                        <div>
                            <FormLabel component="legend" sx={{ mb: 2 }}>
                                Period and cost
                            </FormLabel>
                            <Stack
                                direction={{
                                    xs: 'column',
                                    md: 'row',
                                }}
                                spacing={2}
                                alignItems={{
                                    xs: 'stretch',
                                    md: 'baseline',
                                }}
                            >
                                <DatePicker
                                    views={['year', 'month', 'day']}
                                    control={control}
                                    name="startDate"
                                    label="Start date *"
                                    getErrorProps={getErrorProps}
                                    renderDay={(day, _value, DayComponentProps) => {
                                        const disabled = intervals.some((v) =>
                                            isWithinInterval(addHours(day, 1), v),
                                        );

                                        return (
                                            <PickersDay
                                                {...DayComponentProps}
                                                disabled={disabled}
                                            />
                                        );
                                    }}
                                />
                                <Select
                                    control={control}
                                    name="duration"
                                    label="Duration"
                                    getErrorProps={getErrorProps}
                                    required
                                >
                                    <MenuItem value={0}>Select duration</MenuItem>
                                    <MenuItem value={1}>1 month (4w)</MenuItem>
                                    <MenuItem value={2}>2 months (8w)</MenuItem>
                                    <MenuItem value={3}>3 months (12w)</MenuItem>
                                    <MenuItem value={4}>4 months (16w)</MenuItem>
                                    <MenuItem value={5}>5 months (24w)</MenuItem>
                                    <MenuItem value={6}>6 months (32w)</MenuItem>
                                </Select>
                                {startDate && (
                                    <DatePicker
                                        control={control}
                                        name="endDate"
                                        label="End date"
                                        readOnly
                                    />
                                )}
                                <Select
                                    control={control}
                                    name="credits"
                                    label="Cost *"
                                    getErrorProps={getErrorProps}
                                    disabled={edit}
                                >
                                    <MenuItem value="">Select cost</MenuItem>
                                    {Array.from(Array(6).keys()).map((v) => (
                                        <MenuItem key={v} value={6 - v} disabled={duration < 6 - v}>
                                            {6 - v} credits
                                        </MenuItem>
                                    ))}
                                    <MenuItem value={0}>Free</MenuItem>
                                </Select>
                                <Switch
                                    name="active"
                                    control={control}
                                    rightLabel="Active"
                                    sx={{
                                        top: (t) => t.spacing(4 / 10),
                                        position: 'relative',
                                        pl: {
                                            xs: 0,
                                            md: 1,
                                        },
                                    }}
                                />
                            </Stack>
                        </div>

                        <div>
                            <FormLabel component="legend" sx={{ mb: 1 }}>
                                Client should be able to see and log these fields:
                            </FormLabel>
                            <Stack direction="row" spacing={2}>
                                <Switch name="hasRPE" control={control} rightLabel="RPE" />
                                <Switch
                                    name="hasIntensity"
                                    control={control}
                                    rightLabel="Intensity"
                                />
                            </Stack>
                        </div>

                        {groupsError && !fields.length && (
                            <Alert severity="error">{groupsError}</Alert>
                        )}

                        <Box>
                            {fields.map((v, i) => (
                                <Box key={v.fieldId} mb={5}>
                                    <BlockFormGroup
                                        index={i}
                                        form={form}
                                        onDelete={handleRemove(i)}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Stack>

                    <Button variant="contained" onClick={handleAdd}>
                        Add group
                    </Button>
                </DialogContent>
                <DialogActions>
                    {edit && (
                        <Box flexGrow={1}>
                            <LoadingButton
                                color="error"
                                onClick={handleDeleteBlock}
                                loading={blockDeletion.isLoading}
                            >
                                Delete block
                            </LoadingButton>
                        </Box>
                    )}
                    <Button color="inherit" onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} type="submit">
                        {edit ? 'Save' : 'Add'}
                    </LoadingButton>
                </DialogActions>
            </form>
        </Fragment>
    );
}

export default BlockForm;
