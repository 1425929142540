import { LinearProgress, Typography } from '@mui/material';
import { useGetProfileQuery } from 'api';
import { useAutoNotify } from 'hooks';
import { ClientProfileView } from 'components/Clients';

function ClientProfile({ userId }) {
    const { data, error, isLoading, isSuccess } = useGetProfileQuery(userId);
    useAutoNotify(error);

    if (isLoading) return <LinearProgress />;
    if (!isSuccess) return <Typography>There has been an error.</Typography>;
    if (!data) return <Typography>No profile.</Typography>;

    return <ClientProfileView data={data} />;
}

export default ClientProfile;
