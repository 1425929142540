import { LoadingButton } from '@mui/lab';
import { useAutoNotify, useConfirm } from 'hooks';
import { useDeleteMeMutation } from 'api';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AUTH_PATHS } from '@constants';

function MyAccountDelete() {
    const [confirm, handleCloseDialog] = useConfirm();
    const [logout, setLogout] = useState(false);
    const [submit, { error, isSuccess, isLoading }] = useDeleteMeMutation();
    useAutoNotify(error, isSuccess, 'Account deleted successfully.');

    const handleDeleteAccount = async () => {
        try {
            await confirm(
                'You can contact us instead, maybe we can help. Are you sure you want to continue?',
                'We are sorry to see you go',
                undefined,
                {
                    CancelButtonProps: {
                        color: 'primary',
                    },
                    ConfirmButtonProps: {
                        color: 'inherit',
                    },
                },
            );
            await confirm(
                'This action will delete your account and all related data. Are you sure you want to proceed?',
                'Delete account',
                true,
                {
                    ConfirmButtonProps: {
                        color: 'error',
                    },
                },
            );
            submit();
        } catch (error) {}
    };

    useEffect(() => {
        if (isSuccess) {
            setLogout(true);
            handleCloseDialog();
        }
    }, [isSuccess, handleCloseDialog]);

    if (logout) return <Redirect to={AUTH_PATHS.LOGOUT} />;

    return (
        <LoadingButton loading={isLoading} onClick={handleDeleteAccount} color="error">
            Delete account
        </LoadingButton>
    );
}

export default MyAccountDelete;
