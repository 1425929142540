import {
    Button,
    CircularProgress,
    FormControlLabel,
    Stack,
    Switch,
    useMediaQuery,
} from '@mui/material';
import { useGetResourcesCategoriesQuery } from 'api';
import { useAutoNotify, useRoles } from 'hooks';

function ResourceCategories({
    currentCategory,
    handleSelectCategory = () => {},
    outlined,
    showPublic,
    togglePublic,
}) {
    const smallDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { isAdmin } = useRoles();

    const categories = useGetResourcesCategoriesQuery();
    useAutoNotify(categories.error);

    if (!categories?.isSuccess) return <CircularProgress size="28px" />;

    return (
        <Stack
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            spacing={{
                xs: 1,
                sm: 2,
            }}
        >
            <Stack
                direction="row"
                spacing={1}
                sx={
                    smallDown
                        ? {
                              whiteSpace: 'nowrap',
                              overflowX: 'scroll',
                              mx: -1.5,
                              px: 1.5,
                              pb: 1,

                              '& .MuiButton-root': {
                                  minWidth: 'fit-content',
                              },
                          }
                        : {}
                }
            >
                <Button
                    variant={!currentCategory || !outlined ? 'contained' : 'outlined'}
                    color={!currentCategory ? 'primary' : outlined ? 'lightgrey' : 'light'}
                    onClick={(e) => handleSelectCategory(false)}
                >
                    All
                </Button>
                {categories?.data?.categories.map((v) => (
                    <Button
                        key={v}
                        variant={currentCategory !== v && outlined ? 'outlined' : 'contained'}
                        onClick={(e) => handleSelectCategory(v)}
                        color={currentCategory === v ? 'primary' : outlined ? 'lightgrey' : 'light'}
                    >
                        {v}
                    </Button>
                ))}
            </Stack>
            {isAdmin && (
                <FormControlLabel
                    control={<Switch checked={showPublic} onChange={togglePublic} />}
                    label="Public"
                />
            )}
        </Stack>
    );
}

export default ResourceCategories;
