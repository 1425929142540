import { Fragment } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { ClientsList, ViewClient } from 'components/Clients';

function Clients({ test }) {
    const match = useRouteMatch();

    return (
        <Fragment>
            {/* <EditClient /> */}

            <Switch>
                <Route path={match.url + '/:id'}>
                    <ViewClient />
                </Route>
                <Route path={match.url}>
                    <ClientsList />
                </Route>
            </Switch>
        </Fragment>
    );
}

export default Clients;
