import fitnessStats from 'assets/fitness-stats.svg';
import logo from 'assets/logo.svg';
import { Box, Grid, Container, Typography, Paper, Button } from '@mui/material';
import { MiniFooter } from 'components/Layout';
import { Link, useRouteMatch } from 'react-router-dom';
import { AUTH_PATHS } from '@constants';

const isCurrent = (a, b) => ({ variant: a === b ? 'contained' : undefined });

function AuthLayout({
    title = 'Are you ready to transform your body?',
    subtitle = 'Get your personalized program.',
    image,
    children,
    agreementContent,
}) {
    const { path } = useRouteMatch();

    return (
        <Container
            maxWidth="xl"
            sx={{
                py: 6,
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Grid container spacing={2} alignItems="center" columns={16} flexGrow={1}>
                <Grid
                    item
                    xs={16}
                    lg={10}
                    sx={{
                        textAlign: {
                            xs: 'center',
                            lg: 'left',
                        },
                    }}
                >
                    <Box
                        maxWidth="260px"
                        sx={{
                            mx: {
                                xs: 'auto',
                                lg: 0,
                            },
                        }}
                    >
                        <img src={logo} alt="" />
                    </Box>
                    <Typography variant="h2" component="h2" mt={6}>
                        {title}
                    </Typography>
                    <Typography variant="h2" component="h2">
                        {subtitle}
                    </Typography>
                    <Box
                        px={5}
                        mt={7}
                        mx="auto"
                        // maxWidth="680px"
                        sx={{
                            maxWidth: {
                                xs: 400,
                                lg: 680,
                            },
                            display: {
                                xs: 'none',
                                md: 'block',
                            },
                        }}
                    >
                        <img src={image || fitnessStats} alt="" />
                    </Box>
                </Grid>
                <Grid item xs={16} lg={6}>
                    <Paper
                        sx={{
                            px: {
                                xs: 3,
                                sm: 5,
                            },
                            py: {
                                xs: 4,
                                sm: 7,
                            },
                            boxShadow: '0 0 70px 0 #0002',
                            overflow: 'hidden',
                            maxWidth: 500,
                            mx: 'auto',
                            my: {
                                xs: 2,
                                lg: 4,
                            },
                        }}
                    >
                        <div>
                            <Box
                                display="inline-flex"
                                bgcolor="primary.50"
                                borderRadius={1}
                                p={1 / 2}
                            >
                                <Box mr={1 / 2}>
                                    <Button
                                        {...isCurrent(AUTH_PATHS.LOGIN, path)}
                                        component={Link}
                                        to="/login"
                                    >
                                        Login
                                    </Button>
                                </Box>
                                <Button
                                    {...isCurrent(AUTH_PATHS.REGISTER, path)}
                                    component={Link}
                                    to="/register"
                                >
                                    Sign up
                                </Button>
                            </Box>
                        </div>
                        {children}
                        {agreementContent && (
                            <Typography
                                variant="body2"
                                // mx={-5}
                                // mb={-7}
                                mt={7}
                                px={2}
                                py={1.5}
                                bgcolor="grey.50"
                                sx={{
                                    mx: {
                                        xs: -3,
                                        sm: -5,
                                    },
                                    mb: {
                                        xs: -4,
                                        sm: -7,
                                    },
                                }}
                            >
                                {agreementContent}
                            </Typography>
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <MiniFooter />
        </Container>
    );
}

export default AuthLayout;
