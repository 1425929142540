import { Fragment } from 'react';
import {
    Typography,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Stack,
    useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAutoNotify, useForm } from 'hooks';
import { useUpdateCalendarMutation } from 'api';
import { Switch } from 'form';
import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { HoursDropdown } from 'components/Booking';
import { useCallback } from 'react';

const dataToForm = (data) => {
    if (!data) return {};

    return data.reduce((a, v) => {
        a[v.day] = {
            status: v.status,
            startTime: v.startTime || '',
            endTime: v.endTime || '',
        };
        return a;
    }, {});
};

const formToData = (data, extraFields = {}) => {
    return Object.entries(data).map(([k, v]) => ({
        day: k,
        startTime: v.startTime,
        endTime: v.endTime,
        status: v.status,
    }));
};

const schema = yup.lazy((data) => {
    return yup.object(
        mapValues(data, () =>
            yup
                .object()
                .test(
                    'start_before_end',
                    'Start time must be before end time',
                    (o) => !o.status || o.startTime.slice(0, 2) < o.endTime.slice(0, 2),
                ),
        ),
    );
});

function SettingsForm({ data }) {
    const form = useForm(schema, { defaultValues: dataToForm(data) });
    const isLarge = useMediaQuery((t) => t.breakpoints.up('md'));

    const {
        handleSubmit,
        getErrorProps,
        control,
        watch,
        getError,
        reset,
        formState: { isSubmitted },
        trigger,
    } = form;

    const [submit, { error, isLoading, isSuccess }] = useUpdateCalendarMutation();
    useAutoNotify(error, isSuccess, 'Schedule updated successfully.');

    const onSubmit = (form) => {
        submit(formToData(form));
    };

    const handleHourChange = useCallback(() => {
        if (isSubmitted) trigger();
    }, [isSubmitted, trigger]);

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Table
                    size="small"
                    sx={{
                        my: 3,

                        '& .MuiTableCell-root': {
                            border: 0,
                        },
                    }}
                >
                    <TableBody>
                        {data.map((v, i) => {
                            const statusName = `${v.day}.status`;
                            const status = watch(statusName);

                            return (
                                <Fragment key={v._id}>
                                    <TableRow>
                                        <TableCell sx={{ pl: 0, pr: isLarge ? undefined : 0 }}>
                                            <Typography
                                                variant={isLarge ? 'body1' : 'body2'}
                                                fontWeight="bold"
                                            >
                                                {isLarge ? v.day : v.day?.slice(0, 3)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ px: isLarge ? 4 : 1 }}>
                                            <Switch
                                                name={statusName}
                                                control={control}
                                                rightLabel={
                                                    isLarge
                                                        ? status
                                                            ? 'Open'
                                                            : 'Closed'
                                                        : undefined
                                                }
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                height: (t) => t.spacing(6),
                                                px: isLarge ? undefined : 1 / 2,
                                            }}
                                        >
                                            {status && (
                                                <HoursDropdown
                                                    control={control}
                                                    getErrorProps={getErrorProps}
                                                    emptyLabel="Start"
                                                    name={`${v.day}.startTime`}
                                                    onChange={handleHourChange}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ px: isLarge ? 0 : 1 / 2 }}>
                                            {status && (
                                                <Typography variant="overline">to</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                px: isLarge ? undefined : 0,
                                            }}
                                        >
                                            {status && (
                                                <HoursDropdown
                                                    control={control}
                                                    getErrorProps={getErrorProps}
                                                    emptyLabel="End"
                                                    name={`${v.day}.endTime`}
                                                    onChange={handleHourChange}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    {status && !!getError(v.day) && (
                                        <TableRow>
                                            <TableCell
                                                colSpan={5}
                                                sx={{
                                                    color: 'error.main',
                                                    textAlign: 'right',
                                                    py: 0,
                                                }}
                                            >
                                                {getError(v.day)}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
                <Stack direction="row" justifyContent="flex-end">
                    <Button sx={{ mr: 1 }} color="inherit" onClick={(e) => reset(dataToForm(data))}>
                        Reset values
                    </Button>
                    <LoadingButton loading={isLoading} type="submit" variant="contained">
                        Save changes
                    </LoadingButton>
                </Stack>
            </form>
        </Fragment>
    );
}

export default SettingsForm;
