import { useState, useEffect, useMemo, useCallback } from 'react';

import {
    Box,
    Stack,
    Grid,
    Typography,
    Paper,
    Button,
    Stepper,
    StepLabel,
    Step,
    MobileStepper,
    useMediaQuery,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import stepAsset1 from 'assets/step_1.svg';
import stepAsset2 from 'assets/step_2.svg';
import stepAsset3 from 'assets/step_3.svg';
import stepAsset4 from 'assets/step_4.svg';
import stepAsset5 from 'assets/step_5.svg';
import stepAsset6 from 'assets/step_6.svg';
import stepAsset7 from 'assets/step_success.svg';

import {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    StepSuccess,
    validations,
} from 'components/Questionnaire';

import { impToKg, impToCm } from 'helpers';
import { useCreateMyProfileMutation, useUpdateMyProfileMutation } from 'api';
import { useAccountCompletion, useAutoNotify, useForm } from 'hooks';
import { Link } from 'react-router-dom';

const getSteps = (parentPath) => [
    {
        name: 'General Info',
        Component: Step1,
        image: stepAsset1,
    },
    {
        name: 'Anthropometric measures',
        Component: Step2,
        image: stepAsset2,
    },
    {
        name: 'Training History',
        Component: Step3,
        image: stepAsset3,
    },
    {
        name: 'PAR-Q',
        Component: Step4,
        image: stepAsset4,
    },
    {
        name: 'Goals',
        Component: Step5,
        image: stepAsset5,
    },
    {
        name: 'Visual check',
        Component: Step6,
        image: stepAsset6,
    },
    {
        name: 'Profile completed',
        Component: StepSuccess,
        image: stepAsset7,
    },
];

const weightFields = ['weight', 'fatDistribution', 'fatMass', 'leanBodyMass'];
const lengthFields = ['calves', 'chest', 'height', 'hips', 'neck', 'thighs', 'upperArms', 'waist'];
const checkboxArrays = ['goals'];
const dateFields = ['DOB'];

const dataToForm = (data) => {
    const newData = { ...data };

    weightFields.forEach((v) => {
        if (!newData[v]) return;
        newData[v] = { kg: newData[v] };
    });
    lengthFields.forEach((v) => {
        if (!newData[v]) return;
        newData[v] = { cm: newData[v] };
    });

    return newData;
};
const formToData = (data) => {
    const newData = { ...data };

    weightFields.forEach((v) => {
        if (!newData[v]) return;
        const { kg, st, lb } = newData[v];
        newData[v] = kg || impToKg(st, lb);
    });
    lengthFields.forEach((v) => {
        if (!newData[v]) return;
        const { cm, ft, in: inch } = newData[v];
        newData[v] = cm || impToCm(ft, inch);
    });
    checkboxArrays.forEach((v) => {
        if (!newData[v]) return;
        newData[v] = newData[v].filter((v) => v);
    });
    dateFields.forEach((v) => {
        if (!newData[v]) return;
        newData[v] = new Date(newData[v]).toISOString();
    });

    delete newData.videoURLs;
    delete newData.userId;
    const videoFiles = newData?.video;
    newData.video = videoFiles[0];
    newData.deleteVideo = videoFiles === '';

    return newData;
};

const getDefaultStep = (hasProfile) => (hasProfile ? 6 : 0);

function QuestionnaireForm({ data }) {
    const { hasProfile } = useAccountCompletion();
    const steps = useMemo(() => getSteps(), []);
    const isLargeDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const isXLargeDown = useMediaQuery((theme) => theme.breakpoints.down('xl'));

    const [step, setStep] = useState(getDefaultStep(hasProfile));
    const [kg, setKg] = useState(true);
    const [cm, setCm] = useState(true);

    const { name, Component, image } = steps[step];

    const lastStep = step === steps.length - 2;

    const schema = validations[step];
    const formMethods = useForm(schema, {
        defaultValues: dataToForm(data),
        mode: 'onChange',
        context: { kg, cm },
    });
    const { handleSubmit, trigger, reset } = formMethods;

    const useMutation = hasProfile ? useUpdateMyProfileMutation : useCreateMyProfileMutation;
    const [submit, { error, isLoading, isSuccess }] = useMutation();
    useAutoNotify(error, isSuccess, 'Profile saved.');

    const startOver = () => {
        setStep(0);
        reset(dataToForm(data));
    };
    const handlePrev = () => {
        setStep((v) => v - 1);
    };
    const handleNext = useCallback(() => {
        const handler = async () => {
            const isStepValid = await trigger();
            if (isStepValid) setStep((v) => v + 1);
        };
        handler();
    }, [trigger]);

    const onSubmit = (data) => {
        submit(formToData(data));
    };

    useEffect(() => {
        setStep(getDefaultStep(hasProfile));
    }, [hasProfile]);

    useEffect(() => {
        if (isSuccess) handleNext();
    }, [handleNext, isSuccess]);

    return (
        <Paper sx={{ p: 2 }}>
            {isLargeDown ? (
                <MobileStepper
                    variant="dots"
                    steps={steps?.length}
                    position="static"
                    activeStep={step}
                    sx={{ flexGrow: 1, justifyContent: 'center' }}
                />
            ) : (
                <Stepper activeStep={step} alternativeLabel sx={{ mt: 3 }}>
                    {steps.map(({ name }) => (
                        <Step key={name}>
                            <StepLabel>{name}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            )}

            <Grid
                container
                justifyContent="center"
                mt={{
                    xs: 3,
                    lg: 8,
                }}
                mb={4}
            >
                <Grid item xs={12} lg={10}>
                    <Grid container spacing={10}>
                        {!isXLargeDown && (
                            <Grid item xs={4}>
                                <Box py={step + 1 === steps.length ? 0 : 10}>
                                    <img src={image} alt="" />
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={12} xl={8} mx="auto">
                            <form>
                                <Stack
                                    minHeight="100%"
                                    justifyContent={
                                        step + 1 === steps.length ? 'center' : undefined
                                    }
                                >
                                    <Typography variant="h4" mb={3}>
                                        {name}
                                    </Typography>
                                    <Component
                                        startOver={startOver}
                                        formMethods={formMethods}
                                        kg={kg}
                                        setKg={setKg}
                                        cm={cm}
                                        setCm={setCm}
                                    />
                                    {step < steps.length - 1 && (
                                        <Stack direction="row" spacing={1} mt={4}>
                                            {hasProfile && (
                                                <Button
                                                    component={Link}
                                                    to="/profile"
                                                    color="inherit"
                                                >
                                                    Cancel
                                                </Button>
                                            )}
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-end"
                                                spacing={1}
                                                flexGrow={1}
                                            >
                                                <Button
                                                    disabled={!step || isLoading}
                                                    onClick={handlePrev}
                                                >
                                                    Previous
                                                </Button>
                                                <LoadingButton
                                                    loading={isLoading}
                                                    variant="contained"
                                                    onClick={
                                                        lastStep
                                                            ? handleSubmit(onSubmit)
                                                            : handleNext
                                                    }
                                                >
                                                    {lastStep ? 'Finish' : 'Next'}
                                                </LoadingButton>
                                            </Stack>
                                        </Stack>
                                    )}
                                </Stack>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default QuestionnaireForm;
