import { TableRow, TableCell, Chip, Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAutoNotify, useConfirm, useRoles } from 'hooks';
import { useDeleteResourceMutation } from 'api';
import { Fragment } from 'react';

function ResourcesRow({
    row,
    handleOpen,
    handleEdit,
    isManaging,
    handleAssignResource,
    assignLoading,
    assigned,
}) {
    const { isAdmin } = useRoles();
    const [confirm] = useConfirm();

    const [deleteResource, resourceDeletion] = useDeleteResourceMutation();
    useAutoNotify(resourceDeletion.error, resourceDeletion.isSuccess, 'Resource deleted.');

    const handleDelete = async (e) => {
        try {
            await confirm('Are you sure you want to delete this resource?', 'Delete Resource');
            deleteResource(row._id);
        } catch (error) {}
    };

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
                <Typography
                    variant="subtitle2"
                    fontWeight="600"
                    whiteSpace="nowrap"
                    onClick={handleOpen}
                    sx={{ cursor: 'pointer' }}
                >
                    <i className="far fa-file fa-mr-1 fa-sm" />
                    {row?.title}
                </Typography>
            </TableCell>
            <TableCell>
                {row?.category?.[0] && <Chip label={row?.category?.[0]} size="small" />}
            </TableCell>
            {isAdmin && (
                <Fragment>
                    <TableCell sx={{ textAlign: 'center' }}>
                        {row?.isPublic ? <Chip label="Public" size="small" /> : 'No'}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                        {row?.file ? <Chip label="Yes" size="small" /> : 'No'}
                    </TableCell>
                    <TableCell>
                        <Stack spacing={1} direction="row">
                            {!isManaging && handleEdit && (
                                <Button color="warning" onClick={handleEdit}>
                                    <i className="fas fa-pen fa-mr-1 fa-xs" />
                                    Edit
                                </Button>
                            )}
                            {!isManaging && (
                                <LoadingButton
                                    color="error"
                                    loading={resourceDeletion.isLoading}
                                    onClick={handleDelete}
                                >
                                    <i className="fas fa-trash fa-mr-1 fa-xs" />
                                    Delete
                                </LoadingButton>
                            )}
                            {isManaging && !row?.isPublic && (
                                <LoadingButton
                                    color={assigned ? 'warning' : 'primary'}
                                    loading={assignLoading}
                                    onClick={handleAssignResource}
                                >
                                    <i
                                        className={`fas fa-${
                                            assigned ? 'unlink' : 'paperclip'
                                        } fa-mr-1 fa-xs`}
                                    />
                                    {assigned ? 'Unassign' : 'Assign'}
                                </LoadingButton>
                            )}
                        </Stack>
                    </TableCell>
                </Fragment>
            )}
        </TableRow>
    );
}

export default ResourcesRow;
