import { LinearProgress, Typography, Alert, AlertTitle, useMediaQuery, Box } from '@mui/material';
import { useGetActiveSubscriptionsQuery, useCancelSubscriptionMutation } from 'api';
import { useAutoNotify, useConfirm, useOngoingSubscription, useRoles } from 'hooks';
import { Tag } from 'common';
import { LoadingButton } from '@mui/lab';
import { format } from 'date-fns';
import { Fragment } from 'react';

function ActiveSubscription({ userId }) {
    const { isAdmin } = useRoles();
    const [confirm] = useConfirm();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const { data, error, isLoading, isSuccess } = useGetActiveSubscriptionsQuery(userId, {
        skip: !userId,
    });
    useAutoNotify(error);

    const [cancelSubscription, cancelSubscriptionMutation] = useCancelSubscriptionMutation();

    useAutoNotify(
        cancelSubscriptionMutation.error,
        cancelSubscriptionMutation.isSuccess,
        'Subscription cancelled.',
    );

    const { hasOngoingSubscription, planName, planEndDate } = useOngoingSubscription(userId);

    const handleCancel = (subscriptionId) => async (e) => {
        try {
            await confirm(
                'Are you sure you want to cancel this subscription?',
                'Cancel subscription',
            );
            cancelSubscription(subscriptionId);
        } catch (error) {}
    };

    if (isLoading) return <LinearProgress sx={{ mb: 3 }} />;
    if (!isSuccess) return <Typography>There has been an error.</Typography>;

    const subscriptionId = data?.subscriptions?.[0];

    if (!hasOngoingSubscription && !subscriptionId) return null;

    return (
        <Alert
            severity="info"
            sx={{
                mb: 3,
                flexDirection: {
                    xs: 'column',
                    sm: 'row',
                },
                alignItems: {
                    xs: 'center',
                    sm: 'initial',
                },
                textAlign: {
                    xs: 'center',
                    sm: 'initial',
                },

                '& .MuiAlert-action': isMobile
                    ? {
                          margin: '0 auto',
                          paddingLeft: 0,
                      }
                    : {},
                '& .MuiAlert-message': {
                    maxWidth: '100%',
                },
            }}
            action={
                subscriptionId && (
                    <LoadingButton
                        loading={cancelSubscriptionMutation.isLoading}
                        variant="outlined"
                        onClick={handleCancel(subscriptionId)}
                        sx={{
                            '&.MuiLoadingButton-loading': {
                                borderColor: '#fff8',
                            },
                            '& .MuiLoadingButton-loadingIndicator': {
                                color: '#fff',
                            },
                        }}
                    >
                        Cancel subscription
                    </LoadingButton>
                )
            }
        >
            <AlertTitle sx={{ fontWeight: 'fontWeightBold' }}>
                <Box
                    display="inline-block"
                    sx={{ '&:first-letter': { textTransform: 'uppercase' } }}
                >
                    {planName && planName} plan active
                </Box>
                {subscriptionId && (
                    <Tag
                        size="small"
                        bgcolor="info.light"
                        border={0}
                        color="common.white"
                        component="span"
                        py={1 / 4}
                        ml={{
                            xs: 0,
                            sm: 1.5,
                        }}
                        display={{
                            xs: 'block',
                            sm: 'unset',
                        }}
                    >
                        {subscriptionId}
                    </Tag>
                )}
            </AlertTitle>
            {isAdmin ? 'This client has' : 'You have'} an active{' '}
            {subscriptionId ? 'recurring subscription' : 'plan'}
            {planEndDate && (
                <Fragment>
                    {' '}
                    until <b>{format(planEndDate, 'MMMM do')}</b>
                </Fragment>
            )}
            .
        </Alert>
    );
}

export default ActiveSubscription;
