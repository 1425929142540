import { Fragment } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { Grid, LinearProgress, Typography, Stack, Button, Paper } from '@mui/material';
import { useGetMyBlocksQuery, useGetBlocksByUserIdQuery } from 'api';
import { useAutoNotify } from 'hooks';
import { ExerciseDialog } from 'components/Exercises';
import { Block, BlockDialog } from 'components/Program';

function Program({ userId }) {
    const match = useRouteMatch();
    const useGet = userId ? useGetBlocksByUserIdQuery : useGetMyBlocksQuery;
    const { data, error, isLoading, isSuccess } = useGet(userId);
    useAutoNotify(error);

    if (isLoading) return <LinearProgress />;
    if (!isSuccess) return <Typography>There has been an error.</Typography>;

    return (
        <Fragment>
            <ExerciseDialog />
            <BlockDialog parentPath={match?.url} clientId={userId} blocks={data} />

            <Stack
                direction={{
                    xs: 'column',
                    sm: 'row',
                }}
                alignItems={{
                    xs: 'flex-start',
                    sm: 'center',
                }}
                spacing={{
                    xs: 1,
                    sm: 0,
                }}
                sx={{ mb: 2 }}
            >
                <Typography variant="h5" mr={2}>
                    Training blocks
                </Typography>
                {!!userId && (
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        component={Link}
                        to={match?.url + '/add'}
                    >
                        <i className="fas fa-plus fa-mr-1" /> Add new block
                    </Button>
                )}
            </Stack>
            <Grid container spacing={2}>
                {!data.length && (
                    <Grid item xs={12}>
                        <Paper sx={{ p: 3 }}>
                            <Typography>No training blocks yet.</Typography>
                        </Paper>
                    </Grid>
                )}
                {data.map((v) => (
                    <Grid item xs={12} xl={6} key={v._id}>
                        <Block data={v} disableEdit={!userId} />
                    </Grid>
                ))}
            </Grid>
        </Fragment>
    );
}

export default Program;
