import { useGetBookingsQuery } from 'api';
import { useAutoNotify, useCalendarView, useRoles } from 'hooks';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { DATE_OPTIONS } from '@constants';
import { Alert, AlertTitle, LinearProgress, useMediaQuery } from '@mui/material';
import { Link } from 'routes';

import {
    ViewBookingDialog,
    ViewMonth,
    CalendarHeader,
    ViewWeek,
    ViewDay,
    BookingDialog,
} from 'components/Booking';

import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    isThisWeek,
    isToday,
    startOfDay,
    startOfMonth,
    startOfWeek,
} from 'date-fns';

const getEventsByDate = (events, isWeek) => {
    return events.reduce((a, day) => {
        day.bookings.forEach((booking) => {
            const dateTime = new Date(booking.startDate);
            const date = dateTime.getDate();

            if (isWeek) {
                const hours = ('00' + dateTime.getHours()).slice(-2);
                const minutes = ('00' + dateTime.getMinutes()).slice(-2);
                const time = [hours, minutes].join(':');

                if (!a[date]) a[date] = { [time]: booking };
                else a[date][time] = booking;
            } else {
                if (!a[date]) a[date] = [booking];
                else a[date].push(booking);
            }
        });
        return a;
    }, {});
};

function Calendar() {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const isLarge = useMediaQuery((t) => t.breakpoints.up('lg'));
    const calendarView = useCalendarView();
    const { isWeek } = calendarView;

    const [eventDialog, setEventDialog] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({});
    const [newBookingInterval, setNewBookingInterval] = useState(false);

    const { isAdmin } = useRoles();

    const [start, end] = useMemo(() => {
        if (!isWeek) return [startOfMonth(selectedDate), endOfMonth(selectedDate)];
        if (isLarge) {
            return [startOfWeek(selectedDate, DATE_OPTIONS), endOfWeek(selectedDate, DATE_OPTIONS)];
        }
        return [startOfDay(selectedDate), endOfDay(selectedDate)];
    }, [isLarge, isWeek, selectedDate]);

    const { currentData, error, isFetching, isLoading } = useGetBookingsQuery({
        start_date: start.toString(),
        end_date: end.toString(),
    });

    const isForbidden = error?.status === 403;
    useAutoNotify(isForbidden ? false : error);

    const hasAccess = isAdmin || (!isLoading && !isForbidden);

    const eventsObject = useMemo(() => {
        if (!currentData) return {};
        return getEventsByDate(currentData, isWeek);
    }, [currentData, isWeek]);

    const handleCreate = useCallback(async (startDate, endDate) => {
        setNewBookingInterval({
            start: startDate,
            end: endDate,
        });
    }, []);

    const handleCloseNewBooking = () => {
        setNewBookingInterval(false);
    };

    const handleView = useCallback((event) => {
        setSelectedEvent(event);
        setEventDialog(true);
    }, []);

    const GranularView = isLarge ? ViewWeek : ViewDay;

    if (isLoading) return <LinearProgress />;

    if (!hasAccess)
        return (
            <Alert severity="info">
                <AlertTitle>Premium feature</AlertTitle>
                {error?.data?.message}.{' '}
                <b>
                    <Link to="/plans">Upgrade to Premium</Link>.
                </b>
            </Alert>
        );

    return (
        <Fragment>
            <BookingDialog interval={newBookingInterval} onClose={handleCloseNewBooking} />
            <ViewBookingDialog
                open={eventDialog}
                handleClose={(e) => setEventDialog(false)}
                data={selectedEvent}
            />
            <CalendarHeader
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                calendarView={calendarView}
                mb={3}
                isFetching={isFetching}
                isLarge={isLarge}
            />
            {isWeek ? (
                <GranularView
                    start={start}
                    isThisWeek={isLarge ? isThisWeek(selectedDate) : isToday(selectedDate)}
                    data={eventsObject}
                    handleCreate={handleCreate}
                    handleView={handleView}
                />
            ) : (
                <ViewMonth
                    start={start}
                    end={end}
                    data={eventsObject}
                    handleView={handleView}
                    isLarge={isLarge}
                />
            )}
        </Fragment>
    );
}

export default Calendar;
