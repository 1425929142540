import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import {
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
} from '@mui/material';

import logo from 'assets/logo.svg';
import { useGetMyChatsState } from 'api';
import { useMemo } from 'react';

function Sidebar({ routes, handleMenuClose }) {
    const { pathname } = useLocation();
    const { url } = useRouteMatch();

    const myChats = useGetMyChatsState();
    const anyUnread = useMemo(() => {
        return myChats.data?.some((v) => v?.unreadMessages > 0);
    }, [myChats.data]);

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Box
                component={Link}
                to="/"
                sx={{
                    px: 2,
                    maxHeight: '90px',
                    flex: '1 0 90px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'stretch',
                    transition: '0.3s background-color',

                    '&:hover': {
                        bgcolor: 'grey.100',
                    },
                }}
            >
                <img src={logo} alt="" />
            </Box>
            <List sx={{ flexGrow: 1, p: 2, overflow: 'auto' }}>
                {routes?.map(({ id, title, mainMenu, icon, subheader, disabled }) =>
                    subheader ? (
                        <ListSubheader key={subheader} disableSticky>
                            {subheader}
                        </ListSubheader>
                    ) : mainMenu ? (
                        <ListItemButton
                            key={id}
                            component={Link}
                            to={url + id}
                            selected={pathname.indexOf(url + id) === 0}
                            disabled={disabled}
                            onClick={handleMenuClose}
                            sx={{
                                '&.MuiButtonBase-root.Mui-selected .MuiListItemIcon-root': {
                                    color: 'primary.dark',
                                },
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: (t) => t.spacing(3.5),
                                    color: 'primary.main',
                                }}
                            >
                                <i className={`fas fa-${icon} fa-fw`} />
                            </ListItemIcon>
                            <ListItemText primary={title} />
                            {id === 'chats' && anyUnread && (
                                <ListItemSecondaryAction>
                                    <Box
                                        component="i"
                                        color="error.main"
                                        className="fas fa-circle fa-2xs"
                                    />
                                </ListItemSecondaryAction>
                            )}
                        </ListItemButton>
                    ) : null,
                )}
            </List>
        </Box>
    );
}

export default Sidebar;
