import {
    LinearProgress,
    TableCell,
    Chip,
    TableRow,
    TableBody,
    Table,
    TableHead,
    TableContainer,
    Paper,
} from '@mui/material';

import { useGetSubscriptionsQuery } from 'api';
import { useAutoNotify, useRoles } from 'hooks';
import { format } from 'date-fns';
import { Fragment } from 'react';
import ActiveSubscription from 'components/Subscriptions/ActiveSubscription';

function History({ userId, stripeCustomerId }) {
    const { isAdmin } = useRoles();
    const { data, error, isLoading } = useGetSubscriptionsQuery(stripeCustomerId, {
        skip: !stripeCustomerId,
    });
    useAutoNotify(error);

    if (!stripeCustomerId || (!isLoading && !data?.length)) return 'No payments yet.';
    if (isLoading) return <LinearProgress sx={{ mb: isAdmin ? 0 : 3 }} />;

    return (
        <Fragment>
            <ActiveSubscription userId={userId} />
            <TableContainer component={Paper} variant="outlined">
                <Table sx={{ minWidth: 650, whiteSpace: 'nowrap' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left">Package name</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Recurring</TableCell>
                            <TableCell align="left">Amount</TableCell>
                            <TableCell align="left">Credits</TableCell>
                            <TableCell align="left">Booking Credits</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row) => {
                            return (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>
                                        {format(new Date(row.createdAt), 'd MMM. y, HH:mm')}
                                    </TableCell>
                                    <TableCell align="left">{row.productDescription}</TableCell>
                                    <TableCell align="left">
                                        <Chip
                                            color={row.status === 'succeeded' ? 'success' : 'error'}
                                            variant="outlined"
                                            size="small"
                                            label={row.status}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.recurring ? 'yes' : 'no'}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.amount / 100} {row.currency.toUpperCase()}
                                    </TableCell>
                                    <TableCell align="left">{row.programCredits || 0}</TableCell>
                                    <TableCell align="left">{row.checkInCredits || 0}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    );
}

export default History;
