import { AuthLayout } from 'components/Auth';
import { Redirect, useParams } from 'react-router-dom';

import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import image from 'assets/secure-password.svg';

import { useResetPasswordMutation } from 'api';
import * as yup from 'yup';
import { useAutoNotify, useForm } from 'hooks';

const schema = yup
    .object({
        token: yup.string().required(),
        password: yup.string().required().min(8),
        passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    })
    .required();

function ResetPassword() {
    const { token } = useParams();
    const { handleSubmit, registerField } = useForm(schema);
    const [resetPassword, { error, isLoading, isSuccess }] = useResetPasswordMutation();
    useAutoNotify(error, isSuccess, 'Password has been updated.');

    const onSubmit = (data) => resetPassword(data);

    if (isSuccess) return <Redirect to="/login" />;

    return (
        <AuthLayout image={image} subtitle="Let's start with a strong password.">
            <Box mt={6}>
                <Typography variant="h4" component="h1">
                    Reset password
                </Typography>
                {!token && (
                    <Typography variant="subtitle2" mt={1}>
                        If you can't click the link in the email, paste here the <b>user token</b>.
                    </Typography>
                )}
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box mt={3}>
                    <TextField
                        {...registerField('token')}
                        fullWidth
                        label="User token"
                        type="text"
                        defaultValue={token ?? ''}
                        readOnly={!!token}
                    />
                </Box>
                <Box mt={3}>
                    <TextField
                        {...registerField('password')}
                        fullWidth
                        label="Password"
                        type="password"
                    />
                </Box>
                <Box mt={3}>
                    <TextField
                        {...registerField('passwordConfirm')}
                        fullWidth
                        label="Confirm password"
                        type="password"
                    />
                </Box>
                <Box mt={4}>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        size="large"
                        fullWidth
                        type="submit"
                    >
                        Change password
                    </LoadingButton>
                </Box>
            </form>
        </AuthLayout>
    );
}

export default ResetPassword;
