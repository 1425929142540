import { Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    LinearProgress,
    Typography,
} from '@mui/material';

import { Link } from 'routes';
import { useGetUsersQuery } from 'api';
import { useAutoNotify, useMe } from 'hooks';
import { formatDistance } from 'date-fns';

function ClientsList({ test }) {
    const match = useRouteMatch();
    const me = useMe();

    const { data, error, isLoading } = useGetUsersQuery();
    useAutoNotify(error);

    if (isLoading) return <LinearProgress />;

    return (
        <Fragment>
            <TableContainer component={Paper} sx={{ tableLayout: 'fixed' }}>
                {!!data && (
                    <Table>
                        <TableHead>
                            <TableRow sx={{ whiteSpace: 'nowrap' }}>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Credits</TableCell>
                                <TableCell>Booking credits</TableCell>
                                <TableCell>Follow-up</TableCell>
                                <TableCell>Activated</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <TableRow
                                    hover
                                    key={row._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Link
                                            sx={{
                                                color: row.isCoach
                                                    ? 'primary.main'
                                                    : row.role === 'admin'
                                                    ? 'warning.dark'
                                                    : 'text.primary',
                                            }}
                                            to={`${match?.url}/${row._id}`}
                                        >
                                            {row.name}
                                            {me?._id === row?._id && (
                                                <Typography
                                                    fontSize="0.8em"
                                                    border="1px solid"
                                                    borderColor="inherit"
                                                    borderRadius="50px"
                                                    px={1 / 2}
                                                    py={1 / 4}
                                                    lineHeight="0.9em"
                                                    ml={1}
                                                    variant="overline"
                                                >
                                                    you
                                                </Typography>
                                            )}
                                        </Link>
                                    </TableCell>
                                    <TableCell scope="row">{row.email}</TableCell>
                                    <TableCell scope="row">
                                        {row.role === 'admin' && (
                                            <Box
                                                component="i"
                                                className="fa-sm fa-mr-1 fas fa-shield-alt"
                                                color="warning.dark"
                                            />
                                        )}
                                        {row.isCoach && (
                                            <Box
                                                component="i"
                                                className="fa-sm fa-mr-1 fas fa-dumbbell"
                                                color="primary.main"
                                            />
                                        )}
                                        {row.role === 'user' && (
                                            <Box
                                                component="i"
                                                className="fa-sm fa-mr-1 fas fa-user"
                                                color="text.secondary"
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell scope="row">{row.credits}</TableCell>
                                    <TableCell scope="row">{row.checkInCredits}</TableCell>
                                    <TableCell scope="row">
                                        {!!row?.resurveys?.length && (
                                            <Fragment>
                                                {(!!row?.resurveys?.[0]?.completed ||
                                                    row.needsResurvey) && (
                                                    <Box
                                                        component="span"
                                                        mr={1}
                                                        color={
                                                            !!row?.resurveys?.[0]?.completed
                                                                ? 'success.main'
                                                                : 'warning.main'
                                                        }
                                                    >
                                                        <i
                                                            className={`fa-sm fa-${
                                                                !!row?.resurveys?.[0]?.completed
                                                                    ? 'check-circle fas'
                                                                    : 'exclamation-triangle fas'
                                                            }`}
                                                        />
                                                    </Box>
                                                )}
                                                {!!row?.resurveys?.[0]?.expiresIn &&
                                                    formatDistance(
                                                        new Date(row?.resurveys?.[0]?.expiresIn),
                                                        new Date(),
                                                        {
                                                            addSuffix: true,
                                                        },
                                                    )}
                                            </Fragment>
                                        )}
                                    </TableCell>
                                    <TableCell scope="row">
                                        <Box
                                            color={
                                                row.accountActivated ? 'info.main' : 'warning.main'
                                            }
                                        >
                                            <i
                                                className={`fa-sm fa-${
                                                    row.accountActivated
                                                        ? 'check-circle fas'
                                                        : 'question-circle far'
                                                }`}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </Fragment>
    );
}

export default ClientsList;
