import { chatActions } from 'ducks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMaxStickyChats } from 'hooks';

function useSliceStickyChats() {
    const dispatch = useDispatch();
    const maxAmount = useMaxStickyChats();

    useEffect(() => {
        dispatch(chatActions.sliceStickyChats(maxAmount));
    }, [maxAmount, dispatch]);
}

export default useSliceStickyChats;
