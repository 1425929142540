import { Box, Typography } from '@mui/material';

function EdgeButton({ onClick, icon, children, sx, ...props }) {
    return (
        <Box
            onClick={onClick}
            borderRadius={32}
            px={1.5}
            height={32}
            border={1}
            borderColor="grey.200"
            color="grey.700"
            bgcolor="common.white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            boxShadow={1}
            {...props}
            sx={{
                cursor: 'pointer',
                // position: 'absolute',
                // left: (t) => t.spacing(2),
                // bottom: -16,
                ...sx,
            }}
        >
            {icon && <i className={`fa-mr-1 ${icon}`} style={{ transition: '0.3s transform' }} />}
            <Typography variant="caption">{children}</Typography>
        </Box>
    );
}

export default EdgeButton;
