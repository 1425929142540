import { Redirect } from 'react-router-dom';
import { AuthLayout } from 'components/Auth';

import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import image from 'assets/forgot-password.svg';

import { useForgotPasswordMutation } from 'api';
import * as yup from 'yup';
import { useAutoNotify, useForm } from 'hooks';

const schema = yup
    .object({
        email: yup.string().email().required(),
    })
    .required();

function ForgotPassword() {
    const { handleSubmit, registerField } = useForm(schema);
    const [forgotPassword, { error, isLoading, isSuccess }] = useForgotPasswordMutation();
    useAutoNotify(
        error,
        isSuccess,
        'Success. Click the link in your email to change your password.',
    );

    const onSubmit = (data) => forgotPassword(data);

    if (isSuccess) return <Redirect to="/reset-password" />;

    return (
        <AuthLayout image={image} subtitle="Let's get you a new password first.">
            <Box mt={6}>
                <Typography variant="h4" component="h1">
                    Forgot password
                </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box mt={3}>
                    <TextField {...registerField('email')} fullWidth label="Email" type="email" />
                </Box>
                <Box mt={4}>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        size="large"
                        fullWidth
                        type="submit"
                    >
                        Send me a reset password link
                    </LoadingButton>
                </Box>
            </form>
        </AuthLayout>
    );
}

export default ForgotPassword;
