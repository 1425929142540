import {
    Box,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    LinearProgress,
} from '@mui/material';
import { ALL_HOURS, DAYS, TOPBAR_HEIGHT } from '@constants';
import { DayLabel, Event } from 'components/Booking';
import { useGetCalendarQuery } from 'api';
import { useMemo } from 'react';
import { getTimezoneOffsetHours, makeGMTString, rotateArr } from 'helpers';
import chunk from 'lodash/chunk';
import {
    addDays,
    addMinutes,
    differenceInMinutes,
    isToday,
    isWithinInterval,
    setHours,
    setMinutes,
    setSeconds,
} from 'date-fns';

const CELL_PROPS = { p: 1 / 2, height: 0 };
const HEADER_CELL_PROPS = {
    top: TOPBAR_HEIGHT,
    zIndex: 10,
    whiteSpace: 'nowrap',
};

const tzOffset = getTimezoneOffsetHours();

export const getTimeData = (item) => {
    const hours = parseInt(item / 2);
    const minutes = item % 2 ? '30' : '00';
    const time = `${('00' + hours).slice(-2)}:${minutes}`;
    return { hours, minutes, time };
};

export const getScheduleMatrix = (schedule) => {
    if (!schedule) return false;

    const scheduleArr = schedule.reduce((a, v) => {
        if (!v.startTime || !v.endTime || !v.status) {
            ALL_HOURS.forEach((hour) => a.push(false));
            return a;
        }
        const startNumber = Number(v.startTime.slice(0, 2));
        const endNumber = Number(v.endTime.slice(0, 2));
        for (const currentItem of ALL_HOURS) {
            const { hours } = getTimeData(currentItem);
            a.push(hours >= startNumber && hours < endNumber);
        }
        return a;
    }, []);

    return chunk(rotateArr(scheduleArr, tzOffset * 2), 24 * 2);
};

function ViewWeek({ start, isThisWeek, data, handleCreate = () => {}, handleView = () => {} }) {
    const calendarQuery = useGetCalendarQuery();

    const scheduleMatrix = useMemo(() => {
        return getScheduleMatrix(calendarQuery?.data);
    }, [calendarQuery?.data]);

    if (calendarQuery?.isLoading) return <LinearProgress />;

    return (
        <Box mx={-3 / 4}>
            <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                ...CELL_PROPS,
                                width: '5em',
                                ...HEADER_CELL_PROPS,
                                fontWeight: 'normal',
                                fontSize: 'caption.fontSize',
                                color: 'text.secondary',
                            }}
                        >
                            {makeGMTString(tzOffset)}
                        </TableCell>
                        {DAYS.map((_, dayNumber) => {
                            const day = addDays(start, dayNumber);

                            return (
                                <TableCell
                                    key={dayNumber}
                                    sx={{ ...CELL_PROPS, ...HEADER_CELL_PROPS }}
                                >
                                    {DAYS[dayNumber]}
                                    <DayLabel
                                        day={day}
                                        ml={3 / 4}
                                        fontWeight={isToday(day) ? 'bold' : undefined}
                                    />
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ALL_HOURS.map((currentEntry) => {
                        let CURRENT_HOUR_PROPS = {};
                        const { hours, minutes, time } = getTimeData(currentEntry);

                        const startHours = setMinutes(setHours(new Date(), hours), minutes);
                        const timeInterval = {
                            start: startHours,
                            end: setSeconds(addMinutes(startHours, 29), 59),
                        };
                        const isCurrentHour = isWithinInterval(new Date(), timeInterval);

                        if (isThisWeek && isCurrentHour) {
                            CURRENT_HOUR_PROPS = {
                                borderBottomColor: 'error.main',
                                borderBottomWidth: 2,
                            };
                        }

                        const rowOpen = DAYS.reduce((a, _, dayNumber) => {
                            return a || scheduleMatrix?.[dayNumber]?.[currentEntry];
                        }, false);

                        const FINAL_CELL_PROPS = {
                            ...CELL_PROPS,
                            ...CURRENT_HOUR_PROPS,
                            ...(rowOpen ? {} : { p: 1 / 4 }),
                        };

                        return (
                            <TableRow
                                key={currentEntry}
                                hover
                                sx={{
                                    '&.MuiTableRow-hover:hover': {
                                        bgcolor: '#00000003',
                                    },
                                }}
                            >
                                <TableCell sx={FINAL_CELL_PROPS}>{rowOpen && time}</TableCell>
                                {DAYS.map((_, dayNumber) => {
                                    const day = addDays(start, dayNumber);
                                    const today = isToday(day);
                                    const event = data?.[day.getDate()]?.[time];
                                    const eventStartDate = setMinutes(
                                        setHours(day, +hours),
                                        +minutes,
                                    );
                                    const open =
                                        scheduleMatrix?.[dayNumber]?.[currentEntry] &&
                                        differenceInMinutes(eventStartDate, new Date()) >= 59;

                                    return (
                                        <TableCell
                                            key={dayNumber}
                                            sx={{
                                                ...FINAL_CELL_PROPS,
                                                borderLeft: '1px solid',
                                                borderLeftColor: 'grey.200',
                                                bgcolor: open ? undefined : '#f6f6f6',
                                                position: 'relative',

                                                '&:after': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    width: 11,
                                                    height: 11,
                                                    borderRadius: 5,
                                                    left: -6,
                                                    bottom: -6,
                                                    zIndex: 5,
                                                    bgcolor: 'red',
                                                    display:
                                                        today && isCurrentHour ? 'block' : 'none',
                                                },

                                                '&:hover': {
                                                    cursor: open ? 'pointer' : undefined,
                                                },
                                            }}
                                            onClick={
                                                event || !open
                                                    ? undefined
                                                    : (e) =>
                                                          handleCreate(
                                                              eventStartDate,
                                                              addMinutes(eventStartDate, 30),
                                                          )
                                            }
                                        >
                                            {event && (
                                                <Event
                                                    key={event?._id}
                                                    event={event}
                                                    today={today}
                                                    handleView={handleView}
                                                />
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Box>
    );
}

export default ViewWeek;
