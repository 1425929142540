import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stack,
    ToggleButtonGroup,
    ToggleButton,
    Switch,
    FormControlLabel,
} from '@mui/material';

import { useAutoNotify } from 'hooks';
import { useUpdateUserMutation } from 'api';
import { STATUSES } from 'hooks/useAccountCompletion';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';

function EditAccountCompletionDialog({ open, handleClose = () => {}, data }) {
    const [accountCompletion, setAccountCompletion] = useState(false);
    const [hasProfile, setHasProfile] = useState(false);

    const [submit, { error, isLoading, isSuccess }] = useUpdateUserMutation();
    useAutoNotify(error, isSuccess, 'Status updated successfully.');

    useEffect(() => {
        setAccountCompletion(data?.accountCompletion);
        setHasProfile(data?.hasProfile);
    }, [data?.accountCompletion, data?.hasProfile]);

    const handleChange = (e, newValue) => {
        setAccountCompletion(newValue);
    };
    const handleSwitch = (e) => {
        setHasProfile(e.target.checked);
    };

    const handleSubmit = async () => {
        try {
            await submit({ accountCompletion, hasProfile, userId: data?._id });
            handleClose();
        } catch (error) {}
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Update account completion status</DialogTitle>
            <DialogContent>
                <Stack mt={1} spacing={3}>
                    <ToggleButtonGroup
                        orientation="vertical"
                        value={accountCompletion}
                        exclusive
                        onChange={handleChange}
                        fullWidth
                    >
                        {STATUSES.map((v, i) => (
                            <ToggleButton
                                disabled={accountCompletion === i || isLoading}
                                key={i}
                                value={i}
                            >
                                {v[0].toUpperCase() + v.slice(1)}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                    <FormControlLabel
                        disabled={isLoading}
                        control={<Switch checked={hasProfile} onChange={handleSwitch} />}
                        label="Has profie"
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={handleClose} disabled={isLoading}>
                    Cancel
                </Button>
                <LoadingButton onClick={handleSubmit} loading={isLoading}>
                    Submit
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default EditAccountCompletionDialog;
