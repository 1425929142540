import React, { createRef } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params';

import { ThemeProvider, Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import gbLocale from 'date-fns/locale/en-GB';

import theme from 'theme';
import createStore from 'ducks';

import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from 'snackbar-utils';
import { ConfirmationDialogProvider } from 'common';
import { SocketProvider } from 'common/socket';

const history = createBrowserHistory({
    basename: '/',
});

const store = createStore(history);

function SnackbarWrapper({ children }) {
    const notistackRef = createRef();
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <SnackbarProvider
            // anchorOrigin={{
            //     vertical: 'bottom',
            //     horizontal: 'right',
            // }}
            ref={notistackRef}
            action={(key) => (
                <Button color="inherit" onClick={onClickDismiss(key)}>
                    Dismiss
                </Button>
            )}
            variant="success"
        >
            <SnackbarUtilsConfigurator />
            {children}
        </SnackbarProvider>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <Router history={history}>
                    <QueryParamProvider
                        ReactRouterRoute={Route}
                        stringifyOptions={{
                            transformSearchString: transformSearchStringJsonSafe,
                        }}
                    >
                        <SnackbarWrapper>
                            <ConfirmationDialogProvider>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={gbLocale}
                                >
                                    <SocketProvider>
                                        <App />
                                    </SocketProvider>
                                </LocalizationProvider>
                            </ConfirmationDialogProvider>
                        </SnackbarWrapper>
                    </QueryParamProvider>
                </Router>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
