import { Box } from '@mui/material';
import { useMe, useRoles } from 'hooks';
import { format } from 'date-fns';

function Event({ event, today, handleView, showStartTime, sx = {}, ...props }) {
    const { isAdmin } = useRoles();
    const me = useMe();

    if (!event) return null;

    const isBooking = isAdmin ? event?.type === 1 : !!event?.user;
    const color = isBooking ? 'primary' : 'grey';
    const colorShade = !isBooking ? '500' : today ? 'dark' : 'main';
    const nameFallback = event?.user === me?._id ? me?.name : 'Unknown';

    return (
        <Box
            bgcolor={`${color}.${colorShade}`}
            borderRadius={1 / 2}
            px={3 / 4}
            py={1 / 4}
            color="primary.contrastText"
            onClick={isAdmin || isBooking ? (e) => handleView(event) : undefined}
            display="flex"
            flexWrap="wrap"
            overflow="hidden"
            letterSpacing="-0.025em"
            sx={{ cursor: 'pointer', ...sx }}
            {...props}
        >
            {showStartTime && (
                <Box component="span" mr={1 / 2} fontWeight="bold">
                    {format(new Date(event?.startDate), 'HH:mm')}
                </Box>
            )}
            {isBooking ? event?.user?.[0]?.name || nameFallback : 'Busy'}
        </Box>
    );
}

export default Event;
