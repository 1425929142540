import { Fragment, useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Box, Grid, Typography, LinearProgress, Tooltip, Paper } from '@mui/material';
import { ExerciseDialog } from 'components/Exercises';
import { GroupNotesDialog, LogDialog } from 'components/Activity';
import { useAutoNotify } from 'hooks';
import { useGetGroupNotesByBlockIdQuery, useGetLogsByBlockIdQuery, useGetMyBlocksQuery } from 'api';

import {
    addHours,
    format,
    getISODay,
    isWithinInterval,
    setHours,
    setMinutes,
    startOfToday,
} from 'date-fns';

import { Group } from 'components/Program';
import { DAYS } from '@constants';

function Dashboard() {
    const match = useRouteMatch();

    const { data, error, isLoading, isSuccess } = useGetMyBlocksQuery();
    useAutoNotify(error);

    const currentBlock = useMemo(() => {
        const index = data?.findIndex((v) => {
            return isWithinInterval(new Date(), {
                start: setMinutes(setHours(new Date(v.startDate), 0), 0),
                end: setMinutes(setHours(new Date(v.endDate), 23), 59),
            });
        });
        return data?.[index];
    }, [data]);

    const logs = useGetLogsByBlockIdQuery(currentBlock?._id, { skip: !currentBlock?._id });
    useAutoNotify(logs.error);

    const groupNotes = useGetGroupNotesByBlockIdQuery(currentBlock?._id, {
        skip: !currentBlock?._id,
    });
    useAutoNotify(groupNotes.error);

    const logsByDay = useMemo(
        () =>
            logs?.data?.reduce((a, v) => {
                if (v?.date) {
                    const key = format(new Date(v?.date), 'ddMMy');
                    if (a[key]) {
                        a[key].push(v);
                    } else {
                        a[key] = [v];
                    }
                }
                return a;
            }, {}) || {},
        [logs?.data],
    );

    const groupNotesByDay = useMemo(
        () =>
            groupNotes?.data?.reduce((a, v) => {
                if (v?.date) {
                    const key = format(new Date(v?.date), 'ddMMy');
                    if (a[key]) {
                        a[key].push(v);
                    } else {
                        a[key] = [v];
                    }
                }
                return a;
            }, {}) || {},
        [groupNotes?.data],
    );

    const today = addHours(startOfToday(), 1);
    const currentDayLogs = useMemo(() => logsByDay?.[format(today, 'ddMMy')], [logsByDay, today]);
    const currentDayGroupNotes = useMemo(
        () => groupNotesByDay?.[format(today, 'ddMMy')],
        [groupNotesByDay, today],
    );
    const getGroupNotesByGroupId = useCallback(
        (groupId) => currentDayGroupNotes?.filter((v) => v?.groupId === groupId),
        [currentDayGroupNotes],
    );

    const groups = useMemo(
        () =>
            currentBlock?.groups?.reduce((a, group) => {
                const todayLabel = DAYS[getISODay(today) - 1];
                if (group?.days?.indexOf(todayLabel) > -1) {
                    const currentDayAndGroupLogs = currentDayLogs?.filter(
                        (q) => q?.groupId === group?._id,
                    );
                    const exerciseCount = group?.exercises?.length || 0;

                    a.push({
                        ...group,
                        logsComplete: exerciseCount === currentDayAndGroupLogs?.length,
                        logsStarted:
                            exerciseCount > currentDayAndGroupLogs?.length &&
                            currentDayAndGroupLogs?.length > 0,
                    });
                }
                return a;
            }, []) || [],
        [currentBlock?.groups, currentDayLogs, today],
    );

    const [todayComplete, todayStarted] = useMemo(
        () =>
            groups.reduce(
                (a, v) => {
                    a[0] = a[0] && v.logsComplete;
                    a[1] = a[1] || v.logsStarted;
                    return a;
                },
                [true, false],
            ),
        [groups],
    );

    if (isLoading || logs.isLoading) return <LinearProgress />;
    if (isSuccess && !currentBlock?._id)
        return (
            <Paper sx={{ p: 3 }}>
                <Typography>No data yet.</Typography>
            </Paper>
        );
    if (!isSuccess || !logs.isSuccess) return <Typography>There has been an error.</Typography>;

    return (
        <Fragment>
            <ExerciseDialog />
            <LogDialog parentPath={match?.url} block={currentBlock} selectedDate={today} />
            <GroupNotesDialog parentPath={match?.url} block={currentBlock} selectedDate={today} />

            <Typography variant="h5" mb={2}>
                Today's exercises
                <Tooltip
                    placement="top"
                    title={
                        todayComplete
                            ? 'All exercises are logged'
                            : todayStarted
                            ? 'Only some exercises are logged'
                            : 'Nothing logged'
                    }
                >
                    <Box
                        color={todayComplete ? 'success.dark' : 'warning.dark'}
                        component="span"
                        ml={2}
                    >
                        <i
                            className={`fa-xs fa-mr-half fa-${
                                todayComplete
                                    ? 'check-circle fas'
                                    : todayStarted
                                    ? 'check-circle far'
                                    : 'circle far'
                            }`}
                        />
                    </Box>
                </Tooltip>
            </Typography>
            <Grid container spacing={2}>
                {!groups.length && (
                    <Grid item>
                        <Typography variant="subtitle1" mr={2}>
                            Nothing for today.
                        </Typography>
                    </Grid>
                )}
                {groups.map((v) => (
                    <Grid item xs={12} xl={6} key={v._id}>
                        <Group
                            data={v}
                            selectedDate={today}
                            defaultOpen
                            logs={currentDayLogs}
                            groupNotes={getGroupNotesByGroupId(v._id)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Fragment>
    );
}

export default Dashboard;
