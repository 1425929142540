import { Box, Button, Divider, Stack, Tooltip } from '@mui/material';
import { NewMessage, ConversationConterpart, StickyChatActions } from 'components/Chat';
import { CONVERSATION_HEADER_CLASS } from 'components/Chat/constants';
import { chatSelectors } from 'ducks';
import { useAddStickyChat } from 'hooks';
import { useSelector } from 'react-redux';

function ConversationHeader({ counterpart, conversation, compact, isSticky }) {
    const showNew = useSelector(chatSelectors.getShowNew);
    const addStickyChat = useAddStickyChat();

    const handlePin = () => {
        addStickyChat(conversation);
    };

    return (
        <Box
            position="sticky"
            top={0}
            bgcolor="#fffb"
            zIndex={10}
            sx={{
                backdropFilter: 'blur(8px)',
            }}
            className={CONVERSATION_HEADER_CLASS}
        >
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                height={compact ? 56 : 82}
                px={compact ? 1.5 : 2.5}
            >
                {showNew ? (
                    <NewMessage />
                ) : (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        flexGrow={1}
                    >
                        <ConversationConterpart
                            counterpart={counterpart}
                            compact={compact}
                            isSticky={isSticky}
                        />
                        {!isSticky && (
                            <Tooltip title="Make this conversation sticky">
                                <Button size="narrow" onClick={handlePin}>
                                    <i className="fas fa-thumbtack" />
                                </Button>
                            </Tooltip>
                        )}
                        {isSticky && <StickyChatActions conversation={conversation} active />}
                    </Stack>
                )}
            </Box>
            <Divider />
        </Box>
    );
}

export default ConversationHeader;
