import { FollowUps } from 'components/Reconsultation';
import { useMe } from 'hooks';
import { Paper } from '@mui/material';

function FollowUpsClient({ test }) {
    const me = useMe();

    return (
        <Paper sx={{ p: 3 }}>
            <FollowUps userId={me?._id} />
        </Paper>
    );
}

export default FollowUpsClient;
