import { useDispatch, useSelector } from 'react-redux';
import { uiActions, uiSelectors } from 'ducks';
import { Box, Dialog, DialogContent, Fade, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useS3Query } from 'hooks';

function VideoViewer() {
    const dispatch = useDispatch();
    const value = useSelector(uiSelectors.getImageViewerId);
    const [loading, setLoading] = useState(false);
    const [localValue, setLocalValue] = useState(false);

    useEffect(() => {
        if (!value) return;
        setLocalValue(value);
        setLoading(true);
    }, [value]);

    const handleClose = () => {
        dispatch(uiActions.clearImageViewerId());
    };

    const urlQuery = useS3Query(localValue);

    return (
        <Dialog
            open={!!value}
            onClose={handleClose}
            TransitionProps={{ onExited: () => setLocalValue(false) }}
            fullWidth
            maxWidth="md"
            scroll="body"
        >
            <DialogContent sx={{ textAlign: 'center' }}>
                {loading && <LinearProgress />}
                <Fade in={!loading}>
                    <Box
                        component="img"
                        src={urlQuery?.url}
                        alt=""
                        onLoad={() => setLoading(false)}
                    />
                </Fade>
            </DialogContent>
        </Dialog>
    );
}

export default VideoViewer;
