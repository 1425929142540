import { Stack, TextField } from '@mui/material';

function Step3({ formMethods }) {
    const { registerField } = formMethods;

    return (
        <Stack spacing={4} flexGrow={1}>
            <TextField
                label="What is your history of training activity?"
                {...registerField('trainingHistory')}
                multiline
                minRows={3}
                helperText="State whether you've trained sports as an athlete (as youth and as an adult), or trained casually in the gym (both resistance and cardio)"
            />
            <TextField
                label="What is your injury history?"
                {...registerField('injuryHistory')}
                multiline
                minRows={3}
                helperText="State whether you had any significant injuries in the past that have led you to undergo surgery or injuries that have been improperly healed (scar tissue development/loss of range of motion/loss of strength/loss of stability)"
            />
            <TextField
                label="Repetition maximums"
                {...registerField('strength')}
                multiline
                minRows={1}
                helperText="State your 1RM, 3RM, 10RM in strength exercise (if known)"
            />
            <TextField label="VO2 Max (if known)" {...registerField('vo2')} />
        </Stack>
    );
}

export default Step3;
