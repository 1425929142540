import { TabbedPage } from 'common';
import { Fragment } from 'react';
import { CheckoutInfo, Purchase, History } from 'components/Subscriptions';
import { useGetMeQuery } from 'api';
import { Alert, Button } from '@mui/material';
import { Link } from 'routes';
import { Link as RouterLink } from 'react-router-dom';
import { useRoles } from 'hooks';

function Subscriptions() {
    const meQuery = useGetMeQuery();
    const { isAdmin } = useRoles();

    return (
        <Fragment>
            <CheckoutInfo />

            {!isAdmin && !meQuery?.data?.hasProfile && (
                <Alert
                    severity="warning"
                    sx={{ mb: 2 }}
                    action={
                        <Button
                            color="inherit"
                            size="small"
                            variant="outlined"
                            component={RouterLink}
                            to="/profile/edit"
                        >
                            Start
                        </Button>
                    }
                >
                    You must complete your <Link to="/profile/edit">profile</Link>.
                </Alert>
            )}

            <TabbedPage
                pages={[
                    {
                        id: 'purchase',
                        name: 'Purchase',
                        Component: Purchase,
                    },
                    {
                        id: 'payments',
                        name: 'Payments history',
                        Component: History,
                    },
                ]}
                componentProps={{
                    userId: meQuery?.data?._id,
                    stripeCustomerId: meQuery?.data?.stripeCustomerId,
                }}
            />
        </Fragment>
    );
}

export default Subscriptions;
