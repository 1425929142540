import { useMe } from 'hooks';
import { useCallback } from 'react';

export const getChatCounterpart = (users, me) => {
    return users.find((v) => v?.id !== me?._id);
};

const useGetChatCounterpart = (data) => {
    const me = useMe();

    const getCounterpart = useCallback(
        (data) => {
            if (!data?.users) return {};

            return getChatCounterpart(data?.users, me);
        },
        [me],
    );

    return data ? getCounterpart(data) : getCounterpart;
};

export default useGetChatCounterpart;
