import { InputAdornment, Typography } from '@mui/material';

function makeRecommendedProps(recommendedValue, setValue, name, wrap = (v) => v) {
    return {
        InputProps: recommendedValue
            ? {
                  endAdornment: (
                      <InputAdornment position="end" disableTypography>
                          <Typography
                              whiteSpace="nowrap"
                              color="grey.600"
                              sx={{ cursor: 'pointer' }}
                              variant="body2"
                              onClick={(e) => setValue(name, recommendedValue)}
                          >
                              {wrap(recommendedValue)}
                          </Typography>
                      </InputAdornment>
                  ),
              }
            : undefined,
        InputLabelProps: {
            shrink: true,
        },
    };
}

export default makeRecommendedProps;
