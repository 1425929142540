import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import {
    Card,
    CardActions,
    CardContent,
    Typography,
    Box,
    Grid,
    Divider,
    Stack,
    LinearProgress,
    Container,
    useMediaQuery,
    Alert,
    AlertTitle,
} from '@mui/material';

import { Tag } from 'common';
import {
    useCreateCheckoutSessionMutation,
    useGetActiveSubscriptionsQuery,
    useGetProductsQuery,
} from 'api';
import { useAutoNotify, useMe, useOngoingSubscription } from 'hooks';
import { LoadingButton } from '@mui/lab';
import ActiveSubscription from 'components/Subscriptions/ActiveSubscription';
import { getURLParams } from 'helpers';
import { useCallback } from 'react';
import { isBefore } from 'date-fns';

function Purchase({ userId }) {
    const query = useRef(getURLParams());
    const me = useMe();

    const { data, error, isLoading } = useGetProductsQuery();
    useAutoNotify(error);

    const activeSubscriptionQuery = useGetActiveSubscriptionsQuery(userId);

    const [createCheckoutSession, checkoutSessionCreation] = useCreateCheckoutSessionMutation();
    useAutoNotify(checkoutSessionCreation.error);

    const handleBuy = useCallback(
        async (product) => {
            try {
                createCheckoutSession(product);
            } catch (error) {}
        },
        [createCheckoutSession],
    );

    const isLgDown = useMediaQuery((t) => t.breakpoints.down('lg'));
    const isLg = useMediaQuery((t) => t.breakpoints.only('lg'));

    const processedData = useMemo(() => {
        if (!data) return [];

        return data
            .slice()
            .filter((v) => !!v.product.metadata.category)
            .sort((a, b) => {
                const name = a?.product?.name?.toLowerCase();
                if (name === 'trial') return -1;
                if (name === 'basic') return 1;
                return 0;
            });
    }, [data]);

    const { hasOngoingSubscription } = useOngoingSubscription();

    useEffect(() => {
        const productName = query.current.get('product');
        const product = processedData.find((v) => v?.product?.name?.toLowerCase() === productName);

        if (!product) return;
        if (!activeSubscriptionQuery?.isSuccess) return;
        if (hasOngoingSubscription) return;

        handleBuy(product);
    }, [activeSubscriptionQuery?.isSuccess, hasOngoingSubscription, handleBuy, processedData]);

    const showWinterPromotion = useMemo(() => isBefore(new Date(), new Date(2023, 0, 21)), []);

    if (checkoutSessionCreation.isSuccess) {
        window.location.href = checkoutSessionCreation?.data?.session?.url;
    }

    if (isLoading) return <LinearProgress />;

    if (!processedData?.length) {
        return (
            <Card>
                <Box p={2}>
                    <Typography variant="subtitle1">No plans available.</Typography>
                </Box>
            </Card>
        );
    }

    return (
        <Fragment>
            <ActiveSubscription userId={userId} />
            {showWinterPromotion && (
                <Alert sx={{ mb: 2 }} severity="info">
                    <AlertTitle sx={{ fontWeight: 600 }}>
                        50% off during the Winter Holidays
                    </AlertTitle>
                    Everything is 50% off until 20th of January!
                </Alert>
            )}
            <Container
                maxWidth={isLgDown ? 'xs' : isLg ? 'md' : 'lg'}
                sx={{
                    mx: {
                        xs: 'auto',
                        xl: 'initial',
                    },
                    '&.MuiContainer-root': {
                        px: 0,
                    },
                }}
            >
                <Grid container spacing={3}>
                    {processedData.map((product, i) => {
                        const bestValue = product?.product?.name?.toLowerCase() === 'premium';
                        const recurring = product.type === 'recurring';
                        const { blocks, bookings } = product?.product?.metadata || {};

                        return (
                            <Grid item xs={12} lg={6} xl={4} key={product.id}>
                                <Card
                                    elevation={5}
                                    sx={{
                                        height: '100%',
                                        border: bestValue ? '2px solid' : undefined,
                                        borderColor: 'primary.main',
                                    }}
                                >
                                    <Stack justifyContent="space-between" height="100%">
                                        <CardContent>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <Typography
                                                    variant="h4"
                                                    component="div"
                                                    fontWeight="fontWeightBold"
                                                >
                                                    {product?.product?.name}
                                                </Typography>
                                                {bestValue && (
                                                    <Tag
                                                        size="small"
                                                        bgcolor="success.main"
                                                        fontWeight="fontWeightBold"
                                                    >
                                                        Best value
                                                    </Tag>
                                                )}
                                            </Stack>
                                            <Typography variant="h6">
                                                {showWinterPromotion ? (
                                                    <Fragment>
                                                        <Box
                                                            component="span"
                                                            sx={{ textDecoration: 'line-through' }}
                                                            mr={1 / 2}
                                                        >
                                                            £{product.unit_amount / 100}
                                                        </Box>
                                                        <Box component="span" fontWeight="600">
                                                            £{product.unit_amount / 100 / 2}
                                                        </Box>
                                                        {recurring && (
                                                            <Fragment> first 3 months</Fragment>
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <Box component="span" fontWeight="600">
                                                            £{product.unit_amount / 100}
                                                        </Box>
                                                        {recurring && (
                                                            <Fragment> every 3 months</Fragment>
                                                        )}
                                                    </Fragment>
                                                )}
                                            </Typography>

                                            <Divider sx={{ mx: -2, my: 1.5 }} />

                                            {blocks && (
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography>
                                                        <i className="far fa-calendar fa-sm fa-mr-1 fa-sq" />
                                                        Duration
                                                    </Typography>
                                                    <Typography>
                                                        <Box
                                                            fontWeight="fontWeightBold"
                                                            component="span"
                                                        >
                                                            {blocks} month{blocks > 1 && 's'}
                                                        </Box>
                                                        {recurring && ', recurring'}
                                                    </Typography>
                                                </Stack>
                                            )}
                                            {bookings > 0 && (
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography>
                                                        <i className="fas fa-user-check fa-sm fa-mr-1 fa-sq" />
                                                        Booking
                                                    </Typography>
                                                    <Typography fontWeight="fontWeightBold">
                                                        {bookings} meetings
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </CardContent>

                                        <CardActions sx={{ p: 1.5 }}>
                                            <LoadingButton
                                                variant="contained"
                                                fullWidth
                                                onClick={(e) => handleBuy(product)}
                                                disabled={
                                                    hasOngoingSubscription ||
                                                    checkoutSessionCreation.isLoading ||
                                                    (!recurring && me?.hadTrial)
                                                }
                                                loading={
                                                    checkoutSessionCreation.isLoading &&
                                                    checkoutSessionCreation.originalArgs.id ===
                                                        product.id
                                                }
                                            >
                                                {recurring
                                                    ? 'Subscribe'
                                                    : !recurring && me?.hadTrial
                                                    ? 'Not available'
                                                    : 'Start your trial'}
                                            </LoadingButton>
                                        </CardActions>
                                    </Stack>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </Fragment>
    );
}

export default Purchase;
