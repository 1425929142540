import { Fragment, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';

import { Dialog } from '@mui/material';

import { uiSelectors, uiActions } from 'ducks';
import { useRoutes } from 'hooks';
import { useGetGroupNoteByIdQuery } from 'api';

import { DialogDataProvider } from 'common';
import { GroupNotesForm } from 'components/Activity';

const getPages = (parentPath) => [
    {
        id: parentPath + '/add',
        name: 'Add',
        Component: GroupNotesForm,
    },
    {
        id: parentPath + '/:id/:action(edit)',
        name: 'Edit',
        Component: GroupNotesForm,
    },
];

function GroupNotesDialog({ parentPath, ...props }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [pages, isMatching, params] = useRoutes(getPages, parentPath + '/logs');
    const [storeId, exerciseData] = useSelector(uiSelectors.getCurrentGroupNoteData);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(!!(isMatching || storeId));
    }, [isMatching, storeId]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleExited = useCallback(() => {
        if (storeId) dispatch(uiActions.clearCurrentGroupNoteData());
        if (isMatching) history.push(parentPath);
    }, [dispatch, history, isMatching, parentPath, storeId]);

    return (
        <Fragment>
            <Dialog
                open={open}
                fullWidth
                maxWidth="sm"
                scroll="body"
                TransitionProps={{ onExited: handleExited }}
            >
                <Fragment>
                    <Switch>
                        {pages.map(({ id, Component }) => (
                            <Route key={id} path={id} exact>
                                <DialogDataProvider
                                    hook={useGetGroupNoteByIdQuery}
                                    args={params?.id}
                                    handleClose={handleClose}
                                    Component={Component}
                                    {...props}
                                    exerciseData={exerciseData}
                                />
                            </Route>
                        ))}
                    </Switch>

                    {storeId && (
                        <DialogDataProvider
                            hook={useGetGroupNoteByIdQuery}
                            args={storeId}
                            handleClose={handleClose}
                            Component={GroupNotesForm}
                            storeId={storeId}
                            {...props}
                            exerciseData={exerciseData}
                        />
                    )}
                </Fragment>
            </Dialog>
        </Fragment>
    );
}

export default GroupNotesDialog;
