import { useGetMeState } from 'api';
import { useCallback } from 'react';

const useS3Static = () => {
    const { data } = useGetMeState();

    const getter = useCallback(
        (fileName) => `${data?.awsStaticFolder}/${fileName}`,
        [data?.awsStaticFolder],
    );

    return getter;
};

export default useS3Static;
