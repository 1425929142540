import { DialogPlaceholder } from 'common';
import { useAutoNotify } from 'hooks';

function DialogDataProvider({
    hook: useHook = () => ({}),
    handleClose,
    Component,
    bypass,
    args,
    ...props
}) {
    const skip = !args || args === 'add' || args < 0;
    const { data, error, isLoading, isSuccess } = useHook(args, { skip });
    useAutoNotify(error);

    const content = <Component data={data} handleClose={handleClose} {...props} />;

    if (bypass) return content;

    if (!skip && (isLoading || !isSuccess))
        return (
            <DialogPlaceholder loading={isLoading} handleClose={handleClose}>
                No exercise found
            </DialogPlaceholder>
        );

    return content;
}

export default DialogDataProvider;
