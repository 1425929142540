import { VideoDialog } from 'common';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions, uiSelectors } from 'ducks';

function VideoViewer() {
    const dispatch = useDispatch();
    const videoViewerId = useSelector(uiSelectors.getVideoViewerId);

    const handleVideoClose = () => {
        dispatch(uiActions.clearVideoViewerId());
    };

    return <VideoDialog currentVideo={videoViewerId} onClose={handleVideoClose} />;
}

export default VideoViewer;
