import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

function useRoutes(getPages, parentPath) {
    const pages = useMemo(() => getPages(parentPath), [getPages, parentPath]);
    const routes = useMemo(() => pages.map((v) => v.id), [pages]);
    const match = useRouteMatch({ path: routes, exact: true });
    const isMatching = match !== null;

    return [pages, isMatching, match?.params];
}

export default useRoutes;
