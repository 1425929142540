import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showNew: false,
    newRecipient: null,
    noConversations: false,

    activeStickyChat: false,
    stickyChats: [],
};

const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        toggleShowNew: (state, action) => {
            if (action.payload !== undefined) state.showNew = !!action.payload;
            else state.showNew = !state.showNew;
        },
        setNewRecipient: (state, action) => {
            state.newRecipient = action.payload;
        },
        setNoConversations: (state, action) => {
            state.noConversations = action.payload;
        },

        setActiveStickyChat: (state, action) => {
            state.activeStickyChat = action.payload;
        },
        addStickyChat: (state, action) => {
            const { chat, maxAmount, keepMinimized } = action.payload;
            const exists = state.stickyChats.some((v) => v?._id === chat?._id);
            if (!exists) {
                if (state.stickyChats?.length === maxAmount) state.stickyChats.pop();
                state.stickyChats.unshift(chat);
            }
            if (maxAmount > 1 && !keepMinimized) state.activeStickyChat = chat;
        },
        removeStickyChat: (state, action) => {
            const id = action.payload?._id || action.payload;
            state.stickyChats = state.stickyChats.filter((v) => id !== v?._id);
            if (state.activeStickyChat?._id === id) state.activeStickyChat = false;
        },
        sliceStickyChats: (state, action) => {
            if (!action.payload) return;
            state.stickyChats = state.stickyChats.slice(0, action.payload);

            if (state.stickyChats.some((v) => v?._id === state.activeStickyChat?._id)) return;
            state.activeStickyChat = false;
        },
    },
});

// ACTIONS

function getActions() {
    const {
        setNewRecipient,
        toggleShowNew,
        setNoConversations,
        setActiveStickyChat,
        addStickyChat,
        removeStickyChat,
        sliceStickyChats,
    } = slice.actions;

    return {
        setNewRecipient,
        toggleShowNew,
        setNoConversations,
        setActiveStickyChat,
        addStickyChat,
        removeStickyChat,
        sliceStickyChats,
    };
}

export const actions = getActions();

// SELECTORS

const getNewRecipient = (state) => state.chat.newRecipient;
const getNoConversations = (state) => state.chat.noConversations;
const getShowNew = (state) => state.chat.showNew || getNoConversations(state);
const getActiveStickyChat = (state) => state.chat.activeStickyChat;
const getStickyChats = (state) => state.chat.stickyChats;

export const selectors = {
    getNewRecipient,
    getShowNew,
    getNoConversations,
    getActiveStickyChat,
    getStickyChats,
};

export default slice.reducer;
