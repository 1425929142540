import { useSnackbar } from 'notistack';

let useSnackbarRef;
export function SnackbarUtilsConfigurator() {
    useSnackbarRef = useSnackbar();
    return null;
}

export const snackbar = {
    success(msg) {
        this.notify(msg, 'success');
    },
    warning(msg) {
        this.notify(msg, 'warning');
    },
    info(msg) {
        this.notify(msg, 'info');
    },
    error(msg) {
        this.notify(msg, 'error');
    },
    notify(msg, variant = 'default') {
        useSnackbarRef.enqueueSnackbar(msg, { variant });
    },
};

export default snackbar;
