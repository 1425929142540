import React, { Fragment, useEffect } from 'react';
import * as yup from 'yup';

import { DialogTitle, DialogContent, TextField, DialogActions, Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useUpdateResourceMutation, useAddResourceMutation } from 'api';
import { useAutoNotify, useForm } from 'hooks';
import { Autocomplete, Switch } from 'form';
import { ResourceFileControl } from 'components/Resources';

const schema = yup.object({
    title: yup.string().required('Title is required'),
    category: yup.string().required('You must pick a category').nullable(),
});

function ResourceForm({ data = {}, categories = [], handleClose, add }) {
    const useMutation = !add ? useUpdateResourceMutation : useAddResourceMutation;
    const [submit, { error, isLoading, isSuccess }] = useMutation();
    useAutoNotify(error, isSuccess, `Resource ${!add ? 'updated' : 'added'} successfully.`);

    const form = useForm(schema, {
        defaultValues: { ...data, category: data?.category?.[0] || null },
    });
    const { handleSubmit, registerField, getErrorProps, control } = form;

    const onSubmit = (newData) => submit(newData); // filtered in api file

    useEffect(() => {
        if (isSuccess) handleClose();
    }, [handleClose, isSuccess]);

    return (
        <Fragment>
            <DialogTitle>{!add ? 'Edit' : 'Add'} resource</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Stack spacing={3} sx={{ display: 'flex', flexGrow: 1 }}>
                        <TextField
                            {...registerField('title')}
                            fullWidth
                            label="Title"
                            type="text"
                        />
                        <ResourceFileControl
                            name="newFile"
                            existingValue={data?.file}
                            form={form}
                        />
                        <Autocomplete
                            control={control}
                            name="category"
                            options={categories}
                            disableClearable
                            label="Category"
                            placeholder="Type to filter categories"
                            getErrorProps={getErrorProps}
                        />
                        <Switch name="isPublic" control={control} rightLabel="Public" />
                        <TextField
                            {...registerField('text')}
                            fullWidth
                            label="Content"
                            type="text"
                            multiline
                            minRows={3}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} type="submit">
                        {!add ? 'Save' : 'Add'}
                    </LoadingButton>
                </DialogActions>
            </form>
        </Fragment>
    );
}

export default ResourceForm;
