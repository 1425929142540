import { FormControl, FormLabel, Stack, TextField } from '@mui/material';
import { Switch } from 'form';

function Step4({ formMethods }) {
    const { registerField, watch, control } = formMethods;

    const otherReasonNoActivity = watch('otherReasonNoActivity');

    return (
        <Stack spacing={4} flexGrow={1}>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                    Has your doctor ever said you have a heart condition and that you should only do
                    pshysical activity recommended by a doctor?
                </FormLabel>
                <Switch
                    name="doctorHeartCondition"
                    control={control}
                    leftLabel="No"
                    rightLabel="Yes"
                    mt={1}
                />
            </FormControl>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                    Do you feel pain in your chest when you do physical activity?
                </FormLabel>
                <Switch
                    name="chestPainActivity"
                    control={control}
                    leftLabel="No"
                    rightLabel="Yes"
                    mt={1}
                />
            </FormControl>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                    In the past month, have you had a chest pain when you were not doing physical
                    activity?
                </FormLabel>
                <Switch
                    name="chestPainNoActivity"
                    control={control}
                    leftLabel="No"
                    rightLabel="Yes"
                    mt={1}
                />
            </FormControl>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                    Do you lose balance because of dizziness or do you ever lose consciousness?
                </FormLabel>
                <Switch
                    name="loseBalance"
                    control={control}
                    leftLabel="No"
                    rightLabel="Yes"
                    mt={1}
                />
            </FormControl>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                    Do you have a bone or joint problem (for example back, knee, hip) that could be
                    made worse by a change in your physical activity?
                </FormLabel>
                <Switch
                    name="jointProblem"
                    control={control}
                    leftLabel="No"
                    rightLabel="Yes"
                    mt={1}
                />
            </FormControl>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                    Is your doctor currently prescribing medication for your blood pressure or heart
                    condition?
                </FormLabel>
                <Switch
                    name="medication"
                    control={control}
                    leftLabel="No"
                    rightLabel="Yes"
                    mt={1}
                />
            </FormControl>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                    Do you know of any other reason why you should not take part in physical
                    activity?
                </FormLabel>
                <Switch
                    name="otherReasonNoActivity"
                    control={control}
                    leftLabel="No"
                    rightLabel="Yes"
                    mt={1}
                />
            </FormControl>
            {!!otherReasonNoActivity && (
                <TextField
                    label="Other reasons why you think you shouldn't take part in physical activity"
                    {...registerField('otherReasonText')}
                    multiline
                    minRows={3}
                />
            )}
        </Stack>
    );
}

export default Step4;
