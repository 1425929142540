import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';

import { Dialog } from '@mui/material';

import { useGetBlockByIdQuery } from 'api';
import { useRoutes } from 'hooks';
import { DialogDataProvider } from 'common';
import { BlockForm } from 'components/Program';

const getPages = (parentPath) => [
    {
        id: parentPath + '/add',
        name: 'Add',
        Component: BlockForm,
    },
    {
        id: parentPath + '/:id/:action(edit)',
        name: 'Edit',
        Component: BlockForm,
    },
];

function BlockDialog({ parentPath, ...props }) {
    const history = useHistory();
    const [pages, isMatching, params] = useRoutes(getPages, parentPath);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(!!isMatching);
    }, [isMatching]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleExited = useCallback(() => {
        if (isMatching) history.push(parentPath);
    }, [history, isMatching, parentPath]);

    return (
        <Fragment>
            <Dialog
                open={open}
                fullWidth
                maxWidth="xl"
                scroll="body"
                TransitionProps={{ onExited: handleExited }}
            >
                <Switch>
                    {pages.map(({ id, Component }) => (
                        <Route key={id} path={id} exact>
                            <DialogDataProvider
                                hook={useGetBlockByIdQuery}
                                handleClose={handleClose}
                                Component={Component}
                                args={params?.id}
                                {...props}
                            />
                        </Route>
                    ))}
                </Switch>
            </Dialog>
        </Fragment>
    );
}

export default BlockDialog;
