import { useEffect, useCallback } from 'react';
import { AuthLayout } from 'components/Auth';
import { useParams } from 'react-router-dom';

import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useActivateAccountMutation } from 'api';
import * as yup from 'yup';
import { useAutoNotify, useForm } from 'hooks';

const schema = yup
    .object({
        token: yup.string().required(),
    })
    .required();

function ActivateAccount() {
    const { token } = useParams();
    const { handleSubmit, registerField } = useForm(schema);
    const [activateAccount, { error, isLoading, isSuccess, isError }] =
        useActivateAccountMutation();

    useAutoNotify(error, isSuccess, 'Account activated. You are now logged in.');

    const onSubmit = useCallback((data) => activateAccount(data), [activateAccount]);

    useEffect(() => {
        if (!!token) handleSubmit(onSubmit)();
    }, [handleSubmit, onSubmit, token]);

    const editToken = !token || isError;

    return (
        <AuthLayout>
            <Box mt={6}>
                <Typography variant="h4" component="h1">
                    Activate account
                </Typography>
                {editToken && (
                    <Typography variant="subtitle2" mt={1}>
                        If you can't click the link in the email, paste here the{' '}
                        <b>activation token</b>.
                    </Typography>
                )}
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box mt={3}>
                    <TextField
                        {...registerField('token')}
                        fullWidth
                        label="Activation token"
                        type="text"
                        defaultValue={token ?? ''}
                        readOnly={!editToken}
                    />
                </Box>
                <Box mt={4}>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        size="large"
                        fullWidth
                        type="submit"
                    >
                        Activate account
                    </LoadingButton>
                </Box>
            </form>
        </AuthLayout>
    );
}

export default ActivateAccount;
