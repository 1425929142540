import { useMe } from 'hooks';

export const STATUSES = ['new', 'confirmed email', 'purchased', 'has program'];

const useAccountCompletion = (data) => {
    const meData = useMe();
    const accountCompletion = data ? data?.accountCompletion : meData?.accountCompletion;
    const hasProfile = data ? data?.hasProfile : meData?.hasProfile;

    return {
        isLoaded: accountCompletion !== undefined,
        isCreated: accountCompletion >= 0,
        isConfirmed: accountCompletion >= 1,
        hasPurchased: accountCompletion >= 2,
        hasProgram: accountCompletion >= 3,
        hasProfile,
        accountCompletion,
        accountCompletionLabel: STATUSES[accountCompletion || 0],
    };
};

export default useAccountCompletion;
