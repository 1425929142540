import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

function CustomSelect({ children, control, name, getErrorProps = () => ({}), ...TextFieldProps }) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, ...field } }) => (
                <TextField
                    variant="outlined"
                    select
                    SelectProps={{
                        displayEmpty: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    // helperText=" "
                    {...TextFieldProps}
                    {...field}
                    onChange={(e) => {
                        onChange(e);
                        TextFieldProps?.onChange?.(e);
                    }}
                    {...getErrorProps(name)}
                >
                    {children}
                </TextField>
            )}
        />
    );
}

export default CustomSelect;
