import { format } from 'date-fns';
import { useAutoNotify, useDialogState, useForm, useNotify, useRoles } from 'hooks';
import { Fragment, useCallback } from 'react';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Stack,
    TextField,
} from '@mui/material';
import { useCreateBookingMutation, useGetDiscussionTypesQuery } from 'api';
import { Checkboxes } from 'form';

const schema = yup.object({
    discussionTypes: yup
        .array()
        .min(1, 'You must select at least one option')
        .required('You must select at least one option'),
});

function BookingDialog({ interval, onClose }) {
    const notify = useNotify();
    const { isAdmin } = useRoles();

    const [localInterval, DialogProps] = useDialogState(interval);

    const { handleSubmit, registerField, getError, control, reset } = useForm(
        isAdmin ? undefined : schema,
    );

    const discussionTypesQuery = useGetDiscussionTypesQuery();

    const [createBooking, createBookingMutation] = useCreateBookingMutation();

    useAutoNotify(createBookingMutation.error);

    const handleClose = useCallback(
        (e, reason) => {
            if (!!reason) return;
            reset();
            onClose();
        },
        [onClose, reset],
    );

    const onSubmit = async (form) => {
        try {
            await createBooking({
                ...form,
                startDate: localInterval?.start,
                endDate: localInterval?.end,
                isBusy: isAdmin,
            }).unwrap();

            handleClose();
            createBookingMutation.reset();
            notify(isAdmin ? 'Slot locked.' : 'Booking added.');
        } catch (error) {}
    };

    return (
        <Fragment>
            <Dialog onClose={handleClose} maxWidth="sm" fullWidth {...DialogProps}>
                {discussionTypesQuery.isLoading && <LinearProgress />}
                {discussionTypesQuery.isError && (
                    <DialogContent>
                        <DialogContentText>There has been an error</DialogContentText>
                    </DialogContent>
                )}
                {discussionTypesQuery.isSuccess && !!localInterval && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogTitle>{isAdmin ? 'Lock slot' : 'Book meeting'}</DialogTitle>
                        <DialogContent>
                            <Stack spacing={3}>
                                <DialogContentText>
                                    {isAdmin ? 'Lock this slot' : 'Schedule a meeting'} on{' '}
                                    <b>{format(localInterval?.start, 'EEEE, dd MMMM')}</b> from{' '}
                                    <b>{format(localInterval?.start, 'HH:mm')}</b> to{' '}
                                    <b>{format(localInterval?.end, 'HH:mm')}</b>
                                </DialogContentText>
                                {!isAdmin && (
                                    <Stack spacing={2}>
                                        <Checkboxes
                                            label="What would you like to discuss with your coach in this meeting? *"
                                            name="discussionTypes"
                                            control={control}
                                            getError={getError}
                                            options={discussionTypesQuery?.data}
                                        />
                                        <TextField
                                            {...registerField('notes')}
                                            label="Notes"
                                            fullWidth
                                            type="text"
                                            multiline
                                            minRows={2}
                                        />
                                    </Stack>
                                )}
                                {!isAdmin && (
                                    <DialogContentText component={Stack} spacing={2}>
                                        <div>
                                            You will spend <b>1 booking credit</b>.
                                        </div>
                                        <Box color="grey.600">
                                            You will not be able to cancel this booking within less
                                            than <b>24 hours</b> prior to your meeting.
                                        </Box>
                                    </DialogContentText>
                                )}
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="inherit">
                                Cancel
                            </Button>
                            <LoadingButton type="submit" loading={createBookingMutation?.isLoading}>
                                Submit
                            </LoadingButton>
                        </DialogActions>
                    </form>
                )}
            </Dialog>
        </Fragment>
    );
}

export default BookingDialog;
