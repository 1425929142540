import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, TextField, IconButton, InputAdornment, Collapse } from '@mui/material';

import { Autocomplete } from 'form';
import { useSubForm, useAutoNotify } from 'hooks';
import { useGetExercisesQuery } from 'api';
import { uiActions } from 'ducks';

function BlockFormExercise({ index, form, onDelete }) {
    const dispatch = useDispatch();
    const [showNotes, setShowNotes] = useState(false);

    const subForm = useSubForm('exercises', index, form);
    const { registerSubField, watchSubField, control, getErrorProps, getName } = subForm;

    const exerciseId = watchSubField('exerciseId');

    const { data, error, isLoading } = useGetExercisesQuery();
    useAutoNotify(error);

    return (
        <div>
            <Stack
                direction={{
                    xs: 'column',
                    lg: 'row',
                }}
                spacing={2}
                alignItems={{
                    xs: 'stretch',
                    lg: 'baseline',
                }}
            >
                <Autocomplete
                    control={control}
                    name={getName('exerciseId')}
                    options={data ?? []}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    disableClearable
                    label="Exercise *"
                    placeholder="Type to find exercise"
                    getErrorProps={getErrorProps}
                    defaultValue={null}
                    isOptionEqualToValue={(opt, v) => opt._id === v._id}
                    loading={isLoading}
                    sx={{
                        alignSelf: 'flex-start',
                        flexBasis: { lg: '40em', xl: '30em' },
                    }}
                />

                <TextField
                    {...registerSubField('repetitions')}
                    label="Reps"
                    sx={{ minWidth: '4em' }}
                />
                <TextField {...registerSubField('sets')} label="Sets" sx={{ minWidth: '3.5em' }} />
                <TextField
                    {...registerSubField('guideWeight')}
                    label="Guide Weight"
                    sx={{ minWidth: '8em' }}
                />
                <TextField
                    {...registerSubField('duration')}
                    label="Duration"
                    sx={{ minWidth: '5.5em' }}
                />
                <TextField
                    {...registerSubField('restTime')}
                    label="Rest"
                    sx={{ minWidth: '3.5em' }}
                />
                <TextField {...registerSubField('tempo')} label="Tempo" sx={{ minWidth: '5em' }} />
                <TextField
                    {...registerSubField('intensity')}
                    label="Intensity"
                    sx={{ minWidth: '7em' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />

                <Stack direction="row" spacing={2} alignItems="baseline">
                    <IconButton
                        sx={{ color: 'primary.dark' }}
                        disabled={!exerciseId?._id}
                        onClick={(e) => dispatch(uiActions.setCurrentExerciseId(exerciseId?._id))}
                    >
                        <i className="fas fa-eye fa-2xs fa-sq" />
                    </IconButton>

                    <IconButton
                        sx={{ color: 'primary.dark' }}
                        onClick={(e) => setShowNotes((v) => !v)}
                    >
                        <i className="far fa-sticky-note fa-2xs fa-sq" />
                    </IconButton>

                    <IconButton sx={{ color: 'error.dark' }} onClick={onDelete}>
                        <i className="fas fa-trash fa-2xs fa-sq" />
                    </IconButton>
                </Stack>
            </Stack>

            <Collapse in={showNotes} unmountOnExit>
                <TextField
                    {...registerSubField('notes')}
                    label="Exercise notes"
                    multiline
                    fullWidth
                    autoFocus
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ color: 'primary.dark' }}>
                                <i className="fas fa-sticky-note fa-mr-1" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        mt: 2,
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'grey.200',
                        },
                    }}
                />
            </Collapse>
        </div>
    );
}

export default BlockFormExercise;
