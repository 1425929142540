import { useCallback } from 'react';
import { useState } from 'react';

export const VIEWS = {
    WEEK: 'WEEK',
    MONTH: 'MONTH',
};

const useCalendarView = (defaultValue = VIEWS.WEEK) => {
    const [view, setView] = useState(defaultValue);

    const setWeek = useCallback(() => {
        setView(VIEWS.MONTH);
    }, []);
    const setMonth = useCallback(() => {
        setView(VIEWS.MONTH);
    }, []);

    return {
        view,
        VIEWS,

        isMonth: view === VIEWS.MONTH,
        isWeek: view === VIEWS.WEEK,

        setView,
        setWeek,
        setMonth,
    };
};

export default useCalendarView;
