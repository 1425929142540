import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Stack,
    Typography,
    Box,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import {
    useUpdateGroupNoteMutation,
    useDeleteGroupNoteMutation,
    useAddGroupNoteMutation,
} from 'api';
import { useAutoNotify, useConfirm, useForm } from 'hooks';
import { format } from 'date-fns';

const schema = yup.object({
    note: yup.string().required('This cannot be empty'),
});

const dataToForm = (data) => {
    if (!data) return { note: '' };
    const { note } = data;
    return { note };
};

const formToData = (data, extraFields = {}) => {
    return {
        ...data,
        ...extraFields,
    };
};

function GroupNotesForm({
    data,
    handleClose,
    exerciseData = {},
    block = {},
    selectedDate = new Date(),
    storeId,
}) {
    const { action } = useParams();
    const [confirm] = useConfirm();
    const edit = action === 'edit' || storeId !== 'add';

    const useMutation = edit ? useUpdateGroupNoteMutation : useAddGroupNoteMutation;
    const [submit, { error, isLoading, isSuccess }] = useMutation();
    useAutoNotify(error, isSuccess, `Note ${edit ? 'updated' : 'added'} successfully.`);

    const [deleteGroupNote, groupNoteDeletion] = useDeleteGroupNoteMutation();
    useAutoNotify(groupNoteDeletion.error, groupNoteDeletion.isSuccess, 'Note deleted.');

    const { _id: blockId } = data?.blockId || block;

    const form = useForm(schema, {
        defaultValues: dataToForm(data),
    });
    const { handleSubmit, registerField } = form;

    const onSubmit = (form) => {
        const extraFields = edit
            ? { groupNoteId: data?._id }
            : {
                  date: selectedDate,
                  blockId,
                  groupId: exerciseData?.groupId,
              };

        submit(formToData(form, extraFields));
    };

    const handleDeleteGroupNote = async () => {
        try {
            await confirm('Are you sure you want to delete this note?', 'Delete note');
            deleteGroupNote(data?._id);
        } catch (error) {}
    };

    useEffect(() => {
        if (isSuccess || groupNoteDeletion?.isSuccess) handleClose();
    }, [groupNoteDeletion?.isSuccess, handleClose, isSuccess]);

    return (
        <Fragment>
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <span>{edit ? 'Edit' : 'Add'} note for exercise group</span>
                <div>
                    <Typography
                        variant="overline"
                        lineHeight="1em"
                        display="block"
                        textAlign="right"
                        fontWeight="fontWeightBold"
                    >
                        {format(data?.date ? new Date(data?.date) : selectedDate, 'd MMM')}
                    </Typography>
                    <Typography
                        variant="overline"
                        lineHeight="1em"
                        display="block"
                        textAlign="right"
                        mt={1 / 4}
                    >
                        {exerciseData?.groupTitle}
                    </Typography>
                </div>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Stack spacing={3}>
                        <TextField
                            {...registerField('note')}
                            fullWidth
                            label="Note"
                            type="text"
                            multiline
                            minRows={3}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {edit && (
                        <Box flexGrow={1}>
                            <LoadingButton
                                color="error"
                                onClick={handleDeleteGroupNote}
                                loading={groupNoteDeletion.isLoading}
                            >
                                Delete note
                            </LoadingButton>
                        </Box>
                    )}
                    <Button color="inherit" onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} type="submit">
                        {edit ? 'Save' : 'Add'}
                    </LoadingButton>
                </DialogActions>
            </form>
        </Fragment>
    );
}

export default GroupNotesForm;
