import { addHours, format, formatDistanceToNowStrict } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import locale from 'date-fns/locale/en-GB';
import isFunction from 'lodash/isFunction';

//#region MEASURES

export function cmToImp(cm) {
    if (!cm) return [0, 0];

    const inch = Math.round(cm / 2.54);
    return [Math.floor(inch / 12), inch % 12];
}
export function impToCm(ft = 0, inch = 0) {
    return Math.round((ft * 12 + +inch) * 2.54);
}

export function kgToImp(kg) {
    if (!kg) return [0, 0];

    const lb = Math.round(kg * 2.2046);
    return [Math.floor(lb / 14), lb % 14];
}
export function impToKg(st = 0, lb = 0) {
    return Math.round((st * 14 + +lb) / 2.2046);
}

//#endregion

//#region TIME

export const getCurrentTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getTimezoneOffsetHours = (timeZone) => getTimezoneOffset(timeZone) / 3600 / 1000;
export const makeGMTString = (tzOffset = getTimezoneOffsetHours()) => {
    return `GMT${tzOffset >= 0 ? '+' : ''}${tzOffset}`;
};
export const getCurrentDatestring = () => new Date().toDateString();
export const makeDateWithTime = (time) => new Date(`${getCurrentDatestring()} ${time}`);
export const makeDateWithUTCTime = (time, formatPattern) => {
    const date = makeDateWithTime(time);
    const tzOffset = getTimezoneOffsetHours();
    const offsetDate = addHours(date, tzOffset);
    return formatPattern ? format(offsetDate, formatPattern) : offsetDate;
};
export const getTimeInTimezone = (date, timeZone = 'Europe/London', locale = 'en-GB') => {
    return Intl.DateTimeFormat(locale, {
        timeStyle: 'short',
        timeZone,
    }).format(date);
};

const formatDistanceLocale = {
    // lessThanXSeconds: '{{count}}s',
    // xSeconds: '{{count}}s',
    lessThanXSeconds: 'now',
    xSeconds: 'now',
    halfAMinute: '30s',
    lessThanXMinutes: '{{count}}m',
    xMinutes: '{{count}}m',
    aboutXHours: '{{count}}h',
    xHours: '{{count}}h',
    xDays: '{{count}}d',
    aboutXWeeks: '{{count}}w',
    xWeeks: '{{count}}w',
    aboutXMonths: '{{count}}mo',
    xMonths: '{{count}}mo',
    aboutXYears: '{{count}}y',
    xYears: '{{count}}y',
    overXYears: '{{count}}y',
    almostXYears: '{{count}}y',
};

function formatDistance(token, count, options) {
    options = options || {};

    const result = formatDistanceLocale[token].replace('{{count}}', count);

    if (options.addSuffix) {
        if (options.comparison > 0) {
            return 'in ' + result;
        } else {
            return result + ' ago';
        }
    }

    return result;
}

export function getAbbreviatedDistance(date, addSuffix) {
    if (!date) return '';
    return formatDistanceToNowStrict(new Date(date), {
        addSuffix,
        locale: {
            ...locale,
            formatDistance,
        },
    });
}

//#endregion

//#region OTHERS

export function getBackgroundColor(stringInput) {
    if (!stringInput) return undefined;

    let stringUniqueHash = [...stringInput].reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return `hsl(${stringUniqueHash % 360}, 65%, 55%)`;
}

export const rotateArr = (arr, offset) => {
    const nOffset = offset * -1;
    return arr.slice(nOffset).concat(arr.slice(0, nOffset));
};

export function stringToHslColor(str = '', s = 0, l = 0) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

export function getUserColor(username) {
    return stringToHslColor(username + 'qwertyuiopasdfghjkl', 60, 60);
}

export const getScrollInfo = (el) => {
    const st = el.scrollTop;
    const maxSt = el.scrollHeight - el.offsetHeight;
    return [st, maxSt];
};

export const track = ({ fb = [], ga = [], customFb = false, customGa = false } = {}) => {
    if (process.env.NODE_ENV === 'development') return;

    if (isFunction(window.fbq) && !!fb?.length) {
        window.fbq(customFb ? 'trackCustom' : 'track', ...fb);
    }
    if (isFunction(window.gtag) && !!ga?.length) {
        window.gtag('event', ...ga);
    }
};

export const getURLParams = () => new URLSearchParams(window.location.search);

export const replaceExtension = (filename, extension) => {
    return filename.replace(/\.[^/.]+$/, extension);
};

//#endregion
