import { Stack, Tooltip, Typography } from '@mui/material';

function LogParameter({
    label,
    value,
    tooltip = '',
    button,
    icon,
    reps,
    onClick,
    color,
    sx = {},
    lineHeight = '1em',
}) {
    return (
        <Tooltip title={tooltip} placement="top">
            <Stack
                direction="row"
                spacing={1 / 2}
                alignItems="baseline"
                sx={
                    tooltip
                        ? { borderBottom: '1px dotted grey', cursor: 'default', ...sx }
                        : { ...sx }
                }
                color="text.secondary"
            >
                {!!icon && <i className={`fas fa-${icon} fa-xs`} />}
                {!!label && (
                    <Typography
                        component="span"
                        variant="body2"
                        fontWeight="fontWeightBold"
                        fontSize="0.8em"
                        lineHeight={lineHeight}
                    >
                        {label}
                    </Typography>
                )}
                {!!value && (
                    <Typography
                        component="span"
                        variant="body2"
                        lineHeight={lineHeight}
                        fontWeight={onClick ? 'fontWeightBold' : undefined}
                        sx={reps ? { wordSpacing: (t) => t.spacing(1 / 2) } : undefined}
                    >
                        {value}
                    </Typography>
                )}
                {!!onClick && (
                    <Typography
                        component="span"
                        variant="body2"
                        lineHeight={lineHeight}
                        fontWeight="fontWeightBold"
                        onClick={onClick}
                        color={color}
                        sx={{ cursor: 'pointer' }}
                    >
                        {button}
                    </Typography>
                )}
            </Stack>
        </Tooltip>
    );
}

export default LogParameter;
