import { Stack, Typography, Switch } from '@mui/material';
import { Controller } from 'react-hook-form';

function CustomSwitch({
    name,
    control,
    leftLabel,
    rightLabel,
    onChange: externalOnChange = () => {},
    ...props
}) {
    return (
        <Stack direction="row" spacing={1} alignItems="center" {...props}>
            {leftLabel && <Typography>{leftLabel}</Typography>}
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Switch
                        onChange={(e) => {
                            field.onChange(e.target.checked);
                            externalOnChange(e);
                        }}
                        checked={field.value || false}
                    />
                )}
            />
            {rightLabel && <Typography>{rightLabel}</Typography>}
        </Stack>
    );
}

export default CustomSwitch;
