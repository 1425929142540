import React, { useEffect } from 'react';
import { AuthLayout, AgreementContent } from 'components/Auth';
import { Redirect } from 'react-router-dom';

import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useRegisterMutation } from 'api';
import * as yup from 'yup';
import { useAutoNotify, useForm } from 'hooks';
import { track } from 'helpers';

const schema = yup
    .object({
        name: yup.string().required(),
        email: yup.string().email().required(),
        password: yup.string().required().min(8),
        passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    })
    .required();

const SUBMIT_LABEL = 'Sign up';

function Register() {
    const { handleSubmit, registerField } = useForm(schema);
    const [register, { error, isLoading, isSuccess }] = useRegisterMutation();
    useAutoNotify(
        error,
        isSuccess,
        'Sign up successful. Click the link in your email to activate your account.',
    );

    const onSubmit = (data) => register(data);

    useEffect(() => {
        if (isSuccess) track({ fb: ['CompleteRegistration'], ga: ['sign_up'] });
    }, [isSuccess]);

    if (isSuccess) return <Redirect to="/activate-account" />;

    return (
        <AuthLayout agreementContent={<AgreementContent submitLabel={SUBMIT_LABEL} />}>
            <Box mt={6}>
                <Typography variant="h4" component="h1">
                    Sign up
                </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box mt={3}>
                    <TextField {...registerField('name')} fullWidth label="Name" type="text" />
                </Box>
                <Box mt={3}>
                    <TextField {...registerField('email')} fullWidth label="Email" type="email" />
                </Box>
                <Box mt={3}>
                    <TextField
                        {...registerField('password')}
                        fullWidth
                        label="Password"
                        type="password"
                    />
                </Box>
                <Box mt={3}>
                    <TextField
                        {...registerField('passwordConfirm')}
                        fullWidth
                        label="Confirm password"
                        type="password"
                    />
                </Box>
                <Box mt={4}>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        size="large"
                        fullWidth
                        type="submit"
                    >
                        {SUBMIT_LABEL}
                    </LoadingButton>
                </Box>
            </form>
        </AuthLayout>
    );
}

export default Register;
