import React, { Fragment } from 'react';
import { useRoles } from 'hooks';
import { File } from 'components/Resources';

import {
    DialogTitle,
    DialogContent,
    Stack,
    Chip,
    DialogActions,
    Button,
    DialogContentText,
} from '@mui/material';

function ResourceView({ data, handleClose, handleEdit = () => {} }) {
    const hasCategory = !!data?.category?.length;

    const { isAdmin } = useRoles();

    return (
        <Fragment>
            {data?.title && (
                <DialogTitle>
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        mr={-1}
                    >
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <span>{data?.title}</span>
                            {hasCategory && <Chip label={data?.category?.[0]} size="small" />}
                        </Stack>
                        {isAdmin && (
                            <Button
                                size="small"
                                variant="outlined"
                                color="warning"
                                onClick={handleEdit}
                            >
                                Edit
                            </Button>
                        )}
                    </Stack>
                </DialogTitle>
            )}
            <DialogContent>
                <File file={data?.file} maxWidth="none" mt={1} mb={2} />
                <DialogContentText>{data?.text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Fragment>
    );
}

export default ResourceView;
