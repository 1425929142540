import { useState } from 'react';
import { useController } from 'react-hook-form';

import {
    FormControlLabel,
    Switch,
    FormControl,
    FormLabel,
    FormGroup,
    FormHelperText,
    Stack,
    Box,
} from '@mui/material';

function CustomSwitches({
    control,
    getError,
    name,
    options,
    label,
    direction = 'column',
    spacing,
    verticalSpacing,
}) {
    const { field } = useController({ control, name });
    const [values, setValues] = useState(field.value || []);
    const error = getError(name);

    return (
        <FormControl component="fieldset" variant="standard" error={!!error}>
            {label && <FormLabel component="legend">{label}</FormLabel>}
            <FormGroup>
                <Stack
                    spacing={spacing || (direction === 'column' ? 2 : 1 / 2)}
                    direction={direction}
                    flexWrap="wrap"
                >
                    {options.map((option) => (
                        <Box key={JSON.stringify(option)} pb={verticalSpacing}>
                            <FormControlLabel
                                label={option?.label || option?.value || option}
                                style={{ marginLeft: 0 }}
                                control={
                                    <Switch
                                        onChange={(e) => {
                                            const valuesDraft = e.target.checked
                                                ? [...values, e.target.value]
                                                : values.filter((v) => v !== e.target.value);
                                            field.onChange(valuesDraft);
                                            setValues(valuesDraft);
                                        }}
                                        checked={values.includes(option?.value || option)}
                                        value={option?.value || option}
                                    />
                                }
                            />
                        </Box>
                    ))}
                </Stack>
            </FormGroup>
            {!!error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}

export default CustomSwitches;
