import { Controller } from 'react-hook-form';
import { FormControlLabel, Checkbox, Box } from '@mui/material';

function CustomCheckbox({ control, getError, name, label = '', labelProps = {}, ...props }) {
    // const error = getError(name);

    return (
        <Box {...props}>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <FormControlLabel
                        label={label}
                        control={
                            <Checkbox
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value || false}
                            />
                        }
                        {...labelProps}
                    />
                )}
            />
        </Box>
    );
}

export default CustomCheckbox;
