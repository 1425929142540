import React, { Fragment, useMemo } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useGetAWSCredentialsQuery, useGetMeQuery } from 'api';
import { PrivateRoute, Route } from 'routes';
import { useAccountCompletion, useRoles } from 'hooks';

import { Box } from '@mui/material';
import { AUTH_PATHS, PAGES_PATHS } from '@constants';
import { InitialAnimation } from 'common';
import { Login, Register, ActivateAccount, ForgotPassword, ResetPassword } from 'components/Auth';
import { Main } from 'components/Layout';
import { Exercises } from 'components/Exercises';
import { Dashboard } from 'components/Dashboard';
import { Clients } from 'components/Clients';
import { CurrentSchedule } from 'components/Schedule';
import { Program } from 'components/Program';
import { MyActivity } from 'components/LogHistory';

import { Profile, Questionnaire } from 'components/Questionnaire';
import { FollowUpsClient, CurrentFollowUp } from 'components/Reconsultation';
import { Subscriptions } from 'components/Subscriptions';
import { MyAccount } from 'components/MyAccount';
import { BookingAdmin, BookingUser } from 'components/Booking';
import { Chats, StickyChats } from 'components/Chat';
import { MyResources, Resources } from 'components/Resources';
import { TermsConditions } from 'pages';

const ROUTES_ADMIN = [
    {
        subheader: 'Manage',
    },
    {
        id: 'clients',
        title: 'Clients',
        mainMenu: true,
        icon: 'user-friends',
        Component: Clients,
        exact: false,
    },
    {
        id: 'booking',
        title: 'Booking',
        mainMenu: true,
        icon: 'calendar-check',
        Component: BookingAdmin,
        exact: false,
        fixedTitle: true,
    },
    {
        id: 'chats',
        title: 'Chats',
        mainMenu: true,
        icon: 'comments',
        Component: Chats,
        exact: false,
        fixedTitle: true,
        fixedLayout: true,
    },
    {
        subheader: 'Building blocks',
    },
    {
        id: 'exercises',
        title: 'Exercises',
        mainMenu: true,
        icon: 'dumbbell fa-sm',
        Component: Exercises,
        exact: false,
        fixedTitle: true,
    },
    {
        id: 'resources',
        title: 'Resources',
        mainMenu: true,
        icon: 'file',
        Component: Resources,
        exact: false,
        fixedTitle: true,
    },
    // {
    //     id: 'snippets',
    //     title: 'Snippets',
    //     mainMenu: true,
    //     disabled: true,
    //     icon: 'sticky-note',
    //     Component: () => 'snippets',
    // },
    {
        id: 'account',
        title: 'My Account',
        userMenu: true,
        icon: 'user',
        Component: MyAccount,
        exact: false,
        fixedTitle: true,
    },
];

export const getUserRoutes = ({ hasProfile, hasPurchased, hasProgram, isConfirmed } = {}) => [
    {
        subheader: 'Training',
    },
    {
        id: 'dashboard',
        title: 'Dashboard',
        icon: 'th',
        Component: Dashboard,

        mainMenu: true,
        disabled: !hasPurchased || !hasProfile,
        hideFromMenu: false,
    },
    {
        id: 'calendar',
        title: 'Calendar',
        icon: 'calendar-week',
        Component: CurrentSchedule,
        exact: false,
        fixedTitle: true,

        mainMenu: true,
        disabled: !hasPurchased || !hasProfile,
        hideFromMenu: false,
    },
    {
        id: 'program',
        title: 'My program',
        icon: 'columns',
        Component: Program,

        mainMenu: true,
        disabled: !hasPurchased || !hasProfile,
        hideFromMenu: false,
    },
    {
        id: 'activity',
        title: 'My activity',
        icon: 'clipboard',
        Component: MyActivity,

        mainMenu: true,
        disabled: !hasPurchased || !hasProfile,
        hideFromMenu: false,
    },
    {
        subheader: 'Help',
    },
    {
        id: 'resources',
        title: 'Resources',
        mainMenu: true,
        icon: 'file',
        Component: MyResources,
        exact: false,
        fixedTitle: true,
        disabled: !hasPurchased || !hasProfile,
        hideFromMenu: false,
    },
    {
        id: 'booking',
        title: 'Booking',
        icon: 'calendar-check',
        Component: BookingUser,

        mainMenu: true,
        disabled: !hasPurchased || !hasProfile,
        hideFromMenu: false,
    },
    {
        id: 'chats',
        title: 'Chat',
        icon: 'comments',
        Component: Chats,

        mainMenu: true,
        disabled: !hasPurchased || !hasProfile,
        exact: false,
        fixedTitle: true,
        fixedLayout: true,
    },
    {
        subheader: 'Subscription',
    },
    {
        id: 'plans',
        title: 'Plans',
        icon: 'money-check-alt',
        Component: Subscriptions,

        mainMenu: true,
        fixedTitle: true,
        disabled: !isConfirmed,
        hideFromMenu: false,
        exact: false,
    },
    {
        id: 'profile',
        title: 'Profile',
        icon: 'user',
        Component: Profile,
        exact: true,
        fixedTitle: true,

        userMenu: hasProfile,
        disabled: !hasProfile,
    },
    {
        id: 'profile/edit',
        title: 'Profile',
        icon: 'user',
        Component: Questionnaire,
        exact: true,
        fixedTitle: true,

        userMenu: !hasProfile,
        disabled: hasProgram,
    },
    {
        id: 'follow-ups',
        title: 'Follow-ups',
        icon: 'user',
        Component: FollowUpsClient,
        exact: true,
        fixedTitle: true,

        userMenu: hasProfile,
        disabled: !hasProfile,
    },
    {
        id: 'account',
        title: 'My Account',
        icon: 'user',
        Component: MyAccount,
        exact: false,
        fixedTitle: true,

        userMenu: true,
        disabled: false,
    },
];

export const KEEP_PARENT_TITLE = [];

export const getFixedTitles = (isAdmin) =>
    (isAdmin ? ROUTES_ADMIN : getUserRoutes()).filter((v) => v.fixedTitle).map((v) => '/' + v.id);

function Routes() {
    const { isLoading } = useGetMeQuery();
    const { isAdmin, me } = useRoles();
    useGetAWSCredentialsQuery(undefined, { skip: !me });

    const accountCompletion = useAccountCompletion();

    const routes = useMemo(
        () => (isAdmin ? ROUTES_ADMIN : getUserRoutes(accountCompletion)),
        [accountCompletion, isAdmin],
    );

    if (isLoading) return <InitialAnimation />;

    const enableChats = !routes.find((v) => v?.id === 'chats')?.disabled;

    return (
        <Fragment>
            <Switch>
                <Route path={AUTH_PATHS.LOGIN} publicOnly>
                    <Login />
                </Route>
                <Route path={AUTH_PATHS.REGISTER} publicOnly>
                    <Register />
                </Route>
                <Route path={AUTH_PATHS.ACTIVATE_ACCOUNT} publicOnly>
                    <ActivateAccount />
                </Route>
                <Route path={AUTH_PATHS.FORGOT_PASSWORD} publicOnly>
                    <ForgotPassword />
                </Route>
                <Route path={AUTH_PATHS.RESET_PASSWORD} publicOnly>
                    <ResetPassword />
                </Route>
                <Route path={PAGES_PATHS.TERMS_CONDITIONS}>
                    <TermsConditions />
                </Route>
                <Main routes={routes}>
                    <PrivateRoute path="/">{enableChats && <StickyChats />}</PrivateRoute>
                    {!isAdmin && (
                        <PrivateRoute path="/">
                            {!routes.find((v) => v?.id === 'follow-ups')?.disabled && (
                                <CurrentFollowUp />
                            )}
                        </PrivateRoute>
                    )}
                    <Switch>
                        {routes.map(
                            ({ id, fixedLayout, Component, subheader, exact = true, disabled }) =>
                                !subheader && !disabled ? (
                                    <PrivateRoute key={id} path={'/' + id} exact={exact}>
                                        <Box
                                            p={{
                                                xs: 2,
                                                sm: 3.25,
                                            }}
                                            px={{
                                                xs: 1.5,
                                                sm: 2,
                                            }}
                                            flexGrow={1}
                                            height={fixedLayout ? 550 : undefined}
                                        >
                                            <Component />
                                        </Box>
                                    </PrivateRoute>
                                ) : null,
                        )}
                        {me && !accountCompletion?.hasProfile && !isAdmin && (
                            <Redirect to="profile/edit" />
                        )}
                        {(isAdmin || (me && accountCompletion?.hasProfile)) && (
                            <Redirect to={'/' + routes?.find((v) => v.id && !v.disabled)?.id} />
                        )}
                    </Switch>
                </Main>
            </Switch>
        </Fragment>
    );
}

export default Routes;
