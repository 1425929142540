import React, { Fragment, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Stack,
    FormLabel,
    Typography,
    Box,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useUpdateLogMutation, useAddLogMutation, useDeleteLogMutation } from 'api';
import { useAutoNotify, useConfirm, useForm } from 'hooks';
import { useFieldArray } from 'react-hook-form';
import { LogFormField } from 'components/Activity';
import { format } from 'date-fns';

const schema = yup.object({
    sets: yup.array().of(
        yup.object({
            reps: yup.string().required('This cannot be empty'),
        }),
    ),
});

const DEFAULT_SET_FIELDS = {
    reps: '',
    usedWeight: '',
    intensity: '',
    rpe: '',
};

const dataToForm = (data, { setsCount }) => {
    if (!data) {
        return {
            sets: Array.from({ length: parseInt(setsCount) || 1 }, () => DEFAULT_SET_FIELDS),
            notes: '',
        };
    }

    const { notes, sets } = data;
    return { sets, notes };
};

const formToData = (data, extraFields = {}) => {
    return {
        ...data,
        ...extraFields,
    };
};

function LogForm({
    data,
    handleClose,
    exerciseData = {},
    block = {},
    selectedDate = new Date(),
    storeId,
}) {
    const { action } = useParams();
    const [confirm] = useConfirm();
    const edit = action === 'edit' || storeId !== 'add';

    const useMutation = edit ? useUpdateLogMutation : useAddLogMutation;
    const [submit, { error, isLoading, isSuccess }] = useMutation();
    useAutoNotify(error, isSuccess, `Log ${edit ? 'updated' : 'added'} successfully.`);

    const [deleteLog, logDeletion] = useDeleteLogMutation();
    useAutoNotify(logDeletion.error, logDeletion.isSuccess, 'Log deleted.');

    const { hasIntensity, hasRPE, _id: blockId } = data?.blockId || block;

    const form = useForm(schema, {
        defaultValues: dataToForm(data, { setsCount: exerciseData?.sets }),
    });
    const { handleSubmit, registerField, control } = form;
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'sets',
        keyName: 'fieldId',
    });

    const onSubmit = (form) => {
        const extraFields = edit
            ? { logId: data?._id }
            : {
                  date: selectedDate,
                  blockId,
                  groupId: exerciseData?.groupId,
                  exerciseInstanceId: exerciseData?._id,
                  exerciseId: exerciseData?.exerciseId?._id,
              };

        submit(formToData(form, extraFields));
    };

    const handleDeleteLog = async () => {
        try {
            await confirm('Are you sure you want to delete this log?', 'Delete Log');
            deleteLog(data?._id);
        } catch (error) {}
    };

    useEffect(() => {
        if (isSuccess || logDeletion?.isSuccess) handleClose();
    }, [logDeletion?.isSuccess, handleClose, isSuccess]);

    const handleAdd = useCallback((e) => append(DEFAULT_SET_FIELDS), [append]);
    const handleRemove = useCallback((i) => (e) => remove(i), [remove]);

    return (
        <Fragment>
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <span>{edit ? 'Edit' : 'Add'} log</span>
                <div>
                    <Typography
                        variant="overline"
                        lineHeight="1em"
                        display="block"
                        textAlign="right"
                        fontWeight="fontWeightBold"
                    >
                        {format(data?.date ? new Date(data?.date) : selectedDate, 'd MMM')}
                    </Typography>
                    <Typography
                        variant="overline"
                        lineHeight="1em"
                        display="block"
                        textAlign="right"
                        mt={1 / 4}
                    >
                        {exerciseData?.exerciseId?.name}
                    </Typography>
                </div>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Stack spacing={3}>
                        <div>
                            <FormLabel component="legend" sx={{ mb: 2 }}>
                                Sets {exerciseData?.sets && `(${exerciseData?.sets})`}
                            </FormLabel>
                            <Stack spacing={3}>
                                {fields.map((v, i) => (
                                    <LogFormField
                                        key={v.fieldId}
                                        index={i}
                                        form={form}
                                        onDelete={handleRemove(i)}
                                        disabled={fields.length === 1}
                                        hasIntensity={hasIntensity}
                                        hasRPE={hasRPE}
                                        recommendedReps={exerciseData?.repetitions}
                                        recommendedIntensity={exerciseData?.intensity}
                                        recommendedWeight={exerciseData?.guideWeight}
                                    />
                                ))}
                            </Stack>
                        </div>
                        <div>
                            <Button variant="contained" size="small" onClick={handleAdd}>
                                Add set
                            </Button>
                        </div>
                        <TextField
                            {...registerField('notes')}
                            fullWidth
                            label="Notes"
                            type="text"
                            multiline
                            minRows={2}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {edit && (
                        <Box flexGrow={1}>
                            <LoadingButton
                                color="error"
                                onClick={handleDeleteLog}
                                loading={logDeletion.isLoading}
                            >
                                Delete log
                            </LoadingButton>
                        </Box>
                    )}
                    <Button color="inherit" onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} type="submit">
                        {edit ? 'Save' : 'Add'}
                    </LoadingButton>
                </DialogActions>
            </form>
        </Fragment>
    );
}

export default LogForm;
