import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

function useNotify() {
    const { enqueueSnackbar } = useSnackbar();

    const notify = useCallback(
        (message, variant = 'success') => {
            enqueueSnackbar(message, { variant });
        },
        [enqueueSnackbar],
    );

    return notify;
}

export default useNotify;
