import { Fragment, useRef } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { ACCEPTED_FILES, MAX_FILES } from 'components/Chat/constants';

function FileButton({ disabled, selectedFiles, handleFileChange, editing }) {
    const fileRef = useRef();

    const handleAddFileClick = (e) => {
        fileRef.current.click();
    };

    const maxFiles = selectedFiles?.length >= MAX_FILES;

    return (
        <Fragment>
            <input
                accept={ACCEPTED_FILES.join(',')}
                type="file"
                ref={fileRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple
            />
            <Tooltip
                title={
                    editing
                        ? 'You cannot attach files when editing a message.'
                        : maxFiles
                        ? `You can attach maximum ${MAX_FILES} files`
                        : `Attach ${!!selectedFiles?.length ? 'more' : ''} files`
                }
            >
                <div>
                    <IconButton disabled={disabled || maxFiles} onClick={handleAddFileClick}>
                        <i className="fas fa-plus-circle fa-2xs fa-sq" />
                    </IconButton>
                </div>
            </Tooltip>
        </Fragment>
    );
}

export default FileButton;
