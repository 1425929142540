import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentExerciseId: false,
    currentLogData: false,
    currentGroupNoteData: false,

    videoViewerId: false,
    imageViewerId: false,
};

const slice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setCurrentExerciseId: (state, { payload }) => {
            state.currentExerciseId = payload;
        },
        clearCurrentExerciseId: (state, { payload }) => {
            state.currentExerciseId = false;
        },

        setCurrentLogData: (state, { payload: [id, exerciseData] }) => {
            state.currentLogData = [id, exerciseData];
        },
        clearCurrentLogData: (state, { payload }) => {
            state.currentLogData = false;
        },

        setCurrentGroupNoteData: (state, { payload: [id, exerciseData] }) => {
            state.currentGroupNoteData = [id, exerciseData];
        },
        clearCurrentGroupNoteData: (state, { payload }) => {
            state.currentGroupNoteData = false;
        },

        setVideoViewerId: (state, { payload }) => {
            state.videoViewerId = payload;
        },
        clearVideoViewerId: (state, { payload }) => {
            state.videoViewerId = false;
        },

        setImageViewerId: (state, { payload }) => {
            state.imageViewerId = payload;
        },
        clearImageViewerId: (state, { payload }) => {
            state.imageViewerId = false;
        },
    },
});

function getActions() {
    const { setCurrentExerciseId, clearCurrentExerciseId } = slice.actions;
    const { setCurrentLogData, clearCurrentLogData } = slice.actions;
    const { setCurrentGroupNoteData, clearCurrentGroupNoteData } = slice.actions;
    const { setVideoViewerId, clearVideoViewerId } = slice.actions;
    const { setImageViewerId, clearImageViewerId } = slice.actions;

    return {
        setCurrentExerciseId,
        clearCurrentExerciseId,

        setCurrentLogData,
        clearCurrentLogData,

        setCurrentGroupNoteData,
        clearCurrentGroupNoteData,

        setVideoViewerId,
        clearVideoViewerId,

        setImageViewerId,
        clearImageViewerId,
    };
}

export const actions = getActions();

// SELECTORS

const getCurrentExerciseId = (state) => state.ui.currentExerciseId;
const getCurrentLogData = (state) => state.ui.currentLogData || [];
const getCurrentGroupNoteData = (state) => state.ui.currentGroupNoteData || [];
const getVideoViewerId = (state) => state.ui.videoViewerId || false;
const getImageViewerId = (state) => state.ui.imageViewerId || false;

export const selectors = {
    getCurrentExerciseId,
    getCurrentLogData,
    getCurrentGroupNoteData,
    getVideoViewerId,
    getImageViewerId,
};

export default slice.reducer;
