import { useMemo, useCallback } from 'react';

const useSubForm = (currentName, index, form) => {
    const parentName = form?.parentName ?? '';
    const prefixName = useMemo(
        () => `${parentName}${currentName}.${index}.`,
        [currentName, index, parentName],
    );
    const getName = useCallback((name) => `${prefixName}${name}`, [prefixName]);

    return useMemo(
        () => ({
            ...form,
            registerSubField: (name, ...args) => form.registerField(getName(name), ...args),
            watchSubField: (name, ...args) => form.watch(getName(name), ...args),
            getSubError: (name, ...args) => form.getError(getName(name), ...args),
            triggerSubField: (name, ...args) => form.trigger(getName(name), ...args),
            parentName: prefixName,
            getName,
        }),
        [form, getName, prefixName],
    );
};

export default useSubForm;
