import { LinearProgress, List, Typography } from '@mui/material';
import { useGetUserResurveysQuery } from 'api';
import { SurveyEntry, SurveyDialog } from 'components/Reconsultation';
import { useAutoNotify, useRoles } from 'hooks';
import { Fragment, useCallback, useState } from 'react';

function FollowUps({ userId }) {
    const { isAdmin } = useRoles();
    const { data, error, isLoading } = useGetUserResurveysQuery(userId, { skip: !userId });
    useAutoNotify(error);

    const [currentSurveyId, setCurrentSurveyId] = useState(false);
    const [edit, setEdit] = useState(false);

    const handleClickSurvey = useCallback(
        (id, edit = false) =>
            () => {
                setEdit(edit);
                setCurrentSurveyId(id);
            },
        [],
    );

    const handleEditSuccess = useCallback(() => {
        setEdit(false);
    }, []);

    const handleCloseSurvey = useCallback(
        (e, reason) => {
            if (!!reason && edit) return;

            setCurrentSurveyId(false);
        },
        [edit],
    );

    return (
        <Fragment>
            <SurveyDialog
                id={currentSurveyId}
                onClose={handleCloseSurvey}
                edit={edit}
                onSuccess={handleEditSuccess}
                onEdit={(e) => setEdit(true)}
            />

            {isLoading ? (
                <LinearProgress />
            ) : !data?.length ? (
                <Typography>No entries</Typography>
            ) : (
                <List>
                    {data?.map((v) => (
                        <SurveyEntry
                            key={v?._id}
                            data={v}
                            onClick={handleClickSurvey(v?._id, !v?.completed && !isAdmin)}
                            onEdit={handleClickSurvey(v?._id, true)}
                        />
                    ))}
                </List>
            )}
        </Fragment>
    );
}

export default FollowUps;
