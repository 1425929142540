import { Typography } from '@mui/material';
import { Page } from 'pages';
import { Link } from 'routes';

function TermsConditions() {
    return (
        <Page title="Terms and Conditions, Privacy & Cookies Policy">
            <div>
                <Typography paragraph variant="h5">
                    <b>Terms of Service</b>
                </Typography>
                <Typography paragraph>
                    <b>Effective Date: 0</b>
                    <b>1 July 2022</b>
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Scope of Terms</b>
                </Typography>
                <Typography paragraph>
                    These <b>Terms</b> outline the rules that govern your access and use of the{' '}
                    <b>Site</b> and our <b>Programs</b>. These Terms form an agreement between you,
                    the <b>Client</b> and <b>The Anabolist</b> with respect to the Site and our
                    Programs.
                </Typography>
                <Typography paragraph>
                    Please, read these Terms and other existing agreements and policies on the Site
                    and agree to them before using the Site and our Programs. By accessing this
                    Site, we assume you accept these Terms. Your agreement will be confirmed when
                    you tick the ‘I agree’ or ‘I accept’ box. Do not continue to use the Site and
                    our Programs if you do not agree to all these Terms.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Definitions and interpretations</b>
                </Typography>
                <Typography paragraph>The following terminology applies to these Terms:</Typography>
                <ul>
                    <li>
                        ‘<b>Client</b>’, ‘<b>user</b>’, ‘<b>you</b>’ and “<b>your</b>” refers to
                        you, the individual who accesses the Site and/or participates in our
                        Programs.
                    </li>
                    <li>
                        ‘<b>Programs</b>’ refers to the personalised fitness programs, resources,
                        information, videos, advice and services we provide through the Site.
                    </li>
                    <li>
                        ‘<b>Site</b>’ refers to{' '}
                        <Link target="_blank" href="https://theanabolist.com">
                            https://theanabolist.com
                        </Link>{' '}
                        and the associated web app.
                    </li>
                    <li>
                        ‘<b>Terms</b>’ refer to this Terms of Service agreement that contains the
                        rules and conditions of your access and use of the Site and our Programs.
                    </li>
                    <li>
                        ‘<b>The Anabolist’</b>, ‘<b>our</b>’, ‘<b>we</b>’ and ‘<b>us</b>’ refer to
                        The Anabolist LTD, a limited company operating from the United Kingdom (UK).
                    </li>
                    <li>
                        Any use of the above terminologies or other words in the singular, plural,
                        capitalisation and/or he/she or they, are taken as interchangeable and
                        therefore as referring to the same.
                    </li>
                </ul>

                <Typography paragraph variant="h6">
                    <b>Access eligibility</b>
                </Typography>
                <Typography paragraph>
                    To be eligible to participate in our Programs, you must be at least 18 years of
                    age; be able to enter a legal agreement, such as these Terms and your access and
                    use of the Site and our Programs must not violate any applicable law. By using
                    our Programs, you represent that you meet the foregoing eligibility
                    requirements.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Account creation</b>
                </Typography>
                <Typography paragraph>
                    In order to assess and provide you with a personalised Program, we require you
                    to create a user account and submit certain personal data to us. By creating an
                    account, you warrant that the information you provide is accurate and complete
                    and that you will maintain its accuracy and completeness. You understand that
                    the data you provide is needed for us to accurately provide a Program that fits
                    your current status. We will not be liable if you furnish us with inaccurate
                    data.
                </Typography>
                <Typography paragraph>
                    You are responsible for keeping your account login data confidential. You
                    acknowledge that only you are permitted to access your account and use the
                    Program under it. You may not assign, transfer, license or rent out your account
                    to any third party without our written permission. You also promise to
                    immediately notify us if you perceive any unauthorised access to your account.
                </Typography>
                <Typography paragraph>
                    We reserve the right to refuse your request to create an account or terminate
                    your account if we have a justification that your use of the Site or our
                    Programs violate any applicable law or these Terms.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Programs do not replace professional care</b>
                </Typography>
                <Typography paragraph>
                    You understand that we do not represent our Programs as substitutes for
                    personal, direct, professional medical advice, diagnosis or care. You must not
                    use any information contained on the Site and our Programs to diagnose a medical
                    condition, or otherwise determine a course of action without seeking
                    professional help. The relationship between you and The Anabolist shall not be
                    construed as a patient-doctor or similar privileged relationship due to your use
                    of our Programs. You are advised to seek professional advice with respect to
                    your health. You also represent that you do not have any medical condition that
                    may pose any danger to your health when participating in our Programs. The
                    Anabolist will not be responsible for any damages, including injuries, you may
                    suffer from your use of our Programs.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Privacy Policy</b>
                </Typography>
                <Typography paragraph>
                    In order to accurately provide personalised Programs that fit your current
                    fitness needs, we require you to submit personal data, which we use to assess
                    you. Our collection and use of this personal data, including our other data
                    protection practices, are described in our Privacy Policy. Please, review and
                    consent to our Privacy Policy before you continue using our Programs.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Our Intellectual Property rights</b>
                </Typography>
                <Typography paragraph>
                    The content available on the Site and our Programs, including our trade name
                    (The Anabolist), Site information, texts, videos, images, audios, pricing,
                    button icons, graphics, logos and materials, are our content and trademarks.
                    They are protected by copyright, trademark, database rights and other
                    intellectual property rights in the UK and other locations.
                </Typography>
                <Typography paragraph>
                    You may not extract or use any of our content for any purpose not allowed under
                    these Terms without our written permission. Particularly, you may not use any
                    robots, data miners or similar data gathering or extraction tools to obtain or
                    extract any portion of our content without written permission from us.
                </Typography>
                <Typography paragraph>
                    In addition, the trade name (The Anabolist), logos, trade dress, graphics,
                    button icons, page headers and designs included on the Site or our Programs
                    belong to us. They are registered trademarks across the UK. You may not use our
                    trademarks or trade dress in connection with any product or service in any
                    manner that may cause confusion to our Clients. All other trademarks appearing
                    on the Site that do not belong to us are the intellectual property of their
                    respective owners (who may or may not be affiliated with us).
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Grant of license</b>
                </Typography>
                <Typography paragraph>
                    Subject to your compliance with these Terms, The Anabolist grants you a limited,
                    non-transferable, non-exclusive, non-sublicensable, revocable right and license
                    to use the Site, our content and Programs for your personal, non-commercial use.
                    This right and license do not include the resale or commercial use of any of our
                    content, or the copying of our pricing or description.
                </Typography>
                <Typography paragraph>
                    We reserve the right we do not expressly grant you under these Terms. You may
                    not reproduce, copy, duplicate, sell, resell or otherwise exploit any portion
                    for commercial purposes without our written permission. The license granted
                    herein shall terminate upon your breach of these Terms.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Electronic communications</b>
                </Typography>
                <Typography paragraph>
                    When you use our Programs, including sending us emails, you are communicating
                    with us electronically. We will also communicate with you in various ways,
                    including via email, our agreements or your dashboard. You hereby agree that for
                    contractual purposes, all our agreements, disclosures, notices and other notices
                    we provide via electronic means satisfy any legal requirements.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Subscription and fees</b>
                </Typography>

                <Typography paragraph>
                    <b>Fees</b>
                </Typography>
                <Typography paragraph>
                    <span>
                        To participate in our Programs, you must subscribe to and pay for any of the
                        plans (available in regular subscription and one-time check-in) on the
                        ‘Plans’ section of your account profile. The fee for any plan is due in
                        advance before the Program is provided. Unless stated otherwise, our fees
                        include the applicable taxes. However, our fees do not include any payment
                        method provider charges. We reserve the right to revise our fees and change
                        them from time to time.
                    </span>
                </Typography>
                <Typography paragraph>
                    <b>Payment method</b>
                </Typography>
                <Typography paragraph>
                    You are required to provide a payment method, such as your credit/card and GPay,
                    to enable us to process your payments for any plan you choose. Your payment
                    method data is strictly collected to enable{' '}
                    <Link target="_blank" href="https://stripe.com/legal">
                        Stripe Inc
                    </Link>{' '}
                    , our third-party payment processor, to process your payments on our behalf. By
                    providing your payment method data on the payment page, you authorise us to
                    charge you the amount stated on the plan you choose. You warrant that the
                    payment method you provide is yours or that you have the authority to permit us
                    to charge it.
                </Typography>
                <Typography paragraph>
                    <b>Recurrent subscription</b>
                </Typography>
                <Typography paragraph>
                    You agree that some of the plans are designed to auto-renew after their cycle.
                    This means that unless you cancel such plans before their end date, we will
                    automatically charge your payment method for the next cycle. To prevent
                    recurrent subscription charges, you must cancel your subscription plan before
                    the end date of the current period (please, note that cancellation will not take
                    effect till the end of the current cycle).
                </Typography>
                <Typography paragraph>
                    <b>Refunds policy</b>
                </Typography>
                <Typography paragraph>
                    We offer full or partial refunds on a case-by-case basis if you request to
                    cancel our Programs before we commence them (for check-ins, you must provide us
                    with a 24-hour notice before we provide the Program). For the avoidance of
                    doubt, there will be no refund after our Programs have commenced or are fully
                    provided.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Termination and survival</b>
                </Typography>
                <Typography paragraph>
                    We reserve the right to suspend, limit or terminate your account and use of our
                    Programs without liability if your payment obligation is not met at the
                    appropriate time, or if we have justifiable reason to believe your use violates
                    these Terms or any applicable law. If we terminate your use, your account and
                    the current Program provided through it will be immediately deleted from our
                    database.
                </Typography>
                <Typography paragraph>
                    Upon terminating your use of the Site and Programs, these Terms automatically
                    terminate. However, certain clauses, paragraphs and provisions are expected to
                    remain in force even after termination. Therefore, the clauses, paragraphs and
                    provisions that by their nature are expected to survive the termination of these
                    Terms shall remain binding.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Program disclaimer</b>
                </Typography>
                <Typography paragraph>
                    The Anabolist promises to provide its Programs in professionally and acceptably
                    manner. We will provide a personalised fitness Program that fit the information
                    you provide us; so, endeavour to provide accurate information. Nevertheless, you
                    acknowledge that our Programs may not go according to expectations due to
                    certain factors even when we have accurate information.
                </Typography>
                <Typography paragraph>
                    THEREFORE, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU ACKNOWLEDGE
                    AND AGREE THAT YOUR ACCESS TO THE SITE AND USE OF OUR PROGRAMS ARE AT YOUR SOLE
                    RISK. YOU AGREE THAT THE ANABOLIST PROVIDES THE SITE AND PROGRAMS ON AN ‘AS IS’
                    AND ‘AS AVAILABLE’ BASIS WITHOUT WARRANTIES OF ANY KIND – WHETHER EXPRESS OR
                    IMPLIED. WE MAKE NO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, TITLE AND NON-INFRINGEMENT.
                </Typography>
                <Typography paragraph>
                    WE DO NOT WARRANT THAT (I) THE ADVICE, INFORMATION AND RESOURCES CONTAINED IN
                    OUR PROGRAMS ARE SUITABLE FOR YOUR FITNESS GOALS OR THAT IT IS SAFE, RELIABLE OR
                    PERFECT FOR YOU; (II) OUR PROGRAMS ARE SUBSTITUTE FOR PROFESSIONAL MEDICAL
                    DIAGNOSIS OR CARE OR THAT YOU CAN RELY ON THEM; (III) THE SITE AND OUR PROGRAMS
                    DO NOT CONTAIN ERRORS OR OMISSIONS OR THAT SUCH ERRORS OR OMISSIONS WILL BE
                    CORRECTED IN DUE TIME; (IV) THE DATA YOU SUBMIT TO US IS 100% SAFE FROM
                    UNAUTHORISED ACCESS OR USE; OR (V) THE SITE IS FREE FROM VIRUSES, MALWARE,
                    WORMS, TROJAN HORSES OR OTHER HARMFUL SOFTWARE THAT ARE INTRODUCED BY THIRD
                    PARTIES.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Limitation of liability</b>
                </Typography>
                <Typography paragraph>
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL
                    THE ANABOLIST (ITS AGENTS, LICENSORS, AFFILIATES, EMPLOYEES OR DIRECTORS) BE
                    LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
                    SPECIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION, DAMAGES OF LOSS OF
                    PROFIT, REVENUE, DATA, GOODWILL, PROPERTY OR OTHER INTANGIBLE DAMAGES ARISING
                    FROM YOUR ACCESS AND USE OF THE SITE AND OUR PROGRAMS (EVEN IF WE HAVE HAD A
                    HINT OF THE POSSIBILITY).
                </Typography>
                <Typography paragraph>
                    NOTWITHSTANDING THE LIMITATIONS ABOVE, IF THE ANABOLIST IS FOUND LIABLE FOR ANY
                    CAUSE WHATSOEVER BY A COMPETENT COURT, OUR LIABILITY SHALL NOT EXCEED THE FEES
                    YOU HAVE PAID TO US IN THE PAST THREE (3) MONTHS OR A SUM OF £400 – WHICHEVER IS
                    GREATER.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Indemnity</b>
                </Typography>
                <Typography paragraph>
                    You hereby agree to indemnify, defend and hold The Anabolist (including our
                    affiliates, employees, agents, licensors and directors) harmless from and
                    against any losses, damages, claims, demands or expenses made by a third party
                    due to your use of the Site and Programs or any injuries sustained in connection
                    to them, or your violation of these Terms or any applicable law.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Governing law and jurisdiction</b>
                </Typography>
                <Typography paragraph>
                    These Terms shall be governed by and interpreted in accordance with the laws of
                    England and Wales. All disputes arising out of the Site and our Programs shall
                    be settled in the courts located in England and Wales.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Third-Party Links</b>
                </Typography>
                <Typography paragraph>
                    The Site may contain content, adverts, materials or resources that lead to other
                    sites, apps and platforms (<b>‘Third-Party Links’</b>). Any Third-Party Links
                    available on the Site are for reference only and do not represent our
                    endorsement of their safety, reliability or security. These Terms do not govern
                    the affairs of any Third-Party Links. We implore you to review the agreements
                    associated with any Third-Party Links before attempting to use their services,
                    products or programs. The Anabolist will not be liable for any damages arising
                    from your use of any Third-Party Links.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Amendments to Terms</b>
                </Typography>
                <Typography paragraph>
                    These Terms may be reviewed and amended from time to time as new features are
                    added to our Site or Programs, or there are changes to applicable laws. In the
                    event we amend any portion of these Terms, the Effective Date at the top will be
                    changed to reflect the date the amendments were made. We reserve the right to
                    communicate any major amendments via your email address or any portion of the
                    Site. Nevertheless, you should also review these Terms frequently to be informed
                    of new changes. By your continued use of our Programs or access of the Site
                    after any amendments to these Terms, you represent that you consent to the
                    changes made.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>General</b>
                </Typography>

                <Typography paragraph>
                    <b>Entire agreement</b>
                </Typography>
                <Typography paragraph>
                    These Terms (together with other agreements and policies found on the Site)
                    represent the entire agreement between you and The Anabolist concerning your use
                    of the Site and our Programs. These Terms supersede all previous understanding,
                    covenants and agreements that may have existed between you and The Anabolist
                    prior to these Terms.
                </Typography>
                <Typography paragraph>
                    <b>Waiver</b>
                </Typography>
                <Typography paragraph>
                    If we fail or delay to act on any violation of these Terms at a particular time,
                    it shall not waive or take away our rights to act at another time on the same
                    violation or a similar one. Waivers under these Terms are only valid when put in
                    writing.
                </Typography>
                <Typography paragraph>
                    <b>Assignment</b>
                </Typography>
                <Typography paragraph>
                    You may not assign your rights and obligations under these Terms to a third
                    party without our written approval. The Anabolist, on the other hand, reserves
                    the right to assign its rights to third parties under these Terms.
                </Typography>
                <Typography paragraph>
                    <b>Severability</b>
                </Typography>
                <Typography paragraph>
                    In the event that any portion or provision of these Terms is determined by
                    applicable law to be invalid or unenforceable, the said portion or provision
                    shall be severable from these Terms, and it shall not affect the validity or
                    enforceability of the remaining portions or provisions of these Terms.
                </Typography>
                <Typography paragraph>
                    <b>Force majeure</b>
                </Typography>
                <Typography paragraph>
                    We shall not be liable for any delay or failure to provide our Programs if such
                    a delay or failure is caused by circumstances beyond our reasonable control.
                    These circumstances may include, without limitation, pandemics, government
                    actions, earthquakes and other acts of God.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Contact us</b>
                </Typography>
                <Typography paragraph>
                    If you have any questions concerning these Terms or our Programs, please contact
                    us at{' '}
                    <Link target="_blank" href="mailto:contact@theanabolist.com">
                        contact@theanabolist.com
                    </Link>{' '}
                    or use the{' '}
                    <Link target="_blank" href="https://theanabolist.com/contact/">
                        contact us
                    </Link>{' '}
                    on the homepage.
                </Typography>

                <Typography paragraph variant="h5">
                    <b>Privacy Policy</b>
                </Typography>
                <Typography paragraph>
                    <b>Effective Date: </b>
                    <b>01 July 2022</b>
                </Typography>
                <Typography paragraph variant="h6">
                    <b>The Scope</b>
                </Typography>
                <Typography paragraph>
                    This Privacy Policy (<b>‘Policy’</b>) is in line with the requirements of the
                    General Data Protection Regulation that came into effect in May 2018 (the{' '}
                    <b>‘GDPR’</b>). This Policy describes our data protection practices regarding
                    the data we collect when you access our website and associated web app
                    (collectively, the <b>‘Site’</b>) and use our personalised fitness programs,
                    resources, information, videos, advice and services (our <b>‘Programs’</b>).
                </Typography>
                <Typography paragraph>
                    Please, read the entirety of this Policy before using our Programs or submitting
                    personal data to us. Also read all other documents that apply to your use of the
                    Site and Programs, including our Terms of Service. By submitting personal data
                    to us and using our Programs, you represent that you have read this Policy and
                    consent to it. If you do not agree to this Policy, you do not have our
                    permission to use the Site and our Programs.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Data Controller information</b>
                </Typography>
                <Typography paragraph>
                    The Site and Programs are provided by The Anabolist LTD, a limited company
                    operating from the United Kingdom (UK) (DBA ‘<b>The Anabolist</b>’). This means
                    that The Anabolist is your ‘Data Controller’ – we are responsible for the
                    collection and processing of the data you submit to us concerning the Site and
                    our Programs. We will process your data in accordance with the GDPR and other
                    applicable laws. For further questions about The Anabolist, please contact us
                    via email at{' '}
                    <Link target="_blank" href="mailto:contact@theanabolist.com">
                        contact@theanabolist.com
                    </Link>
                    .
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Data collection and uses</b>
                </Typography>
                <Typography paragraph>
                    Under this Policy, ‘<b>personal data</b>’ refers to any data about an
                    individual, such as you, that can be used to identify such an individual.
                    Non-personal data, on the other hand, is any data that cannot be used to
                    identify such an individual. When you access the Site, fill out forms and use
                    our Programs, we will collect personal data and non-personal data. We will
                    collect the categories of data described below:
                </Typography>
                <ul>
                    <li>
                        <b>Registration data:</b> When you request to create an account via the web
                        app, we require you to submit registration data, which includes your name,
                        email address and password. We will use this data primarily to grant you
                        access to a user account, which is then used to communicate with you and
                        provide you with our Programs.
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Profile data:</b> After creating an account, we require you to submit
                        certain personal data via your profile page. This data may include
                        demographics (such as age and gender), health, job information, Body Mass
                        Index (BMI), body composition, previous training and injury history, your
                        goals and a video showing your current workout. We require and use this data
                        to know your body type and imbalances and recommend the right, personalised
                        Programs for you.
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Payment data:</b> We will collect your credit/debit card data, including
                        the card name, country of issue and other card details needed to process
                        your subscription fees. We collect your card data strictly to process your
                        payments for any plan you choose. Please, note that{' '}
                        <Link target="_blank" href="https://stripe.com/legal">
                            Stripe Inc
                        </Link>{' '}
                        ., our third-party payment processor, is responsible for the collection and
                        processing of payments on our behalf. And they only use the information
                        collected for payment processing only. Please, note that the data collected
                        under this paragraph does not apply when you use Google Pay (GPay), a
                        payment option provided by Google Inc. If you submit payment data to Google
                        Inc. through GPay, it’s not shared with us, nor collected through the Site.
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Transaction data:</b> We will automatically collect information about
                        your transaction on the Site. This information may include the plan you are
                        subscribed to, the starting and end date of your subscription, the fees and
                        other pertinent information. We collect this information to comply with our
                        record-keeping obligations and to safeguard us from any future claims.
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Contact data:</b> We will collect your email address and phone number
                        when you submit them to us or reach out for enquiries. We will collect this
                        data to respond to your enquiries and provide you with our Programs.
                    </li>
                </ul>

                <Typography paragraph variant="h6">
                    <b>Our legal bases for processing your data</b>
                </Typography>
                <Typography paragraph>
                    Under the GDPR, we are allowed to only process your data based on one or more of
                    six legal bases. Our legal bases for processing your data include:
                </Typography>
                <ul>
                    <li>
                        <b>Consent:</b> You have shown that you give The Anabolist a free, informed
                        or unambiguous consent to process your data for the purposes specified (for
                        example, you give us your consent when you provide your payment method data
                        to us).
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Performing a contract:</b> Most of the data we collect, including profile
                        data and workout video, is to perform the contract that exists between us
                        i.e., to be able to identify and provide you with a personalised Program.
                    </li>
                </ul>

                <Typography paragraph variant="h6">
                    <b>Our principles for processing data</b>
                </Typography>
                <Typography paragraph>
                    We process personal data based on the following principles:
                </Typography>
                <ul>
                    <li>We comply with the law and are transparent about how we use your data.</li>
                    <li>We only process your data for the purposes we collect them.</li>
                    <li>We only collect the data we need to provide you with our Programs.</li>
                    <li>We ensure that we process accurate, complete and current data always.</li>
                    <li>We do not retain or store your data with us for longer than we need it.</li>
                    <li>We ensure we protect your data from unauthorised access or use.</li>
                </ul>

                <Typography paragraph variant="h6">
                    <b>Data disclosures</b>
                </Typography>
                <ul>
                    <li>
                        <b>Service providers (data processors):</b> We work with third-party
                        companies who help us execute certain services to enable us to provide our
                        Programs to you successfully. These service providers (referred to as data
                        processors under the GDPR) require access to certain data about you to
                        enable them to perform their services. Therefore, we may disclose personal
                        data to them to perform these services (for example, Stripe Inc. requires
                        access to your credit/debit card details to process your subscription fees
                        on our behalf).
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Law enforcement:</b> The Anabolist complies with the law. We may disclose
                        personal data to law enforcement agencies at their request. This may be with
                        respect to complying with our legal obligations, including if we are
                        required to disclose your data with respect to any ongoing investigations.
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Other companies:</b> We may disclose personal data to companies, entities
                        and organisations we enter into a business transfer agreement, including
                        during a merger, acquisition, sale of assets, bankruptcy or consolidation.
                    </li>
                </ul>

                <Typography paragraph variant="h6">
                    <b>Data retention period</b>
                </Typography>
                <Typography paragraph>
                    Our policy regarding the retention period of your data depends on how long you
                    use our Programs or keep your account with us. We will retain your data on our
                    database until you request to delete your data or account, or we terminate your
                    use based on any perceived breach of our Terms of Service. However, certain
                    data, such as transaction data, may linger with us for more periods even after
                    we delete your data. This may be a result of our obligation to retain records.
                    We may also retain some data for research purposes – in ways that do not
                    override your rights.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Cookie Policy</b>
                </Typography>
                <Typography paragraph>
                    Certain portions of the Site may contain cookies, which are used to perform
                    certain activities, including signing you in and remembering your details. These
                    cookies are set by us and some of our service providers. You can learn more
                    about what cookies are and how they function by reading our Cookie Policy.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Data security</b>
                </Typography>
                <Typography paragraph>
                    The protection of your data is of paramount importance to The Anabolist. Aside
                    from the instances in which we may disclose your data above, we do not disclose
                    your data to any third party without your consent, nor do we engage in the sale
                    of data to third parties. We employ commercially reasonable methods of data
                    protection. We store your data on our database, and it is only accessible by
                    authorised personnel on a need-to-know basis. We also employ technical security
                    measures, such as limiting the number of ports that are open and only permitting
                    connection to the database from the backend domain. We use{' '}
                    <Link target="_blank" href="https://www.heroku.com/">
                        Heroku
                    </Link>{' '}
                    (for our backend security) and{' '}
                    <Link target="_blank" href="https://www.mongodb.com/atlas/database">
                        MongoDB Atlas
                    </Link>{' '}
                    (for database management).
                </Typography>
                <Typography paragraph>
                    Nevertheless, you are aware and acknowledge that the security of personal data
                    in the online space is not 100% guaranteed and reliable. Therefore, even though
                    we want your data to be safe from unauthorised access, use and alteration, we
                    cannot guarantee this.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Data transfers outside of the UK</b>
                </Typography>
                <Typography paragraph>
                    We collect and process your data in the UK in accordance with the GDPR. However,
                    some of our service providers are located outside of the UK; therefore, we may
                    transfer personal data to them in their respective locations. However, when we
                    transfer personal data outside of the UK, we rely on the safeguards provided by
                    the European Commission, including transferring to countries with ‘adequate’
                    data protection, using Standard Contract Clauses and ensuring the receiver of
                    the data also incorporates GDPR practices in their privacy practices.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Your data protection rights</b>
                </Typography>
                <Typography paragraph>
                    The GDPR provides you with certain rights with respect to the personal data you
                    submit to us. Your rights include:
                </Typography>
                <ul>
                    <li>
                        <b>Right to withdraw consent:</b> Where you have given us consent to process
                        your data for specific purposes, you have the right to withdraw your consent
                        at any time. Please, note that some portions of our Programs may become
                        unavailable to you if you withdraw your consent.
                    </li>
                    <li>
                        <b>Right to access and correct your data:</b> You may request to access your
                        data and correct any non-current portion. You may access your{' '}
                        <b>‘Profile’</b> via the drop-down menu at the top-right corner of your
                        dashboard to correct your data. You may also reach out to us at{' '}
                        <Link target="_blank" href="mailto:contact@theanabolist.com">
                            contact@theanabolist.com
                        </Link>{' '}
                        to keep us informed.
                    </li>
                    <li>
                        <b>Right to delete your data:</b> You may request to delete any personal
                        data you have provided to us at any time without undue delay. However, this
                        right may only be possible if your request does not override any of our
                        legal obligations. To exercise this right, please reach out to us at{' '}
                        <Link target="_blank" href="mailto:contact@theanabolist.com">
                            contact@theanabolist.com
                        </Link>{' '}
                        to have your data deleted from our backend manually.
                    </li>
                    <li>
                        <b>Right to restrict processing:</b> You may request that we restrict the
                        processing of specific data for specific purposes. This may be in a
                        situation where you do not want the data to be used but you do not want it
                        to be deleted from our database. You may reach out to us at{' '}
                        <Link target="_blank" href="mailto:contact@theanabolist.com">
                            contact@theanabolist.com
                        </Link>{' '}
                        to exercise this right.
                    </li>
                    <li>
                        <b>Right to data portability:</b> You have the right to request that we
                        provide you with a copy of your data in a machine-readable format so that it
                        may be used across other sites, apps and services. You may reach out to us
                        at{' '}
                        <Link target="_blank" href="mailto:contact@theanabolist.com">
                            contact@theanabolist.com
                        </Link>{' '}
                        to request a copy.
                    </li>
                    <li>
                        <b>Right to reporting:</b> If you believe we are not processing your data as
                        described in this Policy, you may reach out to us at{' '}
                        <Link target="_blank" href="mailto:contact@theanabolist.com">
                            contact@theanabolist.com
                        </Link>{' '}
                        to make us aware. If you are not satisfied with our response, you have the
                        right to report us to the{' '}
                        <Link target="_blank" href="https://ico.org.uk/make-a-complaint">
                            Information Commissioner’s Office (ICO).
                        </Link>{' '}
                    </li>
                </ul>
                <Typography paragraph variant="h6">
                    <b>Children</b>
                </Typography>
                <Typography paragraph>
                    We do not knowingly collect personal data from individuals who, under applicable
                    law, are minors – specifically people of 18 years of age or younger. If we
                    discover that you are a minor who has submitted personal data to us, we may
                    terminate your account and use of our Programs.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Third-Party Links</b>
                </Typography>
                <Typography paragraph>
                    Our Site may contain content, adverts, materials or resources that lead to other
                    sites, apps and platforms (<b>‘Third-Party Links’</b>). Any Third-Party Links
                    available on the Site are for reference only and do not represent our
                    endorsement of their safety, reliability or security. This Policy does not
                    govern the privacy practices of any Third-Party Links. We implore you to review
                    the privacy policies of Third-Party Links before submitting personal data to
                    their owners.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Changes to this Policy</b>
                </Typography>
                <Typography paragraph>
                    This Policy may be reviewed and changed from time to time as new features are
                    added to our Site and Programs, or there are changes to applicable laws. In the
                    event we make changes to this Policy, the Effective Date at the top will be
                    changed to reflect the date the changes were made. We reserve the right to
                    communicate changes via your email address or any portion of the Site.
                    Nevertheless, you should also review this Policy frequently to be informed of
                    new changes. By your continued use of our Programs or access of the Site after
                    any changes to this Policy, you represent that you consent to the changes made.
                </Typography>

                <Typography paragraph>
                    <b> </b>
                    <b> </b>
                </Typography>
                <Typography paragraph variant="h5">
                    <b>Cookie Policy</b>
                </Typography>

                <Typography paragraph>
                    This Cookie Policy is an additional disclosure of our privacy practices as
                    described in our Privacy Policy. It contains information about the cookies used
                    on{' '}
                    <Link target="_blank" href="https://theanabolist.com">
                        https://theanabolist.com
                    </Link>{' '}
                    (the “<b>Site</b>”) and what they are used for. You should read and understand
                    this Cookie Policy and accept it. If you do not agree to our use of cookies, you
                    have the right to update your preference.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>What are cookies?</b>
                </Typography>
                <Typography paragraph>
                    Cookies are small, encrypted text files that website owners or developers embed
                    on your device browser, such as your computer, tablet and smartphone (for
                    example, the browser you currently are using to access this Site), which are
                    used to enable certain functions or gather certain data, as the case may be. The
                    cookies used on a website depend on the functions the web owner wants the
                    cookies to perform.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>The type of cookies we set and why</b>
                </Typography>
                <Typography paragraph>
                    We currently do not set many cookies on the Site. At this time, we only set
                    login cookies, which is a necessary cookie that allows us to remember your login
                    credentials, which allows the Site to remember you when you come back to the
                    Site.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Third-party cookies</b>
                </Typography>
                <Typography paragraph>
                    Like us, some of the third-party service providers we hire may also set their
                    cookies on your browser to enable them to perform their services (for example,{' '}
                    <Link target="_blank" href="https://wordpress.com/">
                        WordPress
                    </Link>{' '}
                    may set their cookies since the Site is hosted on their platform). These cookies
                    are referred to as third-party cookies.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>How long do cookies stay on your browser?</b>
                </Typography>
                <Typography paragraph>
                    The duration of a cookie on a device browser depends on if the cookie is a
                    session or persistent cookie. A session cookie, as its name, uses only a session
                    of your browsing time. It disappears or expires when you close a website. On the
                    other hand, a persistent cookie (as its name) stays more than a browsing
                    session. They have defined expiry dates, which may range from hours to years –
                    unless they are blocked manually by the device owner.
                </Typography>
                <Typography paragraph>
                    In the case of the Site, we currently only use a login cookie, which is
                    categorised as a session cookie.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>How you can control cookies</b>
                </Typography>
                <Typography paragraph>
                    If you do not want cookies to be set on your device browser, you have the right
                    to block them. You can see the ‘Settings’ or ‘Help’ section of your device
                    browser to update your cookie preference. Please, note that the blocking of a
                    specific cookie may render its functions unavailable to you (for example, if you
                    block a login cookie, a smooth login experience may become unavailable).
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Changes</b>
                </Typography>
                <Typography paragraph>
                    We may update this Cookie Policy from time to time as our use of cookies
                    changes. Please, endeavour to review this Cookie Policy on a frequent basis to
                    be informed of changes to our cookies.
                </Typography>
                <Typography paragraph variant="h6">
                    <b>Learn more</b>
                </Typography>
                <Typography paragraph>
                    For more about cookies, you can visit{' '}
                    <Link target="_blank" href="http://www.allaboutcookie.org">
                        www.allaboutcookie.org
                    </Link>
                    . You may also contact us at{' '}
                    <Link target="_blank" href="mailto:contact@theanabolist.com">
                        contact@theanabolist.com
                    </Link>{' '}
                    if you have any questions, enquiries or concerns regarding our data protection
                    practices.
                </Typography>
            </div>
        </Page>
    );
}

export default TermsConditions;
