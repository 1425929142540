import { Stack, TextField, Typography } from '@mui/material';
import { RadioGroup } from 'form';
import { Fragment } from 'react';

function Step2({ form }) {
    const { control, getError, registerField, watch } = form;
    const recommend = watch('recommendToFriends');

    return (
        <Fragment>
            <Stack spacing={3}>
                <Stack spacing={1}>
                    <Typography>How has your overall experience been so far?</Typography>
                    <TextField
                        {...registerField('overallExperience')}
                        fullWidth
                        type="text"
                        multiline
                        minRows={1}
                    />
                </Stack>
                <Stack spacing={1}>
                    <Typography>
                        If we could undergo any changes that would help better with your experience,
                        what would those changes be?
                    </Typography>
                    <TextField
                        {...registerField('anyChanges')}
                        fullWidth
                        type="text"
                        multiline
                        minRows={1}
                    />
                </Stack>
                <Stack spacing={1}>
                    <Typography>
                        What features would you like to see on our platform in the near future?
                    </Typography>
                    <TextField
                        {...registerField('otherFeatures')}
                        fullWidth
                        type="text"
                        multiline
                        minRows={1}
                    />
                </Stack>
                <RadioGroup
                    label="Would you recommend The Anabolist to your friends?"
                    getError={getError}
                    control={control}
                    name="recommendToFriends"
                    items={[
                        { value: 'Yes', label: 'Yes' },
                        { value: 'No', label: 'No' },
                    ]}
                    direction="row"
                />
                {recommend === 'No' && (
                    <Stack spacing={1}>
                        <Typography>Can you tell us why you chose No?</Typography>
                        <TextField
                            {...registerField('recommendToFriendsReason')}
                            fullWidth
                            type="text"
                            multiline
                            minRows={1}
                        />
                    </Stack>
                )}
            </Stack>
        </Fragment>
    );
}

export default Step2;
