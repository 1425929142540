import { Box } from '@mui/material';

const SIZES = {
    small: 12,
    normal: 'body2.fontSize',
    large: 'body1.fontSize',
};

function Tag({
    children,
    size = 'normal',
    bgcolor = 'grey.600',
    color = 'common.white',
    disabled,
    onClick,
    ...props
}) {
    if (!children) return null;

    return (
        <Box
            px={3 / 4}
            py="1px"
            color={disabled ? bgcolor : color}
            border={1}
            borderColor={bgcolor}
            bgcolor={disabled ? undefined : bgcolor}
            borderRadius={1 / 2}
            fontSize={SIZES[size]}
            onClick={onClick}
            sx={{ cursor: onClick ? 'pointer' : undefined }}
            {...props}
        >
            {children}
        </Box>
    );
}

export default Tag;
