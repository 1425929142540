import { io } from 'socket.io-client';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { IS_DEV } from 'components/Developer/helpers';

const SocketContext = createContext(null);

const SOCKET_URL = IS_DEV ? '/' : process.env.REACT_APP_API_BASE;
const SOCKET_OPTIONS = {
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 3000,
    withCredentials: true,
};

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (!socket) return;

        socket.io.on('reconnect', () => {
            socket.disconnect();
            setSocket(io.connect(SOCKET_URL, SOCKET_OPTIONS));
            console.log('Socket: Reconnected to server');
        });

        if (!IS_DEV) return null;

        socket.on('connect', () => {
            console.log('Socket: connected to server');
        });
        socket.on('error', (message) => {
            console.log('Socket error: ', message);
        });
        socket.on('connected', () => {
            console.log('Socket: authentication success');
        });
        socket.on('disconnect', () => {
            console.log('Socket: Disconnected from server');
        });
        socket.on('info', (message) => {
            console.log(message);
        });
        socket.on('newMessage', () => {
            console.log('Socket: new message');
        });
        socket.on('newChats', () => {
            console.log('Socket: new chats');
        });
    }, [socket]);

    const initializeSocket = useCallback(() => {
        if (!socket) setSocket(io.connect(SOCKET_URL, SOCKET_OPTIONS));
    }, [socket]);

    const disconnect = useCallback(() => {
        if (!socket) return;

        socket.disconnect();
        setSocket(null);
    }, [socket]);

    return (
        <SocketContext.Provider value={[socket, initializeSocket, disconnect]}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = (initialize = false) => {
    const [socket, initializeSocket, disconnect] = useContext(SocketContext);

    useEffect(() => {
        if (!initialize) return;

        initializeSocket();

        return () => {
            disconnect();
        };
    }, [disconnect, initialize, initializeSocket, socket]);

    return socket;
};
