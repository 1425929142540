import { useLocalStorage } from 'hooks';
import { useCallback, useMemo } from 'react';

function useInitialLocation() {
    const [value, setValue] = useLocalStorage('initial_location', '');

    const initialLocation = useMemo(() => {
        try {
            const parsedValue = JSON.parse(value);
            return parsedValue;
        } catch (error) {
            return false;
        }
    }, [value]);

    const setInitialLocation = useCallback(
        (obj) => {
            setValue(JSON.stringify(obj));
        },
        [setValue],
    );

    return [initialLocation, setInitialLocation];
}

export default useInitialLocation;
