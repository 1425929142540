import { Box, IconButton, Stack, Typography, Link as MaterialLink, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { useMe, useS3 } from 'hooks';
import { Fragment, useMemo } from 'react';
import Linkify from 'linkify-react';
import { useDispatch } from 'react-redux';
import { uiActions } from 'ducks';
import fileDownload from 'js-file-download';
import { ACCEPTED_IMAGES, ACCEPTED_VIDEOS, getFileIcon } from 'components/Chat/constants';
import { replaceExtension } from 'helpers';

const makeRegex = (arr) => new RegExp(`(${[...arr].map((v) => `\\${v}`).join('|')})$`, 'i');
const checkImageOrVideo = (fileName) => {
    return {
        isVideo: makeRegex(ACCEPTED_VIDEOS).test(fileName),
        isImage: makeRegex(ACCEPTED_IMAGES).test(fileName),
    };
};
const getCorrectFileName = (fileName, mimeType) => {
    const result = fileName.replace(/\.plain$/i, '.txt');
    const isMp4 = mimeType === 'video/mp4';

    return isMp4 ? replaceExtension(result, '.mp4') : result;
};

function ChatMessage({ data, handleMenuClick, editing, messageDataIdKey }) {
    const dispatch = useDispatch();
    const me = useMe();
    const getUrl = useS3(true);
    const isMyMessage = data?.sentBy?._id === me._id || data?.sentBy === me._id;
    const createdAt = new Date(data?.createdAt);

    const details = useMemo(() => {
        const result = [];
        if (data?.read && isMyMessage) {
            result.push(`Read ${format(new Date(data?.readTimestamp), 'HH:mm')}`);
        }
        if (data?.edited) result.push(`Edited ${format(new Date(data?.updatedAt), 'HH:mm')}`);

        return result.join(' • ');
    }, [data?.edited, data?.read, data?.readTimestamp, data?.updatedAt, isMyMessage]);

    const handleFileClick =
        (fileName, { isImage, isVideo }, justView) =>
        async (e) => {
            if (justView) {
                if (isVideo) {
                    dispatch(uiActions.setVideoViewerId(fileName));
                    return;
                }
                if (isImage) {
                    dispatch(uiActions.setImageViewerId(fileName));
                    return;
                }
            }

            const url = await getUrl(fileName, !isVideo && !isImage);
            const response = await fetch(url);
            const blob = await response.blob();
            fileDownload(blob, getCorrectFileName(fileName, blob.type));
        };

    return (
        <div>
            <Box textAlign="center" mt={3} mb={2} position="relative">
                <Typography variant="caption" fontWeight="normal">
                    {!data?.read && !isMyMessage && (
                        <Box
                            display="inline"
                            color="warning.dark"
                            fontWeight="bold"
                            mr={2}
                            position="absolute"
                            left={0}
                        >
                            Unread
                        </Box>
                    )}
                    {format(createdAt, 'EEEE, d MMMM yyyy • ')}
                    <b>{format(createdAt, 'HH:mm')}</b>
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection={isMyMessage ? 'row-reverse' : 'row'}
                alignItems="flex-start"
            >
                <Box
                    {...{ [`data-${messageDataIdKey}`]: data?._id }}
                    bgcolor={
                        data?.removed || editing
                            ? undefined
                            : isMyMessage
                            ? 'primary.main'
                            : 'grey.50'
                    }
                    border={data?.removed || editing ? '1px solid' : undefined}
                    borderColor={editing ? 'warning.dark' : 'grey.300'}
                    borderRadius={2}
                    py={1}
                    px={1.5}
                    maxWidth={500}
                    color={
                        data?.removed
                            ? 'text.disabled'
                            : editing
                            ? 'warning.dark'
                            : isMyMessage
                            ? 'common.white'
                            : undefined
                    }
                >
                    {!data?.removed && !!data?.fileURLs?.length && (
                        <Stack
                            spacing={1 / 2}
                            mt={1 / 2}
                            mb={data?.text ? 1 : 1 / 2}
                            alignItems="flex-start"
                        >
                            {data?.fileURLs?.map((v) => {
                                const isImageOrVideo = checkImageOrVideo(v);

                                return (
                                    <Box
                                        key={v}
                                        width="100%"
                                        maxWidth="340px"
                                        fontSize="body2.fontSize"
                                        bgcolor={isMyMessage ? 'grey.50' : 'grey.200'}
                                        color="text.primary"
                                        borderRadius={1.5}
                                        px={1.5}
                                        py={1}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Tooltip
                                            title={
                                                isImageOrVideo.isVideo
                                                    ? 'Play video'
                                                    : isImageOrVideo.isImage
                                                    ? 'View image'
                                                    : 'Download file'
                                            }
                                            disableInteractive
                                            placement="left"
                                        >
                                            <Box
                                                flexGrow={1}
                                                display="inline-block"
                                                onClick={handleFileClick(v, isImageOrVideo, true)}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <i className={`${getFileIcon(v)} fa-lg fa-mr-1`} />
                                                {getCorrectFileName(v)}
                                            </Box>
                                        </Tooltip>
                                        <Tooltip
                                            title="Download file"
                                            disableInteractive
                                            placement="left"
                                        >
                                            <Box
                                                onClick={handleFileClick(v, isImageOrVideo)}
                                                className="far fa-arrow-alt-circle-down fa-lg fa-ml-2"
                                                component="i"
                                                color="primary.main"
                                                sx={{ cursor: 'pointer' }}
                                            />
                                        </Tooltip>
                                    </Box>
                                );
                            })}
                        </Stack>
                    )}

                    <div>
                        {data?.removed ? (
                            'Message removed'
                        ) : (
                            <Linkify
                                options={{
                                    render: {
                                        url: ({ attributes: { href, ...props }, content }) => (
                                            <MaterialLink
                                                {...props}
                                                href={href}
                                                target="_blank"
                                                sx={{
                                                    fontWeight: isMyMessage ? undefined : 'normal',
                                                    color: isMyMessage ? 'common.white' : undefined,
                                                }}
                                            >
                                                {content}
                                            </MaterialLink>
                                        ),
                                    },
                                }}
                            >
                                {data?.text?.split('\n').map((v) => (
                                    <Fragment key={v}>
                                        {v}
                                        <br />
                                    </Fragment>
                                ))}
                            </Linkify>
                        )}
                    </div>

                    {!data?.removed && details && (
                        <Box textAlign="right">
                            <Typography
                                variant="caption"
                                fontWeight="normal"
                                mt={1}
                                color={editing ? 'warning.main' : isMyMessage ? '#fffc' : '#0008'}
                            >
                                {details}
                            </Typography>
                        </Box>
                    )}
                </Box>
                {isMyMessage && !data?.removed && (
                    <IconButton sx={{ mx: 1 / 4 }} onClick={handleMenuClick}>
                        <i className="fas fa-ellipsis-v fa-sq fa-xs" />
                    </IconButton>
                )}
            </Box>
        </div>
    );
}

export default ChatMessage;
