import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';
import { useGetUsersQuery } from 'api';
import { chatActions, chatSelectors } from 'ducks';
import { useAutoNotify, useRoles } from 'hooks';
import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function NewMessage() {
    const dispatch = useDispatch();
    const { me, isAdmin } = useRoles();

    useEffect(() => {
        return () => dispatch(chatActions.setNewRecipient(null));
    }, [dispatch, isAdmin]);

    const { data, error, isLoading } = useGetUsersQuery();
    useAutoNotify(error);

    const users = useMemo(() => {
        if (!data) return false;

        return data.filter((v) => v?._id !== me?._id);
    }, [data, me?._id]);

    useEffect(() => {
        if (isAdmin || !users?.length) return;

        const coach = users.find((v) => v?.isCoach);
        dispatch(chatActions.setNewRecipient(coach));
    }, [dispatch, isAdmin, users]);

    const newRecipient = useSelector(chatSelectors.getNewRecipient);

    return (
        <Autocomplete
            value={newRecipient}
            onChange={(e, newValue) => {
                dispatch(chatActions.setNewRecipient(newValue));
            }}
            disabled={isLoading || !isAdmin}
            options={users || []}
            fullWidth
            loading={isLoading}
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            getOptionLabel={(option) => option?.name}
            renderOption={(props, { _id, email, isCoach, role, name } = {}) => {
                const important = isAdmin && (isCoach || role === 'admin');
                return (
                    <Box component="li" whiteSpace="nowrap" {...props} key={_id}>
                        {important && (
                            <Box
                                component="i"
                                className={`fa-xs fa-mr-1 fas fa-${
                                    isCoach ? 'dumbbell' : 'shield-alt'
                                }`}
                                color={isCoach ? 'primary.main' : 'warning.main'}
                            />
                        )}
                        <b>{name}</b>
                        {email && <Fragment>&nbsp;• {email}</Fragment>}
                    </Box>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="Search people..."
                    autoFocus
                    InputProps={{
                        ...(params?.InputProps || {}),
                        startAdornment: (
                            <InputAdornment position="start" sx={{ ml: 1 }}>
                                To:
                                {isLoading && <i className="fas fa-circle-notch fa-spin fa-ml-1" />}
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '.MuiOutlinedInput-root': {
                            borderRadius: '20px',
                        },
                    }}
                />
            )}
        />
    );
}

export default NewMessage;
