import { Stack, Typography, Paper, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAutoNotify, useForm } from 'hooks';
import { useUpdateMeMutation } from 'api';
import * as yup from 'yup';

const schema = yup
    .object({
        name: yup.string().required('This is required'),
    })
    .required();

function MyAccountMeForm({ data: { name, email } = {} }) {
    const { handleSubmit, registerField, reset } = useForm(schema, {
        defaultValues: { name },
    });

    const [submit, { error, isSuccess, isLoading }] = useUpdateMeMutation();
    useAutoNotify(error, isSuccess, 'Information updated successfully.');

    const onSubmit = async (form) => {
        try {
            submit(form);
        } catch (error) {
            reset();
        }
    };

    return (
        <Paper sx={{ p: 3, flexGrow: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
                <Stack spacing={5} sx={{ height: '100%' }}>
                    <div>
                        <Typography variant="h6">General info</Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            Update general info
                        </Typography>
                    </div>
                    <Stack spacing={3} sx={{ flexGrow: 1 }}>
                        <TextField {...registerField('name')} fullWidth label="Name *" />
                        <TextField fullWidth label="Email *" type="email" disabled value={email} />
                    </Stack>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        size="large"
                        fullWidth
                        type="submit"
                    >
                        Save
                    </LoadingButton>
                </Stack>
            </form>
        </Paper>
    );
}

export default MyAccountMeForm;
