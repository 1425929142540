import { Box, Container, Paper, Typography } from '@mui/material';
import { MiniFooter } from 'components/Layout';
import { Link } from 'react-router-dom';

import logo from 'assets/logo.svg';

function Page({ children, title, subtitle }) {
    return (
        <Container
            sx={{
                py: {
                    xs: 2,
                    lg: 6,
                },
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box component={Link} maxWidth="260px" to="/">
                <img src={logo} alt="" />
            </Box>
            {title && (
                <Typography
                    variant="h2"
                    component="h2"
                    mt={{
                        xs: 4,
                        lg: 6,
                    }}
                >
                    {title}
                </Typography>
            )}
            {subtitle && (
                <Typography variant="h2" component="h2">
                    {subtitle}
                </Typography>
            )}
            <Paper
                sx={{
                    px: {
                        xs: 3,
                        sm: 5,
                    },
                    py: {
                        xs: 4,
                        sm: 5,
                    },
                    boxShadow: '0 0 70px 0 #0002',
                    overflow: 'hidden',
                    // maxWidth: 500,
                    mx: 'auto',
                    my: {
                        xs: 2,
                        lg: 4,
                    },
                }}
            >
                {children}
            </Paper>
            <MiniFooter />
        </Container>
    );
}

export default Page;
