import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { useS3 } from 'hooks';
import { useDispatch } from 'react-redux';
import { uiActions } from 'ducks';
import fileDownload from 'js-file-download';
import { getFileIcon } from 'components/Chat/constants';
import { ACCEPTED_IMAGES, ACCEPTED_VIDEOS } from 'components/Chat/constants';

const makeRegex = (arr) => new RegExp(`(${[...arr].map((v) => `\\${v}`).join('|')})$`, 'i');
const checkImageOrVideo = (fileName) => {
    return {
        isVideo: makeRegex(ACCEPTED_VIDEOS).test(fileName),
        isImage: makeRegex(ACCEPTED_IMAGES).test(fileName),
    };
};

const getCorrectFileName = (fileName) => fileName.replace(/\.plain$/i, '.txt');

function File({ file, viewOnly, ...props }) {
    const dispatch = useDispatch();
    const getUrl = useS3(true);

    const handleFileClick =
        (fileName, { isImage, isVideo }, justView) =>
        async (e) => {
            if (justView) {
                if (isVideo) {
                    dispatch(uiActions.setVideoViewerId(fileName));
                    return;
                }
                if (isImage) {
                    dispatch(uiActions.setImageViewerId(fileName));
                    return;
                }
            }

            const url = await getUrl(fileName, !isVideo && !isImage);
            const response = await fetch(url);
            const blob = await response.blob();
            fileDownload(blob, getCorrectFileName(fileName));
        };

    const isImageOrVideo = checkImageOrVideo(file);
    const allowView = !viewOnly || isImageOrVideo.isVideo || isImageOrVideo.isImage;

    if (!file) return null;

    return (
        <Box
            key={file}
            width="100%"
            maxWidth="340px"
            fontSize="body2.fontSize"
            bgcolor="grey.200"
            color="text.primary"
            borderRadius={1.5}
            px={1.5}
            py={1}
            display="flex"
            alignItems="center"
            {...props}
        >
            <Tooltip
                title={
                    isImageOrVideo.isVideo
                        ? 'Play video'
                        : isImageOrVideo.isImage
                        ? 'View image'
                        : viewOnly
                        ? ''
                        : 'Download file'
                }
                disableInteractive
                placement="left"
            >
                <Box
                    flexGrow={1}
                    display="inline-block"
                    onClick={allowView ? handleFileClick(file, isImageOrVideo, true) : undefined}
                    sx={allowView ? { cursor: 'pointer' } : undefined}
                    textOverflow="ellipsis"
                    overflow="hidden"
                >
                    <i className={`${getFileIcon(file)} fa-lg fa-mr-1`} />
                    {getCorrectFileName(file)}
                </Box>
            </Tooltip>
            {!viewOnly && (
                <Tooltip title="Download file" disableInteractive placement="left">
                    <Box
                        onClick={handleFileClick(file, isImageOrVideo)}
                        className="far fa-arrow-alt-circle-down fa-lg fa-ml-2"
                        component="i"
                        color="primary.main"
                        sx={{ cursor: 'pointer' }}
                    />
                </Tooltip>
            )}
        </Box>
    );
}

export default File;
