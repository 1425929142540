import { Dialog, DialogContent } from '@mui/material';
import { S3VideoBox } from 'common';
import { useEffect, useState } from 'react';

function VideoDialog({ currentVideo, onClose }) {
    const [localVideo, setLocalVideo] = useState(false);

    useEffect(() => {
        if (!currentVideo) return;
        setLocalVideo(currentVideo);
    }, [currentVideo]);

    return (
        <Dialog
            open={!!currentVideo}
            onClose={onClose}
            TransitionProps={{ onExited: () => setLocalVideo(false) }}
            fullWidth
            maxWidth="md"
            scroll="body"
        >
            <DialogContent>
                <S3VideoBox noFallback width="100%" video={localVideo} autoPlay controls />
            </DialogContent>
        </Dialog>
    );
}

export default VideoDialog;
