import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
    DialogTitle,
    DialogContent,
    Stack,
    Typography,
    Chip,
    Grid,
    DialogActions,
    Button,
    useMediaQuery,
} from '@mui/material';

import { S3VideoBox } from 'common';
import { uiActions } from 'ducks';
import { useRoles } from 'hooks';

function MyChip({ item }) {
    return (
        <Chip
            key={item}
            label={item}
            size="small"
            sx={{
                display: 'inline-flex',
                margin: 1 / 4,
            }}
        />
    );
}

function ExerciseView({ data, handleClose }) {
    const dispatch = useDispatch();
    const anyCategories = !!data?.categories?.length;
    const anyMuscles = !!data?.musclesWorked?.length;

    const { isAdmin } = useRoles();
    const isLarge = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    const handleEdit = (e) => {
        dispatch(uiActions.clearCurrentExerciseId());
    };

    return (
        <Fragment>
            {data?.name && (
                <DialogTitle>
                    <Stack spacing={2} direction="row" alignItems="flex-start">
                        <span>{data.name}</span>
                        {isAdmin && (
                            <Button
                                size="small"
                                variant="outlined"
                                color="warning"
                                component={Link}
                                to={`/exercises/${data?._id}/edit`}
                                onClick={handleEdit}
                            >
                                Edit
                            </Button>
                        )}
                    </Stack>
                </DialogTitle>
            )}
            <DialogContent>
                <Stack spacing={2}>
                    {(anyCategories || anyMuscles) && (
                        <Stack
                            direction="row"
                            divider={
                                isLarge ? (
                                    <Typography variant="subtitle2" px={1}>
                                        •
                                    </Typography>
                                ) : undefined
                            }
                            alignItems="center"
                            flexWrap="wrap"
                            ml={{
                                xs: -1 / 4,
                                sm: 0,
                            }}
                        >
                            {anyCategories && (
                                <Fragment>
                                    <Typography
                                        variant="subtitle2"
                                        display="inline-block"
                                        width={{
                                            xs: '100%',
                                            sm: 'auto',
                                        }}
                                        ml={{
                                            xs: 1 / 4,
                                            sm: 0,
                                        }}
                                    >
                                        Categories:
                                    </Typography>

                                    {data?.categories.map((v, i) => (
                                        <MyChip key={v} item={v} index={i} isLarge={isLarge} />
                                    ))}
                                </Fragment>
                            )}
                            {anyMuscles && (
                                <Fragment>
                                    <Typography
                                        variant="subtitle2"
                                        display="inline-block"
                                        width={{
                                            xs: '100%',
                                            sm: 'auto',
                                        }}
                                        ml={{
                                            xs: 1 / 4,
                                            sm: 0,
                                        }}
                                        mt={{
                                            xs: 1,
                                            sm: 0,
                                        }}
                                    >
                                        Muscles worked:
                                    </Typography>

                                    {data?.musclesWorked.map((v, i) => (
                                        <MyChip key={v} item={v} index={i} isLarge={isLarge} />
                                    ))}
                                </Fragment>
                            )}
                        </Stack>
                    )}
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <Typography>{data?.description}</Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <S3VideoBox width="100%" video={data?.videoURL} controls />
                            </Grid>
                        </Grid>
                    </div>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Fragment>
    );
}

export default ExerciseView;
