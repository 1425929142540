import { format, formatDistance } from 'date-fns';
import { Fragment } from 'react';
import { SurveyInfo } from 'components/Reconsultation';

import { Box, Button, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useRoles } from 'hooks';

function SurveyEntry({ data, onClick, onEdit }) {
    const { isAdmin } = useRoles();
    const date = format(new Date(data?.expiresIn), 'EEE, dd MMM. YYY');
    const distance = formatDistance(new Date(data?.expiresIn), new Date(), {
        addSuffix: true,
    });

    return (
        <ListItem
            disablePadding
            secondaryAction={isAdmin && <Button onClick={onEdit}>Edit</Button>}
            sx={{
                '& > .MuiListItemButton-root': {
                    pr: 8,
                },
            }}
        >
            <ListItemButton onClick={onClick}>
                <ListItemIcon sx={{ minWidth: (t) => t.spacing(4.5) }}>
                    <i className="far fa-clipboard fa-lg" />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Fragment>
                            <Box component="span" fontWeight="600">
                                To fill before:{' '}
                            </Box>
                            {date} <i>({distance})</i>
                        </Fragment>
                    }
                    secondary={
                        <Box component="span" display="inline-block" mt={1 / 2}>
                            <SurveyInfo data={data} />
                        </Box>
                    }
                />
            </ListItemButton>
        </ListItem>
    );
}

export default SurveyEntry;
