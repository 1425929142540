import { Box, CircularProgress } from '@mui/material';

function InitialAnimation() {
    return (
        <Box
            width="100vw"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <CircularProgress />
        </Box>
    );
}

export default InitialAnimation;
