import { Box, LinearProgress, Stack } from '@mui/material';
import { MyAccountDelete, MyAccountMeForm, MyAccountPassForm } from 'components/MyAccount';
import { useGetMeQuery } from 'api';
import { Fragment } from 'react';

function MyAccount() {
    const { data, isLoading, isSuccess } = useGetMeQuery();

    if (isLoading) return <LinearProgress />;
    if (!isSuccess) return null;

    return (
        <Fragment>
            <Stack
                direction={{
                    xs: 'column',
                    md: 'row',
                }}
                spacing={3}
                sx={{
                    maxWidth: {
                        xs: 400,
                        md: 1000,
                    },
                    mx: 'auto',
                }}
            >
                <MyAccountMeForm data={data} />
                <MyAccountPassForm />
            </Stack>
            <Box textAlign="center" mt={6}>
                <MyAccountDelete />
            </Box>
        </Fragment>
    );
}

export default MyAccount;
