import { Fragment } from 'react';
import { CircularProgress, DialogContentText, Box, DialogActions, Button } from '@mui/material';

function DialogPlaceholder({ loading, children, handleClose }) {
    return (
        <Fragment>
            <Box
                width="100%"
                height="300px"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                {loading ? <CircularProgress /> : <DialogContentText>{children}</DialogContentText>}
            </Box>
            {handleClose && (
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            )}
        </Fragment>
    );
}

export default DialogPlaceholder;
