import { Fragment } from 'react';
import { Typography, LinearProgress } from '@mui/material';
import { useAutoNotify } from 'hooks';
import { useGetCalendarQuery } from 'api';
import { SettingsForm } from 'components/Booking';
import { getTimezoneOffsetHours, makeGMTString } from 'helpers';

function Settings() {
    const { data, error, isLoading, isSuccess } = useGetCalendarQuery();
    useAutoNotify(error);

    if (isLoading) return <LinearProgress />;
    if (!isSuccess) return null;

    const gmtLabel = makeGMTString(getTimezoneOffsetHours('Europe/London'));

    return (
        <Fragment>
            <Typography variant="overline">London time ({gmtLabel})</Typography>
            <Typography variant="h5" mb={1}>
                Set Standard Hours
            </Typography>
            <Typography variant="body1">
                Configure the standard hours when you accept bookings.
            </Typography>

            <SettingsForm data={data} />
        </Fragment>
    );
}

export default Settings;
