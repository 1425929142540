import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toFormData, sortByDate } from 'api/utils';
import { IS_DEV } from 'components/Developer/helpers';
import flatten from 'lodash/flatten';

export const baseUrl = !IS_DEV
    ? process.env.REACT_APP_API_BASE + process.env.REACT_APP_API_PREFIX
    : process.env.REACT_APP_API_PREFIX;

export const API_REDUCER_PATH = 'mainApi';

const api = createApi({
    reducerPath: API_REDUCER_PATH,
    baseQuery: fetchBaseQuery({
        baseUrl,
        credentials: 'include',
    }),

    tagTypes: [
        'Users',
        'Me',
        'Exercises',
        'Categories',
        'Blocks',
        'Logs',
        'GroupNotes',
        'Profile',
        'Products',
        'Subscriptions',
        'Active Subscription',
        'Calendar',
        'Bookings',
        'Discussion-Types',
        'Chats',
        'Messages',
        'ChatFiles',
        'AWS-Credentials',
        'Resurveys',
        'Resources',
        'User-Resources',
    ],

    endpoints: (builder) => ({
        //#region PRODUCTS / SUBSCRIPTIONS

        getProducts: builder.query({
            query: () => '/subscriptions/products',
            providesTags: ['Products'],
            transformResponse: (response) => response?.data,
        }),
        getSubscriptions: builder.query({
            query: (customerId) => `/subscriptions/${customerId}`,
            providesTags: (result, error, arg) => [{ type: 'Subscriptions', id: arg }],
            transformResponse: (response) => response?.data,
        }),
        createCheckoutSession: builder.mutation({
            query: (product) => {
                const price = (product?.unit_amount || 0) / 100;

                return {
                    url: '/subscriptions/create-checkout-session',
                    method: 'POST',
                    body: {
                        ...product,
                        cancelURL: `${
                            window.location.origin + window.location.pathname
                        }?cancelled=true`,
                        successURL: `${
                            window.location.origin + window.location.pathname
                        }?success=true&session_id={CHECKOUT_SESSION_ID}&price=${price}`,
                    },
                };
            },
        }),
        getActiveSubscriptions: builder.query({
            query: (customerId) => `/users/${customerId}/activeSubscription`,
            providesTags: (result, error, arg) => ['Active Subscription'],
            transformResponse: (response) => response?.data,
        }),
        cancelSubscription: builder.mutation({
            query: (subscriptionId) => ({
                url: `/subscriptions/${subscriptionId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Active Subscription'] : undefined),
        }),

        //#endregion

        //#region ME

        getMe: builder.query({
            query: () => '/users/me',
            providesTags: ['Me'],
            transformResponse: (response) => response?.data,
        }),
        updateMe: builder.mutation({
            query: (data) => ({
                url: '/users/updateMe',
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Me'] : undefined),
        }),
        updateMyPass: builder.mutation({
            query: (data) => ({
                url: '/users/updateMyPassword',
                method: 'PATCH',
                body: data,
            }),
        }),
        deactivateMe: builder.mutation({
            query: () => ({
                url: '/users/deactivateMe',
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Me'] : undefined),
        }),
        deleteMe: builder.mutation({
            query: () => ({
                url: '/users/me',
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Me'] : undefined),
        }),

        getAWSCredentials: builder.query({
            query: () => '/aws-credentials',
            providesTags: ['AWS-Credentials'],
            transformResponse: (response) => response?.data,
        }),
        invalidateAWSCredentials: builder.mutation({
            queryFn: () => ({ data: null }),
            invalidatesTags: ['AWS-Credentials'],
        }),

        //#endregion

        //#region USERS

        getUsers: builder.query({
            query: () => '/users',
            providesTags: (result) =>
                result?.length ? result.map((v) => ({ type: 'Users', id: v._id })) : ['Users'],
            transformResponse: (response) => response?.data,
        }),
        getUserById: builder.query({
            query: (id) => `/users/${id}`,
            providesTags: (result, error, arg) => [{ type: 'Users', id: arg }],
            transformResponse: (response) => response?.data,
        }),
        updateUser: builder.mutation({
            query: ({ userId, ...data }) => ({
                url: `/users/${userId}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Users', id: arg.userId }] : undefined,
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: `/users/${userId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Users'] : undefined),
        }),

        //#endregion

        //#region AUTH

        login: builder.mutation({
            query: ({ email, password }) => ({
                url: '/users/login',
                method: 'POST',
                body: { email, password },
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Me'] : undefined),
        }),
        register: builder.mutation({
            query: ({ name, email, password, passwordConfirm }) => ({
                url: '/users/signup',
                method: 'POST',
                body: { name, email, password, passwordConfirm },
            }),
        }),
        activateAccount: builder.mutation({
            query: ({ token }) => ({
                url: `/users/activate/${token}`,
                method: 'PATCH',
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Me'] : undefined),
        }),
        forgotPassword: builder.mutation({
            query: ({ email }) => ({
                url: '/users/forgotPassword',
                method: 'POST',
                body: { email },
            }),
        }),
        resetPassword: builder.mutation({
            query: ({ token, password, passwordConfirm }) => ({
                url: `/users/resetPassword/${token}`,
                method: 'PATCH',
                body: { password, passwordConfirm },
            }),
        }),
        logout: builder.query({
            query: () => '/users/logout',
        }),

        //#endregion

        //#region EXERCISES

        getCategories: builder.query({
            query: () => '/exercises/categories',
            providesTags: ['Categories'],
            transformResponse: (response) => response?.data,
        }),
        getExercises: builder.query({
            query: () => '/exercises',
            providesTags: (result) =>
                result?.length
                    ? result.map(({ _id }) => ({ type: 'Exercises', id: _id }))
                    : ['Exercises'],
            transformResponse: (response) => response?.data,
        }),
        getExerciseById: builder.query({
            query: (id) => `/exercises/${id}`,
            providesTags: (result, error, arg) => [{ type: 'Exercises', id: arg }],
            transformResponse: (response) => response?.data,
        }),
        updateExercise: builder.mutation({
            query: ({ _id, name, description, categories, musclesWorked, video }) => ({
                url: `/exercises/${_id}`,
                method: 'PATCH',
                body: toFormData({
                    name,
                    description,
                    categories,
                    musclesWorked,
                    video: video?.[0],
                    deleteVideo: video === '',
                }),
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Exercises', id: arg._id }, { type: 'Blocks' }] : undefined,
        }),
        addExercise: builder.mutation({
            query: ({ name, description, categories, musclesWorked, video }) => ({
                url: '/exercises',
                method: 'POST',
                body: toFormData({
                    name,
                    description,
                    categories,
                    musclesWorked,
                    video: video?.[0],
                }),
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Exercises'] : undefined),
        }),
        deleteExercise: builder.mutation({
            query: (id) => ({
                url: `/exercises/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Exercises', id: arg }] : undefined,
        }),

        //#endregion

        //#region PROGRAM / BLOCKS

        getBlocksByUserId: builder.query({
            query: (clientId) => ({
                url: '/blocks',
                params: { clientId },
            }),
            providesTags: (result, error, arg) =>
                result?.length ? result.map((v) => ({ type: 'Blocks', id: v._id })) : ['Blocks'],
            transformResponse: (response) => response?.data,
        }),
        getMyBlocks: builder.query({
            query: () => '/blocks',
            providesTags: (result, error, arg) =>
                result?.length ? result.map((v) => ({ type: 'Blocks', id: v._id })) : ['Blocks'],
            transformResponse: (response) => response?.data,
        }),
        getBlockById: builder.query({
            query: (blockId) => `/blocks/${blockId}`,
            providesTags: (result, error, arg) => [{ type: 'Blocks', id: arg }],
            transformResponse: (response) => response?.data,
        }),

        addBlock: builder.mutation({
            query: (data) => ({
                url: '/blocks',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? ['Blocks', { type: 'Users', id: arg?.clientId }] : undefined,
        }),
        updateBlock: builder.mutation({
            query: ({ blockId, ...data }) => ({
                url: `/blocks/${blockId}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Blocks', id: arg.blockId }] : undefined,
        }),
        deleteBlock: builder.mutation({
            query: (blockId) => ({
                url: `/blocks/${blockId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Blocks'] : undefined),
        }),

        //#endregion

        //#region LOGS

        getLogsByBlockId: builder.query({
            query: (blockId) => ({
                url: '/logs',
                params: { blockId },
            }),
            providesTags: (result, error, arg) =>
                result?.length ? result.map((v) => ({ type: 'Logs', id: v._id })) : ['Logs'],
            transformResponse: (response) => sortByDate(response?.data),
        }),
        getLogsByUserId: builder.query({
            query: (clientId) => ({
                url: '/logs',
                params: { clientId },
            }),
            providesTags: (result, error, arg) =>
                result?.length ? result.map((v) => ({ type: 'Logs', id: v._id })) : ['Logs'],
            transformResponse: (response) => sortByDate(response?.data, undefined, -1),
        }),
        getLogById: builder.query({
            query: (logId) => `/logs/${logId}`,
            providesTags: (result, error, arg) => [{ type: 'Logs', id: arg }],
            transformResponse: (response) => response?.data,
        }),
        addLog: builder.mutation({
            query: (data) => ({
                url: '/logs',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Logs'] : undefined),
        }),
        updateLog: builder.mutation({
            query: ({ logId, ...data }) => ({
                url: `/logs/${logId}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Logs', id: arg.logId }] : undefined,
        }),
        deleteLog: builder.mutation({
            query: (logId) => ({
                url: `/logs/${logId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Logs'] : undefined),
        }),

        //#endregion

        //#region GROUP NOTES

        getGroupNotesByBlockId: builder.query({
            query: (blockId) => `blocks/${blockId}/groupNotes`,
            providesTags: (result, error, arg) =>
                result?.length
                    ? result.map((v) => ({ type: 'GroupNotes', id: v._id }))
                    : ['GroupNotes'],
            transformResponse: (response) => sortByDate(response?.data),
        }),
        getGroupNotesByUserId: builder.query({
            query: (clientId) => `/users/${clientId}/groupNotes`,
            providesTags: (result, error, arg) =>
                result?.length
                    ? result.map((v) => ({ type: 'GroupNotes', id: v._id }))
                    : ['GroupNotes'],
            transformResponse: (response) => sortByDate(response?.data, undefined, -1),
        }),
        getGroupNoteById: builder.query({
            query: (groupNoteId) => `/groupNotes/${groupNoteId}`,
            providesTags: (result, error, arg) => [{ type: 'GroupNotes', id: arg }],
            transformResponse: (response) => response?.data,
        }),
        getGroupNotes: builder.query({
            query: () => '/groupNotes',
            providesTags: (result, error, arg) =>
                result?.length
                    ? result.map((v) => ({ type: 'GroupNotes', id: v._id }))
                    : ['GroupNotes'],
            transformResponse: (response) => response?.data,
        }),
        addGroupNote: builder.mutation({
            query: (data) => ({
                url: '/groupNotes',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['GroupNotes'] : undefined),
        }),
        updateGroupNote: builder.mutation({
            query: ({ groupNoteId, ...data }) => ({
                url: `/groupNotes/${groupNoteId}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'GroupNotes', id: arg.groupNoteId }] : undefined,
        }),
        deleteGroupNote: builder.mutation({
            query: (groupNoteId) => ({
                url: `/groupNotes/${groupNoteId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['GroupNotes'] : undefined),
        }),

        //#endregion

        //#region PROFILE

        getProfile: builder.query({
            query: (userId) => ({
                url: '/profile/' + userId,
            }),
            providesTags: (result, error, arg) =>
                result ? [{ type: 'Profile', id: result?._id }] : ['Profile'],
            transformResponse: (response) => response?.data?.[0],
        }),
        getMyProfile: builder.query({
            query: () => ({
                url: '/profile',
            }),
            providesTags: (result, error, arg) =>
                result ? [{ type: 'Profile', id: result?._id }] : ['Profile'],
            transformResponse: (response) => response?.data?.[0],
        }),
        createMyProfile: builder.mutation({
            query: ({ _id, ...data }) => ({
                url: '/profile',
                method: 'POST',
                body: toFormData(data),
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Profile', 'Me'] : undefined),
        }),
        updateMyProfile: builder.mutation({
            query: ({ _id, ...data }) => ({
                url: `/profile/${_id}`,
                method: 'PATCH',
                body: toFormData(data),
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Profile'] : undefined),
        }),

        //#endregion

        //#region CALENDAR

        getCalendar: builder.query({
            query: () => ({
                url: '/calendar',
            }),
            providesTags: (result, error, arg) => ['Calendar'],
            transformResponse: (response) => response?.data,
        }),
        updateCalendar: builder.mutation({
            query: (data) => ({
                url: '/calendar',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? ['Calendar', 'Bookings'] : undefined,
        }),

        //#endregion

        //#region BOOKINGS

        getBookings: builder.query({
            query: ({ start_date, end_date, user_id } = {}) => ({
                url: '/booking',
                params: { start_date, end_date, user_id },
            }),
            providesTags: (result, error, arg) => {
                if (!result) return ['Bookings'];
                const flattenedArr = flatten(result.map((v) => v.bookings));
                return result?.length
                    ? flattenedArr.map(({ _id }) => ({ type: 'Bookings', id: _id }))
                    : ['Bookings'];
            },
            transformResponse: (response) => response?.data?.response,
        }),
        getBooking: builder.query({
            query: (bookingId) => ({
                url: '/booking/' + bookingId,
            }),
            providesTags: (result, error, arg) =>
                result ? [{ type: 'Bookings', id: result?._id }] : ['Bookings'],
            transformResponse: (response) => response?.data,
        }),
        createBooking: builder.mutation({
            query: ({ startDate, endDate, isBusy, userId, notes, discussionTypes }) => ({
                url: '/booking',
                method: 'POST',
                body: {
                    startDate,
                    endDate,
                    type: isBusy ? 2 : 1,
                    user: userId,
                    notes,
                    discussionTypes,
                },
            }),
            invalidatesTags: (result, error, arg) => (!error ? ['Bookings', 'Me'] : undefined),
        }),
        deleteBooking: builder.mutation({
            query: (bookingId) => ({
                url: '/booking/' + bookingId,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Bookings', id: arg }, 'Me'] : undefined,
        }),
        getDiscussionTypes: builder.query({
            query: (bookingId) => ({
                url: '/booking/discussionTypes',
            }),
            providesTags: (result, error, arg) =>
                result ? [{ type: 'Discussion-Types', id: result?._id }] : ['Discussion-Types'],
            transformResponse: (response) => response?.data?.discussionTypes,
        }),

        //#endregion

        //#region CHAT & MESSAGES

        getMyChats: builder.query({
            query: ({ skip, limit } = {}) => ({
                url: '/chats/mine',
                params: { skip, limit },
            }),
            providesTags: (result, error, arg) =>
                result?.length ? result.map(({ _id }) => ({ type: 'Chats', id: _id })) : ['Chats'],
            transformResponse: (response) => response?.data,
        }),
        getChatById: builder.query({
            query: (chatId) => ({
                url: `/chats/${chatId}`,
            }),
            providesTags: (result, error, arg) =>
                result ? [{ type: 'Chats', id: result?._id }] : ['Chats'],
            transformResponse: (response) => response?.data,
        }),
        getChatMessages: builder.query({
            query: ({ chatId, skip = 0, limit = 20 } = {}) => ({
                url: `/chats/${chatId}/messages`,
                params: { skip, limit },
            }),
            providesTags: (result, error, arg) => [{ type: 'Messages', id: arg.chatId }],
            transformResponse: (response) => [response?.data.reverse(), response?.total],
        }),

        getMessageById: builder.query({
            query: (messageId) => ({
                url: `/messages/${messageId}`,
            }),
            providesTags: (result, error, arg) => [{ type: 'Messages', id: result?.chat }],
            transformResponse: (response) => response?.data,
        }),
        sendMessage: builder.mutation({
            query: ({ userId, text, fileURLs, chatId, limit }) => ({
                url: '/messages',
                method: 'POST',
                body: {
                    user: userId,
                    message: {
                        text,
                        fileURLs,
                    },
                },
            }),
            async onQueryStarted({ chatId, limit }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedMessage } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('getChatMessages', { chatId, limit }, (draft) => {
                            draft.push(updatedMessage?.data);
                        }),
                    );
                } catch {}
            },
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Messages', id: arg?.chatId }, 'Chats'] : undefined,
        }),
        updateMessage: builder.mutation({
            query: ({ messageId, text, chatId, limit }) => ({
                url: '/messages/' + messageId,
                method: 'PATCH',
                body: {
                    text,
                },
            }),
            async onQueryStarted({ chatId, limit }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedMessage } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('getChatMessages', { chatId, limit }, (draft) => {
                            const index = draft.findIndex(
                                (v) => v._id === updatedMessage?.data?._id,
                            );
                            draft[index] = updatedMessage?.data;
                        }),
                    );
                } catch {}
            },
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Messages', id: arg?.chatId }, 'Chats'] : undefined,
        }),
        markMessageRead: builder.mutation({
            query: ({ messageIds, chatId }) => ({
                url: '/messages/read',
                method: 'PATCH',
                body: {
                    messages: messageIds,
                },
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Messages', id: arg?.chatId }, 'Chats'] : undefined,
        }),
        deleteMessage: builder.mutation({
            query: ({ messageId, chatId, limit }) => ({
                url: '/messages/' + messageId,
                method: 'DELETE',
            }),
            async onQueryStarted({ chatId, limit }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedMessage } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('getChatMessages', { chatId, limit }, (draft) => {
                            const index = draft.findIndex(
                                (v) => v._id === updatedMessage?.data?._id,
                            );
                            draft[index] = updatedMessage?.data;
                        }),
                    );
                } catch {}
            },
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Messages', id: arg?.chatId }, 'Chats'] : undefined,
        }),
        uploadMessageFile: builder.mutation({
            query: (file) => ({
                url: '/messages/upload',
                method: 'POST',
                body: toFormData({ file }),
            }),
            // providesTags: (result, error, arg) => [{ type: 'ChatFiles', id: result?.fileName }],
            // invalidatesTags: (result, error, arg) =>
            //     !error ? [{ type: 'Messages', id: arg?.chatId }] : undefined,
            transformResponse: (response) => response?.data,
        }),

        //#endregion

        //#region RESURVEY

        getResurveys: builder.query({
            query: () => '/resurveys',
            providesTags: (result) =>
                result?.length
                    ? result.map(({ _id }) => ({ type: 'Resurveys', id: _id }))
                    : ['Resurveys'],
            transformResponse: (response) => response?.data,
        }),
        getUserResurveys: builder.query({
            query: (userId) => `/users/${userId}/resurveys`,
            providesTags: (result) =>
                result?.length
                    ? result.map(({ _id }) => ({ type: 'Resurveys', id: _id }))
                    : ['Resurveys'],
            transformResponse: (response) => response?.data,
        }),
        getResurvey: builder.query({
            query: (resurveyId) => '/resurveys/' + resurveyId,
            providesTags: (result, error, arg) =>
                result ? [{ type: 'Resurveys', id: result?._id }] : ['Resurveys'],
            transformResponse: (response) => response?.data,
        }),
        updateResurvey: builder.mutation({
            query: ({
                _id: resurveyId,

                focusNext,
                favouriteExercises,
                leastFavouriteExercises,
                unavailabilityToTrain,
                bestDays,
                significantChanges,
                overallExperience,
                anyChanges,
                otherFeatures,
                recommendToFriends,
                recommendToFriendsReason,

                isMandatory,
                read,
                skipped,

                noRefetch,
            }) => ({
                url: '/resurveys/' + resurveyId,
                method: 'PATCH',
                body: {
                    focusNext,
                    favouriteExercises,
                    leastFavouriteExercises,
                    unavailabilityToTrain,
                    bestDays,
                    significantChanges,
                    overallExperience,
                    anyChanges,
                    otherFeatures,
                    recommendToFriends,
                    recommendToFriendsReason,

                    isMandatory,
                    read,
                    skipped,
                },
            }),
            invalidatesTags: (result, error, arg) => {
                if (arg?.noRefetch) return undefined;
                return !error ? [{ type: 'Resurveys', id: arg?._id }, 'Me'] : undefined;
            },
        }),
        deleteResurvey_DANGEROUS: builder.mutation({
            query: (resurveyId) => ({
                url: '/resurveys' + resurveyId,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Resurveys', id: arg }] : undefined,
        }),

        //#endregion

        //#region EXERCISES

        //#region RESOURCES
        addResource: builder.mutation({
            query: ({ title, text, category, isPublic, file, newFile }) => ({
                url: '/resources',
                method: 'POST',
                body: toFormData({
                    title,
                    text,
                    category,
                    isPublic,
                    file: newFile?.[0] || file || undefined,
                }),
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? ['Resources', 'User-Resources'] : undefined,
        }),
        updateResource: builder.mutation({
            query: ({ _id, title, text, category, isPublic, file, newFile }) => ({
                url: `/resources/${_id}`,
                method: 'PATCH',
                body: toFormData({
                    title,
                    text,
                    category,
                    isPublic,
                    file: newFile?.[0] || file || undefined,
                    deleteFile: newFile === '',
                }),
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Resources', id: arg._id }, 'User-Resources'] : undefined,
        }),
        deleteResource: builder.mutation({
            query: (id) => ({
                url: `/resources/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'Resources', id: arg }, 'User-Resources'] : undefined,
        }),
        getResourcesCategories: builder.query({
            query: () => '/resources/categories',
            providesTags: ['Resources'],
            transformResponse: (response) => response?.data,
        }),
        getResources: builder.query({
            query: () => '/resources',
            providesTags: (result) =>
                result?.length
                    ? result.map(({ _id }) => ({ type: 'Resources', id: _id }))
                    : ['Resources'],
            transformResponse: (response) => response?.data,
        }),
        getResourceById: builder.query({
            query: (id) => `/resources/${id}`,
            providesTags: (result, error, arg) => [{ type: 'Resources', id: arg }],
            transformResponse: (response) => response?.data,
        }),
        getUserResources: builder.query({
            query: (userId) => `/users/${userId}/resources`,
            providesTags: (result, error, arg) =>
                result?.length
                    ? result
                          .map(({ _id }) => ({ type: 'Resources', id: _id }))
                          .concat([{ type: 'User-Resources', id: arg }])
                    : ['Resources'],
            transformResponse: (response) => response?.data?.resources,
        }),
        assignResources: builder.mutation({
            query: ({ userId, resources }) => ({
                url: `/users/${userId}/resources`,
                method: 'PATCH',
                body: {
                    resources,
                },
            }),
            invalidatesTags: (result, error, arg) =>
                !error ? [{ type: 'User-Resources', id: arg?.userId }] : undefined,
        }),
        //#endregion
    }),
});

export const {
    util,

    // products
    useGetProductsQuery,
    useGetSubscriptionsQuery,
    useCreateCheckoutSessionMutation,
    useGetActiveSubscriptionsQuery,
    useCancelSubscriptionMutation,

    // me
    useGetMeQuery,
    useUpdateMeMutation,
    useUpdateMyPassMutation,
    useDeactivateMeMutation,
    useDeleteMeMutation,

    useGetAWSCredentialsQuery,
    useInvalidateAWSCredentialsMutation,

    // users
    useGetUsersQuery,
    useGetUserByIdQuery,
    useUpdateUserMutation,
    useDeleteUserMutation,

    // auth
    useLoginMutation,
    useRegisterMutation,
    useActivateAccountMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useLogoutQuery,

    // exercises
    useGetCategoriesQuery,
    useGetExercisesQuery,
    useGetExerciseByIdQuery,
    useUpdateExerciseMutation,
    useAddExerciseMutation,
    useDeleteExerciseMutation,

    // program / blocks
    useGetBlocksByUserIdQuery,
    useGetMyBlocksQuery,

    useGetBlockByIdQuery,
    useAddBlockMutation,
    useUpdateBlockMutation,
    useDeleteBlockMutation,

    // logs
    useGetLogsByBlockIdQuery,
    useGetLogsByUserIdQuery,
    useGetLogByIdQuery,
    useAddLogMutation,
    useUpdateLogMutation,
    useDeleteLogMutation,

    // group notes
    useGetGroupNotesByBlockIdQuery,
    useGetGroupNotesByUserIdQuery,
    useGetGroupNoteByIdQuery,
    useGetGroupNotesQuery,
    useAddGroupNoteMutation,
    useUpdateGroupNoteMutation,
    useDeleteGroupNoteMutation,

    // profile
    useGetProfileQuery,
    useGetMyProfileQuery,
    useCreateMyProfileMutation,
    useUpdateMyProfileMutation,

    // calendar
    useGetCalendarQuery,
    useUpdateCalendarMutation,

    // bookings
    useGetBookingsQuery,
    useGetBookingQuery,
    useCreateBookingMutation,
    useDeleteBookingMutation,
    useGetDiscussionTypesQuery,

    // chat & messages
    useGetMyChatsQuery,
    useGetChatByIdQuery,
    useGetChatMessagesQuery,
    useGetMessageByIdQuery,
    useSendMessageMutation,
    useUpdateMessageMutation,
    useMarkMessageReadMutation,
    useDeleteMessageMutation,
    useUploadMessageFileMutation,

    // resurveys
    useGetResurveysQuery,
    useGetUserResurveysQuery,
    useGetResurveyQuery,
    useUpdateResurveyMutation,

    // exercises
    useGetResourcesCategoriesQuery,
    useGetResourcesQuery,
    useGetResourceByIdQuery,
    useGetUserResourcesQuery,
    useUpdateResourceMutation,
    useAddResourceMutation,
    useDeleteResourceMutation,
    useAssignResourcesMutation,
} = api;

export const { useQueryState: useGetMeState } = api.endpoints.getMe;
export const { useQueryState: useGetMyChatsState } = api.endpoints.getMyChats;
export const { useQueryState: useGetChatMessagesState } = api.endpoints.getChatMessages;
export const { useQueryState: useGetExerciseByIdState } = api.endpoints.getExerciseById;
export const { useQueryState: useGetAWSCredentialsState } = api.endpoints.getAWSCredentials;

export default api;
