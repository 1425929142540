import { Fragment } from 'react';
import { Switch as RouterSwitch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { Stack, Paper } from '@mui/material';
import { makePath } from 'routes/utils';
import { TabbedPageMenu } from 'common';

function TabbedPage({ pages, children, componentProps = {} }) {
    const match = useRouteMatch();

    return (
        <Fragment>
            <Stack spacing={3} overflow="hidden">
                {children}

                <div>
                    <TabbedPageMenu pages={pages} />
                </div>
            </Stack>

            <RouterSwitch>
                {pages.map(({ id, Component, props = {}, PaperProps = {} }) => (
                    <Route key={id} path={makePath(match.url, id)}>
                        <Paper
                            sx={{
                                p: {
                                    xs: 1.5,
                                    sm: 3,
                                },
                                mt: 2,
                                ...PaperProps,
                            }}
                        >
                            <Component {...componentProps} {...props} />
                        </Paper>
                    </Route>
                ))}
                <Redirect to={makePath(match.url, pages[0].id)} />
            </RouterSwitch>
        </Fragment>
    );
}

export default TabbedPage;
