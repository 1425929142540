import { Fragment, useCallback, useState } from 'react';
import { Box, TextField, Stack, Button, InputAdornment, Tooltip, IconButton } from '@mui/material';

import { VideoBox, VideoDialog } from 'common';

function VideoFileControl({ existingValue, form, name, ...props }) {
    const [dialogVideo, setDialogVideo] = useState(false);
    const { registerField, watch, setValue } = form;

    const newVideoValue = watch(name, false);
    const setVideoValue = useCallback((val) => setValue(name, val), [name, setValue]);

    const handleVideoClick = (val) => (e) => {
        setDialogVideo(val);
    };
    const handleVideoClose = (e) => {
        setDialogVideo(false);
    };

    const videoChanged = (newVideoValue?.length ?? 0) !== 0 || newVideoValue === '';

    return (
        <Fragment>
            <VideoDialog currentVideo={dialogVideo} onClose={handleVideoClose} />
            <Box
                display="flex"
                pb={1}
                maxWidth={{
                    xs: '100%',
                    sm: '50%',
                }}
                width={{
                    xs: '100%',
                    sm: 'initial',
                }}
                flexDirection={{
                    xs: 'column',
                    sm: 'row',
                }}
                alignItems={!existingValue || videoChanged ? 'flex-start' : 'center'}
                {...props}
            >
                {videoChanged && (
                    <TextField
                        label={newVideoValue === '' ? 'Video deleted' : 'New video file'}
                        disabled
                        value={newVideoValue?.[0]?.name}
                        error={newVideoValue === ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title="Undo changes to video" placement="top">
                                        <IconButton onClick={() => setVideoValue(undefined)}>
                                            <i className="fas fa-undo fa-xs fa-sq" />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                {!!existingValue && !videoChanged && (
                    <Box
                        width={{
                            xs: '100%',
                            sm: '70px',
                        }}
                        minWidth="70px"
                        mr={{
                            sm: 2,
                        }}
                        mb={{
                            xs: 2,
                            sm: 0,
                        }}
                    >
                        <VideoBox
                            onClick={handleVideoClick}
                            width="100%"
                            video={existingValue}
                            disableVideo
                        />
                    </Box>
                )}
                {!videoChanged && (
                    <Stack
                        direction="row"
                        spacing={2}
                        alignSelf={!existingValue ? 'flex-start' : undefined}
                    >
                        <label>
                            <input
                                {...registerField(name, {
                                    noErrorHelpers: true,
                                    refKey: 'ref',
                                })}
                                accept="video/*"
                                type="file"
                                style={{ display: 'none' }}
                            />
                            <Button
                                variant={!existingValue ? 'contained' : 'outlined'}
                                component="span"
                                size="large"
                                color={!existingValue ? 'primary' : 'info'}
                                sx={{
                                    whiteSpace: {
                                        xs: 'initial',
                                        sm: 'nowrap',
                                    },
                                    textAlign: 'center',
                                }}
                            >
                                {!existingValue ? 'Add video' : 'Change video'}
                            </Button>
                        </label>
                        {!!existingValue && (
                            <Button
                                variant="outlined"
                                component="span"
                                size="large"
                                color="error"
                                sx={{
                                    whiteSpace: {
                                        xs: 'initial',
                                        sm: 'nowrap',
                                    },
                                    textAlign: 'center',
                                }}
                                onClick={() => setVideoValue('')}
                            >
                                Delete video
                            </Button>
                        )}
                    </Stack>
                )}
            </Box>
        </Fragment>
    );
}

export default VideoFileControl;
