import { Fragment } from 'react';
import { MenuItem } from '@mui/material';
import { Select } from 'form';
import { BOOKING_HOURS } from '@constants';
import { getTimeInTimezone, makeDateWithUTCTime } from 'helpers';

function HoursDropdown({ emptyLabel = 'Select one', ...props }) {
    return (
        <Fragment>
            <Select required size="small" fullWidth {...props}>
                <MenuItem value="">{emptyLabel}</MenuItem>
                {BOOKING_HOURS.map((v) => {
                    const value = `${('00' + v).slice(-2)}:00`;
                    return (
                        <MenuItem key={v} value={value}>
                            {getTimeInTimezone(makeDateWithUTCTime(value))}
                        </MenuItem>
                    );
                })}
            </Select>
        </Fragment>
    );
}

export default HoursDropdown;
