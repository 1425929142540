import { AuthLayout, AgreementContent } from 'components/Auth';
import { Link } from 'routes';

import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useLoginMutation } from 'api';
import * as yup from 'yup';
import { useAutoNotify, useForm } from 'hooks';
import { useEffect } from 'react';
import { track } from 'helpers';

const schema = yup
    .object({
        email: yup.string().email().required(),
        password: yup.string().required(),
    })
    .required();

const SUBMIT_LABEL = 'Login';

function Login() {
    const { handleSubmit, registerField } = useForm(schema);
    const [login, { error, isLoading, isSuccess }] = useLoginMutation();
    useAutoNotify(error, isSuccess, 'Login successful.');

    const onSubmit = (data) => login(data);

    useEffect(() => {
        if (isSuccess) track({ fb: ['CompleteLogin'], customFb: true, ga: ['login'] });
    }, [isSuccess]);

    return (
        <AuthLayout agreementContent={<AgreementContent submitLabel={SUBMIT_LABEL} />}>
            <Box mt={6}>
                <Typography variant="h4" component="h1">
                    Login
                </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box mt={3}>
                    <TextField {...registerField('email')} fullWidth label="Email" type="email" />
                </Box>
                <Box mt={3}>
                    <TextField
                        {...registerField('password')}
                        fullWidth
                        label="Password"
                        type="password"
                    />
                </Box>
                <Box mt={1} textAlign="right">
                    <Link to="/forgot-password">Forgot Password?</Link>
                </Box>
                <Box mt={4}>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        size="large"
                        fullWidth
                        type="submit"
                    >
                        {SUBMIT_LABEL}
                    </LoadingButton>
                </Box>
            </form>
        </AuthLayout>
    );
}

export default Login;
