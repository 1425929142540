import { useGetUserResurveysQuery } from 'api';
import { SurveyDialog } from 'components/Reconsultation';
import { differenceInDays, isSameDay } from 'date-fns';
import { useAutoNotify, useMe } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

const STAGES = [-7, -2, 0];

function CurrentFollowUp() {
    const [currentSurveyId, setCurrentSurveyId] = useState(false);
    const [edit, setEdit] = useState(false);

    const me = useMe();
    const userId = me?._id;

    const { data, error } = useGetUserResurveysQuery(userId, { skip: !userId });
    useAutoNotify(error);

    const latestSurvey = useMemo(() => {
        return data?.[0];
    }, [data]);

    const skipped = useMemo(() => {
        if (!latestSurvey) return true;
        if (!latestSurvey?.skipped) return false;
        if (latestSurvey?.skipped && !latestSurvey?.isMandatory) return true;

        const currentDate = new Date();
        const skippedDate = new Date(latestSurvey?.skipped);
        const deadline = new Date(latestSurvey?.expiresIn);
        const currDiff = differenceInDays(currentDate, deadline);
        const skipDiff = differenceInDays(skippedDate, deadline);

        if (currDiff < STAGES[1]) return true;
        if (currDiff < STAGES[2]) return skipDiff >= STAGES[1];
        return isSameDay(currentDate, skippedDate);
    }, [latestSurvey]);

    const showSurvey = me?.needsResurvey && !skipped;

    useEffect(() => {
        if (showSurvey && latestSurvey?._id) {
            setEdit(true);
            setCurrentSurveyId(latestSurvey?._id);
        }
    }, [latestSurvey?._id, showSurvey]);

    const handleEdit = useCallback(() => {
        setEdit(true);
    }, []);

    const handleEditSuccess = useCallback(() => {
        setEdit(false);
    }, []);

    const handleCloseSurvey = useCallback(
        (e, reason) => {
            if (!!reason && edit) return;

            setCurrentSurveyId(false);
        },
        [edit],
    );

    return (
        <SurveyDialog
            id={currentSurveyId}
            onClose={handleCloseSurvey}
            edit={edit}
            onSuccess={handleEditSuccess}
            onEdit={handleEdit}
        />
    );
}

export default CurrentFollowUp;
