import { Fragment, useState, useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';

import {
    Stack,
    Paper,
    Button,
    TextField,
    FormLabel,
    Collapse,
    Typography,
    Box,
    Alert,
    useMediaQuery,
} from '@mui/material';

import { useSubForm } from 'hooks';
import { EdgeButton } from 'common';
import { DAYS } from '@constants';
import { BlockFormExercise } from 'components/Program';
import { Switch, Switches } from 'form';

function BlockFormGroup({ index, form, onDelete }) {
    const [open, setOpen] = useState(true);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isMediumDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const isLargeDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const subForm = useSubForm('groups', index, form);

    const { registerSubField, watchSubField, control, getName, getSubError, getError } = subForm;

    const { fields, append, remove } = useFieldArray({
        control,
        name: getName('exercises'),
        keyName: 'fieldId',
    });

    const title = watchSubField('title');
    const exercisesError = getSubError('exercises');

    const handleAdd = useCallback(
        (e) =>
            append({
                exerciseId: null,
                intensity: '',
                repetitions: '',
                restTime: '',
                sets: '',
                tempo: '',
                notes: '',
            }),
        [append],
    );

    const handleRemove = useCallback((i) => (e) => remove(i), [remove]);

    return (
        <Fragment>
            <Paper
                variant="outlined"
                sx={{
                    p: 2,
                    pb: open ? 4 : 3,
                    position: 'relative',
                    borderColor: open ? 'grey.300' : undefined,
                    bgcolor: '#fafafa',
                    boxShadow: open ? 3 : undefined,
                    transition: '0.3s box-shadow, 0.2s border-color',

                    '&:hover': {
                        borderColor: 'grey.400',
                    },
                }}
            >
                <Collapse in={!open}>
                    <Stack pt={1}>
                        <Typography variant="overline" lineHeight="1em" mb={3 / 4}>
                            Group #{index + 1}
                        </Typography>
                        <Typography variant="subtitle1">{title || 'Untitled'}</Typography>
                    </Stack>
                </Collapse>
                <Collapse in={open}>
                    <Stack spacing={2} pt={1}>
                        <Stack
                            direction={{
                                xs: 'column',
                                md: 'row',
                            }}
                            spacing={2}
                        >
                            <TextField label="Name *" fullWidth {...registerSubField('title')} />
                            <TextField label="Goals" fullWidth {...registerSubField('goals')} />
                        </Stack>
                        <div>
                            <Switch
                                name={getName('isCorrective')}
                                control={control}
                                rightLabel="Corrective group"
                            />
                        </div>
                        <Switches
                            label="Days *"
                            name={getName('days')}
                            control={control}
                            getError={getError}
                            options={DAYS}
                            direction={isMediumDown ? 'column' : 'row'}
                            spacing={isMediumDown ? 1 / 2 : undefined}
                            verticalSpacing={isMediumDown ? undefined : 1 / 2}
                        />
                        <div>
                            <FormLabel component="legend" sx={{ mt: 1, mb: 2 }}>
                                Exercises *
                            </FormLabel>

                            {exercisesError && !fields.length && (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    {exercisesError}
                                </Alert>
                            )}

                            <Stack spacing={isLargeDown ? 5 : 2}>
                                {fields.map((v, i) => (
                                    <BlockFormExercise
                                        key={v.fieldId}
                                        index={i}
                                        form={subForm}
                                        onDelete={handleRemove(i)}
                                    />
                                ))}
                            </Stack>
                            <Button
                                onClick={handleAdd}
                                sx={{ mt: fields.length ? 2 : 0, alignSelf: 'flex-start' }}
                                variant="contained"
                            >
                                Add exercise
                            </Button>
                        </div>
                        <TextField
                            {...registerSubField('notes')}
                            label="Notes"
                            multiline
                            minRows={2}
                            fullWidth
                        />
                    </Stack>
                </Collapse>

                <Box
                    sx={{
                        position: 'absolute',
                        bottom: -16,
                        left: (t) => t.spacing(isSmall ? 1 : 2),
                        right: (t) => t.spacing(isSmall ? 1 : 2),
                    }}
                >
                    <Stack direction="row" spacing={1 / 2} justifyContent="space-between">
                        <EdgeButton
                            icon={`fas fa-angle-down ${open ? 'fa-rotate-180' : ''}`}
                            onClick={(e) => setOpen((v) => !v)}
                        >
                            {open ? 'Close' : 'Open'}
                        </EdgeButton>
                        <EdgeButton
                            icon={'fas fa-trash fa-xs'}
                            onClick={onDelete}
                            color="error.dark"
                            whiteSpace="nowrap"
                        >
                            Remove group
                        </EdgeButton>
                    </Stack>
                </Box>
            </Paper>
        </Fragment>
    );
}

export default BlockFormGroup;
