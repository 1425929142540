import { useMemo } from 'react';
import { Alert, AlertTitle, Grid, Stack, Typography } from '@mui/material';
import { Card, S3VideoBox } from 'common';
import { format } from 'date-fns';

function ClientProfileView({ data }) {
    const [bmi, bmiLabel] = useMemo(() => {
        if (!data?.weight || !data?.height) return [];

        const metricHeight = Math.round(data.height);
        const metricWeight = Math.round(data.weight * 10) / 10;

        const value = Math.round((metricWeight / metricHeight / metricHeight) * 100000) / 10;
        let label;

        if (isNaN(value)) {
            label = false;
        } else if (value < 18.5) {
            label = 'underweight';
        } else if (value < 25) {
            label = 'normal';
        } else if (value < 30) {
            label = 'overweight';
        } else {
            label = 'obese';
        }

        return [value, label];
    }, [data?.weight, data?.height]);

    const [whr, whrLabel] = useMemo(() => {
        if (!data?.waist || !data?.hips) return [];

        const metricWaist = Math.round(data.waist);
        const metricHips = Math.round(data.hips);

        const value = Math.round((metricWaist / metricHips) * 100) / 100;
        let label;

        if (isNaN(value)) {
            label = false;
        } else if (value < 1) {
            label = 'is within moderate range';
        } else {
            label =
                'has an increased risk of heart disease and other conditions that are linked to being overweight';
        }

        return [value, label];
    }, [data?.waist, data?.hips]);

    if (!data) return <Typography>There has been an error.</Typography>;

    return (
        <Stack spacing={5}>
            <div>
                <Typography variant="h5">General info</Typography>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Date of Birth">
                            <Typography>{format(new Date(data.DOB), 'd MMM. y')}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Gender">
                            <Typography>{data.gender}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Sleeping habits">
                            <Typography>{data.sleepingHabits}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Water intake">
                            <Typography>{data.waterIntake}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Occupation">
                            <Typography>{data.occupation}</Typography>
                        </Card>
                    </Grid>
                </Grid>
            </div>

            <div>
                <Typography variant="h5">Anthropometric measures</Typography>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Height">
                            <Typography>{data.height} cm</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Weight">
                            <Typography>{data.weight} kg</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {!isNaN(bmi) && bmi > 0 && bmi < Infinity && (
                            <Alert severity="info" sx={{ height: '100%' }}>
                                <AlertTitle>
                                    The Body Mass Index (BMI) is <b>{bmi}</b>
                                </AlertTitle>
                                This result suggests your client is within the <b>{bmiLabel}</b>{' '}
                                range.
                            </Alert>
                        )}
                    </Grid>
                </Grid>

                <Typography mt={3}>Body composition</Typography>

                <Grid container spacing={2} sx={{ mt: 0 }} alignContent="stretch">
                    <Grid item xs={12} sm={5} md={4} xl={2}>
                        <Card label="Lean Body Mass">
                            <Typography>{data.leanBodyMass} kg</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={7} md={8} xl={4}>
                        <Card label="Lean Body Mass Measurement Method">
                            <Typography>{data.leanBodyMassMeasurement}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={5} md={4} xl={2}>
                        <Card label="Fat Mass">
                            <Typography>{data.fatMass} kg</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={7} md={8} xl={4}>
                        <Card label="Fat Mass Measurement Method">
                            <Typography>{data.fatMassMeasurement}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={5} md={4} xl={2}>
                        <Card label="Fat Distribution">
                            <Typography>{data.fatDistribution} kg</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={7} md={8} xl={4}>
                        <Card label="Fat Distribution Measurement Method">
                            <Typography>{data.fatDistributionMeasurement}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={5} md={4} xl={2}>
                        <Card label="Body Fat Percentage">
                            <Typography>{data.bodyFatPercentage} %</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={7} md={8} xl={4}>
                        <Card label="Body Fat Percentage Measurement Method">
                            <Typography>{data.bodyFatPercentageMeasurement}</Typography>
                        </Card>
                    </Grid>
                    {data.otherBodyComposition && (
                        <Grid item xs={12}>
                            <Card label="Other">
                                <Typography>{data.otherBodyComposition}</Typography>
                            </Card>
                        </Grid>
                    )}
                </Grid>

                <Typography mt={3}>Circumference measurement</Typography>

                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Waist">
                            <Typography>{data.waist} cm</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Hips">
                            <Typography>{data.hips} cm</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Neck">
                            <Typography>{data.neck} cm</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Chest">
                            <Typography>{data.chest} cm</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Upper Arms">
                            <Typography>{data.upperArms} cm</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Thighs">
                            <Typography>{data.thighs} cm</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card label="Calves">
                            <Typography>{data.calves} cm</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} xl={10}>
                        {!isNaN(whr) && whr > 0 && whr < Infinity && (
                            <Alert severity="info" sx={{ height: '100%' }}>
                                <AlertTitle>
                                    The Waist-to-Hip Ratio (WHR) is <b>{whr}</b>
                                </AlertTitle>
                                This result suggests your client {whrLabel}.
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            </div>

            <div>
                <Typography variant="h5">Training History</Typography>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12} lg={6}>
                        <Card label="History of training activity">
                            <Typography>{data.trainingHistory}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card label="Injury history">
                            <Typography>{data.injuryHistory}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card label="Repetition maximums">
                            <Typography>{data.strength}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card label="VO2 Max">
                            <Typography>{data.vo2}</Typography>
                        </Card>
                    </Grid>
                </Grid>
            </div>

            <div>
                <Typography variant="h5">PAR-Q</Typography>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <Card label="Has your doctor ever said you have a heart condition and that you should only do pshysical activity recommended by a doctor?">
                            <Typography>
                                {data.doctorHeartCondition ? (
                                    <Typography color="error" component="span">
                                        Yes
                                    </Typography>
                                ) : (
                                    'No'
                                )}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card label="Do you feel pain in your chest when you do physical activity?">
                            <Typography>
                                {data.chestPainActivity ? (
                                    <Typography color="error" component="span">
                                        Yes
                                    </Typography>
                                ) : (
                                    'No'
                                )}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card label="In the past month, have you had a chest pain when you were not doing physical activity?">
                            <Typography>
                                {data.chestPainNoActivity ? (
                                    <Typography color="error" component="span">
                                        Yes
                                    </Typography>
                                ) : (
                                    'No'
                                )}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card label="Do you lose balance because of dizziness or do you ever lose consciousness?">
                            <Typography>
                                {data.loseBalance ? (
                                    <Typography color="error" component="span">
                                        Yes
                                    </Typography>
                                ) : (
                                    'No'
                                )}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card label="Do you have a bone or joint problem (for example back, knee, hip) that could be made worse by a change in your physical activity?">
                            <Typography>
                                {data.jointProblem ? (
                                    <Typography color="error" component="span">
                                        Yes
                                    </Typography>
                                ) : (
                                    'No'
                                )}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card label="Is your doctor currently prescribing medication for your blood pressure or heart condition?">
                            <Typography>
                                {data.medication ? (
                                    <Typography color="error" component="span">
                                        Yes
                                    </Typography>
                                ) : (
                                    'No'
                                )}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card label="Do you know of any other reason why you should not take part in physical activity?">
                            <Typography>
                                {data.otherReasonNoActivity ? (
                                    <Typography color="error" component="span">
                                        Yes
                                    </Typography>
                                ) : (
                                    'No'
                                )}
                            </Typography>
                        </Card>
                    </Grid>
                    {data.otherReasonText && (
                        <Grid item xs={12}>
                            <Card label="Other reasons why you think you shouldn't take part in physical activity">
                                <Typography>{data.otherReasonText}</Typography>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </div>

            <div>
                <Typography variant="h5">Goals</Typography>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12} lg={6}>
                        <Card label="What are your short and long-term goals?">
                            <Typography>{data.goals.join(', ')}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card label="Other goals">
                            <Typography>{data.otherGoals}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card label="Diet">
                            <Typography>{data.diet}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card label="Most recent exercise program">
                            <Typography>{data.otherExerciseProgram}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card label="How many times per week are you able to train?">
                            <Typography>{data.trainDuration} times/week</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card label="How much time do you have available per training session?">
                            <Typography>{data.sessionTime} hours</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card label="What's the breakdown of your training sessions?">
                            <Typography>{data.otherExerciseProgram}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card label="What equipment do you have available?">
                            <Typography>{data.otherExerciseProgram}</Typography>
                        </Card>
                    </Grid>
                </Grid>
            </div>

            <div>
                <Typography variant="h5">Visual check</Typography>
                <S3VideoBox width="100%" maxWidth={500} mt={2} video={data?.videoURL} controls />
            </div>
        </Stack>
    );
}

export default ClientProfileView;
