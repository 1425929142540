import { Stack, TextField, Typography } from '@mui/material';
import { Fragment } from 'react';

function Step1({ form }) {
    const { registerField } = form;

    return (
        <Fragment>
            <Stack spacing={3}>
                <Stack spacing={1}>
                    <Typography>
                        What would you like to focus on in the next phase of your program? *
                    </Typography>
                    <TextField
                        {...registerField('focusNext')}
                        fullWidth
                        type="text"
                        multiline
                        minRows={1}
                    />
                </Stack>
                <Stack spacing={1}>
                    <Typography>
                        What were your favourite exercises from the past phase? *
                    </Typography>
                    <TextField
                        {...registerField('favouriteExercises')}
                        fullWidth
                        type="text"
                        multiline
                        minRows={1}
                    />
                </Stack>
                <Stack spacing={1}>
                    <Typography>What were your least favourite exercises and why? *</Typography>
                    <TextField
                        {...registerField('leastFavouriteExercises')}
                        fullWidth
                        type="text"
                        multiline
                        minRows={1}
                    />
                </Stack>
                <Stack spacing={1}>
                    <Typography>When will you be unable to train or commit properly? *</Typography>
                    <TextField
                        {...registerField('unavailabilityToTrain', {
                            helperText:
                                'List any time periods during which you won’t be available to train, or it would be difficult to commit',
                        })}
                        fullWidth
                        type="text"
                        multiline
                        minRows={1}
                    />
                </Stack>
                <Stack spacing={1}>
                    <Typography>Which days would suit you best for training? *</Typography>
                    <TextField
                        {...registerField('bestDays')}
                        fullWidth
                        type="text"
                        multiline
                        minRows={1}
                    />
                </Stack>
                <Stack spacing={1}>
                    <Typography>Significant changes your last training cycle *</Typography>
                    <TextField
                        {...registerField('significantChanges', {
                            helperText:
                                'List any significant changes (moved to a different gym, different equipment, time constraints, new job, etc.)',
                        })}
                        fullWidth
                        type="text"
                        multiline
                        minRows={1}
                    />
                </Stack>
            </Stack>
        </Fragment>
    );
}

export default Step1;
