import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { DialogTitle, DialogContent, TextField, DialogActions, Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useUpdateExerciseMutation, useAddExerciseMutation } from 'api';
import { useAutoNotify, useForm } from 'hooks';
import { Autocomplete } from 'form';
import { VideoFileControl } from 'components/Exercises';

const schema = yup.object({
    name: yup.string().required(),
    description: yup.string(),
    categories: yup.array(),
    musclesWorked: yup.array(),
    video: yup.mixed().required(),
});

function ExerciseForm({
    data = { categories: [], musclesWorked: [] },
    categories: { categories, musclesWorked } = {},
    handleClose,
}) {
    const { action } = useParams();
    const edit = action === 'edit';

    const useMutation = edit ? useUpdateExerciseMutation : useAddExerciseMutation;
    const [submit, { error, isLoading, isSuccess }] = useMutation();
    useAutoNotify(error, isSuccess, `Exercise ${edit ? 'updated' : 'added'} successfully.`);

    const form = useForm(schema, { defaultValues: data });
    const { handleSubmit, registerField, getErrorProps, control } = form;

    const onSubmit = (newData) => submit(newData); // filtered in api file

    useEffect(() => {
        if (isSuccess) handleClose();
    }, [handleClose, isSuccess]);

    return (
        <Fragment>
            <DialogTitle>{edit ? 'Edit' : 'Add'} exercise</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Stack spacing={3} sx={{ display: 'flex', flexGrow: 1, mt: 3 }}>
                        <Stack
                            direction={{
                                xs: 'column',
                                md: 'row',
                            }}
                            spacing={3}
                            alignItems="flex-start"
                        >
                            <TextField
                                {...registerField('name')}
                                fullWidth
                                label="Name"
                                type="text"
                            />
                            <VideoFileControl
                                name="video"
                                existingValue={data?.videoURL}
                                form={form}
                            />
                        </Stack>

                        <Autocomplete
                            control={control}
                            name="categories"
                            options={categories}
                            multiple
                            disableClearable
                            label="Categories"
                            placeholder="Type to add categories"
                            getErrorProps={getErrorProps}
                        />
                        <Autocomplete
                            control={control}
                            name="musclesWorked"
                            options={musclesWorked}
                            multiple
                            freeSolo
                            disableClearable
                            label="Muscles worked"
                            placeholder="Type to add muscle types"
                            getErrorProps={getErrorProps}
                        />
                        <TextField
                            {...registerField('description')}
                            fullWidth
                            label="Description"
                            type="text"
                            multiline
                            minRows={3}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} type="submit">
                        {edit ? 'Save' : 'Add'}
                    </LoadingButton>
                </DialogActions>
            </form>
        </Fragment>
    );
}

export default ExerciseForm;
