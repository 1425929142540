import { PAGES_PATHS } from '@constants';
import { Fragment } from 'react';
import { Link } from 'routes';

function AgreementContent({ submitLabel = 'the submit button' }) {
    return (
        <Fragment>
            By clicking <b>{submitLabel}</b> you agree to our{' '}
            <Link to={PAGES_PATHS.TERMS_CONDITIONS} sx={{ display: 'inline' }}>
                Terms of service, privacy & cookie policies
            </Link>
            .
        </Fragment>
    );
}

export default AgreementContent;
