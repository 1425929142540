export const AUTH_PATHS = {
    LOGIN: '/login',
    REGISTER: '/register',
    ACTIVATE_ACCOUNT: '/activate-account/:token?',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password/:token?',
    LOGOUT: '/logout',
};

export const PAGES_PATHS = {
    TERMS_CONDITIONS: '/terms-and-conditions',
}

export const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const BOOKING_HOURS = Array.from({ length: 18 }, (_, i) => i + 5);
export const ALL_HOURS = Array.from({ length: 24 * 2 }, (_, i) => i);

export const TOPBAR_HEIGHT = {
    xs: 60,
    md: 90,
};
export const DATE_OPTIONS = { weekStartsOn: 1 };
