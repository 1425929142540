import { Controller } from 'react-hook-form';

import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    FormHelperText,
    Radio,
} from '@mui/material';

function CustomRadioGroup({ control, label, getError, name, items, direction = 'column' }) {
    const error = getError(name);

    return (
        <FormControl component="fieldset" error={!!error} variant="standard">
            <FormLabel component="legend">{label}</FormLabel>
            <Controller
                render={({ field: { value, ...field } }) => (
                    <RadioGroup value={value || null} {...field} sx={{ flexDirection: direction }}>
                        {items.map((v) => (
                            <FormControlLabel
                                key={v?.value || v}
                                value={v?.value || v}
                                control={<Radio />}
                                label={v?.label || v?.value || v}
                            />
                        ))}
                    </RadioGroup>
                )}
                name={name}
                control={control}
            />
            {!!error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}

export default CustomRadioGroup;
