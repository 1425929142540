import { Fragment, useState, useMemo, useCallback } from 'react';
import { isWithinInterval, eachWeekOfInterval, format, setMinutes } from 'date-fns';

import { useRouteMatch } from 'react-router-dom';

import {
    Stack,
    Typography,
    Paper,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@mui/material';

import { DATE_OPTIONS, DAYS } from '@constants';
import { useGetMyBlocksQuery, useGetLogsByBlockIdQuery, useGetGroupNotesByBlockIdQuery } from 'api';
import { useAutoNotify } from 'hooks';
import { Group } from 'components/Program';
import { ExerciseDialog } from 'components/Exercises';
import { setHours } from 'date-fns';
import { GroupNotesDialog, LogDialog } from 'components/Activity';
import { ScheduleDay } from 'components/Schedule';

// to={`${match?.url}/logs/add`}

function NoData() {
    return (
        <Paper sx={{ p: 3 }}>
            <Typography>No data yet.</Typography>
        </Paper>
    );
}

function CurrentSchedule() {
    const match = useRouteMatch();
    const [groupDialog, setGroupDialog] = useState(false);
    const [currentGroup, setCurrentGroup] = useState(false);
    const [blockIndex, setBlockIndex] = useState(false);
    const [selectedDate, setSelectedDate] = useState(false);
    const { data, error, isLoading, isSuccess } = useGetMyBlocksQuery();
    useAutoNotify(error);

    const [currentBlock, currentBlockIndex] = useMemo(() => {
        if (blockIndex !== false) return [data?.[blockIndex], blockIndex];

        const index = data?.findIndex((v) => {
            return isWithinInterval(new Date(), {
                start: setMinutes(setHours(new Date(v.startDate), 0), 0),
                end: setMinutes(setHours(new Date(v.endDate), 23), 59),
            });
        });
        return [data?.[index], index];
    }, [blockIndex, data]);

    const logs = useGetLogsByBlockIdQuery(currentBlock?._id, { skip: !currentBlock?._id });
    useAutoNotify(logs.error);

    const groupNotes = useGetGroupNotesByBlockIdQuery(currentBlock?._id, {
        skip: !currentBlock?._id,
    });
    useAutoNotify(groupNotes.error);

    const logsByDay = useMemo(
        () =>
            logs?.data?.reduce((a, v) => {
                if (v?.date) {
                    const key = format(new Date(v?.date), 'ddMMy');
                    if (a[key]) {
                        a[key].push(v);
                    } else {
                        a[key] = [v];
                    }
                }
                return a;
            }, {}),
        [logs?.data],
    );

    const groupNotesByDay = useMemo(
        () =>
            groupNotes?.data?.reduce((a, v) => {
                if (v?.date) {
                    const key = format(new Date(v?.date), 'ddMMy');
                    if (a[key]) {
                        a[key].push(v);
                    } else {
                        a[key] = [v];
                    }
                }
                return a;
            }, {}) || {},
        [groupNotes?.data],
    );

    const currentDayGroupNotes = useMemo(
        () => (selectedDate ? groupNotesByDay?.[format(selectedDate, 'ddMMy')] : false),
        [groupNotesByDay, selectedDate],
    );
    const getGroupNotesByGroupId = useCallback(
        (groupId) => currentDayGroupNotes?.filter((v) => v?.groupId === groupId),
        [currentDayGroupNotes],
    );

    const currentInterval = useMemo(
        () => ({
            start: setMinutes(setHours(new Date(currentBlock?.startDate), 0), 0),
            end: setMinutes(setHours(new Date(currentBlock?.endDate), 23), 59),
        }),
        [currentBlock?.endDate, currentBlock?.startDate],
    );

    const weeks = useMemo(
        () => (currentBlock ? eachWeekOfInterval(currentInterval, DATE_OPTIONS) : false),
        [currentBlock, currentInterval],
    );

    if (isLoading || logs?.isLoading) return <LinearProgress />;
    if (isSuccess && !currentBlock?._id) return <NoData />;
    if (!isSuccess || !logs?.isSuccess) return <Typography>There has been an error.</Typography>;
    if (!weeks?.length) return <NoData />;

    const handleViewGroup = (group, currentDay) => (e) => {
        setCurrentGroup(group);
        setGroupDialog(true);
        setSelectedDate(currentDay);
    };
    const handleGroupClose = () => {
        setGroupDialog(false);
    };
    const handleExited = () => {
        setCurrentGroup(false);
        setSelectedDate(false);
    };

    return (
        <Fragment>
            <Dialog
                open={groupDialog}
                maxWidth="sm"
                fullWidth
                onClose={handleGroupClose}
                TransitionProps={{ onExited: handleExited }}
                scroll="body"
            >
                <DialogTitle>Group preview</DialogTitle>
                <DialogContent>
                    {!!currentGroup && (
                        <Group
                            data={currentGroup}
                            selectedDate={selectedDate}
                            defaultOpen
                            logs={logsByDay?.[format(selectedDate, 'ddMMy')]}
                            groupNotes={getGroupNotesByGroupId(currentGroup._id)}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleGroupClose}>Close</Button>
                </DialogActions>
            </Dialog>

            <ExerciseDialog />
            <LogDialog parentPath={match?.url} block={currentBlock} selectedDate={selectedDate} />
            <GroupNotesDialog
                parentPath={match?.url}
                block={currentBlock}
                selectedDate={selectedDate}
            />

            {data?.length > 1 && (
                <Stack direction="row" spacing={1} mb={2}>
                    <Button
                        variant="contained"
                        color="light"
                        onClick={(e) => setBlockIndex(Math.max(currentBlockIndex - 1, 0))}
                        disabled={!currentBlockIndex}
                    >
                        <i className="fas fa-angle-left" />
                    </Button>
                    <Button variant="contained" color="light" onClick={(e) => setBlockIndex(false)}>
                        Current block
                    </Button>
                    <Button
                        variant="contained"
                        color="light"
                        onClick={(e) =>
                            setBlockIndex(Math.min(currentBlockIndex + 1, data?.length - 1))
                        }
                        disabled={currentBlockIndex === data?.length - 1}
                    >
                        <i className="fas fa-angle-right" />
                    </Button>
                </Stack>
            )}

            <TableContainer component={Paper} sx={{ p: 1, maxWidth: 1600 }}>
                <Table sx={{ tableLayout: 'fixed' }}>
                    <TableHead
                        sx={{
                            display: {
                                xs: 'none',
                                lg: 'table-header-group',
                            },
                        }}
                    >
                        <TableRow>
                            {DAYS.map((v) => (
                                <TableCell key={v} divider="none">
                                    {v.slice(0, 3)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {weeks.map((weekFirstDay, i) => (
                            <ScheduleDay
                                weekFirstDay={weekFirstDay}
                                key={i}
                                currentBlock={currentBlock}
                                logsByDay={logsByDay}
                                groupNotesByDay={groupNotesByDay}
                                currentInterval={currentInterval}
                                handleViewGroup={handleViewGroup}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    );
}

export default CurrentSchedule;
