import { Typography, Paper } from '@mui/material';

function Card({ label, children }) {
    return (
        <Paper
            variant="outlined"
            sx={{
                px: 2,
                pt: 1,
                pb: 1.5,
                height: '100%',
            }}
        >
            <Typography variant="overline" lineHeight="1.5em" mb="0.5em" display="inline-block">
                {label}
            </Typography>
            {children}
        </Paper>
    );
}

export default Card;
