import { useAutoNotify, useForm, useNotify, useRoles } from 'hooks';
import { useUpdateResurveyMutation } from 'api';
import * as yup from 'yup';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Step1, Step2, SurveyInfo } from 'components/Reconsultation';
import { LoadingButton } from '@mui/lab';

import stepAsset1 from 'assets/step_3.svg';
import stepAsset2 from 'assets/step_2.svg';

import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Stack,
    Step,
    StepLabel,
    Stepper,
} from '@mui/material';

const validations = [
    yup.object({
        focusNext: yup.string().required('This cannot be empty'),
        favouriteExercises: yup.string().required('This cannot be empty'),
        leastFavouriteExercises: yup.string().required('This cannot be empty'),
        unavailabilityToTrain: yup.string().required('This cannot be empty'),
        bestDays: yup.string().required('This cannot be empty'),
        significantChanges: yup.string().required('This cannot be empty'),
    }),
    yup.object({}),
];

const getSteps = () => [
    {
        name: 'Follow-up',
        Component: Step1,
        image: stepAsset1,
    },
    {
        name: 'Survey',
        Component: Step2,
        image: stepAsset2,
    },
];

function SurveyEdit({ onClose, data, onSuccess }) {
    const { isAdmin } = useRoles();
    const steps = useMemo(() => getSteps(), []);
    const [step, setStep] = useState(0);
    const notify = useNotify();

    const { Component, image } = steps[step];

    const [submit, { isLoading, error }] = useUpdateResurveyMutation();
    useAutoNotify(error);

    const schema = validations[step];
    const form = useForm(schema, { defaultValues: data });
    const { handleSubmit, trigger } = form;

    useEffect(() => {
        if (isAdmin || !data || !!data?.read) return;

        const handleRead = async () => {
            try {
                await submit({
                    _id: data?._id,
                    read: new Date().toISOString(),
                    noRefetch: true,
                }).unwrap();
            } catch (error) {}
        };

        handleRead();
    }, [data, isAdmin, submit]);

    const onSubmit = async (form) => {
        try {
            await submit(form).unwrap();
            notify('Form submitted successfully!');
            onSuccess();
        } catch (error) {}
    };

    const handleNext = async (e) => {
        if (!!step) {
            handleSubmit(onSubmit)();
            return;
        }

        const isStepValid = await trigger();
        if (isStepValid) setStep((v) => v + 1);
    };

    const handleSkip = async () => {
        try {
            await submit({
                _id: data?._id,
                skipped: new Date().toISOString(),
            }).unwrap();

            onClose();
        } catch (error) {}
    };

    return (
        <Fragment>
            <DialogTitle>Periodical follow-up</DialogTitle>
            <DialogContent>
                <Box mb={2}>
                    <SurveyInfo data={data} />
                </Box>
                <DialogContentText>
                    Thank you for being a valued customer and continuing our subscription services.
                    We hope we helped you reach your goals on a 3 month period.
                </DialogContentText>
                <DialogContentText sx={{ mt: { xs: 1, lg: 1 / 3 } }}>
                    Please answer the next few questions so we can help you further in your fitness
                    journey and transformation!
                </DialogContentText>
                <Stepper activeStep={step} alternativeLabel sx={{ mt: 4, width: '100%' }}>
                    {steps.map(({ name }) => (
                        <Step key={name}>
                            <StepLabel>{name}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box mt={3} mb={1} px={{ xs: 0, lg: 2 }}>
                    <Grid container spacing={4}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box
                                p={{ xs: 0, md: 4 }}
                                pb={{ xs: 1, md: 8 }}
                                maxWidth={{
                                    xs: 400,
                                    md: 'none',
                                }}
                            >
                                <img src={image} alt="" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Component form={form} />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                {isAdmin ? (
                    <Button color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                ) : (
                    <LoadingButton color="inherit" onClick={handleSkip} loading={isLoading}>
                        I'll fill out later
                    </LoadingButton>
                )}
                <Stack direction="row">
                    {!!step && (
                        <Button color="inherit" onClick={() => setStep((v) => v - 1)}>
                            Previous
                        </Button>
                    )}
                    <LoadingButton
                        loading={isLoading}
                        onClick={handleNext}
                        variant="contained"
                        sx={{ ml: 1 }}
                    >
                        {!step ? 'Next' : 'Finish'}
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </Fragment>
    );
}

export default SurveyEdit;
