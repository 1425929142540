import * as yup from 'yup';

import {
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useAutoNotify, useConfirm, useForm } from 'hooks';
import { useUpdateUserMutation } from 'api';
import { useEffect } from 'react';

const schema = yup.object({
    credits: yup
        .number()
        .required()
        .min(0, 'Min. value is 0')
        .max(100, 'Max. value is 100')
        .typeError('This is required.'),
    checkInCredits: yup
        .number()
        .required()
        .min(0, 'Min. value is 0')
        .max(100, 'Max. value is 100')
        .typeError('This is required.'),
});

function EditCreditsDialog({
    open,
    handleClose = () => {},
    data: { credits, checkInCredits, _id },
}) {
    const [confirm] = useConfirm();

    const { handleSubmit, registerField, reset } = useForm(schema, {
        defaultValues: { credits, checkInCredits },
    });

    const [submit, { error, isLoading, isSuccess }] = useUpdateUserMutation();
    useAutoNotify(error, isSuccess, 'Credits updated successfully.');

    useEffect(() => {
        if (open) reset({ credits, checkInCredits });
    }, [checkInCredits, credits, open, reset]);

    const onSubmit = async (form) => {
        try {
            await confirm('Are you sure you want to update these credits?', 'Update credits');
            const newData = { ...form, userId: _id };
            await submit(newData);
            handleClose();
        } catch (error) {}
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Edit credits</DialogTitle>
                <DialogContent>
                    <Stack direction="row" spacing={2} py={2}>
                        <TextField
                            {...registerField('credits')}
                            fullWidth
                            label="Credits *"
                            type="number"
                        />
                        <TextField
                            {...registerField('checkInCredits')}
                            fullWidth
                            label="Booking credits *"
                            type="number"
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} type="submit">
                        Submit
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default EditCreditsDialog;
