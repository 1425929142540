import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollToTop(ref = { current: window }) {
    const location = useLocation();

    useEffect(() => {
        ref.current.scroll(0, 0);
    }, [location.pathname, ref]);
}

export default useScrollToTop;
