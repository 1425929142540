import { Stack, Avatar, Typography, Box, useMediaQuery } from '@mui/material';

function CreditsBadge({ value, checkIn, small }) {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isLarge = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    if (!value && value !== 0) return null;

    const showSmall = isSmall || small;

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            {(!isLarge || !small) && (
                <Avatar
                    sx={{
                        width: small ? 36 : 48,
                        height: small ? 36 : 48,
                        bgcolor: 'common.white',
                        color: 'primary.main',
                    }}
                >
                    <i
                        className={`fas fa-${checkIn ? 'user-check' : 'dumbbell'} fa-${
                            small ? 'xs' : 'sm'
                        }`}
                    />
                </Avatar>
            )}
            <Box textAlign="left">
                <Typography
                    variant={showSmall ? 'subtitle2' : 'subtitle1'}
                    fontWeight="fontWeightBold"
                    lineHeight="1em"
                    color={checkIn ? 'text.secondary' : 'text.primary'}
                >
                    {value} Credits
                </Typography>
                <Typography variant="subtitle2" lineHeight="1em" mt={1 / 5} color="text.secondary">
                    {checkIn ? 'Booking' : 'Training'}
                </Typography>
            </Box>
        </Stack>
    );
}

export default CreditsBadge;
