import { useRef, useContext } from 'react';
import { ConfirmationDialogContext } from 'common/ConfirmationDialog';

function useConfirm() {
    const { openDialog, handleCloseDialog } = useContext(ConfirmationDialogContext);
    const promiseRef = useRef();

    const thePromise = (
        content,
        title,
        keepDialogOpen,
        { ConfirmButtonProps = {}, CancelButtonProps = {} } = {},
    ) => {
        const promise = new Promise((res, rej) => {
            promiseRef.current = [res, rej];
        });
        const [confirm, cancel] = promiseRef.current;
        openDialog({
            content,
            title,
            confirm,
            cancel,
            keepDialogOpen,
            ConfirmButtonProps,
            CancelButtonProps,
        });
        return promise;
    };

    return [thePromise, handleCloseDialog];
}

export default useConfirm;
