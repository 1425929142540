import { Box, LinearProgress, Stack, useMediaQuery } from '@mui/material';
import { ConversationHeader, ConversationHistory, ConversationInput } from 'components/Chat';
import { useGetChatCounterpart } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { chatSelectors } from 'ducks';

const MESSAGES_BATCH_COUNT = 20;

function Conversation({ currentConversation, compact, isSticky }) {
    const isMobile = useMediaQuery((t) => t.breakpoints.down('md'));
    const showCompact = isMobile || compact;
    const showNew = useSelector(chatSelectors.getShowNew);
    const newRecipient = useSelector(chatSelectors.getNewRecipient);

    const counterpart = useGetChatCounterpart(currentConversation);
    const currentChatId = currentConversation?._id;

    const boxRef = useRef(false);
    const userScrolledRef = useRef(false);

    const [jumpButtonBottom, setJumpButtonBottom] = useState(0);
    const [limit, setLimit] = useState(MESSAGES_BATCH_COUNT);
    const [editingMessage, setEditingMessage] = useState(false);
    const [scrollTimestamp, setScrollTimestamp] = useState(undefined);

    const handleUserScrolled = useRef(
        debounce(() => setScrollTimestamp(new Date().getTime()), 200),
    );

    useEffect(() => {
        handleUserScrolled.current();
    }, []);

    const loadMoreMessages = useCallback(() => {
        setLimit((v) => v + MESSAGES_BATCH_COUNT);
    }, []);

    const handleEditMessage = (id, text) => {
        setEditingMessage({ id, text });
    };
    const handleCancelEditMessage = () => {
        setEditingMessage(false);
    };

    if (currentConversation === null) return <LinearProgress />;

    return (
        <Stack height="100%" overflow="scroll" ref={boxRef} onScroll={handleUserScrolled.current}>
            <ConversationHeader
                conversation={currentConversation}
                counterpart={counterpart}
                compact={showCompact}
                isSticky={isSticky}
            />
            <Box py={1.5} px={showCompact ? 1.5 : 2.5} flexGrow={1} position="relative">
                {!showNew && (
                    <ConversationHistory
                        chatId={currentChatId}
                        limit={limit}
                        loadMoreMessages={loadMoreMessages}
                        handleEditMessage={handleEditMessage}
                        editingMessageId={editingMessage?.id}
                        boxRef={boxRef}
                        userScrolledRef={userScrolledRef}
                        jumpButtonBottom={jumpButtonBottom}
                        scrollTimestamp={scrollTimestamp}
                    />
                )}
            </Box>
            {(!showNew || newRecipient) && (
                <ConversationInput
                    userId={(showNew && newRecipient?._id) || counterpart?.id}
                    chatId={currentChatId}
                    limit={limit}
                    editingMessage={!showNew && editingMessage}
                    cancelEditMessage={handleCancelEditMessage}
                    boxRef={boxRef}
                    userScrolledRef={userScrolledRef}
                    compact={showCompact}
                    setJumpButtonBottom={setJumpButtonBottom}
                />
            )}
        </Stack>
    );
}

export default Conversation;
