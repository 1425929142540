import { Typography, Tooltip, useMediaQuery } from '@mui/material';

function ExerciseParameter({ label, value, tooltip = '' }) {
    const mediumDown = useMediaQuery((t) => t.breakpoints.down('md'));

    return (
        <Tooltip title={tooltip} placement="top">
            <div>
                <Typography variant="subtitle2" fontWeight="400" lineHeight="1.3em">
                    {label}
                </Typography>
                <Typography
                    variant="subtitle1"
                    fontSize={mediumDown ? '1em' : undefined}
                    fontWeight="600"
                    lineHeight="1.3em"
                >
                    {value}
                </Typography>
            </div>
        </Tooltip>
    );
}

export default ExerciseParameter;
