import { Stack } from '@mui/material';
import { CreditsBadge } from 'common';

function CreditsWidget({ data, small, ...props }) {
    return (
        <Stack direction="row" alignItems="center" spacing={3} {...props}>
            <CreditsBadge value={data?.credits} small={small} />
            <CreditsBadge value={data?.checkInCredits} checkIn small={small} />
        </Stack>
    );
}

export default CreditsWidget;
