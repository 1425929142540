import { chatActions } from 'ducks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMaxStickyChats } from 'hooks';

function useAddStickyChat() {
    const dispatch = useDispatch();
    const maxAmount = useMaxStickyChats();

    return useCallback(
        (chat, keepMinimized) => {
            dispatch(chatActions.addStickyChat({ chat, maxAmount, keepMinimized }));
        },
        [maxAmount, dispatch],
    );
}

export default useAddStickyChat;
