import { useRouteMatch, Link as RouterLink } from 'react-router-dom';

import { TableRow, TableCell, Chip, Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Link } from 'routes';
import { useAutoNotify, useConfirm } from 'hooks';
import { useDeleteExerciseMutation } from 'api';
import { VideoBox } from 'common';

function ExercisesRow({ row, handleVideoClick }) {
    const match = useRouteMatch();
    const [confirm] = useConfirm();

    const [deleteExercise, exerciseDeletion] = useDeleteExerciseMutation();
    useAutoNotify(exerciseDeletion.error, exerciseDeletion.isSuccess, 'Exercise deleted.');

    const handleDelete = async (e) => {
        try {
            await confirm('Are you sure you want to delete this exercise?', 'Delete Exercise');
            deleteExercise(row._id);
        } catch (error) {}
    };

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell sx={{ width: '100px' }}>
                <VideoBox
                    onClick={handleVideoClick}
                    width="100%"
                    video={row.videoURL}
                    disableVideo
                />
            </TableCell>
            <TableCell component="th" scope="row">
                <Link
                    sx={{ color: 'text.primary', whiteSpace: 'nowrap' }}
                    to={match?.url + '/' + row._id}
                >
                    {row.name}
                </Link>
            </TableCell>
            <TableCell>
                <Stack direction="row" flexWrap="wrap">
                    {row.categories.map((v) => (
                        <Chip
                            key={v}
                            label={v}
                            size="small"
                            sx={{ margin: 1 / 4, display: 'inline-flex' }}
                        />
                    ))}
                </Stack>
            </TableCell>
            <TableCell>
                <Stack direction="row" flexWrap="wrap">
                    {row.musclesWorked.map((v) => (
                        <Chip
                            key={v}
                            label={v}
                            size="small"
                            sx={{ margin: 1 / 4, display: 'inline-flex' }}
                        />
                    ))}
                </Stack>
            </TableCell>
            <TableCell>
                <Stack spacing={1} direction="row">
                    <Button
                        color="warning"
                        component={RouterLink}
                        to={`${match?.url}/${row._id}/edit`}
                    >
                        <i className="fas fa-pen fa-mr-1 fa-xs" />
                        Edit
                    </Button>
                    <LoadingButton
                        color="error"
                        loading={exerciseDeletion.isLoading}
                        onClick={handleDelete}
                    >
                        <i className="fas fa-trash fa-mr-1 fa-xs" />
                        Delete
                    </LoadingButton>
                </Stack>
            </TableCell>
        </TableRow>
    );
}

export default ExercisesRow;
