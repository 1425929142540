import { Route, Redirect, useLocation } from 'react-router-dom';
import { useInitialLocation, useMe } from 'hooks';
import { useEffect } from 'react';

function PrivateRoute({ children, ...rest }) {
    const user = useMe();
    const location = useLocation();
    const [, setInitialLocation] = useInitialLocation();

    useEffect(() => {
        if (!user && location.pathname !== '/logout') setInitialLocation(location);
    }, [location, setInitialLocation, user]);

    return <Route {...rest} render={(props) => (user ? children : <Redirect to="/login" />)} />;
}

export default PrivateRoute;
