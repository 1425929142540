import { Box, TextField, Divider, InputAdornment, IconButton, Stack, Tooltip } from '@mui/material';
import { chatActions, chatSelectors } from 'ducks';
import { useDispatch, useSelector } from 'react-redux';

function ChatsListHeader({ value, setValue, onClear }) {
    const dispatch = useDispatch();
    const showNew = useSelector(chatSelectors.getShowNew);

    const handleInputKeyDown = (e) => {
        if (e.key === 'Escape') onClear();
    };
    const handleNewConversationClick = () => {
        dispatch(chatActions.toggleShowNew());
    };

    return (
        <Box
            position="sticky"
            top={0}
            bgcolor="#fff4"
            zIndex={10}
            sx={{
                backdropFilter: 'blur(8px)',
            }}
        >
            <Stack direction="row" alignItems="center" spacing={1} px={2.5} py={2} height={82}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Search..."
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <i
                                    className="fas fa-search fa-sm"
                                    style={{ lineHeight: '0.8em' }}
                                />
                            </InputAdornment>
                        ),
                        endAdornment: value ? (
                            <InputAdornment position="end">
                                <IconButton onClick={onClear}>
                                    <i className="far fa-times-circle fa-2xs fa-sq" />
                                </IconButton>
                            </InputAdornment>
                        ) : undefined,
                    }}
                    onKeyDown={handleInputKeyDown}
                    sx={{
                        '.MuiOutlinedInput-root': {
                            pt: '2px',
                            pr: 1 / 2,
                            borderRadius: '20px',
                        },
                    }}
                />
                <Tooltip title={showNew ? 'Cancel' : 'New conversation'}>
                    <IconButton
                        size="large"
                        color="primary"
                        onClick={handleNewConversationClick}
                        sx={{
                            '&, &:hover': {
                                bgcolor: showNew ? 'primary.main' : undefined,
                                color: showNew ? 'common.white' : undefined,
                            },
                        }}
                    >
                        <Box
                            component="i"
                            className="fas fa-plus fa-2xs fa-md fa-sq"
                            sx={{
                                '&:before': {
                                    transform: showNew ? 'rotate(135deg)' : 'rotate(0)',
                                    transition: '0.3s',
                                    display: 'inline-block',
                                },
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Stack>
            <Divider />
        </Box>
    );
}

export default ChatsListHeader;
