import { Fragment } from 'react';
import { Avatar } from '@mui/material';
import { getUserColor } from 'helpers';

function ChatAvatar({ name, compact, mini }) {
    const userColor = getUserColor(name);
    const size = mini ? 24 : compact ? 32 : 44;

    return (
        <Fragment>
            <Avatar
                sx={{
                    bgcolor: userColor,
                    fontSize: mini ? '0.75em' : compact ? 'body2.fontSize' : 'subtitle1.fontSize',
                    fontWeight: mini ? 'bold' : undefined,
                    width: size,
                    height: size,
                }}
            >
                {(name || '')
                    .split(' ')
                    .slice(0, 2)
                    .map((v) => (v[0] || '').toUpperCase())
                    .join('')}
            </Avatar>
        </Fragment>
    );
}

export default ChatAvatar;
