import { useCallback } from 'react';
import { Box, TextField, Stack, Button, InputAdornment, Tooltip, IconButton } from '@mui/material';
import { File } from 'components/Resources';

function ResourceFileControl({ existingValue, form, name, ...props }) {
    const { registerField, watch, setValue } = form;

    const newFileValue = watch(name, false);
    const setFileValue = useCallback((val) => setValue(name, val), [name, setValue]);

    const fileChanged = (newFileValue?.length ?? 0) !== 0 || newFileValue === '';

    return (
        <Box
            display="flex"
            pb={1}
            flexDirection={{
                xs: 'column',
                sm: 'row',
            }}
            alignItems={!existingValue || fileChanged ? 'flex-start' : 'center'}
            {...props}
        >
            {fileChanged && (
                <TextField
                    label={newFileValue === '' ? 'File deleted' : 'New file'}
                    disabled
                    value={newFileValue?.[0]?.name}
                    error={newFileValue === ''}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title="Undo changes to file" placement="top">
                                    <IconButton onClick={() => setFileValue(undefined)}>
                                        <i className="fas fa-undo fa-xs fa-sq" />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {!!existingValue && !fileChanged && (
                <Box mr={2} flex="1 1 auto" width={0} alignSelf="stretch">
                    <File
                        file={existingValue}
                        maxWidth="none"
                        viewOnly
                        whiteSpace="nowrap"
                        height="100%"
                    />
                </Box>
            )}
            {!fileChanged && (
                <Stack
                    direction="row"
                    spacing={2}
                    alignSelf={!existingValue ? 'flex-start' : undefined}
                >
                    <label>
                        <input
                            {...registerField(name, {
                                noErrorHelpers: true,
                                refKey: 'ref',
                            })}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        <Button
                            variant={!existingValue ? 'contained' : 'outlined'}
                            component="span"
                            size="large"
                            color={!existingValue ? 'primary' : 'info'}
                            sx={{
                                whiteSpace: {
                                    xs: 'initial',
                                    sm: 'nowrap',
                                },
                                textAlign: 'center',
                            }}
                        >
                            {!existingValue ? 'Add file' : 'Change file'}
                        </Button>
                    </label>
                    {!!existingValue && (
                        <Button
                            variant="outlined"
                            component="span"
                            size="large"
                            color="error"
                            sx={{
                                whiteSpace: {
                                    xs: 'initial',
                                    sm: 'nowrap',
                                },
                                textAlign: 'center',
                            }}
                            onClick={() => setFileValue('')}
                        >
                            Delete file
                        </Button>
                    )}
                </Stack>
            )}
        </Box>
    );
}

export default ResourceFileControl;
