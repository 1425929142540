import React, { useState } from 'react';

import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useCallback } from 'react';

export const ConfirmationDialogContext = React.createContext({});

const initialState = {
    open: false,
    content: false,
    title: false,
    confirm: () => {},
    cancel: () => {},
    keepDialogOpen: false,
    noCancel: false,
};

const ConfirmationDialogProvider = ({ children }) => {
    const [dialog, setDialog] = useState(initialState);
    const [loading, setLoading] = useState(false);

    const openDialog = (payload) => {
        setDialog((state) => ({
            ...state,
            ...payload,
            open: true,
        }));
    };

    const resetDialog = () => {
        setDialog(initialState);
        setLoading(false);
    };

    const closeDialog = useCallback(() => {
        setDialog((prev) => ({
            ...prev,
            open: false,
        }));
    }, []);

    const onConfirm = () => {
        dialog.confirm();
        if (dialog.keepDialogOpen) setLoading(true);
        else closeDialog();
    };

    const onCancel = () => {
        dialog.cancel();
        closeDialog();
    };

    return (
        <ConfirmationDialogContext.Provider value={{ openDialog, handleCloseDialog: closeDialog }}>
            <Dialog
                open={dialog.open}
                onClose={onCancel}
                fullWidth
                maxWidth="xs"
                TransitionProps={{ onExited: resetDialog }}
            >
                <DialogTitle>{dialog.title || 'Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialog.content || 'Do you confirm this action?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={loading}
                        color="inherit"
                        onClick={onCancel}
                        {...(dialog?.CancelButtonProps || {})}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={loading}
                        onClick={onConfirm}
                        {...(dialog?.ConfirmButtonProps || {})}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            {children}
        </ConfirmationDialogContext.Provider>
    );
};

export default ConfirmationDialogProvider;
