import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: 0,
};

const slice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
        decrement: (state) => {
            state.value -= 1;
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload;
        },
    },
});


// ACTIONS

function getActions() {
    const { setCredentials } = slice.actions;

    return {
        setCredentials,
    };
}

export const actions = getActions();


// SELECTORS

const getCounter = (state) => state.counter.value;

export const selectors = {
    getCounter,
};

export default slice.reducer;
