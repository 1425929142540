import { Box } from '@mui/material';

function VideoBox({
    video,
    onClick,
    ratio = 3 / 4,
    autoPlay = false,
    controls = false,
    preload,
    disableVideo = false,
    noFallback = false,
    ...props
}) {
    if (!noFallback && (!video || video.indexOf('undefined') > -1)) {
        return (
            <Box textAlign="center">
                <i className="fas fa-video-slash" />
            </Box>
        );
    }

    return (
        <Box {...props}>
            <Box
                sx={{
                    paddingTop: `${ratio * 100}%`,
                    position: 'relative',
                    bgcolor: 'grey.800',
                    borderRadius: 1 / 2,
                    overflow: 'hidden',
                    maxHeight: 0,

                    '& .overlay': {
                        display: !!onClick ? 'flex' : 'none',
                        cursor: 'pointer',
                        bgcolor: (theme) => `${theme.palette.grey[800]}33`,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        transition: 'background-color 0.3s',
                    },
                    '& .play': {
                        color: 'common.white',
                        opacity: 0.9,
                        textShadow: '0 2px 2px #0004',
                        transition: 'transform 0.3s',
                        lineHeight: '1em',
                    },
                    '& video': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    },
                    '&:hover': {
                        '& .overlay': {
                            bgcolor: (theme) => `${theme.palette.grey[800]}bb`,
                        },
                        '& .play': {
                            transform: 'scale(1.3)',
                        },
                    },
                }}
                onClick={onClick?.(video)}
            >
                <div className="overlay">
                    <div className="play">
                        <i className="fas fa-play fa-lg" />
                    </div>
                </div>
                {!disableVideo && (
                    <video
                        key={video}
                        controls={controls}
                        autoPlay={autoPlay}
                        preload={preload}
                        controlsList="nodownload"
                        style={onClick ? { transform: 'scale(2.5)' } : undefined}
                    >
                        <source src={video} type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                )}
            </Box>
        </Box>
    );
}

export default VideoBox;
