import { Dialog } from '@mui/material';
import { useGetResourceByIdQuery, useGetResourcesCategoriesQuery } from 'api';
import { DialogDataProvider } from 'common';
import { ResourceForm, ResourceView } from 'components/Resources';
import { useAutoNotify } from 'hooks';
import { Fragment, useEffect, useState } from 'react';

function ResourceDialog({ id, edit, handleClose, handleEdit }) {
    const [{ id: localId, edit: localEdit }, setLocalValue] = useState({ id, edit });

    useEffect(() => {
        if (!id) return;
        setLocalValue({ id, edit });
    }, [id, edit]);

    const categories = useGetResourcesCategoriesQuery();
    useAutoNotify(categories.error);

    return (
        <Fragment>
            <Dialog
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                open={!!id}
                TransitionProps={{ onExited: () => setLocalValue({ id, edit }) }}
            >
                <DialogDataProvider
                    hook={useGetResourceByIdQuery}
                    args={localId}
                    handleClose={handleClose}
                    handleEdit={handleEdit}
                    categories={categories?.data?.categories || []}
                    Component={localEdit ? ResourceForm : ResourceView}
                    add={localId === -1}
                />
            </Dialog>
        </Fragment>
    );
}

export default ResourceDialog;
