import { Box, Stack, Typography, TableRow, TableCell, Tooltip, useMediaQuery } from '@mui/material';

import { addDays, addHours, format, isPast, isToday, isWithinInterval } from 'date-fns';
import { DAYS } from '@constants';

function ScheduleDay({ weekFirstDay, currentBlock, logsByDay, currentInterval, handleViewGroup }) {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    return (
        <TableRow>
            {DAYS.map((v, i) => {
                const currentDay = addHours(addDays(weekFirstDay, i), 1);

                const groups = currentBlock?.groups?.reduce((a, group) => {
                    if (group?.days?.indexOf(v) > -1) {
                        const currentDayLogs = logsByDay?.[format(currentDay, 'ddMMy')];
                        const currentDayAndGroupLogs = currentDayLogs?.filter(
                            (q) => q?.groupId === group?._id,
                        );
                        const exerciseCount = group?.exercises?.length || 0;

                        a.push({
                            ...group,
                            logsComplete: exerciseCount === currentDayAndGroupLogs?.length,
                            logsStarted:
                                exerciseCount > currentDayAndGroupLogs?.length &&
                                currentDayAndGroupLogs?.length > 0,
                        });
                    }
                    return a;
                }, []);

                const today = isToday(currentDay);
                const show = isWithinInterval(currentDay, currentInterval);

                if (!groups?.length && isMobile && !today) return null;

                return (
                    <TableCell
                        key={v}
                        scope="row"
                        sx={{
                            verticalAlign: 'top',
                            p: 3 / 4,
                            height: {
                                xs: 'auto',
                                lg: 0,
                            },
                            display: {
                                xs: 'block',
                                lg: 'table-cell',
                            },
                        }}
                        divider="none"
                    >
                        <Box
                            borderRadius={1}
                            border={today ? 2 : 1}
                            borderColor={
                                today
                                    ? 'primary.main'
                                    : groups?.length && !isPast(currentDay)
                                    ? '#b9d8fb'
                                    : 'grey.200'
                            }
                            bgcolor={groups?.length && !isPast(currentDay) ? '#fdfdff' : undefined}
                            p={5 / 4}
                            height="100%"
                        >
                            <Stack spacing={1.5}>
                                <Typography
                                    variant="overline"
                                    fontWeight="fontWeightBold"
                                    fontSize="body2.fontSize"
                                    lineHeight="1em"
                                    color={
                                        today
                                            ? 'text.primary'
                                            : `grey.${
                                                  groups?.length && !isPast(currentDay) ? 6 : 5
                                              }00`
                                    }
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box component="span">
                                        {format(currentDay, isMobile ? 'eee, d MMM' : 'd MMM')}
                                        {today && (
                                            <Box
                                                ml={3 / 4}
                                                component="span"
                                                color="warning.main"
                                                display="inline-block"
                                                sx={{
                                                    transform: 'scale(1.5) translateY(-0.5px)',
                                                }}
                                            >
                                                •
                                            </Box>
                                        )}
                                    </Box>
                                </Typography>
                                {show && (
                                    <div>
                                        {groups.map((group) => (
                                            <Tooltip
                                                placement="left"
                                                title={
                                                    group.logsComplete
                                                        ? 'All exercises are logged'
                                                        : group.logsStarted
                                                        ? 'Only some exercises are logged'
                                                        : 'Nothing logged'
                                                }
                                                key={group._id}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    color={
                                                        isPast(currentDay) && !today
                                                            ? 'grey.600'
                                                            : 'text.secondary'
                                                    }
                                                    fontWeight="fontWeightBold"
                                                    lineHeight="1.33em"
                                                    mb="0.25em"
                                                    borderRadius={1 / 2}
                                                    onClick={handleViewGroup(group, currentDay)}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        verticalAlign: 'center',
                                                    }}
                                                >
                                                    <Box
                                                        color={
                                                            !isPast(currentDay) && !today
                                                                ? 'text.secondary'
                                                                : group.logsComplete
                                                                ? 'success.dark'
                                                                : group.logsStarted || today
                                                                ? 'warning.dark'
                                                                : 'error.dark'
                                                        }
                                                        component="span"
                                                    >
                                                        <i
                                                            className={`fa-sm fa-mr-half fa-${
                                                                group.logsComplete
                                                                    ? 'check-circle fas'
                                                                    : isPast(currentDay) && !today
                                                                    ? 'exclamation-circle fas'
                                                                    : group.logsStarted
                                                                    ? 'check-circle far'
                                                                    : 'circle far'
                                                            }`}
                                                        />
                                                    </Box>
                                                    {group.title}
                                                </Typography>
                                            </Tooltip>
                                        ))}
                                    </div>
                                )}
                            </Stack>
                        </Box>
                    </TableCell>
                );
            })}
        </TableRow>
    );
}

export default ScheduleDay;
