import { Stack, TextField, IconButton } from '@mui/material';
import { makeRecommendedProps } from 'form';
import { useSubForm } from 'hooks';

function LogFormField({
    index,
    form,
    onDelete,
    recommendedReps,
    recommendedIntensity,
    recommendedRpe,
    recommendedWeight,
    disabled,
    hasIntensity,
    hasRPE,
}) {
    const subForm = useSubForm('sets', index, form);
    const { registerSubField, getName, setValue } = subForm;

    return (
        <Stack
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            spacing={2}
            alignItems="flex-start"
        >
            <TextField
                fullWidth
                label={`Reps (set ${index + 1})`}
                {...registerSubField('reps')}
                type="text"
                {...makeRecommendedProps(recommendedReps, setValue, getName('reps'))}
                autoFocus={!index}
                inputProps={{
                    inputMode: 'numeric',
                }}
            />
            <TextField
                fullWidth
                label="Used weight"
                {...registerSubField('usedWeight')}
                type="text"
                {...makeRecommendedProps(recommendedWeight, setValue, getName('usedWeight'))}
            />
            {hasIntensity && (
                <TextField
                    fullWidth
                    label="Intensity (%)"
                    {...registerSubField('intensity')}
                    type="text"
                    {...makeRecommendedProps(
                        recommendedIntensity,
                        setValue,
                        getName('intensity'),
                        (v) => `${v}%`,
                    )}
                />
            )}
            {hasRPE && (
                <TextField
                    fullWidth
                    label="RPE"
                    {...registerSubField('rpe')}
                    type="text"
                    {...makeRecommendedProps(recommendedRpe, setValue, getName('rpe'))}
                />
            )}
            <IconButton
                tabIndex={-1}
                sx={{ color: 'error.dark', mt: '0.5em !important' }}
                onClick={onDelete}
                disabled={disabled}
            >
                <i className="fas fa-trash fa-2xs fa-sq" />
            </IconButton>
        </Stack>
    );
}

export default LogFormField;
