import { Link as RouterLink, useRouteMatch, useLocation } from 'react-router-dom';
import { Stack, Button } from '@mui/material';
import { makePath } from 'routes/utils';

function TabbedPageMenu({ pages }) {
    const { pathname } = useLocation();
    const match = useRouteMatch();

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                overflowX: 'auto',
            }}
            mx={{
                xs: -2,
                sm: -3.25,
            }}
            px={{
                xs: 2,
                sm: 3.25,
            }}
            pb={1}
        >
            {pages.map((v) => (
                <Button
                    key={v.id}
                    variant="contained"
                    color={pathname.indexOf(makePath(match.url, v.id)) === 0 ? undefined : 'light'}
                    component={RouterLink}
                    to={makePath(match.url, v.id)}
                    disabled={v.disabled}
                    sx={{
                        whiteSpace: 'nowrap',
                        flexShrink: 0,
                    }}
                >
                    {v.name}
                </Button>
            ))}
        </Stack>
    );
}

export default TabbedPageMenu;
