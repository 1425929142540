import {
    Box,
    Chip,
    Divider,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useAutoNotify, useMe, useNotify, useRoles, useScrollToBottom } from 'hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CONVERSATION_INPUT_CLASS, MAX_FILES, MAX_MB_FILE_SIZE } from 'components/Chat/constants';
import { FileButton } from 'components/Chat';
import {
    useGetChatMessagesState,
    useSendMessageMutation,
    useUpdateMessageMutation,
    useUploadMessageFileMutation,
} from 'api';

const DEFAULT_FILES = [];

function ConversationInput({
    userId,
    chatId,
    editingMessage,
    limit,
    cancelEditMessage = () => {},
    boxRef,
    userScrolledRef,
    compact,
    setJumpButtonBottom = () => {},
}) {
    const scrollToBottom = useScrollToBottom(boxRef, userScrolledRef);
    const textFieldRef = useRef(null);
    const inputWrapRef = useRef(null);
    const isMedium = useMediaQuery((t) => t.breakpoints.up('md'));
    const [message, setMessage] = useState('');
    const [selectedFiles, setSelectedFiles] = useState(DEFAULT_FILES);
    const [areFilesUploading, setAreFilesUploading] = useState(false);

    const me = useMe();
    const { isAdmin } = useRoles();
    const chatMessages = useGetChatMessagesState({ chatId, limit });

    const lastMessageIsTheirs = useMemo(() => {
        if (isAdmin || (!!userId && !chatId)) return true;
        const lastMessage = chatMessages.data?.[0]?.at(-1);
        if (!lastMessage) return false;

        const id = lastMessage?.sentBy?._id;
        if (!id) return false;

        return id !== me?._id;
    }, [chatId, chatMessages.data, isAdmin, me?._id, userId]);

    const notify = useNotify();

    const useMutation = editingMessage ? useUpdateMessageMutation : useSendMessageMutation;
    const [submit, { isLoading, error, isSuccess, reset }] = useMutation();
    useAutoNotify(error);

    const [uploadFile, uploadFileMutation] = useUploadMessageFileMutation();
    useAutoNotify(uploadFileMutation.error);

    const handleFileChange = (e) => {
        const newFiles = [...e.target.files];
        if (selectedFiles.length + newFiles.length > MAX_FILES) {
            notify(`You cannot attach more than ${MAX_FILES} files. Please try again.`, 'info');
            return;
        }
        const filesTooLarge = [];

        const filterFunc = (prev) => (file) => {
            const include = !prev.some((q) => file?.name === q?.name);
            if (include && file.size > 1024 * 1024 * MAX_MB_FILE_SIZE) {
                filesTooLarge.push(file);
                return false;
            }
            return include;
        };
        setSelectedFiles((prev) => [...prev, ...newFiles.filter(filterFunc(prev))]);

        e.target.value = '';

        if (!filesTooLarge?.length) return;

        notify(
            <Box ml={2}>
                <Box mb={1}>
                    These files are larger than {MAX_MB_FILE_SIZE} MB and have been skipped:
                </Box>
                {filesTooLarge.map((v) => (
                    <div key={v?.name}>{v?.name}</div>
                ))}
            </Box>,
            'info',
        );
    };
    const handleFileDelete = (i) => (e) => {
        setSelectedFiles((prev) => {
            const newArr = [...prev];
            newArr.splice(i, 1);
            return newArr;
        });
    };

    const handleSubmit = async () => {
        const fileURLs = [];

        if (selectedFiles?.length) {
            setAreFilesUploading(true);
            try {
                for (const file of selectedFiles) {
                    const { data } = await uploadFile(file);
                    const url = data?.fileName;
                    if (url) fileURLs.push(url);
                }
            } catch (error) {
                return;
            }
            setAreFilesUploading(false);
        } else if (!message.trim()) {
            return;
        }

        const dataObj = { text: message.trim(), fileURLs, chatId, limit };

        if (editingMessage) dataObj.messageId = editingMessage?.id;
        else dataObj.userId = userId;

        submit(dataObj);
        scrollToBottom(true, editingMessage);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
        }
        if (e.key === 'Escape') {
            e.preventDefault();
            cancelEditMessage();
        }
    };

    useEffect(() => {
        if (!isMedium) return;
        textFieldRef.current?.focus();
    }, [chatId, isMedium]);

    useEffect(() => {
        if (isSuccess) {
            setSelectedFiles(DEFAULT_FILES);
            setMessage('');
            cancelEditMessage();
            reset();
            if (!isMedium) return;
            textFieldRef.current?.focus();
        }
    }, [cancelEditMessage, isMedium, isSuccess, reset]);

    useEffect(() => {
        setSelectedFiles(DEFAULT_FILES);
        setMessage(editingMessage?.text || '');
        if (!isMedium) return;
        textFieldRef.current?.focus();
    }, [editingMessage, isMedium]);

    useEffect(() => {
        scrollToBottom(true, true);
    }, [scrollToBottom, selectedFiles]);

    useEffect(() => {
        setJumpButtonBottom((inputWrapRef.current?.offsetHeight || 85) + 20);
    }, [setJumpButtonBottom, editingMessage, selectedFiles, chatMessages]);

    const isUploadingOrLoading = isLoading || !!areFilesUploading;

    return (
        <Box
            position="sticky"
            bottom={0}
            bgcolor="#fff8"
            sx={{
                backdropFilter: 'blur(8px)',
            }}
            className={CONVERSATION_INPUT_CLASS}
        >
            <Divider />
            <Box py={1.5} px={compact ? 1.5 : 2.5} ref={inputWrapRef}>
                {editingMessage && (
                    <Chip
                        size="small"
                        label="Editing a message"
                        color="warning"
                        variant="outlined"
                        sx={{ mb: 1 }}
                        onDelete={cancelEditMessage}
                        disabled={isLoading}
                    />
                )}
                {!!selectedFiles?.length && (
                    <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                        {selectedFiles.map((v, i) => (
                            <Chip
                                key={v?.name}
                                size="small"
                                label={v?.name}
                                color="primary"
                                variant="outlined"
                                onDelete={handleFileDelete(i)}
                                disabled={isUploadingOrLoading}
                                sx={{ mr: 1, mb: 1 }}
                            />
                        ))}
                        {areFilesUploading && (
                            <Typography
                                variant="caption"
                                display="inline-block"
                                whiteSpace="nowrap"
                                mb={1}
                            >
                                <i className="fas fa-circle-notch fa-spin fa-mr-half" />{' '}
                                Uploading...
                            </Typography>
                        )}
                    </Box>
                )}
                <TextField
                    fullWidth
                    placeholder={
                        lastMessageIsTheirs || editingMessage
                            ? 'Type a message...'
                            : 'You can only edit your last message until the trainer responds.'
                    }
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    inputRef={textFieldRef}
                    disabled={isUploadingOrLoading || (!lastMessageIsTheirs && !editingMessage)}
                    multiline
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FileButton
                                    disabled={
                                        isUploadingOrLoading ||
                                        !!editingMessage ||
                                        !lastMessageIsTheirs
                                    }
                                    selectedFiles={selectedFiles}
                                    handleFileChange={handleFileChange}
                                    editing={!!editingMessage}
                                />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    color="primary"
                                    disabled={
                                        (!message.trim() && !selectedFiles?.length) ||
                                        isUploadingOrLoading
                                    }
                                    onClick={handleSubmit}
                                >
                                    <i
                                        className={`fas fa-sq fa-${
                                            isLoading ? 'circle-notch fa-spin' : 'paper-plane'
                                        }`}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '.MuiOutlinedInput-root': {
                            borderRadius: '28px',
                        },
                        '.MuiInputBase-input.Mui-disabled': {
                            textFillColor: '#000',
                        },
                    }}
                />
            </Box>
        </Box>
    );
}

export default ConversationInput;
