import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm as useReactHookForm } from 'react-hook-form';
import get from 'lodash/get';
import * as yup from 'yup';

function useForm(schema = yup.object({}), options = {}) {
    const form = useReactHookForm({
        resolver: yupResolver(schema),
        ...options,
    });

    const {
        register,
        formState: { errors, submitCount },
        trigger,
    } = form;

    const getErrorProps = useCallback(
        (name, helperText = '') => {
            const error = get(errors, name);
            const helperArr = [];
            if (error?.message) helperArr.push(error?.message);
            if (helperText) helperArr.push(helperText);

            return {
                helperText: helperArr.join(' • '),
                error: !!error,
            };
        },
        [errors],
    );

    const registerField = useCallback(
        (name, config = {}) => {
            const {
                noErrorHelpers,
                refKey = 'inputRef',
                onChange: externalOnChange = () => {},
                manualValidateField,
                helperText = '',
            } = config;

            const { ref, onChange, ...more } = register(name);
            const errorHelpers = noErrorHelpers ? {} : getErrorProps(name, helperText);
            const extraProps = {};

            return {
                ...more,
                [refKey]: ref,
                onChange: (e) => {
                    onChange(e);
                    externalOnChange(e);
                    if (manualValidateField && !!submitCount) trigger(manualValidateField);
                },
                ...errorHelpers,
                ...extraProps,
            };
        },
        [register, getErrorProps, submitCount, trigger],
    );

    return {
        ...form,
        registerField,
        getErrorProps,
        getError: (name) => get(errors, `${name}.message`),
    };
}

export default useForm;
