import {
    configureStore,
    combineReducers,
    createAction,
    isRejectedWithValue,
    // isRejected,
} from '@reduxjs/toolkit';
// import { setupListeners } from '@reduxjs/toolkit/query'

import { AUTH_PATHS } from '@constants';

import counter from './test';
import ui from './ui';
import chat from './chat';

import mainApi from 'api';
import snackbar from 'snackbar-utils';

export const RESET_STATE_ACTION_TYPE = 'RESET_STATE_ACTION_TYPE';
export const resetStateAction = createAction(RESET_STATE_ACTION_TYPE, () => ({ payload: null }));

export const rtkQueryError = (history) => (api) => (next) => (action) => {
    // eslint-disable-next-line no-unused-vars
    const { getState, dispatch } = api;

    if (isRejectedWithValue(action)) {
        const currentPath = history?.location?.pathname;
        const { status, data } = action?.payload ?? {};
        const { endpointName } = action?.meta?.arg ?? {};

        if (status === 401) {
            if (
                currentPath !== AUTH_PATHS.LOGIN &&
                !['getMe', 'updateMyPass'].includes(endpointName)
            ) {
                snackbar.error(data?.message ?? 'You do not seem to be logged in.');
                history.push(AUTH_PATHS.LOGOUT);
            }
        }
    }
    return next(action);
};

const createMiddleware = (history) => {
    return [mainApi.middleware, rtkQueryError(history)];
};

const reducers = {
    counter,
    ui,
    chat,

    [mainApi.reducerPath]: mainApi.reducer,
};

const combinedReducer = combineReducers(reducers);

export const rootReducer = (state, action) => {
    if (action.type === RESET_STATE_ACTION_TYPE) state = {};
    return combinedReducer(state, action);
};

const createStore = (history) =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(createMiddleware(history)),
    });

export { actions as counterActions, selectors as counterSelectors } from './test';
export { actions as uiActions, selectors as uiSelectors } from './ui';
export { actions as chatActions, selectors as chatSelectors } from './chat';

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)

export default createStore;
