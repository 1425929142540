import { format } from 'date-fns';
import { Link, useRouteMatch } from 'react-router-dom';
import { Stack, Typography, Paper, Button, Box } from '@mui/material';
import { Tag } from 'common';
import { Group } from 'components/Program';
import { useRoles } from 'hooks';

function Block({ data, disableEdit }) {
    const match = useRouteMatch();
    const { isAdmin } = useRoles();

    return (
        <Paper elevation={3} sx={{ p: { xs: 1, md: 2 }, height: '100%' }}>
            <Stack
                direction={{
                    xs: 'column',
                    sm: 'row',
                }}
                spacing={1}
                alignItems="flex-start"
                justifyContent="space-between"
            >
                <div>
                    <Stack
                        direction={{
                            xs: 'column',
                            sm: 'row',
                        }}
                        alignItems={{
                            xs: 'flex-start',
                            sm: 'center',
                        }}
                        spacing={{
                            xs: 1 / 2,
                            sm: 2,
                        }}
                        sx={{ color: 'text.secondary', pl: '3px', mb: 1 }}
                    >
                        <Stack direction="row" alignItems="center">
                            <i className="far fa-calendar fa-xs fa-mr-1" />
                            <Typography variant="body2" lineHeight="1em">
                                {format(new Date(data?.startDate), 'd MMM. Y')} -{' '}
                                {format(new Date(data?.endDate), 'd MMM. Y')}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                            <Box
                                component="span"
                                lineHeight="1em"
                                color={data?.paid ? undefined : 'error.dark'}
                            >
                                <i className="fas fa-coins fa-xs fa-mr-1" />
                            </Box>
                            <Typography
                                variant="body2"
                                lineHeight="1em"
                                color={data?.paid ? undefined : 'error.dark'}
                            >
                                {data?.credits} credits
                            </Typography>
                        </Stack>
                    </Stack>
                    {isAdmin && (
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1 / 2}
                            sx={{ pl: '2px' }}
                        >
                            {!disableEdit && (
                                <Tag
                                    size="small"
                                    bgcolor={`${data?.active ? 'success' : 'warning'}.main`}
                                >
                                    {data?.active ? 'Active' : 'Inactive'}
                                </Tag>
                            )}
                            <Tag size="small" disabled={!data?.hasRPE}>
                                RPE
                            </Tag>
                            <Tag size="small" disabled={!data?.hasIntensity}>
                                Intensity
                            </Tag>
                        </Stack>
                    )}
                </div>
                {!disableEdit && (
                    <Button
                        variant="outlined"
                        size="small"
                        color="warning"
                        component={Link}
                        to={`${match?.url}/${data._id}/edit`}
                    >
                        Edit
                    </Button>
                )}
            </Stack>
            <Stack sx={{ mt: 1.5 }} spacing={2}>
                {(data?.groups ?? []).map((v) => (
                    <Group data={v} key={v._id} />
                ))}
            </Stack>
        </Paper>
    );
}

export default Block;
