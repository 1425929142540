import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

function useAutoNotify(error, isSuccess, successMessage = 'Action successful.') {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error?.data?.message ?? 'There has been an error.', {
                variant: 'error',
            });
        }
    }, [enqueueSnackbar, error]);

    useEffect(() => {
        if (isSuccess) enqueueSnackbar(successMessage);
    }, [enqueueSnackbar, isSuccess, successMessage]);
}

export default useAutoNotify;
