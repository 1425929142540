import { Fragment } from 'react';
import { Box, Stack, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import { ChatAvatar } from 'components/Chat';
import { useGetChatCounterpart, useMe } from 'hooks';
import { getAbbreviatedDistance } from 'helpers';
import { useSelector } from 'react-redux';
import { chatSelectors } from 'ducks';

function ChatsListItem({ data, selected, onConversationSelect }) {
    const me = useMe();
    const counterpart = useGetChatCounterpart(data);
    const unread = !!data?.unreadMessages;
    const latestMessage = data?.messages?.[0];
    const showNew = useSelector(chatSelectors.getShowNew);
    const isMyMessage = latestMessage?.sentBy === me?._id;

    return (
        <Fragment>
            <ListItem
                button
                sx={{
                    my: 1 / 2,
                    borderRadius: 1,

                    '&.Mui-selected': {
                        bgcolor: '#08f2',
                    },
                }}
                selected={selected && !showNew}
                onClick={onConversationSelect}
            >
                <ListItemAvatar>
                    <ChatAvatar name={counterpart?.name} />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Box>
                            {counterpart?.name}
                            {unread && (
                                <Box
                                    width={8}
                                    height={8}
                                    borderRadius={4}
                                    bgcolor="primary.main"
                                    display="inline-block"
                                    ml={3 / 4}
                                    position="relative"
                                    top={-1}
                                />
                            )}
                        </Box>
                    }
                    secondary={
                        latestMessage && (
                            <Stack direction="row" divider={<span>•</span>} spacing={1 / 2}>
                                <Box
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    color={unread ? 'primary.main' : undefined}
                                    fontStyle={
                                        latestMessage?.removed || !latestMessage?.text
                                            ? 'italic'
                                            : undefined
                                    }
                                >
                                    {isMyMessage && 'You: '}
                                    {latestMessage?.removed
                                        ? 'Message removed.'
                                        : latestMessage?.text ||
                                          (isMyMessage ? 'Sent files' : 'Received files')}
                                </Box>
                                <Box whiteSpace="nowrap">
                                    {getAbbreviatedDistance(latestMessage?.createdAt)}
                                </Box>
                            </Stack>
                        )
                    }
                    primaryTypographyProps={{
                        fontWeight: unread ? 'bold' : undefined,
                        fontSize: 'body1.fontSize',
                        color: unread ? 'grey.800' : 'common.black',
                    }}
                    secondaryTypographyProps={{
                        component: 'div',
                        color: '#0007',
                    }}
                />
            </ListItem>
        </Fragment>
    );
}

export default ChatsListItem;
