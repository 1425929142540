import * as yup from 'yup';

const emptyStringToNull = (parsed, orig) => (orig === '' ? null : parsed);

const imperialMassSchema = (required, min = 0) =>
    yup
        .number()
        // eslint-disable-next-line no-template-curly-in-string
        .min(min, 'This hast to be at least ${min}')
        .typeError('This must be a number')
        .nullable()
        .transform(emptyStringToNull)
        [required ? 'required' : 'optional']('This is required');

const massSchema = (required, min = 0) =>
    yup.object({
        kg: yup.mixed().when('$kg', {
            is: true,
            then: yup
                .number()
                .positive('This has to be greater than 0')
                .typeError('This must be a number')
                .nullable()
                .transform(emptyStringToNull)
                [required ? 'required' : 'optional']('This is required'),
        }),
        st: yup.mixed().when('$kg', {
            is: false,
            then: imperialMassSchema(required, min),
        }),
        lb: yup.mixed().when('$kg', {
            is: false,
            then: imperialMassSchema(required, min),
        }),
    });

const imperialLengthSchema = (required, min = 0) =>
    yup
        .number()
        // eslint-disable-next-line no-template-curly-in-string
        .min(min, 'This hast to be at least ${min}')
        .typeError('This must be a number')
        .nullable()
        .transform(emptyStringToNull)
        [required ? 'required' : 'optional']('This is required');

const lengthSchema = (required, min = 0) =>
    yup.object({
        cm: yup.mixed().when('$cm', {
            is: true,
            then: yup
                .number()
                .positive('This has to be greater than 0')
                .typeError('This must be a number')
                .nullable()
                .transform(emptyStringToNull)
                [required ? 'required' : 'optional']('This is required'),
        }),
        ft: yup.mixed().when('$cm', {
            is: false,
            then: imperialLengthSchema(required, min),
        }),
        in: yup.mixed().when('$cm', {
            is: false,
            then: imperialLengthSchema(required, min),
        }),
    });

export const validations = [
    // STEP 1
    yup.object({
        DOB: yup.date().required().typeError('Invalid date'),
        gender: yup.mixed().required('Select a gender'),
        sleepingHabits: yup.mixed().required('Select an option'),
        waterIntake: yup.mixed().required('Select an option'),
    }),

    // STEP 2
    yup.object({
        height: lengthSchema(true),
        weight: massSchema(true),
        leanBodyMass: massSchema(),
        fatMass: massSchema(),
        fatDistribution: massSchema(),
        bodyFatPercentage: yup
            .number()
            .positive('This has to be greater than 0')
            .max(100, 'This cannot be more than 100')
            .typeError('This must be a number')
            .nullable()
            .transform(emptyStringToNull),
        waist: lengthSchema(false, 1),
        hips: lengthSchema(false, 1),
        neck: lengthSchema(false, 1),
        chest: lengthSchema(false, 1),
        upperArms: lengthSchema(false, 1),
        thighs: lengthSchema(false, 1),
        calves: lengthSchema(false, 1),
    }),

    // STEP 3
    yup.object({}),

    // STEP 4
    yup.object({}),

    // STEP 5
    yup.object({}),

    // STEP 6
    yup.object({}),
];
